import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useTransition } from '@react-spring/web';
import { animated } from '@react-spring/web';
import useOnClickedOutside from 'src/views/hooks/useOnClickedOutside';

type Direction = 'down' | 'up';

export type DropdownContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  /** Whether the dropdown should be open */
  dropdownOpen: boolean;
  /** Callback to set the open status of the dropdown when clicked outside */
  setDropdownOpen?: (open: boolean) => void;
  /** Open direction */
  direction?: Direction;
  position?: string;
};

/** Container for a dropdown with show/hide animations */
const DropdownContainer: React.FC<DropdownContainerProps> = ({
  dropdownOpen,
  setDropdownOpen,
  direction = 'down',
  position = 'absolute',
  children,
  className,
  ...htmlProps
}) => {
  /** Close dropdown when clicked outside */
  const dropdownOpenRef = useRef(dropdownOpen);
  const timerRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    const timer = setTimeout(() => {
      dropdownOpenRef.current = dropdownOpen;
    }, 1);
    return () => {
      clearTimeout(timer);
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [dropdownOpen]);
  const dropdownRef = useOnClickedOutside<HTMLDivElement>(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    if (dropdownOpenRef.current) {
      timerRef.current = setTimeout(() => {
        setDropdownOpen?.(false);
      }, 2);
    }
  });

  /** Enter/Leave transition of dropdown */
  const transitions = useTransition(dropdownOpen, {
    from: { transform: 'scale(0.8)', opacity: 0 },
    enter: { transform: 'scale(1)', opacity: 1 },
    leave: { transform: 'scale(0.8)', opacity: 0 },
    config: { friction: 30, tension: 400, mass: 1 },
  });

  return (
    <div>
      {transitions(
        (style, item) =>
          item && (
            <animated.div
              ref={dropdownRef}
              className={clsx(
                'z-10 mt-2 bg-background rounded-lg shadow-lg overflow-hidden ring-1 ring-neutral-300',
                position,
                {
                  'top-full': direction === 'down',
                  'bottom-full': direction === 'up',
                },
                className,
              )}
              {...(htmlProps as Record<string, unknown>)}
              style={style as never}
            >
              {children}
            </animated.div>
          ),
      )}
    </div>
  );
};

export default DropdownContainer;
