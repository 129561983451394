/* eslint-disable no-param-reassign */
import { Vector } from 'src/services/math/types';
import { User } from 'src/types/conference';
import { BubbleRenderData, SessionRenderData } from 'src/types/conference/render/ConferenceViewTypes';
import { playSound } from '../audio/audioService';

const logChanges = false;

export const logSessionRenderData = (sessionRenderData: SessionRenderData): void => {
  console.log(
    `Session: ${JSON.stringify({
      users: sessionRenderData.users.map((u) => u.id),
      conversations: sessionRenderData.conversations.map((c) => {
        return { id: c.id, users: c.users.map((u) => u.id) };
      }),
    })}`,
  );
};

/** Generates a Bubble from User and possible conversation */
const getUserBubble = (user: User, conversationId: string | null, dimensions: Vector): BubbleRenderData => {
  return {
    pos: (user.isMe
      ? new Vector(dimensions.x / dimensions.y, 1)
      : new Vector((dimensions.x / dimensions.y) * 0.5, 0.5)
    ).add(Vector.random(0.01)),
    radius: 0.07 + 0.02 * Math.random(),
    scale: 1,
    content: user,
    conversationId,
    type: 'user',
  };
};

const getDisplayBubble = (user: User, conversationId: string | null, dimensions: Vector): BubbleRenderData => {
  return {
    pos: user.isMe ? new Vector(dimensions.x / dimensions.y, 1) : new Vector((dimensions.x / dimensions.y) * 0.5, 0.5),
    radius: 0.07 + 0.02 * Math.random(),
    scale: 1,
    content: user,
    conversationId,
    type: 'display',
  };
};

export const generateBubbles = (
  { users, conversations }: SessionRenderData,
  dimensions: Vector,
): BubbleRenderData[] => {
  const conversationOfUser: { [key: string]: string | null } = {};
  const userIds = users.map((u) => u.id);
  userIds.forEach((u) => {
    conversationOfUser[u] = conversations.find((c) => c.users.map((v) => v.id).includes(u))?.id ?? null;
  });
  const userBubbles: BubbleRenderData[] = users.map((u) => getUserBubble(u, conversationOfUser[u.id], dimensions));

  return userBubbles;
};

export const updateBubbles = (
  currentBubbles: BubbleRenderData[],
  { users: usersRenderData, conversations: conversationsRenderData }: SessionRenderData,
  { users: usersRenderDataNew, conversations: conversationsRenderDataNew }: SessionRenderData,
  dimensions: Vector,
): [BubbleRenderData[], boolean] => {
  // User Ids
  const users = usersRenderData.map((u) => u.id);
  const usersNew = usersRenderDataNew.map((u) => u.id);
  const myUser = usersRenderDataNew.find((u) => u.isMe)?.id;
  // User Id to user mapping
  const userForId: { [key: string]: User } = {};
  usersRenderData.forEach((u) => {
    userForId[u.id] = u;
  });
  const userForIdNew: { [key: string]: User } = {};
  usersRenderDataNew.forEach((u) => {
    userForIdNew[u.id] = u;
  });
  conversationsRenderData = conversationsRenderData.filter((c) => c.users.length > 1);
  conversationsRenderDataNew = conversationsRenderDataNew.filter((c) => c.users.length > 1);
  // Conversation Ids
  const conversations = conversationsRenderData.map((c) => c.id);
  const conversationsNew = conversationsRenderDataNew.map((c) => c.id);
  // Users in a conversations
  const usersInConversation = conversationsRenderData.flatMap((conversation) => conversation.users.map((u) => u.id));
  const usersInConversationNew = conversationsRenderDataNew.flatMap((conversation) =>
    conversation.users.map((u) => u.id),
  );
  // Users not in conversations
  const usersNotInConversation = users.filter((u) => !usersInConversation.includes(u));
  const usersNotInConversationNew = usersNew.filter((u) => !usersInConversationNew.includes(u));
  // User to conversation mapping
  const conversationOfUser: { [key: string]: string | null } = {};
  users.forEach((u) => {
    conversationOfUser[u] = conversationsRenderData.find((c) => c.users.map((v) => v.id).includes(u))?.id ?? null;
  });
  const conversationOfUserNew: { [key: string]: string | null } = {};
  usersNew.forEach((u) => {
    conversationOfUserNew[u] = conversationsRenderDataNew.find((c) => c.users.map((v) => v.id).includes(u))?.id ?? null;
  });
  // Users sharing display
  const usersSharingDisplay = usersRenderData.filter((u) => u.sharingDisplay).map((u) => u.id);
  const usersSharingDisplayNew = usersRenderDataNew.filter((u) => u.sharingDisplay).map((u) => u.id);
  // Users not sharing display
  const usersNotSharingDisplay = usersRenderData.filter((u) => !u.sharingDisplay).map((u) => u.id);
  const usersNotSharingDisplayNew = usersRenderDataNew.filter((u) => !u.sharingDisplay).map((u) => u.id);

  // Changes between the two session states
  // Conversations that did not exist before but exist now
  const conversationsAdded = conversationsNew.filter((u) => !conversations.includes(u));
  // Conversations that did exist before but do not exist now
  const conversationsRemoved = conversations.filter((u) => !conversationsNew.includes(u));
  // Users that are now in a conversation but were in no conversation before
  const usersEnteredConversation = usersInConversationNew.filter((u) => !usersInConversation.includes(u));
  // Users that are not in a conversation but were in a conversation before and are still in the session
  const usersLeftConversation = usersNotInConversationNew
    .filter((u) => users.includes(u))
    .filter((u) => usersNew.includes(u))
    .filter((u) => !usersNotInConversation.includes(u));
  // Users that were in a conversation before and are still in a conversation but its a different conversation
  const usersChangedConversation = usersInConversationNew
    .filter((u) => users.includes(u))
    .filter((u) => usersNew.includes(u))
    .filter((u) => usersInConversation.includes(u))
    .filter((u) => conversationOfUser[u] !== conversationOfUserNew[u]);
  // Users that were not in the session before but are in the session now
  const usersJoined = usersNew.filter((u) => !users.includes(u));
  // Users that were in the session before but are not in the session now
  const usersLeft = users.filter((u) => !usersNew.includes(u));
  const usersChangedStatus = users.filter((u) => userForId[u].status !== userForIdNew[u]?.status);
  // Users that did not share display before but are sharing display now
  const usersNowSharingDisplay = usersSharingDisplayNew.filter((u) => !usersSharingDisplay.includes(u));
  // Users that shared display before but are not not sharing display
  const usersNowNotSharingDisplay = usersNotSharingDisplayNew
    .filter((u) => users.includes(u))
    .filter((u) => usersNew.includes(u))
    .filter((u) => !usersNotSharingDisplay.includes(u));

  // Output changes
  if (logChanges) {
    console.log(`Users before: ${JSON.stringify(users)}`);
    console.log(`Users now: ${JSON.stringify(usersNew)}`);
    console.log(`Conversation of users before: ${JSON.stringify(conversationOfUser)}`);
    console.log(`Conversation of users now: ${JSON.stringify(conversationOfUserNew)}`);
    console.log(`Added conversations: ${conversationsAdded}`);
    console.log(`Removed conversations: ${conversationsRemoved}`);
    console.log(`Users joined: ${usersJoined}`);
    console.log(`Users left: ${usersLeft}`);
    console.log(`Users entered conversation: ${usersEnteredConversation}`);
    console.log(`Users left conversation: ${usersLeftConversation}`);
    console.log(`Users changed conversation: ${usersChangedConversation}`);
    console.log(`Users now sharing display: ${usersNowSharingDisplay}`);
    console.log(`Users now not sharing display: ${usersNowNotSharingDisplay}`);
  }

  // Bubble changes

  /** Indicator on if there was any change of the bubbles */
  const anyBubbleChange =
    usersJoined.length !== 0 ||
    usersLeft.length !== 0 ||
    usersChangedStatus.length !== 0 ||
    usersEnteredConversation.length !== 0 ||
    usersLeftConversation.length !== 0 ||
    usersChangedConversation.length !== 0 ||
    usersNowSharingDisplay.length !== 0 ||
    usersNowNotSharingDisplay.length !== 0;

  // Move my bubble to bottom right if not in a conversation
  if (anyBubbleChange && myUser && !conversationOfUserNew[myUser]) {
    const userBubble = currentBubbles.find((b) => b.content.id === myUser);
    if (userBubble) userBubble.pos = new Vector(dimensions.x / dimensions.y, 1);
  }

  // Add new users
  usersJoined.forEach((u) => {
    const user = userForIdNew[u];
    currentBubbles.push(getUserBubble(user, conversationOfUserNew[user.id], dimensions));
  });

  // Remove existing users
  usersLeft.forEach((u) => {
    currentBubbles = currentBubbles.filter((b) => b.content.id !== u);
  });

  // Add display bubbles when a user has a new display stream
  usersNowSharingDisplay.forEach((u) => {
    const user = userForIdNew[u];
    currentBubbles.push(getDisplayBubble(user, conversationOfUser[user.id], dimensions));
  });

  // Remove display bubbles when user stops sharing screen
  const removeBubbles: BubbleRenderData[] = [];
  usersNowNotSharingDisplay.forEach((u) => {
    const found = currentBubbles.find((b) => b.content.id === u && b.type === 'display');
    if (found) {
      removeBubbles.push(found);
    }
  });

  // Remove display bubbles when user leaves conversation
  usersLeftConversation.forEach((u) => {
    const found = currentBubbles.find((b) => b.content.id === u && b.type === 'display');
    if (found) {
      removeBubbles.push(found);
    }
  });

  currentBubbles = currentBubbles.filter((b) => {
    return !removeBubbles.includes(b);
  });

  // Soundeffect when entering/changing conversation
  if (usersEnteredConversation.concat(usersChangedConversation).find((u) => userForIdNew[u]?.isMe))
    playSound('sfx_enter_conversation');

  // Users entered conversation
  usersEnteredConversation.concat(usersChangedConversation).forEach((u) => {
    const conversation = conversationOfUserNew[u];
    let conversationBubbles = currentBubbles.filter(
      (b) => conversationOfUserNew[b.content.id] === conversation && b.content.id !== u,
    );
    const userBubble = currentBubbles.find((b) => b.content.id === u);
    if (userBubble && conversationBubbles.length > 0) {
      // Position to closest bubble(s)
      conversationBubbles = conversationBubbles.sort(
        (b1, b2) => Vector.subtract(b1.pos, userBubble.pos).length() - Vector.subtract(b2.pos, userBubble.pos).length(),
      );
      if (conversationBubbles.length > 1) {
        const center = Vector.multiply(0.5, Vector.add(conversationBubbles[0].pos, conversationBubbles[1].pos));
        const direction = Vector.subtract(conversationBubbles[1].pos, conversationBubbles[0].pos).add(
          Vector.random(0.01),
        );
        let perpendicularDirection = new Vector(-direction.y, direction.x);
        const directionToCenter = Vector.subtract(userBubble.pos, center);
        const dot = perpendicularDirection.dot(directionToCenter);
        if (dot < 0) perpendicularDirection = perpendicularDirection.scale(-1);
        perpendicularDirection = perpendicularDirection.divide(perpendicularDirection.length());
        perpendicularDirection = perpendicularDirection.scale(0.1);
        userBubble.pos = Vector.add(center, perpendicularDirection);
      }
      // If only 2 users in the conversation, move just one
      // TODO: Decide which bubble should be moved based on action
      else if (conversationBubbles[0].content.id < userBubble.content.id) {
        const direction = Vector.subtract(conversationBubbles[0].pos, userBubble.pos);
        userBubble.pos = Vector.add(userBubble.pos, Vector.multiply(0.9, direction));
      }
    } else {
      console.error(
        `Tried adding a joining user to a non existent conversation. User: ${u} Conversation: ${conversationOfUserNew[u]}`,
      );
    }
  });

  currentBubbles.forEach((b) => {
    const user = userForIdNew[b.content.id];
    b.content = user ?? b.content;
    b.radius = 1;
    if (b.content.status === 'Break') b.radius = 0.6;
    if (b.content.isMe) b.radius = 0.6;
    if (b.type === 'display') b.radius = 0.3;
    b.conversationId = conversationOfUserNew[b.content.id];
  });

  return [currentBubbles, anyBubbleChange];
};

export const canDragUser = (
  myBubble: BubbleRenderData | undefined,
  b1: BubbleRenderData | null,
  b2: BubbleRenderData,
): boolean => {
  if (!myBubble) return false;
  if (b1?.type !== 'user' || b2?.type !== 'user') return false;
  const canDrag =
    // Dragging someone
    b1 != null &&
    // Dragging me onto someone...
    ((b1.content.isMe &&
      !b2.content.isMe &&
      // ...that is currently not in my conversation.
      (b1.conversationId !== b2.conversationId || b2.conversationId === null) &&
      // ...and not on a break
      b2.content.status !== 'Break') ||
      // Dragging someone
      (!b1.content.isMe &&
        // ...that is currently not in a conversation
        b1.conversationId === null &&
        // ...and not on a break
        b1.content.status !== 'Break' &&
        // ...onto me
        (b2.content.isMe ||
          // ...or onto someone in my conversation
          (b2.conversationId !== null && b2.conversationId === myBubble?.conversationId))));
  return canDrag;
};

export const tryJoining = (
  myBubble: BubbleRenderData,
  b1: BubbleRenderData | null,
  b2: BubbleRenderData | null,
  join: (u1: User, u2: User) => void,
): boolean => {
  if (b1 && b1.content.status !== 'Break' && b2 && b2.content.status !== 'Break') {
    const meJoiningOther =
      b1.content.isMe && !b2.content.isMe && (b2.conversationId !== b1.conversationId || b2.conversationId === null);
    const otherJoiningMe =
      !b1.content.isMe &&
      b2.content.isMe &&
      b1.conversationId === null &&
      (b2.conversationId !== b1.conversationId || b2.conversationId === null);
    const otherJoiningMyConversation =
      !b1.content.isMe &&
      b1.conversationId === null &&
      b2.conversationId !== null &&
      b2.conversationId === myBubble.conversationId &&
      b2.conversationId !== b1.conversationId;
    if (meJoiningOther || otherJoiningMe || otherJoiningMyConversation) {
      join(b1.content, b2.content);
      return true;
    }
  }
  return false;
};
