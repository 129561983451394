import clsx from 'clsx';
import React, { useState } from 'react';
import { SpaceNameData } from 'src/services/forms/schemaService';
import { actions } from 'src/store/store';
import { Space } from 'src/types/spaces/SpaceType';
import { Contact } from 'src/types/users/Contact';
import PageIndicator from '../../core/pageindicator/PageIndicator';
import Center from '../../core/transform/Center';
import SpaceImageForm from '../forms/SpaceImageForm';
import SpaceNameForm from '../forms/SpaceNameForm';
import InviteUsersView from '../inivite/InviteUsersView';

export type CreateSpaceState = 'name' | 'image' | 'invite';
const pages: CreateSpaceState[] = ['name', 'image', 'invite'];

type Props = React.HTMLAttributes<HTMLDivElement> & {
  onDismiss: () => void;
  onCreatedSpace: (spaceId: number) => void;
};

const CreateSpaceView: React.FC<Props> = ({ onDismiss, onCreatedSpace, className, ...htmlProps }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [spaceNameData, setSpaceNameData] = useState<SpaceNameData>({ name: '', description: '' });
  const [spaceImageData, setSpaceImageData] = useState<number>(0);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(false);

  const onCreateSpace = async () => {
    setLoading(true);
    try {
      const result = await actions.createSpace({
        data: { ...spaceNameData, imageId: spaceImageData },
        usersToInvite: selectedContacts.map((c) => c.email),
      });
      setLoading(false);
      onCreatedSpace((result as unknown as { payload: Space }).payload.id);
      onDismiss();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Center col className={clsx('space-y-8', className)} {...htmlProps}>
      {/* Content depending on auth state */}
      <SpaceNameForm
        isModal={false}
        show={pages[pageIndex] === 'name'}
        onClose={onDismiss}
        data={spaceNameData}
        onChangedData={(v) => {
          setSpaceNameData(v);
          setPageIndex((i) => i + 1);
        }}
        formProps={{ submitTextKey: 'SHARED_BUTTON_NEXT' }}
      />
      <SpaceImageForm
        isModal={false}
        show={pages[pageIndex] === 'image'}
        data={spaceImageData}
        onChangedData={(v) => {
          setSpaceImageData(v);
          setPageIndex((i) => i + 1);
        }}
        onClose={onDismiss}
        onBack={() => setPageIndex((i) => i - 1)}
        submitTextKey="SHARED_BUTTON_NEXT"
      />
      <InviteUsersView
        isModal={false}
        show={pages[pageIndex] === 'invite'}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
        onClose={onDismiss}
        onBack={() => setPageIndex((i) => i - 1)}
        onSubmit={onCreateSpace}
        submitTextKey="SPACE_CREATE_TITLE"
        loading={loading}
      />
      <PageIndicator
        indicators={pages.map((_, index) => (pageIndex > index ? 'on' : pageIndex === index ? 'active' : 'off'))}
        clickableStates={['on']}
        textKey="SPACE_CREATE_TITLE"
        onSelectedPage={setPageIndex}
        className="hidden sm:block"
      />
    </Center>
  );
};

export default CreateSpaceView;
