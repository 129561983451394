import webpageDictionary from 'src/assets/localization/website_dictionary.json';

/* eslint-disable max-len */
export const localDictionary = {
  timestamp: 0,
  language: 'en',
  dictionary: {
    SHARED_BUTTON_SIGN_UP: 'Sign Up',
    SHARED_BUTTON_LOGIN: 'Login',
    SHARED_BUTTON_SIGNOUT: 'Sign out',
    SHARED_BUTTON_CLOSE: 'Close',
    SHARED_BUTTON_SAVE: 'Save',
    SHARED_BUTTON_BACK: 'Back',
    SHARED_BUTTON_EDIT: 'Edit',
    SHARED_BUTTON_NEXT: 'Next',
    SHARED_BUTTON_CANCEL: 'Cancel',
    SHARED_BUTTON_CHANGE_IMAGE: 'Change Image',
    SHARED_BUTTON_UPLOAD_IMAGE: 'Upload Image',
    SHARED_BUTTON_REMOVE: 'Remove',
    SHARED_BUTTON_INVITE: 'Invite',
    SHARED_BUTTON_ADD: 'Add',
    SHARED_BUTTON_LEAVE_SPACE: 'Leave Space',
    SHARED_BUTTON_DELETE_SPACE: 'Delete Space',
    SHARED_BUTTON_LEAVE_CREW: 'Leave Crew',
    SHARED_BUTTON_DELETE_CREW: 'Delete Crew',
    SHARED_BUTTON_CREATE: 'Create',
    SHARED_BUTTON_CONFIRM: 'Confirm',
    SHARED_BUTTON_RETURN_TO_LOGIN: 'Return to Login',
    SHARED_BUTTON_SUBMIT: 'Submit',
    SHARED_BUTTON_DECLINE: 'Decline',
    SHARED_BUTTON_ACCEPT: 'Accept',
    SHARED_LIST_EMPTY: 'This list is empty!',
    SHARED_TEXT_SPACE: 'Space',
    SHARED_TEXT_SPACES: 'Spaces',
    SHARED_TEXT_SPACE_MEMBERS: 'Space Members',
    SHARED_TEXT_MEMBERS: 'Members',
    SHARED_TEXT_ERROR: 'Error',
    SHARED_TEXT_RETRY: 'Retry',
    SHARED_TEXT_OKAY: 'Okay',
    SHARED_TEXT_INVALID: 'invalid',
    SHARED_TEXT_REQUIRED: 'required',
    SHARED_TEXT_BY: 'by',
    SHARED_TEXT_OF: 'of',
    SHARED_TEXT_PROFILE_FORM_HELP:
      'Please enter your full name as well as the name that should be displayed to other users.',
    SHARED_TEXT_DEFAULT_DISCARD_WARNING: 'Do you want to discard your changes without saving?',
    SHARED_TEXT_LOCALIZATION_FORM_HELP: 'Please choose your location and your preferred language.',
    SHARED_TEXT_MAX_LENGTH_WARNING: '{0} characters left',
    SHARED_TEXT_DRAG_AND_DROP_UPLOAD_IMAGE: 'Drag-and-drop to upload a custom image',
    SHARED_TEXT_CUSTOM_IMAGE: 'Custom image',
    SHARED_TEXT_IMAGE_UPLOAD_RESTRICTIONS: 'Formats: jpeg or png. Max. file size: 10 MB.',
    SHARED_TITLE_INFO: 'Info',
    SHARED_LABEL_TERMS_OF_USE: 'Terms of Use',
    SHARED_ROLE_ADMIN: 'Admin',
    SHARED_ROLE_MEMBER: 'Member',
    SHARED_ROLE_OWNER: 'Owner',
    SHARED_ROLE_INVITED: 'Invited',
    SHARED_ERROR_UNKNOWN_ERROR: 'Unknown error occured',
    SHARED_ERROR_NETWORK_ERROR: 'No server connection',
    SHARED_ERROR_API_ERROR: 'The requested action could not be completed.',
    SHARED_TITLE_ACCOUNT: 'Account',
    SHARED_TEXT_EMAIL: 'E-Mail',
    AUTH_SCREEN_TABLET_WELCOME_TITLE: 'Welcome to',
    AUTH_SCREEN_PASSWORD_PLACEHOLDER: 'Password',
    AUTH_SCREEN_CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm Password',
    AUTH_SCREEN_FORGOT_PASSWORD: 'Forgot your password?',
    AUTH_SCREEN_LOGIN_NO_ACCOUNT: 'No account yet?',
    AUTH_SCREEN_SIGNUP_HAVE_ACCOUNT: 'Already have an account?',
    AUTH_SCREEN_SIGNUP_CREATE_ACCOUNT: 'Create Account',
    AUTH_SCREEN_SENDING_EMAIL: 'Sending e-mail...',
    AUTH_SCREEN_EMAIL_SENT: 'E-mail sent',
    AUTH_SCREEN_VERIFY_EMAIL: 'Verify your e-mail',
    AUTH_SCREEN_CONFIRMATION_LINK_SENT: 'We have sent a confirmation link to your email {0}',
    AUTH_SCREEN_CALL_TO_ACTION_FOLLOW_LINK: 'Please follow this link to complete your registration!',
    AUTH_SCREEN_DID_NOT_RECEIVE_LINK: "Didn't receive your link?",
    AUTH_SCREEN_RESEND_LINK: 'Resend Confirmation E-Mail',
    AUTH_SCREEN_SET_NEW_PASSWORD: 'Set New Password',
    AUTH_SCREEN_NEW_PASSWORD_WAS_SET: 'New password was set.',
    AUTH_SCREEN_RESET_PASSWORD: 'Reset Password',
    AUTH_SCREEN_CALL_TO_ACTION_ENTER_EMAIL_TO_RESET_PASSWORD:
      'Enter your e-mail below to receive a link to reset your password:',
    AUTH_SCREEN_VERIFIED: 'verified',
    AUTH_SCREEN_CALL_TO_ACTION_ENTER_VERIFICATION_CODE:
      'Enter the code that we have sent to your e-mail {0} and choose a new password:',
    AUTH_SCREEN_PLACEHOLDER_VERIFICATION_CODE: 'Verification Code (6 digits)',
    AUTH_SCREEN_PLACEHOLDER_NEW_PASSWORD: 'New Password',
    AUTH_SCREEN_ERROR_INCORRECT_CREDENTIALS: 'Incorrect username or password.',
    AUTH_SCREEN_ERROR_INVALID_VERIFICATION_CODE: 'Verification Code invalid.',
    AUTH_SCREEN_ERROR_ATTEMPT_LIMIT_EXCEEDED: 'Attempt limit exceeded, please try again later.',
    AUTH_SCREEN_ERROR_USERNAME_EXISTS: 'Account with this e-mail already exists.',
    AUTH_SCREEN_ERROR_INVALID_PASSWORD: 'Invalid password.',
    OVERVIEW_SCREEN_YOUR_SPACES: 'Your Spaces',
    OVERVIEW_SCREEN_CREATE_CREW_BUTTON_LABEL: 'Create a Crew',
    OVERVIEW_SCREEN_EMPTY_SPACES_LIST: 'No spaces here. Create or join a space!',
    PROFILE_OPTIONS_INVITATIONS: 'Invitations',
    PROFILE_OPTIONS_SUBSCRIPTIONS: 'Subscriptions',
    PROFILE_OPTIONS_SETTINGS: 'Settings',
    AUTH_SIGNUP_VALIDATION_LENGTH: 'min. 8 characters',
    AUTH_SIGNUP_VALIDATION_CHARACTERS: 'min. 1 lowercase and uppercase letter, 1 number and 1 special character',
    ENTER_SPACE_BUTTON_ENTER_SPACE: 'Enter Space',
    ENTER_SPACE_TEXT_USER_SELECTION_ALERT:
      'When entering the conference, you will be muted until joining a conversation',
    SPACE_MANAGEMENT_TITLE_INVITE_USERS: 'Invite Users',
    SPACE_MANAGEMENT_TITLE_ADD_USERS: 'Add Users',
    SPACE_MANAGEMENT_TITLE_EDIT_USER: 'Edit User',
    SPACE_MANAGEMENT_TITLE_SELECT_IMAGE: 'Select an Image',
    PROFILE_SETTINGS_TITLE: 'Profile Settings',
    PROFILE_SETTINGS_TITLE_NAME: 'Name',
    PROFILE_SETTINGS_TITLE_CHANGE_AVATAR: 'Change Profile Image',
    SPACE_IMAGES_TITLE_CHANGE_IMAGE: 'Upload a Space image',
    PROFILE_SETTINGS_PLACEHOLDER_FIRST_NAME: 'First Name',
    PROFILE_SETTINGS_PLACEHOLDER_LAST_NAME: 'Last Name',
    PROFILE_SETTINGS_PLACEHOLDER_DISPLAY_NAME: 'Display Name',
    PROFILE_SETTINGS_PLACEHOLDER_SEARCH_INVITE: 'Search or invite via e-mail',
    PROFILE_SETTINGS_TITLE_LOCATION_AND_LANGUAGE: 'Location and Language',
    PROFILE_SETTINGS_LABEL_LOCATION: 'Location',
    PROFILE_SETTINGS_LABEL_LANGUAGE: 'Language',
    PROFILE_SETTINGS_INFO_TEXT_FULL_NAME:
      'We are using your First name and Last Name on the bills from your subscriptions. They are not displayed publicly to any other user.',
    PROFILE_SETTINGS_INFO_TEXT_LOCALIZATION:
      'We are using your Location to determine the currency for your subscriptions. Language determines the language settings of this app. It does not affect any functionality or payments.',
    PROFILE_INVITES_TITLE: 'Invitations',
    PROFILE_INVITES_OPEN_PLURAL: 'You have {0} open invitations.',
    PROFILE_INVITES_OPEN_SINGULAR: 'You have {0} open invitation.',
    PROFILE_SPACE_INVITES_TITLE: 'Space Invites',
    PROFILE_SPACE_INVITES_EMPTY_LIST: 'No open Space invites.',
    PROFILE_CREW_INVITES_TITLE: 'Crew Invites',
    PROFILE_CREW_INVITES_EMPTY_LIST: 'No open Crew invites.',
    PROFILE_SETTINGS_NOTIFICATIONS: 'Notifications',
    PROFILE_SETTINGS_EMAIL_NOTIFICATIONS: 'E-Mail Notifications',
    SPACE_CREATE_DESCRIPTION_INFO: 'Add a description that will be displayed to everyone in your space.',
    SPACE_CREATE_TITLE: 'Create Space',
    SPACE_CREATE_LABEL_SPACE_NAME: 'Space Name',
    SPACE_CREATE_LABEL_DESCRIPTION: 'Description (optional)',
    SPACE_CREATE_LABEL_INVITE_USERS: 'Invite Users',
    SPACE_ADD_USERS: 'Add Users',
    SPACE_ADD_SPACES_TO_USER_TITLE: 'Add Space Access',
    SPACE_REMOVE_USER: 'Remove user from space',
    SPACE_DELETE_CONFIRM: 'Do you really want to delete this space?',
    SPACE_LEAVE_CONFIRM: 'Do you really want to leave this space?',
    SPACE_EDIT_CONFIRM: 'Do you want to cancel editing the space?',
    SPACE_CREATE_CONFIRM: 'Do you want to cancel creating the space?',
    SPACE_TOKEN_REGENERATE_CONFIRM: 'Your current invitation link will be deactivated. Continue?',
    SPACE_MEMBERS_ONLINE: 'Members online:',
    CREW_CREATE_TITLE: 'Create Crew',
    CREW_CREATE_LABEL_CREW_NAME: 'Crew Name',
    CREW_CREATE_LABEL_DESCRIPTION: 'Description (optional)',
    CREW_CREATE_DESCRIPTION_INFO: 'Add a description that will be displayed to everyone in your crew.',
    CREW_REMOVE_USER: 'Remove user from crew',
    CREW_USER_SPACE_ACCESS: '{0} has access to the following spaces of {1}',
    CREW_USER_ADD_SPACES: 'Select Crew Spaces to which {0} should get access to:',
    CREW_SPACE_ADD_USERS: 'Select Crew Members which should be added to {0}:',
    CREW_DELETE_CONFIRM: 'Do you really want to delete this crew?',
    CREW_LEAVE_CONFIRM: 'Do you really want to leave this crew?',
    CREW_EDIT_CONFIRM: 'Do you want to cancel editing the crew?',
    CREW_CREATE_CONFIRM: 'Do you want to cancel creating the crew?',
    CREW_MANAGEMENT_CREW_SPACES_TITLE: 'Crew Spaces',
    CREW_MANAGEMENT_CREW_MEMBERS_TITLE: 'Crew Members',
    CONTACTS_SEARCH_CREW_MEMBER: 'Search crew members',
    TERMS_OF_USE_TITLE: 'Terms of Use',
    PRIVACY_POLICY_TITLE: 'Privacy Policy',
    TERMS_OF_USE_CONFIRM_STATEMENT: 'I have read and agree to the Terms of Use',
    ONBOARDING_WELCOME_TITLE: 'Welcome in Space',
    ONBOARDING_WELCOME_TEXT_CONFIRMATION: 'Thank you for confirming your e-mail.',
    ONBOARDING_WELCOME_TEXT_CALL_TO_ACTION: 'Complete your profile to start collaborating on the next level!',
    ONBOARDING_WELCOME_BUTTON_START: 'Get Started',
    ONBOARDING_TERMS_INFO: 'To use Nevolane Space, you must read and agree to our Terms of Use.',
    SUBSCRIPTION_CURRENT_INFO: 'Your current subscription:',
    SUBSCRIPTION_TITLE: 'Subscription',
    SUBSCRIPTION_PERSONAL_CHOOSE: 'Choose your subscription',
    SUBSCRIPTION_CREW_CHOOSE: 'Choose your crew’s size',
    SUBSCRIPTION_P0_TITLE: 'Free Tier',
    SUBSCRIPTION_P1_TITLE: 'Starter',
    SUBSCRIPTION_P2_TITLE: 'Advanced',
    SUBSCRIPTION_P3_TITLE: 'Premium',
    SUBSCRIPTION_P0_FEATURE1: 'up to 3 spaces',
    SUBSCRIPTION_P0_FEATURE2: 'max. 5 members per Space',
    SUBSCRIPTION_P0_FEATURE3: '40 hours of space time per month',
    SUBSCRIPTION_P1_FEATURE1: 'up to 10 spaces',
    SUBSCRIPTION_P1_FEATURE2: 'max. 10 members per Space',
    SUBSCRIPTION_P1_FEATURE3: '100 hours of space time per month',
    SUBSCRIPTION_P2_FEATURE1: 'up to 25 spaces',
    SUBSCRIPTION_P2_FEATURE2: 'max. 15 members per Space',
    SUBSCRIPTION_P2_FEATURE3: 'unlimited time in spaces',
    SUBSCRIPTION_C1_TITLE: 'Starter',
    SUBSCRIPTION_C2_TITLE: 'Advanced',
    SUBSCRIPTION_C3_TITLE: 'Premium',
    SUBSCRIPTION_C1_FEATURE1: 'up to 10 members',
    SUBSCRIPTION_C1_FEATURE2: 'max. 5 members per Space',
    SUBSCRIPTION_C2_FEATURE1: 'up to 25 members',
    SUBSCRIPTION_C2_FEATURE2: 'max. 10 members per Space',
    SUBSCRIPTION_C3_FEATURE1: 'up to 50 members',
    SUBSCRIPTION_C3_FEATURE2: 'max. 15 members per Space',
    SUBSCRIPTION_CANCEL: 'Cancel subscription',
    SUBSCRIPTION_REACTIVATE: 'Reactivate subscription',
    SUBSCRIPTION_DOWNGRADE_CANCEL: 'Cancel downgrade',
    SUBSCRIPTION_PAYMENTS_TITLE: 'Payments',
    SUBSCRIPTION_PAYMENTS_NEXT: 'Next Payment',
    SUBSCRIPTION_PAYMENTS_NONE: 'No payments scheduled.',
    SUBSCRIPTION_PAYMENTS_METHOD: 'Payment Method',
    SUBSCRIPTION_PAYMENTS_METHOD_NONE: 'no payment method',
    SUBSCRIPTION_INVOICES_TITLE: 'Invoices',
    SUBSCRIPTION_INVOICES_INVOICE: 'Invoice',
    SUBSCRIPTION_PRICE_FREE: 'Free',
    SUBSCRIPTION_DOWNGRADE_INFO: 'Downgrade to {0} on {1}.',
    SUBSCRIPTION_TAG_MOST_POPULAR: 'Most Popular',
    SUBSCRIPTION_DOWNGRADE_CONFIRM_TITLE: 'Change subscription',
    SUBSCRIPTION_DOWNGRADE_PERIOD_CONFIRM_TITLE: 'Change billing period',
    SUBSCRIPTION_CANCEL_CONFIRM_TITLE: 'Cancel subscription',
    SUBSCRIPTION_CANCEL_PENDING_DOWNGRADE: 'Upgrade subscription',
    SUBSCRIPTION_PENDING_DOWNGRADE_DESCRIPTION:
      'Before choosing a new subscription, please confirm that you want to cancel your currently scheduled cancellation. We will then forward you directly to the upgrade.',
    SUBSCRIPTION_DOWNGRADE_CONFIRM_DESCRIPTION:
      'Do you really want to change to {0}? This change will take effect at the end of your current billing period.',
    SUBSCRIPTION_DOWNGRADE_CONFIRM_PERIOD_DESCRIPTION:
      'Do you really want to change to monthly billing? This change will take effect at the end of your current billing period.',
    SUBSCRIPTION_UPGRADE_CONFIRM_TITLE: 'Upgrade',
    SUBSCRIPTION_UPGRADE_CONFIRM_DESCRIPTION: 'We will forward you to Stripe, our partner for secure payments.',
    SUBSCRIPTION_INCLUDES_TEXT: 'Your new subscription includes:',
    SUBSCRIPTION_WELCOME: 'Welcome to your new subscription!',
    IMPRESSUM_TITLE: 'Imprint',
    IMPRESSUM_PATENT_PENDING: 'Patent pending',
    IMPRESSUM_TEXT_MANAGING_DIRECTOR: 'Managing Director',
    IMPRESSUM_TEXT_LICENSES: 'Licenses',
    IMPRESSUM_ADDRESS_COUNTRY: 'Switzerland',
    CHANGE_PASSWORD_TITLE: 'Change Password',
    CHANGE_PASSWORD_LABEL_NEW_PASSWORD: 'New Password',
    CHANGE_PASSWORD_LABEL_OLD_PASSWORD: 'Old Password',
    CHANGE_PASSWORD_CALL_TO_ACTION: 'Enter your new password below:',
    CHANGE_PASSWORD_ERROR_WRONG_OLD_PASSWORD: 'Wrong Password',
    CHANGE_PASSWORD_LABEL_CHANGING_PASSWORD: 'Changing Password...',
    PERMISSION_REQUEST_CAMERA_MICROPHONE: 'Please enable microphone and camera access in the device settings',
    MICROPHONE_DEVICE_NOT_DETECTED: 'No microphone detected. Connect one and make sure permissions are enabled.',
    SUBSCRIPTION_RESTRICTION_MAX_SPACES_INVITE: 'max spaces',
    LOBBY_NOT_FOUND_TITLE: 'Space Not Found',
    LOBBY_NOT_FOUND_DESCRIPTION:
      "The Space you were looking for does not exist. This link may have been invalidated by the Space's owner.",
    LOBBY_SCREEN_WAITING_FOR_OWNER: 'Waiting for host to start the space...',
    LOBBY_SCREEN_WAITING_FOR_OWNER_DESCRIPTION: 'This Space will be accessible when the host is present.',
    LOBBY_SCREEN_HOST_LEFT: 'The Host has closed this Space.',
    LOBBY_SCREEN_HOST_LEFT_DESCRIPTION:
      'Create your own Nevolane Space account for free to host Spaces and collaborate on the next level!',
    LOBBY_TITLE: 'Hi, there!',
    LOBBY_DESCRIPTION: "You're about to enter {0} as a guest.",
    LOBBY_ENTER_NAME: 'Enter your name:',
    LOBBY_ENTER_AS_GUEST: 'Enter as a guest',
    LOBBY_OR_LOGIN: 'Or login to your account:',
    SHARED_TEXT_PUBLIC: 'Public',
    SHARED_TEXT_PUBLIC_ACCESS: 'Public Access',
    PUBLIC_SPACE_LINK_INFO: 'Anyone can enter this spce with the following link:',
    PUBLIC_SPACE_LINK_TIP:
      'Tip: To revoke access to your public space, create a new link. This will invalidate the current link.',
    PUBLIC_SPACE_INVITATION_LINK: 'Invitation link',
    PUBLIC_SPACE_LINK: 'Link',
    PUBLIC_SPACE_REGENERATE_LINK: 'Create new link',
    PUBLIC_SPACE_START: 'Start Public Space',
    BETA_PHASE_TITLE: 'Beta Phase',
    BETA_PHASE_TITLE_LABEL: 'upgraded during beta phase',
    BETA_PHASE_DESCRIPTION: 'Nevolane Space is currently in Beta. We have upgraded your free tier for a limited time.',
    BETA_PHASE_SPACE_LIMIT_TITLE: 'Limit reached',
    BETA_PHASE_SPACE_LIMIT_DESCRIPTION: 'You reached the space limit.',
    BETA_PHASE_SPACE_MEMBER_LIMIT_DESCRIPTION: 'You reached the maximum number of space members.',
    BETA_SUBSCRIPTION_TITLE: 'Free',
    BETA_SUBSCRIPTION_FEATURE1: 'Available Spaces: up to',
    BETA_SUBSCRIPTION_FEATURE2: 'Members per Space: up to',
    BETA_SUBSCRIPTION_PREMIUM: 'Premium',
    BETA_SUBSCRIPTION_AVAILABLE_SOON: 'available soon',
    TUTORIAL_TITLE: 'Introduction',
    TUTORIAL_SKIP: 'Skip Introduction',
    TUTORIAL_SPACES_SUBTITLE: "Let's do a quick tour to show you around!",
    TUTORIAL_SPACES_TEXT1: 'When you enter a Space, you can see each other as if you were all in the same room.',
    TUTORIAL_SPACES_TEXT2:
      'But unlike in a meeting, not just one of you is talking, while everyone else has to listen.',
    TUTORIAL_CONVERSATIONS_BUTTON_LABEL: 'Start a Conversation',
    TUTORIAL_CONVERSATIONS_TEXT1: 'To start a conversation, simply tap whoever you want to talk to!',
    TUTORIAL_CONVERSATIONS_TEXT2:
      "The other users in the Space can't hear you, unless they join your conversation, too.",
    TUTORIAL_UNAVAILABLE_BUTTON_LABEL: 'Take a Break',
    TUTORIAL_UNAVAILABLE_TEXT1: 'When you want to take a break or you are busy, set yourself to unavailable.',
    TUTORIAL_UNAVAILABLE_TEXT2: 'When you are unavailable, your camera and microphone will remain turned off.',
    TUTORIAL_CREATE_JOIN_SPACE_BUTTON_LABEL: 'Join a Space',
    TUTORIAL_CREATE_JOIN_SPACE_TEXT1: "To start, create a new Space or accept an invite to a friend's Space!",
    TUTORIAL_COMPLETE_BUTTON_LABEL: 'Get Started',
    TUTORIAL_ILLUSTRATION_LABEL_INVITES: 'Check your invites',
    TUTORIAL_ILLUSTRATION_LABEL_CREATE_SPACE: 'Create a new Space',
    TUTORIAL_SKIP_CONFIRM_TITLE: 'Skip Introduction?',
    TUTORIAL_SKIP_CONFIRM_TEXT: 'Are you sure that you want to skip the introduction?',
    ANNOUNCEMENT_TITLE_MAINTENANCE: 'Maintenance',
    CONFERENCE_STATUS_BREAK: 'Away',
    CONFERENCE_STATUS_ACTIVE: 'Active',
    CONFERENCE_CONNECTING: 'Connecting...',
    CONFERENCE_LEAVE: 'Leave',
    CONFERENCE_DROP_HERE: 'Drop here',
    CONFERENCE_TURN_CAMERA_ON: 'Enable camera',
    CONFERENCE_TURN_CAMERA_OFF: 'Disable camera',
    CONFERENCE_CHANGE_STATUS_AVAILABLE: 'To available',
    CONFERENCE_CHANGE_STATUS_UNAVAILABLE: 'To unavailable',
    CONFERENCE_SHARE_DISPLAY_ON: 'Share screen',
    CONFERENCE_SHARE_DISPLAY_OFF: 'Stop sharing',
    CONFERENCE_TURN_MIC_ON: 'Unmute',
    CONFERENCE_TURN_MIC_OFF: 'Mute',
    SHARED_TEXT_LOADING: 'Loading...',
    SHARED_TEXT_OR: 'or',
    SHARED_TEXT_FROM: 'from',
    SHARED_TEXT_VIEW: 'View',
    SHARED_TEXT_USERS: 'users',
    SHARED_TEXT_CHOOSE: 'Choose',
    SHARED_BUTTON_MANAGE: 'Manage',
    SHARED_BUTTON_DOWNLOAD: 'Download',
    SHARED_TEXT_MONTH: 'month',
    SHARED_TEXT_MONTHLY: 'Monthly',
    SHARED_TEXT_YEAR: 'year',
    SHARED_TEXT_YEARLY: 'Yearly',
    SHARED_TEXT_WELCOME_TITLE: 'Welcome to Nevolane Space',
    SHARED_TEXT_WELCOME_DESCRIPTION:
      "To get started, accept an invitation to another user's Space or create a Space yourself.",
    SETTINGS_DELETE_ACCOUNT: 'Delete Account',
    SETTINGS_DELETE_ACCOUNT_CONFIRM: 'Do you really want to delete your Account?',
    SETTINGS_DELETE_ACCOUNT_DESCRIPTION:
      'Deleting your account cannot be undone. You will loose all your spaces, contacts and your subscription.',
    SHARED_TEXT_QUIT: 'Quit',
    SHARED_TEXT_COPY_LINK: 'Copy Link',
    SHARED_TEXT_COPIED: 'Copied!',
    SPACE_INVITE_CTA_TITLE: 'Your new space',
    SPACE_INVITE_CTA_DESCRIPTION: 'To get started, invite some users to your new Space.',
    SHARED_TEXT_LETS_GO: "Let's go!",
    SHARED_TEXT_CURRENT: 'Current',
    SHARED_TEXT_CHANGE: 'Change',
    SHARED_TEXT_LOCKED: 'Locked',
    SHARED_TEXT_NOT_AVAILABLE: 'Not available',
    SPACE_LOCKED_DESCRIPTION: 'You have too many spaces. Remove unused spaces or upgrade your subscription.',
    SPACE_LOCKED_MEMBERS_DESCRIPTION:
      'Your subscription does not allow Spaces of this size. Remove some Space members or upgrade your subscription.',
    SPACE_LOCKED_OWNER_DESCRIPTION:
      'The owner does not have a sufficient subscription for a Space of this size. When the owner has upgraded their subscription, you will be able to continue using this Space.',
    ...webpageDictionary[0].dictionary,
  },
};

export type DictionaryData = typeof localDictionary;
export type DictionaryType = DictionaryData['dictionary'];
export type DictionaryKeyType = keyof DictionaryType;
