/**
 * Append entries to object
 * @param object1 The object to which values will be appended
 * @param property The property of the object to search
 * @param object2 The object from which values will be taken
 * @returns The new object with appended values
 */
export const append = <T,>(object1: T, property: keyof T, object2: T): T => {
  const newObject = { ...object1 };
  const originalEntries = object1[property];

  newObject[property] = { ...originalEntries, ...object2[property] };

  return newObject;
};

/**
 * Find the value in an array
 * @param arr The array in which the values will be checked
 * @param value The value to check for
 * @param property The property in the array of objects to match to
 * @returns The index of the value searched
 */
/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
export const findIndex = <T,>(arr: Array<T>, value: any, property: keyof T): number => {
  return arr.findIndex((entry) => entry[property] === value);
};

/**
 * Find the value in an array
 * @param arr The array in which the values will be checked
 * @param value The value to check for
 * @param property The property in the array of objects to match to
 * @returns The entry that was searched for
 */
export const findEntry = <T,>(arr: Array<T>, value: any, property: keyof T): T | undefined => {
  return arr.find((entry) => entry[property] === value);
};
