import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import UHText from '../text/UHText';
import Center from '../transform/Center';

export type IndicatorState = 'off' | 'on' | 'active';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  state: IndicatorState;
  text?: string;
  textKey?: DictionaryKeyType;
};

const PageIndicatorEntry: React.FC<Props> = ({ state, text, textKey, className, ...htmlProps }) => {
  const localizedText = useLocalizedValue(text, textKey);
  return (
    <Center
      className={clsx(
        'w-6 h-6 rounded-full',
        {
          'bg-primary ring-2 ring-background': state === 'active',
          'bg-primary': state === 'on',
          'bg-neutral-300': state === 'off',
          'cursor-pointer': htmlProps.onClick,
        },
        className,
      )}
      {...htmlProps}
    >
      {localizedText && (
        <UHText
          className={clsx({ 'text-typoInverted': ['active', 'on'].includes(state), 'text-neutral': state === 'off' })}
          variant="button-sm-medium"
          text={localizedText}
        />
      )}
    </Center>
  );
};

export default PageIndicatorEntry;
