export class StorageService {
  static syncPromise: Promise<void> | null = null;

  /**
   * This is used to set a specific item in storage
   */
  static setItem(key: string, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * This is used to get a specific key from storage
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getItem(key: string): any {
    const loadedObject = localStorage.getItem(key);
    if (loadedObject === null) return undefined;
    return JSON.parse(loadedObject);
  }

  /**
   * This is used to remove an item from storage
   */
  static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * This is used to clear the storage
   */
  static clear(): void {
    localStorage.clear();
  }
}
