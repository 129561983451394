import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import UHIcon from '../core/icon/UHIcon';
import UHText from '../core/text/UHText';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  icon?: string;
  title?: string;
  titleKey?: DictionaryKeyType;
  width?: 'sm' | 'default' | 'full';
  bottomPadding?: 'default' | 'none';
  onClickedTitle?: () => void;
};

const PageContainer: React.FC<Props> = ({
  icon,
  title,
  titleKey,
  width = 'default',
  bottomPadding = 'default',
  onClickedTitle,
  className,
  children,
  ...htmlProps
}) => {
  const localizedTitle = useLocalizedValue(title, titleKey);
  return (
    <div
      className={clsx(
        'relative pt-6 px-3 w-full sm:px-8',
        { 'max-w-6xl': width === 'default', 'max-w-2xl': width === 'sm', 'pb-20': bottomPadding === 'default' },
        className,
      )}
      {...htmlProps}
    >
      {(icon !== undefined || localizedTitle !== undefined) && (
        <div
          className={clsx('flex items-center mb-6 mt-5 w-max select-none', { 'cursor-pointer': onClickedTitle })}
          onClick={onClickedTitle}
        >
          {icon !== undefined && <UHIcon icon={icon} className="mr-4 text-primary-700 text-3xl" />}
          {localizedTitle !== undefined && (
            <UHText className="text-primary-700" variant="title-2xl" text={localizedTitle} />
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default PageContainer;
