import { UHHistory } from 'src/views/hooks/navigation/useUHHistory';

export const isExternalLink = (url: string): boolean => {
  const tmp = document.createElement('a');
  tmp.href = url;
  return tmp.host !== window.location.host;
};

export const openLink = (url: string, history: UHHistory | undefined = undefined, newTab = false): void => {
  const isExternal = isExternalLink(url);
  if (isExternal || newTab) {
    window.open(
      !isExternal ? `${window.location.protocol}//${window.location.host}${url}` : url,
      newTab ? '_blank' : '_self',
    );
  } else {
    history?.navigate(url);
  }
};
