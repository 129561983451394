import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import UHIcon from '../icon/UHIcon';
import UHText from '../text/UHText';
import Center from '../transform/Center';

export type DropdownButtonProps = React.HTMLAttributes<HTMLDivElement> & {
  icon?: string;
  title?: string;
  titleKey?: DictionaryKeyType;
  infoTitle?: string;
  infoTitleKey?: DictionaryKeyType;
  variant?: 'outline' | 'filled';
  size?: 'default' | 'small';
  disabled?: boolean;
};

const DropdownButton: React.FC<DropdownButtonProps> = ({
  icon,
  title,
  titleKey,
  infoTitle,
  infoTitleKey,
  variant = 'outline',
  size = 'default',
  disabled = false,
  className,
  ...htmlProps
}) => {
  const localizedTitle = useLocalizedValue(title, titleKey);
  const localizedInfoTitle = useLocalizedValue(infoTitle, infoTitleKey);

  return (
    <div
      className={clsx(
        'relative flex items-center pl-3 rounded-lg select-none',
        {
          'bg-neutral-700 text-typoInverted': variant === 'filled',
          'ring-1 ring-neutral-300': variant === 'outline',
          'h-14': infoTitleKey,
          'h-10': !infoTitleKey,
          'pr-6': size === 'default',
          'pr-3': size === 'small',
          'cursor-pointer': !disabled,
        },
        className,
      )}
      {...htmlProps}
    >
      {/* Info Title */}
      {infoTitleKey && (
        <UHText variant="body-xs" className="absolute left-3 top-1.5 text-neutral" text={localizedInfoTitle} />
      )}
      <div className={clsx('flex items-center', { 'pt-4': infoTitleKey })}>
        {/* Icon */}
        {icon && (
          <Center className="flex-shrink-0 mr-1 w-6">
            <UHIcon className={clsx('text-xl', { 'text-neutral': disabled })} icon={icon} />
          </Center>
        )}
        {/* Title */}
        <UHText
          variant="body"
          className={clsx('wrap line-clamp-1', { 'text-neutral': disabled })}
          text={localizedTitle}
        />
      </div>
      <div className="flex-1" />
      {/* Dropdown icon */}
      <UHIcon
        className={clsx('text-neutral-700 ', {
          'text-xl': size === 'default',
          'text-base': size === 'small',
          invisible: disabled,
        })}
        icon="chevron-down"
      />
    </div>
  );
};

export default DropdownButton;
