import clsx from 'clsx';
import React, { useState } from 'react';
import { animated, useTransition } from '@react-spring/web';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useCurrentBreakpoint } from 'src/views/hooks/useCurrentBreakpoint';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import { useUpdateEffect } from 'src/views/hooks/useUpdateEffect';
import UHIcon from '../core/icon/UHIcon';
import UHText from '../core/text/UHText';
import Center from '../core/transform/Center';

type TooltipPosition = 'top' | 'left' | 'right' | 'bottom';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  text?: string;
  textKey?: DictionaryKeyType;
  icon?: string;
  iconClass?: string;
  onHide?: () => void;
  onShow?: () => void;
  position?: TooltipPosition;
};

const UHTooltip: React.FC<Props> = ({
  text,
  textKey,
  icon,
  iconClass,
  onHide,
  onShow,
  position = 'top',
  className,
  children,
  ...htmlProps
}) => {
  const [mouseOver, setMouseOver] = useState(false);
  const localizedText = useLocalizedValue(text, textKey);

  const onMouseEnter = () => {
    setMouseOver(true);
    onShow?.();
  };

  const onMouseLeave = () => {
    setMouseOver(false);
  };

  const mobile = useCurrentBreakpoint() === 'xs';
  const show = localizedText && mouseOver && !mobile;

  // Call hide callback after mouse moved away
  useUpdateEffect(() => {
    let hideTimeout: NodeJS.Timeout | undefined;
    if (!show && onHide) {
      hideTimeout = setTimeout(onHide, 1000);
    }
    return () => {
      if (hideTimeout) clearTimeout(hideTimeout);
    };
  }, [show, onHide]);

  /** Enter/Leave transition of content */
  const transitionsContent = useTransition(show, {
    from: { transform: 'scale(1.05) translateY(0px)', opacity: 0 },
    enter: { transform: 'scale(1) translateY(0px)', opacity: 1 },
    leave: { transform: 'scale(1.05) translateY(0px)', opacity: 0 },
    config: { friction: 30, tension: 300, mass: 1 },
  });

  return (
    <div className={clsx('relative', className)} {...htmlProps}>
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {children}
      </div>
      {/* Anchor line */}
      <Center
        className={clsx('absolute z-20', {
          'bottom-full left-0 right-0 pb-2': position === 'top',
          'top-full left-0 right-0 pt-2': position === 'bottom',
          'left-full top-0 bottom-0 pl-2': position === 'right',
          'right-full top-0 bottom-0 pr-2': position === 'left',
        })}
      >
        {/* Tooltip */}
        {transitionsContent(
          (style, item) =>
            item && (
              <animated.div
                className={clsx('absolute flex px-2 py-1 w-max bg-black bg-opacity-70 rounded-lg pointer-events-none', {
                  'bottom-full': position === 'top',
                  'top-full': position === 'bottom',
                  'left-full': position === 'right',
                  'right-full': position === 'left',
                })}
                style={style as never}
              >
                {icon && (
                  <UHIcon
                    className={clsx(
                      'text-typoInverted select-none',
                      { 'mr-1.5': localizedText },
                      iconClass ?? 'text-2xl',
                    )}
                    icon={icon}
                  />
                )}
                <UHText className="text-center text-typoInverted" text={localizedText} />
              </animated.div>
            ),
        )}
      </Center>
    </div>
  );
};

export default UHTooltip;
