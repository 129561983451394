import clsx from 'clsx';
import React, { useState } from 'react';
import { Vector } from 'src/services/math/types';
import { BubbleRenderData } from 'src/types/conference/render/ConferenceViewTypes';
import { isDisplayStream } from 'src/types/conference/UserStreamType';
import Fade from 'src/views/components/animations/Fade';
import Scale from 'src/views/components/animations/Scale';
import VideoStreamView from 'src/views/components/core/video/VideoStreamView';
import useLayoutSize from 'src/views/hooks/useLayoutSize';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  bubble?: BubbleRenderData;
  open: boolean;
};

const BubbleDetailView: React.FC<Props> = ({ bubble, open, className, ...htmlProps }) => {
  // Container dimensions
  const [containerRef, containerDimensions] = useLayoutSize();
  containerDimensions.x = Math.max(containerDimensions.x, 1);
  containerDimensions.y = Math.max(containerDimensions.y, 1);

  // Content dimensions
  const [contentDimensions, setContentDimensions] = useState<Vector>(new Vector(16, 9));
  contentDimensions.x = Math.max(contentDimensions.x, 1);
  contentDimensions.y = Math.max(contentDimensions.y, 1);

  const videoStream = bubble?.content?.streams.find((val) => isDisplayStream(val))?.stream;

  // Scaled content dimensions
  const contentScale = Math.min(
    containerDimensions.x / contentDimensions.x,
    containerDimensions.y / contentDimensions.y,
  );
  const scaledContentDimensions = contentDimensions.scale(contentScale);

  return (
    <Fade
      speed="fast"
      distance={0}
      fadeTarget={open ? 1 : 0}
      className={clsx('rounded overflow-hidden', className)}
      {...htmlProps}
    >
      <Scale scale={open ? 1 : 0} speed="fast" className="pb-6 px-4 h-full">
        {/* Full canvas */}
        <div className="flex justify-center w-full h-full" ref={containerRef}>
          {/* Container with size of content */}
          <div
            className="transitionfix relative bg-background rounded-xl shadow-lg overflow-hidden"
            style={{ width: `${scaledContentDimensions.x}px`, height: `${scaledContentDimensions.y}px` }}
          >
            {/* Content */}
            {videoStream && (
              <VideoStreamView
                className="absolute inset-0"
                rounded={false}
                videoStream={videoStream}
                onChangedDimensions={setContentDimensions}
              />
            )}
          </div>
        </div>
      </Scale>
    </Fade>
  );
};

export default BubbleDetailView;
