import {
  Space,
  SpaceInviteType,
  SpaceMemberUpdateType,
  SpaceTokenRegenerateType,
  SpaceUpdateType,
} from 'src/types/spaces/SpaceType';
import { UHAPI } from '.';

export const getAllSpaces = async (): Promise<Space[]> => {
  const spaces = await UHAPI.get<Space[]>({ path: `/spaces` });
  spaces.sort((v1, v2) => v1.id - v2.id);
  return spaces;
};

export const getPublicSpace = async (token: string): Promise<Space> => {
  return UHAPI.get<Space>({ path: `/public/space/${token}` });
};

export const createPublicSpace = async (data: SpaceUpdateType): Promise<Space> => {
  return UHAPI.post<Space>({ path: `/public-space`, body: data });
};

export const regeneratePublicSpaceToken = async (data: SpaceTokenRegenerateType): Promise<Space> => {
  return UHAPI.patch<Space>({ path: `/public-space`, body: data });
};

export const inviteSpaceUsers = async (data: SpaceInviteType): Promise<Space> => {
  const { usersToInvite, spaceId } = data;
  const users = usersToInvite.map((email: string) => {
    return { email };
  });

  return UHAPI.post({ path: '/invites/spaces/send', body: { spaceId, users } });
};

export const createNewSpace = async (data: SpaceUpdateType): Promise<Space> => {
  return UHAPI.post<Space>({ path: `/spaces`, body: data });
};

export const updateSpace = async (id: number, data: SpaceUpdateType): Promise<Space> => {
  return UHAPI.patch({ path: `/spaces/${id}`, body: data });
};

export const removeSpace = async (spaceId: number): Promise<void> => {
  await UHAPI.del({ path: `/spaces/${spaceId}` });
};

export const updateSpaceUser = async (data: SpaceMemberUpdateType): Promise<void> => {
  UHAPI.patch<Space>({ path: `/spaces/${data.spaceId}/members/${data.memberId}`, body: { role: data.role } });
};

export const removeUserFromSpace = async (spaceId: number, memberId: number): Promise<void> => {
  return UHAPI.del({ path: `/spaces/${spaceId}/members/${memberId}` });
};

export const leaveSpace = async (spaceId: number): Promise<void> => {
  return UHAPI.post({ path: `/spaces/${spaceId}/leave` });
};

export const cancelInvite = async (inviteId: number): Promise<Space> => {
  return UHAPI.post({ path: `/invites/spaces/${inviteId}/cancel` });
};

export const addCrewSpaceUser = async (spaceId: number, crewMemberId: number): Promise<Space> => {
  return UHAPI.post({ path: `/spaces/${spaceId}/members`, body: { memberIds: [crewMemberId] } });
};

export const addCrewSpaceUsers = async (spaceId: number, crewMemberIds: number[]): Promise<Space | undefined> => {
  return UHAPI.post({ path: `/spaces/${spaceId}/members`, body: { memberIds: crewMemberIds } });
};

export const addCrewMemberToSpaces = async (memberId: number, spaceIds: number[]): Promise<Space[]> => {
  return UHAPI.post({ path: `/spaces/member/${memberId}`, body: { spaceIds } });
};

export const updateSpaceImage = async (id: number, imageBinary: string | ArrayBuffer | null): Promise<Space> => {
  return UHAPI.post({ path: `/space-image/${id}`, body: { imageBinary } });
};
