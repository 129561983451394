import React, { useEffect, useState } from 'react';
import { getPublicSpace } from 'src/services/api/spacesAPI';
import { Space } from 'src/types/spaces/SpaceType';
import UHText from 'src/views/components/core/text/UHText';
import { useQueryParam } from 'src/views/hooks/navigation/useQueryParam';
import Center from 'src/views/components/core/transform/Center';
import { useTryAutologin } from 'src/views/hooks/auth/useTryAutologin';
import LoadingScreen from '../Loading/LoadingScreen';
import EnterLobbyView from './components/EnterLobbyView';
import { allPages } from 'src/services/pages/pageService';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import UHButton from 'src/views/components/core/buttons/UHButton';

type LobbyState = 'loading-space' | 'not-found' | 'loaded';

const LobbyScreen: React.FC = () => {
  const [lobbyState, setLobbyState] = useState<LobbyState>('loading-space');
  const { isTryingAutologin, isAuthenticated } = useTryAutologin();
  const history = useUHHistory();

  const token = useQueryParam('id');

  const [space, setSpace] = useState<Space | undefined>();

  const loadSpace = async () => {
    if (!token) setLobbyState('not-found');
    else {
      setLobbyState('loading-space');
      try {
        setSpace(await getPublicSpace(token));
        setLobbyState('loaded');
      } catch (error) {
        setLobbyState('not-found');
      }
    }
  };

  useEffect(() => {
    loadSpace();
  }, []);

  useEffect(() => {
    // Redirect authenticated user to user media selection
    if (lobbyState === 'loaded' && isAuthenticated && space) {
      history.navigate(allPages.userMediaSelection.url.replaceAll(':spaceId', space.id.toString()), {
        state: {
          publicSpace: space,
          token,
        },
      });
    }
  }, [lobbyState, isTryingAutologin, isAuthenticated]);

  return (
    <Center className="main-gradient w-screen h-screen" col>
      {/* Loading */}
      {(isTryingAutologin || (isAuthenticated && lobbyState === 'loading-space')) && (
        <LoadingScreen className="flex-1 self-stretch" />
      )}
      {/* Enter lobby view */}
      {!isTryingAutologin && !isAuthenticated && lobbyState === 'loaded' && space && token && (
        <EnterLobbyView space={space} token={token} />
      )}
      {/* Space not found */}
      {!isTryingAutologin && lobbyState === 'not-found' && (
        <Center col>
          {/* Nevolane title */}
          <UHText
            className="-mt-24 mb-40 text-primary-700"
            variant="title-xl"
            text="Nevolane Space"
            onClick={() => history.pushPage('landing')}
          />
          <UHText className="mb-4 text-primary-700 text-6xl" text="404" />
          <UHText className="mb-12 text-primary-700" variant="body-lg" textKey="LOBBY_NOT_FOUND_TITLE" />
          <UHText className="mb-12 max-w-md text-center" textKey="LOBBY_NOT_FOUND_DESCRIPTION" />
          <UHButton titleKey="SHARED_BUTTON_BACK" onClick={() => history.pushPage('landing')} />
        </Center>
      )}
    </Center>
  );
};

export default LobbyScreen;
