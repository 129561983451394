import { CrewInviteType, Crew, CrewUpdateType, CrewCreateType, CrewMemberUpdateType } from 'src/types/crews/CrewType';
import { Space } from 'src/types/spaces/SpaceType';
import { UHAPI } from '.';

export const getAllCrews = async (): Promise<Crew[]> => {
  try {
    return await UHAPI.get<Crew[]>({ path: '/crews' });
  } catch (error) {
    return [];
  }
};

export const inviteCrewUsers = async (data: CrewInviteType): Promise<Crew> => {
  const { usersToInvite, crewId } = data;
  const users = usersToInvite.map((email: string) => {
    return { email };
  });

  return UHAPI.post({ path: 'invites/crews/send', body: { crewId, users } });
};

export const createNewCrew = async (data: CrewCreateType): Promise<Crew> => {
  return UHAPI.post({ path: '/crews', body: data });
};

export const updateCrew = async (id: number, data: CrewUpdateType): Promise<Crew> => {
  return UHAPI.patch({ path: `/crews/${id}`, body: data });
};

export const removeCrew = async (crewId: number): Promise<void> => {
  await UHAPI.del({ path: `/crews/${crewId}` });
};

export const updateCrewUser = async (data: CrewMemberUpdateType): Promise<void> => {
  await UHAPI.patch<Space>({ path: `/crews/${data.crewId}/members/${data.memberId}`, body: { role: data.role } });
};

export const removeUserFromCrew = async (crewId: number, memberId: number): Promise<void> => {
  await UHAPI.del({ path: `/crews/${crewId}/members/${memberId}` });
};

export const leaveCrew = async (crewId: number): Promise<void> => {
  return UHAPI.post({ path: `/crews/${crewId}/leave` });
};

export const cancelCrewInvite = async (inviteId: number): Promise<Crew> => {
  return UHAPI.post({ path: `/invites/crews/${inviteId}/cancel` });
};
