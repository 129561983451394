import React, { PropsWithChildren } from 'react';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import Center from 'src/views/components/core/transform/Center';

type Props = {
  icon: string;
};

export const FeatureCard: React.FC<PropsWithChildren<Props>> = ({ icon, children }) => {
  return (
    <div className="flex flex-col px-6 py-12 w-full bg-background rounded-xl border border-neutral-250 shadow-sm hover:shadow-md space-y-4 group transform hover:-translate-y-1 transition-all">
      <Center className="pt-2 pb-4">
        <UHIcon icon={icon} className="text-primary-300 text-4xl" />
      </Center>
      {children}
    </div>
  );
};
