import { Invites } from 'src/types/invites/Invite';
import { UHAPI } from '.';

export const fetchInvites = async (): Promise<Invites> => {
  return UHAPI.get<Invites>({ path: `/invites` });
};

export const respondToSpaceInvite = async (inviteId: number, accepted: boolean): Promise<Invites> => {
  return UHAPI.post({ path: `/invites/spaces/${inviteId}/${accepted ? 'accept' : 'reject'}` });
};

export const respondToCrewInvite = async (inviteId: number, accepted: boolean): Promise<Invites> => {
  return UHAPI.post({ path: `/invites/crews/${inviteId}/${accepted ? 'accept' : 'reject'}` });
};
