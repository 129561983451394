import clsx from 'clsx';
import { Formik } from 'formik';
import React from 'react';
import { OnboardingNameData, onboardingNameSchema } from 'src/services/forms/schemaService';
import FormikButton from 'src/views/components/core/buttons/FormikButton';
import FormikTextField from 'src/views/components/core/inputs/FormikTextField';
import UHText from 'src/views/components/core/text/UHText';
import { OnboardingState } from '../OnboardingScreen';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  setOnboardingState: (state: OnboardingState) => void;
  nameData: OnboardingNameData;
  setNameData: (data: OnboardingNameData) => void;
};

const OnboardingNameForm: React.FC<Props> = ({
  setOnboardingState,
  nameData,
  setNameData,
  className,
  ...htmlProps
}) => {
  const onSubmit = (values: OnboardingNameData) => {
    setNameData(values);
    setOnboardingState('terms');
  };

  return (
    <div className={clsx('flex flex-col items-center', className)} {...htmlProps}>
      <UHText variant="title-xl" className="mb-6 mt-4 text-primary" textKey="PROFILE_SETTINGS_TITLE_NAME" />
      <UHText className="mb-4" textKey="SHARED_TEXT_PROFILE_FORM_HELP" />
      {/* Form */}
      <Formik<OnboardingNameData>
        validateOnMount={true}
        initialValues={nameData}
        validationSchema={onboardingNameSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <div className="flex flex-col w-full">
              {/* Textfields */}
              <FormikTextField className="mb-10" name="firstName" labelKey="PROFILE_SETTINGS_PLACEHOLDER_FIRST_NAME" />
              <FormikTextField className="mb-10" name="lastName" labelKey="PROFILE_SETTINGS_PLACEHOLDER_LAST_NAME" />
              <FormikTextField
                className="mb-10"
                name="displayName"
                labelKey="PROFILE_SETTINGS_PLACEHOLDER_DISPLAY_NAME"
                onEnter={handleSubmit}
              />
              {/* Submit */}
              <FormikButton className="self-center" titleKey="SHARED_BUTTON_NEXT" />
            </div>
          );
        }}
      </Formik>
      {/* Back button */}
      <UHText className="mt-4 text-primary" textKey="SHARED_BUTTON_BACK" onClick={() => setOnboardingState('intro')} />
    </div>
  );
};

export default OnboardingNameForm;
