import {
  autoLoginInitThunk,
  deleteAccountThunk,
  initThunk,
  initUserDataThunk,
  signInInitThunk,
  signInThunk,
  signOutThunk,
  signUpThunk,
  tryAutoLoginThunk,
  updatePersonalSubscriptionThunk,
  updateAvatarThunk,
  removeAvatarThunk,
  updateUserSettingsThunk,
} from 'src/store/user/thunk';
import { userReducer } from 'src/store/user/slice';
import { invitesReducer } from 'src/store/invites/slice';
import { fetchInvitesThunk, respondToCrewInviteThunk, respondToSpaceInviteThunk } from 'src/store/invites/thunk';
import { localizationReducer } from 'src/store/localization/slice';
import { fetchDictionaryThunk, fetchLanguagesThunk } from 'src/store/localization/thunk';
import { crewsReducer } from 'src/store/crews/slice';
import { spacesReducer } from 'src/store/spaces/slice';
import {
  fetchSpacesThunk,
  createSpaceThunk,
  updateSpaceThunk,
  removeSpaceThunk,
  leaveSpaceThunk,
  cancelInviteThunk,
  removeSpaceUserThunk,
  updateSpaceUserThunk,
  inviteSpaceUsersThunk,
  createCrewSpaceThunk,
  addCrewSpaceUserThunk,
  addCrewMemberToSpacesThunk,
  createPublicSpaceThunk,
  regeneratePublicSpaceTokenThunk,
  createRandomSpaceThunk,
  updateSpaceImageThunk,
} from 'src/store/spaces/thunk';
import { configureStore, bindActionCreators, Middleware } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import {
  createCrewThunk,
  fetchCrewsThunk,
  inviteCrewUsersThunk,
  updateCrewThunk,
  cancelCrewInviteThunk,
  leaveCrewThunk,
  removeCrewThunk,
  updateCrewUserThunk,
  removeCrewUserThunk,
  updateCrewSubscriptionThunk,
} from './crews/thunk';
import { fetchAnnouncementsThunk } from './announcements/thunk';
import { announcementsReducer } from './announcements/slice';
import { blogReducer } from './blog/slice';
import { fetchBlogEntriesThunk } from './blog/thunk';

export const errorMiddleware: Middleware = () => (next) => (action) => {
  if (action?.meta?.requestStatus === 'rejected') {
    // let errorString = `ERROR: ${action?.type}`;
    // if (action?.error?.code) errorString += `\nCODE: ${action.error.code}`;
    // if (action?.error?.message) errorString += `\nMESSAGE: ${action?.error?.message}`;
    // if (action?.meta?.arg) errorString += `\nARG: ${action?.meta?.arg}`;
    // console.log(errorString);
    throw action?.error;
  }

  // try {
  //   let logString = `${action?.type}`;
  //   if (action?.meta?.arg) logString += ` ARG: ${JSON.stringify(action?.meta?.arg)}`;
  //   console.log(logString);
  // } catch (error) {
  //   console.log(error);
  // }

  return next(action);
};

const store = configureStore({
  reducer: {
    user: userReducer,
    localization: localizationReducer,
    spaces: spacesReducer,
    invites: invitesReducer,
    crews: crewsReducer,
    announcements: announcementsReducer,
    blog: blogReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(errorMiddleware),
});

/** Type of the application state */
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

/** Action creators to group dispatches */
export const actions = bindActionCreators(
  {
    init: initThunk,
    signIn: signInThunk,
    signOut: signOutThunk,
    signUp: signUpThunk,
    deleteAccount: deleteAccountThunk,
    initUserData: initUserDataThunk,
    tryAutoLogin: tryAutoLoginThunk,
    autoLoginInit: autoLoginInitThunk,
    signInInit: signInInitThunk,
    fetchDictionary: fetchDictionaryThunk,
    fetchLanguages: fetchLanguagesThunk,
    updateUserSettings: updateUserSettingsThunk,
    updateAvatar: updateAvatarThunk,
    updateSpaceImage: updateSpaceImageThunk,
    removeAvatar: removeAvatarThunk,
    updatePersonalSubscription: updatePersonalSubscriptionThunk,
    fetchSpaces: fetchSpacesThunk,
    fetchCrews: fetchCrewsThunk,
    createSpace: createSpaceThunk,
    createRandomSpace: createRandomSpaceThunk,
    createPublicSpace: createPublicSpaceThunk,
    regeneratePublicSpaceToken: regeneratePublicSpaceTokenThunk,
    createCrewSpace: createCrewSpaceThunk,
    createCrew: createCrewThunk,
    updateCrew: updateCrewThunk,
    updateCrewSubscription: updateCrewSubscriptionThunk,
    updateSpace: updateSpaceThunk,
    inviteSpaceUsers: inviteSpaceUsersThunk,
    inviteCrewUsers: inviteCrewUsersThunk,
    removeSpace: removeSpaceThunk,
    leaveSpace: leaveSpaceThunk,
    leaveCrew: leaveCrewThunk,
    fetchInvites: fetchInvitesThunk,
    respondToInvite: respondToSpaceInviteThunk,
    respondToCrewInvite: respondToCrewInviteThunk,
    cancelInvite: cancelInviteThunk,
    cancelCrewInvite: cancelCrewInviteThunk,
    removeSpaceUser: removeSpaceUserThunk,
    updateSpaceUser: updateSpaceUserThunk,
    updateCrewUser: updateCrewUserThunk,
    removeCrewUser: removeCrewUserThunk,
    addCrewSpaceUsers: addCrewSpaceUserThunk,
    addCrewMemberToSpaces: addCrewMemberToSpacesThunk,
    removeCrew: removeCrewThunk,
    fetchAnnouncements: fetchAnnouncementsThunk,
    fetchBlog: fetchBlogEntriesThunk,
  },
  store.dispatch,
);

export default store;
