import { clamp } from './utils';

export class Vector {
  x: number;

  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  static zero = (): Vector => {
    return new Vector(0, 0);
  };

  static add = (v1: Vector, v2: Vector): Vector => {
    return new Vector(v1.x + v2.x, v1.y + v2.y);
  };

  static subtract = (v1: Vector, v2: Vector): Vector => {
    return new Vector(v1.x - v2.x, v1.y - v2.y);
  };

  static multiply = (s: number, v: Vector): Vector => {
    return new Vector(s * v.x, s * v.y);
  };

  static random = (x?: number, y?: number): Vector => {
    // eslint-disable-next-line no-param-reassign
    x = x ?? 1;
    // eslint-disable-next-line no-param-reassign
    y = y ?? x ?? 1;
    return new Vector(Math.random() * x, Math.random() * y);
  };

  add = (v: Vector): Vector => {
    return new Vector(this.x + v.x, this.y + v.y);
  };

  subtract = (v: Vector): Vector => {
    this.x -= v.x;
    this.y -= v.y;
    return new Vector(this.x - v.x, this.y - v.y);
  };

  scale = (a: number): Vector => {
    return new Vector(this.x * a, this.y * a);
  };

  divide = (a: number): Vector => {
    return new Vector(this.x / a, this.y / a);
  };

  clamp = (v1: number, v2: number): Vector => {
    return new Vector(clamp(this.x, v1, v2), clamp(this.y, v1, v2));
  };

  clamp01 = (): Vector => {
    return this.clamp(0, 1);
  };

  length = (): number => {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  };

  dot = (v: Vector): number => {
    return this.x * v.x + this.y * v.y;
  };
}
