import { DictionaryData, DictionaryType, localDictionary } from 'src/types/localization/dictionaryType';
import LocalizationService from 'src/services/localization/localizationService';
import { LanguagesDict } from 'src/store/localization/types';
import { UHAPI } from '.';

export const fetchDictionary = async (code: string, timestamp: number | undefined): Promise<DictionaryType> => {
  try {
    const result = await UHAPI.get<DictionaryData>({
      path: `/public/dictionaries?languageCode=${code}${timestamp ? `&date=${timestamp}` : ''}`,
    });
    return LocalizationService.createLocalizedString(LocalizationService.updateDictionary(result));
  } catch (error) {
    return localDictionary as never;
  }
};

export const fetchLanguages = async (): Promise<LanguagesDict> => {
  try {
    return (await UHAPI.get<{ languages: LanguagesDict }>({ path: '/public/languages' })).languages;
  } catch (error) {
    return { en: 'English' };
  }
};
