import { createSlice } from '@reduxjs/toolkit';
import {
  tryAutoLoginThunk,
  signInThunk,
  signOutThunk,
  fetchUserSettingsThunk,
  initUserDataThunk,
  initThunk,
  updateUserSettingsThunk,
  updateAvatarThunk,
  updatePersonalSubscriptionThunk,
  deleteAccountThunk,
  removeAvatarThunk,
} from 'src/store/user/thunk';
import { countryCode, deviceLanguage } from 'src/services/device/deviceLocalization';
import { UserState } from './types';

export const initialState: UserState = {
  user: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    displayName: '',
    onboarded: false,
    locationCode: countryCode ?? 'ch',
    languageCode: deviceLanguage ?? 'en',
    emailNotifications: true,
    pushNotificationsSpaceInvite: true,
    pushNotificationsSpaceEvents: true,
    roles: [],
  },
  cognitoUser: undefined,
  initialized: false,
};

const userSlice = createSlice({
  name: '@@user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(tryAutoLoginThunk.fulfilled, (state, action) => {
        state.cognitoUser = action.payload;
      })
      .addCase(signInThunk.fulfilled, (state, action) => {
        state.cognitoUser = action.payload;
      })
      .addCase(fetchUserSettingsThunk.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(updatePersonalSubscriptionThunk.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(updateUserSettingsThunk.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(updateAvatarThunk.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(removeAvatarThunk.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(initUserDataThunk.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(initThunk.fulfilled, (state) => {
        state.initialized = true;
      })
      .addCase(signOutThunk.fulfilled, (state) => {
        state.cognitoUser = undefined;
        state.user = initialState.user;
        state.initialized = false;
      })
      .addCase(deleteAccountThunk.fulfilled, (state) => {
        state.cognitoUser = undefined;
        state.user = initialState.user;
        state.initialized = false;
      });
  },
});

export const userReducer = userSlice.reducer;
