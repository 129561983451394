import React from 'react';
import UHText from 'src/views/components/core/text/UHText';

const VerificationSuccessScreen: React.FC = () => {
  return (
    <div className="main-gradient fixed flex items-center justify-center w-screen h-screen">
      <div className="flex flex-col text-center space-y-4">
        <UHText className="text-success text-2xl font-bold" textKey="WEBSITE.VERIFICATION_SUCCESS_TITLE" />
        <UHText textKey="WEBSITE.VERIFICATION_SUCCESS_1" />
        <UHText textKey="WEBSITE.VERIFICATION_SUCCESS_2" />
      </div>
    </div>
  );
};

export default VerificationSuccessScreen;
