/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function paymentCardBrandVisa(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M211.328 184.445l-23.465 144.208h37.542l23.468-144.208h-37.545zm-55.052 0l-35.794 99.185-4.234-21.358.003.007-.933-4.787c-4.332-9.336-14.365-27.08-33.31-42.223a150.19 150.19 0 00-16.705-11.559l32.531 124.943h39.116l59.733-144.208h-40.407zm146.521 40.035c0-16.304 36.563-14.209 52.629-5.356l5.357-30.972s-16.534-6.288-33.768-6.288c-18.632 0-62.875 8.148-62.875 47.739 0 37.26 51.928 37.723 51.928 57.285 0 19.562-46.574 16.066-61.944 3.726l-5.586 32.373s16.763 8.148 42.382 8.148c25.616 0 64.272-13.271 64.272-49.37 0-37.493-52.395-40.985-52.395-57.285zm153.2-40.035h-30.185c-13.938 0-17.332 10.747-17.332 10.747l-55.988 133.461h39.131l7.828-21.419h47.728l4.403 21.419h34.472l-30.057-144.208zm-45.727 93.196l19.728-53.966 11.098 53.966H410.27z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
      />
      <path
        d="M104.132 198.022s-1.554-13.015-18.144-13.015H25.715l-.706 2.446s28.972 5.906 56.767 28.033c26.562 21.148 35.227 47.51 35.227 47.51l-12.871-64.974z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
      />
    </svg>
  );
}

function paymentCardBrandMastercard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.407 108" {...props}>
      <path fill="none" d="M0 0H152.407V108H0z" />
      <path fill="#ff5f00" d="M60.4117 25.6968H91.9117V82.3032H60.4117z" />
      <path
        d="M62.412 54a35.938 35.938 0 0113.75-28.303 36 36 0 100 56.606A35.938 35.938 0 0162.412 54z"
        fill="#eb001b"
      />
      <path
        d="M134.407 54a35.999 35.999 0 01-58.245 28.303 36.005 36.005 0 000-56.606A35.999 35.999 0 01134.407 54zM130.972 76.308v-1.16h.468v-.235h-1.19v.236h.467v1.159zm2.31 0V74.91h-.364l-.42.962-.42-.962h-.364v1.398h.257v-1.054l.394.908h.267l.394-.91v1.056z"
        fill="#f79e1b"
      />
    </svg>
  );
}

function paymentCardBrandAmex(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 700 700" xmlSpace="preserve" {...props}>
      <switch>
        <g>
          <path
            fill="#fff"
            d="M599 601L350.4970093 601 99 601 99 348.5150146 99 101 347.1489868 101 599 101 599 350.9750061 599 601z"
          />
          <path
            d="M599 601v-79.227h-60.32l-31.057-34.34-31.213 34.34H277.527V361.727h-64.191l79.622-180.193h76.788l27.411 61.731v-61.731h95.049l16.503 46.518 16.611-46.518H599V101H99v500h500zm-50.16-99.265H599l-66.353-70.364L599 361.807h-49.362l-40.977 44.778-40.583-44.778h-50.172l65.965 69.964-65.965 69.964h48.776l41.178-45.177 40.98 45.177zm11.735-70.305L599 472.309v-81.417l-38.425 40.538zm-223.627 37.922v-21.79h78.957v-31.584h-78.957v-21.787h80.955v-32.384H298.766v139.928h119.137v-32.383h-80.955zm224.27-127.851H599V201.573h-58.771l-31.385 87.153-31.183-87.153h-59.768v139.928h37.779v-97.949l35.983 97.949h33.581l35.982-98.151v98.151zm-186.303 0h42.978l-61.765-139.928h-49.176l-61.771 139.928h41.979l11.596-27.985h64.365l11.794 27.985zm-24.985-59.37h-37.983l18.992-45.776 18.991 45.776z"
            fill="#006fcf"
          />
        </g>
      </switch>
    </svg>
  );
}

function playInverted(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 1440" {...props}>
      <path
        data-name="Rechteck 1"
        d="M0 0h1440v1440H0V0zm890.615 700.172a26 26 0 010 41.672L629.947 936.415c-17.148 12.8-41.552.562-41.552-20.836V526.437c0-21.4 24.4-33.636 41.552-20.836z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export const icons = {
  paymentCardBrandVisa,
  paymentCardBrandMastercard,
  paymentCardBrandAmex,
  playInverted,
};

export type SvgIconType = keyof typeof icons;

export const getSvgElement = (key: SvgIconType): ((props: React.SVGProps<SVGSVGElement>) => JSX.Element) => {
  return icons[key];
};
