import clsx from 'clsx';
import React, { useState } from 'react';
import { UHAnalytics } from 'src/services/analytics/analyticEventsService';
import { openLink } from 'src/services/url/linkService';
import { actions } from 'src/store/store';
import { UpdatableUserSettings } from 'src/types/users/userType';
import UHButton from 'src/views/components/core/buttons/UHButton';
import UHCheckbox from 'src/views/components/core/inputs/UHCheckbox';
import UHArrowLink from 'src/views/components/core/text/UHArrowLink';
import UHText from 'src/views/components/core/text/UHText';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import { OnboardingState } from '../OnboardingScreen';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  setOnboardingState: (state: OnboardingState) => void;
  userData: UpdatableUserSettings;
};

const OnboardingTermsView: React.FC<Props> = ({ setOnboardingState, userData, className, ...htmlProps }) => {
  const history = useUHHistory();
  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const onComplete = async () => {
    setLoading(true);
    try {
      await actions.updateUserSettings(userData);
      await actions.createPublicSpace();
      UHAnalytics.completedOnboarding();
      setLoading(false);
      await actions.init();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <div
      className={clsx('flex flex-col items-center', { 'pointer-events-none select-none': loading }, className)}
      {...htmlProps}
    >
      <UHText variant="title-xl" className="mb-12 mt-8 text-primary" textKey="TERMS_OF_USE_TITLE" />
      <UHText className="mb-8" textKey="ONBOARDING_TERMS_INFO" />
      <UHArrowLink
        className="self-start mb-6"
        textKey="TERMS_OF_USE_TITLE"
        onClick={() => openLink('/terms', history, true)}
      />
      <UHCheckbox
        size="sm"
        className="mb-8"
        selected={accepted}
        setSelected={setAccepted}
        textProps={{ textKey: 'TERMS_OF_USE_CONFIRM_STATEMENT' }}
      />
      <UHButton
        variant={accepted ? 'default' : 'disabled'}
        className="self-center"
        titleKey="SHARED_BUTTON_CONFIRM"
        loading={loading}
        onClick={onComplete}
      />
      {/* Back button */}
      <UHText className="mt-4 text-primary" textKey="SHARED_BUTTON_BACK" onClick={() => setOnboardingState('name')} />
    </div>
  );
};

export default OnboardingTermsView;
