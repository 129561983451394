import clsx from 'clsx';
import React from 'react';
import UHImage from '../../core/image/UHImage';
import UHText from '../../core/text/UHText';
import { env } from '../../../../services/environment/envService';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  space: { customImage?: string; imageId?: number | null | undefined; name?: string };
  variant?: 'xs' | 'sm' | 'md' | 'lg';
  showTitle?: boolean;
};

const suffixForVariant = {
  xs: '-xs',
  sm: '-sm',
  md: '-md',
  lg: '-md',
};

const SpaceImage: React.FC<Props> = ({
  space,
  variant = 'md',
  showTitle = true,
  className,
  children,
  ...htmlProps
}) => {
  const imageSrc = space.customImage
    ? `${env.ImageCDN}/${space.customImage}${suffixForVariant[variant] ?? ''}`
    : `/assets/images/SpacesBackgrounds/${space.imageId}.jpg`;
  return (
    <div
      className={clsx(
        'transitionfix overflow-hidden',
        {
          rounded: ['xs'].includes(variant),
          'rounded-lg': ['sm'].includes(variant),
          'rounded-2xl': ['md', 'lg'].includes(variant),
        },
        className,
      )}
      {...htmlProps}
    >
      <div className="aspect-w-9 aspect-h-5 relative w-full h-full">
        <UHImage className="w-full h-full" src={imageSrc} key={imageSrc} />
        {/* Dark gradient overlay */}
        {showTitle && (
          <div className="absolute z-0 left-0 right-0 top-0 h-24 bg-gradient-to-b from-black to-transparent opacity-60" />
        )}
        {/* Space name */}
        {showTitle && (
          <div className="absolute left-0 right-0 top-4 px-5">
            <UHText
              variant={variant === 'md' ? 'title-sm' : 'title'}
              className="wrap line-clamp-2 text-typoInverted"
              text={space.name}
            />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default SpaceImage;
