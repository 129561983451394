import clsx from 'clsx';
import React from 'react';
import { parseStylizedText } from 'src/services/string/stringService';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';

export type TextVariant =
  | 'title-3xl'
  | 'title-2xl'
  | 'title-xl'
  | 'title'
  | 'title-sm'
  | 'body-xxs'
  | 'body-xs'
  | 'body-sm'
  | 'body'
  | 'body-lg'
  | 'button-xs'
  | 'button-xs-bold'
  | 'button-sm-medium'
  | 'button'
  | 'listTitle';

export type UHTextProps = React.HTMLAttributes<HTMLDivElement> & {
  text?: string;
  textKey?: DictionaryKeyType;
  variant?: TextVariant;
  tag?: string;
};

const UHText: React.FC<UHTextProps> = ({ text, textKey, variant, tag = 'div', className, ...htmlProps }) => {
  const localizedText = useLocalizedValue(text, textKey);
  const CustomTag = `${tag}` as keyof JSX.IntrinsicElements;

  return (
    <CustomTag
      className={clsx(className, {
        'text-xxs font-body font-light': variant === 'body-xxs',
        'text-xs font-body font-light': variant === 'body-xs',
        'text-sm font-body': variant === 'body-sm',
        'text-base font-body': variant === 'body',
        'text-lg font-body': variant === 'body-lg',
        'text-lg font-semibold font-body': variant === 'listTitle',
        'text-base font-sans font-medium': variant === 'title-sm',
        'text-xl font-sans font-medium': variant === 'title',
        'text-2xl font-sans': variant === 'title-xl',
        'text-3xl font-sans font-medium': variant === 'title-2xl',
        'text-4xl font-sans': variant === 'title-3xl',
        'text-xs font-sans font-light': variant === 'button-xs',
        'text-xs font-sans font-semibold': variant === 'button-xs-bold',
        'text-sm font-body font-medium': variant === 'button-sm-medium',
        'text-base font-medium font-sans': variant === 'button',
        'cursor-pointer': htmlProps.onClick,
      })}
      {...(htmlProps as Record<string, unknown>)}
    >
      {parseStylizedText(localizedText)}
    </CustomTag>
  );
};

export default UHText;
