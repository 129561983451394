import { MediaKind } from 'mediasoup-client/lib/RtpParameters';
import { UserStreamStatus } from './UserStreamStatusType';

/**
 * User Stream Type for the User
 */
export type UserStream = {
  /**
   * {@link MediaStream}
   */
  stream?: MediaStream;

  /**
   * {@link MediaKind}
   */
  kind: MediaKind;

  /**
   * Stream transmission quality
   */
  score: number;

  /**
   * Stream status
   */
  status: UserStreamStatus;

  /**
   * Producer ID
   */
  producerId: string;

  appData?: Record<string, unknown>;
};

export const isVideoStream = (stream: UserStream): boolean => {
  return !!(stream.kind === 'video' && !stream.appData?.display);
};

export const isDisplayStream = (stream: UserStream): boolean => {
  return !!(stream.kind === 'video' && stream.appData?.display);
};

export const isAudioStream = (stream: UserStream): boolean => {
  return !!(stream.kind === 'audio');
};
