import {
  fetchCrewsThunk,
  createCrewThunk,
  updateCrewThunk,
  removeCrewThunk,
  leaveCrewThunk,
  removeCrewUserThunk,
  updateCrewUserThunk,
  inviteCrewUsersThunk,
  cancelCrewInviteThunk,
  updateCrewSubscriptionThunk,
} from 'src/store/crews/thunk';
import { CrewsState } from 'src/store/crews/types';
import { createSlice } from '@reduxjs/toolkit';

export const initialState: CrewsState = {
  crews: [],
};

const crewsSlice = createSlice({
  name: '@@crews',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCrewsThunk.fulfilled, (state, action) => {
        state.crews = action.payload;
      })
      .addCase(removeCrewUserThunk.fulfilled, (state, action) => {
        const crewIndex = state.crews.findIndex((c) => c.id === action.meta.arg.crewId);
        if (crewIndex !== -1) {
          state.crews[crewIndex].members = state.crews[crewIndex].members.filter(
            (m) => m.id !== action.meta.arg.memberId,
          );
        }
      })
      .addCase(updateCrewUserThunk.fulfilled, (state, action) => {
        const crewIndex = state.crews.findIndex((c) => c.id === action.meta.arg.crewId);
        if (crewIndex !== -1) {
          const memberIndex = state.crews[crewIndex].members.findIndex((m) => m.id === action.meta.arg.memberId);
          if (memberIndex !== -1) state.crews[crewIndex].members[memberIndex].role = action.meta.arg.role;
        }
      })
      .addCase(createCrewThunk.fulfilled, (state, action) => {
        state.crews.push(action.payload);
      })
      .addCase(updateCrewThunk.fulfilled, (state, action) => {
        const updatedCrew = action.payload;
        const index = state.crews.findIndex((c) => c.id === updatedCrew.id);
        if (index !== -1) {
          state.crews[index] = updatedCrew;
        }
      })
      .addCase(updateCrewSubscriptionThunk.fulfilled, (state, action) => {
        const updatedCrew = action.payload;
        const index = state.crews.findIndex((c) => c.id === updatedCrew.id);
        if (index !== -1) {
          state.crews[index] = updatedCrew;
        }
      })
      .addCase(removeCrewThunk.fulfilled, (state, action) => {
        state.crews = state.crews.filter((c) => c.id !== action.meta.arg);
      })
      .addCase(leaveCrewThunk.fulfilled, (state, action) => {
        state.crews = state.crews.filter((c) => c.id !== action.meta.arg);
      })
      .addCase(inviteCrewUsersThunk.fulfilled, (state, action) => {
        const updatedCrew = action.payload;
        const index = state.crews.findIndex((c) => c.id === updatedCrew.id);
        if (index !== -1) {
          state.crews[index] = updatedCrew;
        }
      })
      .addCase(cancelCrewInviteThunk.fulfilled, (state, action) => {
        const updatedCrew = action.payload;
        const index = state.crews.findIndex((c) => c.id === updatedCrew.id);
        if (index !== -1) {
          state.crews[index] = updatedCrew;
        }
      });
  },
});

export const crewsReducer = crewsSlice.reducer;
