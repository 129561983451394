import { GetUpcomingInvoice } from 'src/types/subscriptions/upcomingInvoice';
import Stripe from 'stripe';
import { UHAPI } from '.';

export const getInvoices = async (): Promise<Stripe.Invoice[]> => {
  return UHAPI.get({ path: '/invoices' });
};

export const getUpcomingInvoice = async (): Promise<GetUpcomingInvoice> => {
  return UHAPI.get({ path: '/upcoming-invoice' });
};
