import { Crew, CrewUpdateType, CrewCreateType, CrewInviteType, CrewMemberUpdateType } from 'src/types/crews/CrewType';
import {
  getAllCrews,
  createNewCrew,
  updateCrew,
  removeCrew,
  cancelCrewInvite,
  leaveCrew,
  removeUserFromCrew,
  updateCrewUser,
  inviteCrewUsers,
} from 'src/services/api/crewsAPI';
import { updateCrewSubscription } from 'src/services/api/subscriptionAPI';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchCrewsThunk = createAsyncThunk('@@crews/fetchCrews', async (): Promise<Crew[]> => {
  return getAllCrews();
});

export const createCrewThunk = createAsyncThunk(
  '@@crews/createCrew',
  async (params: { data: CrewCreateType; usersToInvite: string[] }): Promise<Crew> => {
    let crew = await createNewCrew(params.data);
    if (params.usersToInvite) crew = await inviteCrewUsers({ crewId: crew.id, usersToInvite: params.usersToInvite });
    return crew;
  },
);

export const updateCrewUserThunk = createAsyncThunk('@@crews/updateCrewUser', async (data: CrewMemberUpdateType) => {
  await updateCrewUser(data);
});

export const removeCrewUserThunk = createAsyncThunk(
  '@@crews/removeCrewUser',
  async (data: { memberId: number; crewId: number }) => {
    await removeUserFromCrew(data.crewId, data.memberId);
  },
);

export const updateCrewThunk = createAsyncThunk(
  '@@crews/updateCrew',
  async (params: { id: number; data: CrewUpdateType }): Promise<Crew> => {
    return updateCrew(params.id, params.data);
  },
);

export const updateCrewSubscriptionThunk = createAsyncThunk(
  '@@crews/updateCrewSubscription',
  async (params: { id: number; tier: string }): Promise<Crew> => {
    return updateCrewSubscription(params.id, params.tier);
  },
);

export const removeCrewThunk = createAsyncThunk('@@crews/removeCrew', async (crewId: number) => {
  await removeCrew(crewId);
});

export const leaveCrewThunk = createAsyncThunk('@@crews/leaveCrew', async (crewId: number) => {
  await leaveCrew(crewId);
});

export const cancelCrewInviteThunk = createAsyncThunk(
  '@@crew/cancelInvite',
  async (inviteId: number): Promise<Crew> => {
    return cancelCrewInvite(inviteId);
  },
);

export const inviteCrewUsersThunk = createAsyncThunk(
  '@@crews/inviteCrewUsers',
  async (data: CrewInviteType): Promise<Crew> => {
    return inviteCrewUsers(data);
  },
);
