import {
  cancelDowngrade,
  createCheckoutSession,
  createPortalSession,
  downgradeTier,
  getManagePaymentsUrl,
  updateSubscription,
} from '../api/subscriptionAPI';
import { getStaticData } from '../staticData/staticDataService';
import { allPages } from '../pages/pageService';
import { UserSubscription } from 'src/types/subscriptions/downgradeTier';

const onFirstSubscription = async (tier: string, period: string) => {
  const session = await createCheckoutSession({
    tier,
    period,
    successUrl: `${window.location.origin}${allPages.subscriptionsCompleted.url}`,
    cancelUrl: `${window.location.origin}${allPages.subscriptions.url}`,
  });
  const redirectToCheckout = async () => {
    const { url } = session.session;
    if (!url) return;
    window.location.href = url;
  };

  redirectToCheckout();
};

const onUpgradeSubscription = async (tier: string, period: string) => {
  const session = await createPortalSession({
    redirectUrl: `${window.location.origin}${allPages.subscriptions.url}`,
    action: 'upgrade',
    toTier: tier,
    period,
  });
  window.location.href = session.url;
};

const onDowngradeSubscription = async (tier: string, period: string): Promise<UserSubscription[]> => {
  return downgradeTier({ tier, period });
};

export const changeSubscriptionTier = async (
  fromTier: string,
  toTier: string,
  period: string,
  currentPeriod: string,
  hasPendingDowngrade: boolean,
): Promise<UserSubscription[] | undefined> => {
  const subscriptions = getStaticData().personalSubscriptions;
  const currentIndex = subscriptions.findIndex((s) => s.tier === fromTier);
  const newIndex = subscriptions.findIndex((s) => s.tier === toTier);
  // First subscription (Upgrade from P0)
  if (currentIndex === 0 && newIndex > 0) {
    await onFirstSubscription(toTier, period);
  }

  // Upgrade
  else if (
    (newIndex > currentIndex && (currentPeriod === period || (currentPeriod === 'monthly' && period === 'yearly'))) ||
    (newIndex === currentIndex && period === 'yearly')
  ) {
    if (hasPendingDowngrade) await cancelDowngrade();
    await onUpgradeSubscription(toTier, period);
  }
  // Downgrade
  else if (newIndex < currentIndex || (newIndex === currentIndex && period === 'monthly')) {
    return onDowngradeSubscription(toTier, period);
  }
};

export const reactivateCancelledSubscription = async (): Promise<UserSubscription[]> => {
  return updateSubscription({ option: 'RENEW' });
};

export const managePaymentMethod = async (): Promise<void> => {
  // Navigate to stripe
  const { url } = await getManagePaymentsUrl(`${window.location.origin}${allPages.subscriptions.url}`);
  window.location.href = url;
};
