import {
  fetchSpacesThunk,
  createSpaceThunk,
  updateSpaceThunk,
  removeSpaceThunk,
  leaveSpaceThunk,
  removeSpaceUserThunk,
  updateSpaceUserThunk,
  inviteSpaceUsersThunk,
  cancelInviteThunk,
  createCrewSpaceThunk,
  addCrewSpaceUserThunk,
  addCrewMemberToSpacesThunk,
  createPublicSpaceThunk,
  regeneratePublicSpaceTokenThunk,
  updateSpaceImageThunk,
} from 'src/store/spaces/thunk';
import { SpacesState } from 'src/store/spaces/types';
import { createSlice } from '@reduxjs/toolkit';
import { UHAnalytics } from 'src/services/analytics/analyticEventsService';

export const initialState: SpacesState = {
  spaces: [],
};

const spacesSlice = createSlice({
  name: '@@spaces',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpacesThunk.fulfilled, (state, action) => {
        state.spaces = action.payload;
      })
      .addCase(removeSpaceUserThunk.fulfilled, (state, action) => {
        const spaceIndex = state.spaces.findIndex((s) => s.id === action.meta.arg.space.id);
        if (spaceIndex !== -1) {
          state.spaces[spaceIndex].members = state.spaces[spaceIndex].members.filter(
            (m) => m.id !== action.meta.arg.memberId,
          );
        }
        UHAnalytics.removedSpaceUser();
      })
      .addCase(updateSpaceUserThunk.fulfilled, (state, action) => {
        const spaceIndex = state.spaces.findIndex((s) => s.id === action.meta.arg.spaceId);
        if (spaceIndex !== -1) {
          const memberIndex = state.spaces[spaceIndex].members.findIndex((m) => m.id === action.meta.arg.memberId);
          if (memberIndex !== -1) state.spaces[spaceIndex].members[memberIndex].role = action.meta.arg.role;
        }
      })
      .addCase(createSpaceThunk.fulfilled, (state, action) => {
        state.spaces.push(action.payload);
        UHAnalytics.createdSpace();
      })
      .addCase(createPublicSpaceThunk.fulfilled, (state, action) => {
        if (action.payload) state.spaces.push(action.payload);
      })
      .addCase(updateSpaceThunk.fulfilled, (state, action) => {
        const updatedSpace = action.payload;
        const index = state.spaces.findIndex((s) => s.id === updatedSpace.id);
        if (index !== -1) state.spaces[index] = updatedSpace;
      })
      .addCase(updateSpaceImageThunk.fulfilled, (state, action) => {
        const updatedSpace = action.payload;
        const index = state.spaces.findIndex((s) => s.id === updatedSpace.id);
        if (index !== -1) state.spaces[index] = updatedSpace;
        UHAnalytics.uploadedSpaceImage();
      })
      .addCase(regeneratePublicSpaceTokenThunk.fulfilled, (state, action) => {
        const updatedSpace = action.payload;
        const index = state.spaces.findIndex((s) => s.id === updatedSpace.id);
        if (index !== -1) state.spaces[index] = updatedSpace;
        UHAnalytics.regeneratedToken();
      })
      .addCase(inviteSpaceUsersThunk.fulfilled, (state, action) => {
        const updatedSpace = action.payload;
        const index = state.spaces.findIndex((s) => s.id === updatedSpace.id);
        if (index !== -1) state.spaces[index] = updatedSpace;
        UHAnalytics.invitedUsersToSpace();
      })
      .addCase(removeSpaceThunk.fulfilled, (state, action) => {
        state.spaces = state.spaces.filter((s) => s.id !== action.meta.arg.id);
        UHAnalytics.deletedSpace();
      })
      .addCase(leaveSpaceThunk.fulfilled, (state, action) => {
        state.spaces = state.spaces.filter((s) => s.id !== action.meta.arg);
        UHAnalytics.leftSpace();
      })
      .addCase(cancelInviteThunk.fulfilled, (state, action) => {
        const updatedSpace = action.payload;
        const index = state.spaces.findIndex((s) => s.id === updatedSpace.id);
        if (index !== -1) state.spaces[index] = updatedSpace;
      })
      .addCase(createCrewSpaceThunk.fulfilled, (state, action) => {
        state.spaces.push(action.payload);
      })
      .addCase(addCrewSpaceUserThunk.fulfilled, (state, action) => {
        const updatedSpace = action.payload;
        if (updatedSpace) {
          const spaceindex = state.spaces.findIndex((s) => s.id === updatedSpace.id);
          if (spaceindex !== -1) {
            state.spaces[spaceindex] = updatedSpace;
          }
        }
      })
      .addCase(addCrewMemberToSpacesThunk.fulfilled, (state, action) => {
        const updatedSpaces = action.payload;
        updatedSpaces.forEach((updatedSpace) => {
          const spaceindex = state.spaces.findIndex((s) => s.id === updatedSpace.id);
          if (spaceindex !== -1) {
            state.spaces[spaceindex] = updatedSpace;
          }
        });
      });
  },
});

export const spacesReducer = spacesSlice.reducer;
