import { format, formatISO, isBefore, parseISO } from 'date-fns';
import { padNumber } from '../string/stringService';

// Formatting

export const formatWithFormatString = (date: Date, formatString: string): string => {
  return !isNaN(date.getTime()) ? format(date, formatString) : '';
};

export const formattedDuration = (totalSeconds: number): string => {
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`;
};

export const formatDateTime = (date: Date | undefined, dateFormat = 'yyyy.MM.dd', timeFormat = 'HH:mm'): string => {
  if (!date) return '---';
  return `${format(date, dateFormat)} at ${format(date, timeFormat)}`;
};

export const formatMonth = (date: Date): string => {
  return formatWithFormatString(date, 'MMMM');
};

export const formatMonthShort = (date: Date): string => {
  const month = formatMonth(date);
  if (month.length > 5) return month.slice(0, 3);
  return month;
};

export const formatYear = (date: Date): string => {
  return formatWithFormatString(date, 'y');
};

export const formatDay = (date: Date): string => {
  return formatWithFormatString(date, 'dd');
};

export const formatWeekDay = (date: Date): string => {
  return formatWithFormatString(date, 'EEEE');
};

export const formatWeekDayShort = (date: Date): string => {
  return formatWeekDay(date).slice(0, 2);
};

export const formatDateMonth = (date: Date): string => {
  return formatWithFormatString(date, 'dd.MM');
};

export const formatDate = (date: Date): string => {
  return formatWithFormatString(date, 'dd.MM.yy');
};

export const formatDateRange = (date1: Date | undefined, date2: Date | undefined): string => {
  if (!date1 || !date2) return '-';
  return `${formatDate(date1)} - ${formatDate(date2)}`;
};

export const formatDateISO = (date: Date): string => {
  return formatISO(date);
};

// Parsing

export const isValidDate = (date: Date): boolean => {
  return !isNaN(date.getTime());
};

// eslint-disable-next-line
export const parseDate = (dateString: any): Date | undefined => {
  if (!dateString) return undefined;
  const date = parseISO(dateString);
  if (isValidDate(date)) return date;
  else return undefined;
};

export const transformPropertiesToDate = <T>(o: T, props: string[]): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anyO = o as any;
  props.forEach((p) => {
    anyO[p] = parseDate(anyO[p]);
  });
};

// Sorting

export const compareDatesDesc = (a: Date, b: Date): number => {
  return b.getTime() - a.getTime();
};

export const compareDatesAsc = (a: Date, b: Date): number => {
  return a.getTime() - b.getTime();
};

export const sortDates = (dates: Date[], order: 'desc' | 'asc' = 'asc'): Date[] => {
  if (order === 'desc') return dates.sort(compareDatesDesc);
  else return dates.sort(compareDatesAsc);
};

// Comparison

export type DateInterval = {
  start: Date;
  end: Date;
};

export const getDateOverlap = (a: DateInterval, b: DateInterval): DateInterval | undefined => {
  if (isBefore(a.end, b.start) || isBefore(b.end, a.start)) return undefined;
  return {
    start: isBefore(a.start, b.start) ? b.start : a.start,
    end: isBefore(a.end, b.end) ? a.end : b.end,
  };
};
