import clsx from 'clsx';
import React from 'react';
import { Price, SubscriptionPeriod } from 'src/services/subscriptions/subscriptionService';
import UHText from 'src/views/components/core/text/UHText';
import Center from 'src/views/components/core/transform/Center';
import { useLocalizedKey } from 'src/views/hooks/useLocalizedString';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  price?: Price;
  subscriptionPeriod?: SubscriptionPeriod;
  chosenPeriod?: SubscriptionPeriod;
  variant?: 'md' | 'lg';
};

const PriceInfo: React.FC<Props> = ({
  price,
  subscriptionPeriod,
  chosenPeriod,
  variant = 'md',
  className,
  ...htmlProps
}) => {
  let priceOfPeriod = price?.absolute ?? (subscriptionPeriod === 'yearly' ? price?.yearly : price?.monthly);
  if (chosenPeriod) priceOfPeriod = chosenPeriod === 'yearly' ? price?.yearly : price?.monthly;
  if (priceOfPeriod && subscriptionPeriod === 'yearly') priceOfPeriod /= 12;

  const yearText = useLocalizedKey('SHARED_TEXT_YEAR');
  const yearPriceText = `${price?.currency ?? ''} ${price?.yearly?.toFixed(2) ?? ''} / ${yearText}`;
  return (
    <div className={clsx('flex justify-center', className)} {...htmlProps}>
      {priceOfPeriod && (
        <Center col>
          <div
            className={clsx(
              'flex justify-center',
              { 'items-start': variant === 'lg', 'items-end': variant === 'md' },
              className,
            )}
          >
            <UHText
              className="inline-block mr-0.5"
              variant={variant === 'lg' ? 'body-lg' : 'title-2xl'}
              text={`${price?.currency ?? ''}`}
            />

            <UHText
              className="inline"
              variant={variant === 'lg' ? 'title-3xl' : 'title-2xl'}
              text={`${Math.floor(priceOfPeriod).toString()}`}
            />

            <UHText
              className={clsx('text inline-block transform', { '-translate-y-2.5': variant === 'md' })}
              variant="body-sm"
              text={Math.round(100 * (priceOfPeriod - Math.floor(priceOfPeriod))).toString()}
            />
            {!price?.absolute && <UHText className="inline mx-1 text-neutral" variant="body-lg" text={` / `} />}
            {!price?.absolute && (
              <UHText
                className="inline text-neutral"
                variant="body-lg"
                textKey={chosenPeriod === 'yearly' ? 'SHARED_TEXT_YEAR' : 'SHARED_TEXT_MONTH'}
              />
            )}
          </div>
          {subscriptionPeriod === 'yearly' && (
            <UHText className="text-neutral" variant="body-xs" text={yearPriceText} />
          )}
        </Center>
      )}
      {!priceOfPeriod && <UHText variant="title-xl" textKey="SUBSCRIPTION_PRICE_FREE" />}
    </div>
  );
};

export default PriceInfo;
