import React from 'react';
import UHText from 'src/views/components/core/text/UHText';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';

const ImpressumScreen: React.FC = () => {
  const history = useUHHistory();

  const backAction = () => {
    history.pushPage('landing');
  };

  return (
    <main className="bg-card">
      {/* Body with sections */}
      <div className="page-width flex flex-col mb-4 mt-16 max-w-2xl">
        {/* Card Title */}
        <UHText tag="h1" variant="listTitle" className="mb-4" textKey="WEBSITE.IMPRINT_TITLE" />
        {/* Text Content */}
        {/* Address */}
        <div className="mb-4">
          <UHText tag="p" className="text-white" textKey="WEBSITE.IMPRINT_ADDRESS_LINE1" />
          <UHText tag="p" className="text-white" textKey="WEBSITE.IMPRINT_ADDRESS_LINE2" />
          <UHText tag="p" className="text-white" textKey="WEBSITE.IMPRINT_ADDRESS_LINE3" />
          <UHText tag="p" className="text-white" textKey="WEBSITE.IMPRINT_ADDRESS_LINE4" />
        </div>
        {/* Contact */}
        <div className="mb-4">
          <UHText
            tag="h2"
            className="text-white mb-2 text-base font-semibold"
            textKey="WEBSITE.IMPRINT_CONTACT_TITLE"
          />
          <UHText
            tag="p"
            className="text-primary cursor-pointer"
            text="https://www.nevolane.space"
            onClick={backAction}
          />
          <a href="mailto:support@nevolane.com">
            <UHText tag="p" className="text-primary cursor-pointer" text="support@nevolane.com" />
          </a>
        </div>
        {/* UID */}
        <div className="flex">
          <UHText tag="p" className="text-white" textKey="WEBSITE.IMPRINT_UID" />
          <UHText tag="p" className="text-white" text=": CHE-365.972.482" />
        </div>
        {/* Managing Director */}
        <div className="flex">
          <UHText tag="p" className="text-white" textKey="WEBSITE.IMPRINT_MANAGING_DIRECTOR" />
          <UHText tag="p" className="text-white" text=": Christian Schorr" />
        </div>
        <div className="mt-24">
          <a
            href="https://www.freepik.com/vectors/population"
            target="_blank"
            rel="noreferrer noopener"
            className="sm text-neutral"
          >
            Population vector created by freepik - www.freepik.com
          </a>
        </div>
      </div>
    </main>
  );
};

export default ImpressumScreen;
