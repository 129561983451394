import React, { useState } from 'react';
import { ConferenceContainer } from './components/ConferenceContainer';
import ConferenceMenu from './components/ConferenceMenu';
import clsx from 'clsx';
import Absolute from 'src/views/components/core/transform/Absolute';
import MockConferenceControlPanel from './components/MockConferenceControlPanel';
import { ConferenceData } from './hooks/useConference';
import { Space } from 'src/types/spaces/SpaceType';
import UHText from 'src/views/components/core/text/UHText';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import ModalContainer from 'src/views/components/core/containers/ModalContainer';
import UHButton from 'src/views/components/core/buttons/UHButton';
import { getPublicSpaceLink } from 'src/services/publicSpace/publicSpaceService';
import CopyToClipboard from 'src/views/components/tooltip/CopyToClipboard';
import { BubbleRenderData } from 'src/types/conference/render/ConferenceViewTypes';
import BubbleDetailView from './components/BubbleDetailView';
import Center from 'src/views/components/core/transform/Center';
import { useMouseMovedRecently } from 'src/views/hooks/useMouseMovedRecently';
import MediaDebugView from 'src/views/components/media-stream/MediaDebugView';
import { useHasRole } from 'src/views/hooks/auth/useHasRole';
import { Role } from 'src/types/users/userType';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  space: Space;
  conferenceData: ConferenceData;
};

const detailViewTopInset = 100;
const detailViewMargin = 4;

const ConferenceWrapper: React.FC<Props> = ({ space, conferenceData, className, ...htmlProps }) => {
  const connecting = conferenceData.reconnecting;
  const onBreak = conferenceData.myUser.status === 'Break';
  const enableDebugInfo = useHasRole([Role.COMMUNITY_MANAGER, Role.EXECUTIVE, Role.ADMIN]);

  const isPublicSpace = !!space.token;
  const publicSpaceLink = getPublicSpaceLink(space);

  const [selectedBubbleDetail, setSelectedBubbleDetail] = useState<BubbleRenderData | undefined>();
  const detailViewOpen = !!selectedBubbleDetail;

  const [showMediaDebugOverlay, setShowMediaDebugOverlay] = useState(false);

  // Mouse movement for showing menu while detail view is presented
  const { mouseMovedRecently, onMouseMoved } = useMouseMovedRecently();

  const noMicrophones = conferenceData.mediaStreamData.microphones.length === 0;

  return (
    <div onMouseMove={onMouseMoved} className={clsx('relative flex flex-col', className)} {...htmlProps}>
      {/* Background */}
      <div className="relative flex flex-col flex-grow">
        {/* Top conference canvas */}
        <div
          className="bg-card relative flex-grow rounded-b-3xl overflow-hidden"
          onClick={detailViewOpen ? () => setSelectedBubbleDetail(undefined) : undefined}
        >
          {/* Conference */}
          <div className={clsx('w-full h-full', { 'pb-16 pt-4 px-4': !detailViewOpen })}>
            <ConferenceContainer
              className="w-full h-full"
              style={
                detailViewOpen ? { height: `${detailViewTopInset}px`, marginTop: `${detailViewMargin}px` } : undefined
              }
              onLeave={conferenceData.onLeaveConversation}
              onJoinUser={conferenceData.onJoinedUser}
              onOpenBubbleDetail={setSelectedBubbleDetail}
              sessionRenderData={conferenceData.sessionRenderData}
              detailViewOpen={detailViewOpen}
              selectedBubbleDetail={selectedBubbleDetail}
            />
          </div>
          {/* Bubble Detail View */}
          <BubbleDetailView
            bubble={selectedBubbleDetail}
            open={detailViewOpen}
            className={clsx('absolute inset-0', { 'pointer-events-none': !detailViewOpen })}
            style={{ top: `${detailViewTopInset + detailViewMargin * 2}px` }}
          />
          {/* Connect Overlay */}
          <ModalContainer showModal={connecting} fullScreen={false} transparentBg>
            <Absolute center className="main-gradient">
              <UHText textKey="CONFERENCE_CONNECTING" />
            </Absolute>
          </ModalContainer>
          {/* Break Overlay */}
          <ModalContainer showModal={onBreak} fullScreen={false} transparentBg>
            <Absolute center className="main-gradient flex-col">
              <UHIcon icon="user-clock" className="mb-4 text-neutral text-7xl" />
              <UHText variant="title" className="text-neutral" textKey="CONFERENCE_STATUS_BREAK" />
              <UHButton
                className="mt-10"
                width="md"
                height="md"
                titleKey="SHARED_BUTTON_BACK"
                onClick={() => conferenceData.onChangeStatus('Active')}
              />
            </Absolute>
          </ModalContainer>
        </div>
        {/* Bottom bar */}
        <div
          className={clsx(
            'absolute z-1 bottom-0 left-0 right-0 flex flex-1 items-center pb-4 px-6 h-14 pointer-events-none transition-opacity duration-200',
            {
              'opacity-0 ': detailViewOpen && !mouseMovedRecently,
            },
          )}
        >
          {/* Space Name */}
          <div className="flex flex-1 items-center pointer-events-auto">
            {isPublicSpace && <UHIcon className="inline mx-2 text-primary-300 text-2xl" icon="earth-americas" />}
            <UHText
              variant="title"
              className="wrap line-clamp-1 inline text-primary transition-colors"
              text={space.name}
            />
            {isPublicSpace && (
              <CopyToClipboard copyContent={publicSpaceLink}>
                <UHIcon className="inline ml-2 text-primary text-xl" icon="link" />
              </CopyToClipboard>
            )}
          </div>
          {/* Conference menu */}
          <ConferenceMenu
            className={clsx({
              'pointer-events-none': detailViewOpen && !mouseMovedRecently,
              'pointer-events-auto': !detailViewOpen || mouseMovedRecently,
            })}
            conferenceData={conferenceData}
          />
          <div className="hidden flex-1 md:block" />
          <div />
        </div>
        {/* No microphone warning */}
        {noMicrophones && (
          <Center
            className={clsx('absolute bottom-0 left-2 right-2 pointer-events-none transition-all duration-300', {
              'h-0': detailViewOpen && !mouseMovedRecently,
              'h-14': !detailViewOpen || mouseMovedRecently,
            })}
          >
            <Center className="absolute bottom-full flex justify-center mb-3 p-3 bg-background rounded-xl shadow">
              <UHText className="text-center text-warning-500" textKey="MICROPHONE_DEVICE_NOT_DETECTED" />
            </Center>
          </Center>
        )}
      </div>
      {/* Debug Panel */}
      {conferenceData.isMock && (
        <MockConferenceControlPanel className="mb-8" mockConference={conferenceData.mockConference} />
      )}
      {/* Debug overlay button */}
      {enableDebugInfo && (
        <UHIcon
          className="fixed top-9 right-9 text-xs text-neutral-300"
          icon="info"
          onClick={() => setShowMediaDebugOverlay(true)}
        />
      )}
      {/* Debug overlay */}
      {showMediaDebugOverlay && enableDebugInfo && (
        <Absolute className="z-100">
          <MediaDebugView
            mediaStreamData={conferenceData.mediaStreamData}
            conferenceData={conferenceData}
            onClose={() => setShowMediaDebugOverlay(false)}
          />
        </Absolute>
      )}
    </div>
  );
};

export default ConferenceWrapper;
