export type ErrorType = {
  code?: string;
  message?: string;
};

export class UHError extends Error {
  code?: string;

  constructor(msg: string | undefined = undefined, code: string | undefined = undefined) {
    super(msg);
    this.code = code;
    Object.setPrototypeOf(this, UHError.prototype);
  }
}
