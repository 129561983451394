import React, { useEffect } from 'react';
import { UHAnalytics } from 'src/services/analytics/analyticEventsService';
import { verifyEmail } from 'src/services/api/userAPI';
import UHText from 'src/views/components/core/text/UHText';
import { useQueryParam } from 'src/views/hooks/navigation/useQueryParam';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';

const VerificationScreen: React.FC = () => {
  const history = useUHHistory();

  const email = useQueryParam('email');
  const code = useQueryParam('code');

  useEffect(() => {
    (async () => {
      const success = await verifyEmail(email ?? '', code ?? '');
      if (success) {
        history.replacePage('verificationSuccess');
        UHAnalytics.verifiedEmail();
      } else {
        history.replacePage('verificationError');
        UHAnalytics.verifyEmailFailed();
      }
    })();
  }, []);

  return (
    <div className="main-gradient fixed flex items-center justify-center w-screen h-screen">
      <div className="flex flex-col text-center space-y-4">
        <UHText className="text-neutral-300 text-2xl font-bold" textKey="WEBSITE.VERIFICATION_LOADING_1" />
        <UHText textKey="WEBSITE.VERIFICATION_LOADING_2" />
      </div>
    </div>
  );
};

export default VerificationScreen;
