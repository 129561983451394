import clsx from 'clsx';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { allPages } from 'src/services/pages/pageService';
import { isSpaceDisabled } from 'src/services/subscriptions/restrictionsService';
import { useTypedSelector } from 'src/store/store';
import { Space } from 'src/types/spaces/SpaceType';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import UHText from '../../core/text/UHText';
import SpaceImage from '../../space/image/SpaceImage';
import UHTooltip from '../../tooltip/UHTooltip';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  space: Space;
  collapsed?: boolean;
  inConference?: boolean;
};

const DrawerSpaceEntry: React.FC<Props> = ({
  space,
  collapsed = false,
  inConference = false,
  className,
  ...htmlProps
}) => {
  const history = useUHHistory();
  const location = useLocation();
  const user = useTypedSelector((state) => state.user.user);
  const spaces = useTypedSelector((state) => state.spaces.spaces);

  const path = (inConference ? allPages.conference : allPages.spaceDetail).url.replaceAll(
    ':spaceId',
    space.id.toString(),
  );
  const pathToMatch =
    `(${allPages.spaceDetail.url}|${allPages.conference.url}|${allPages.userMediaSelection.url})`.replaceAll(
      ':spaceId',
      space.id.toString(),
    );
  const selected = matchPath(location.pathname.replaceAll(':spaceId', '*'), pathToMatch);

  const activeSpaceMembers = space.members.filter((m) => m.isActive);

  const spaceDisabled = isSpaceDisabled(user, space, spaces);

  return (
    <div
      className={clsx(
        'flex items-center p-1 rounded-lg cursor-pointer transition-all duration-200',
        {
          'hover:bg-neutral-200': !collapsed,
          'bg-neutral-200': selected && !collapsed,
          'bg-primary': selected && collapsed,
          'opacity-30 hover:opacity-100': !selected && collapsed,
        },
        className,
      )}
      onClick={() => {
        if (!(spaceDisabled && inConference)) history.navigate(path);
      }}
      {...htmlProps}
    >
      <UHTooltip
        text={collapsed ? space.name : undefined}
        position="right"
        icon={spaceDisabled ? 'lock' : undefined}
        iconClass={spaceDisabled ? 'text-base' : undefined}
      >
        <SpaceImage className="w-16" variant="xs" space={space} showTitle={false} />
      </UHTooltip>
      {!collapsed && (
        <UHText
          variant="body-sm"
          className="wrap line-clamp-2 flex-1 flex-shrink ml-2 text-primary-700"
          text={space.name}
        />
      )}
      {!collapsed && activeSpaceMembers.length > 0 && (
        <div className="mr-2 w-2 h-2 bg-secondary-700 rounded-full animate-pulse" />
      )}
    </div>
  );
};

export default DrawerSpaceEntry;
