import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import UHTooltip from '../../tooltip/UHTooltip';
import UHIcon from '../icon/UHIcon';
import Center from '../transform/Center';

type Props = React.HTMLAttributes<HTMLInputElement> & {
  icon: string;
  iconClassName?: string;
  type?: 'active' | 'inactive' | 'danger';
  text?: string;
  textKey?: DictionaryKeyType;
};

const UHLabledIconButton: React.FC<Props> = ({
  icon,
  iconClassName,
  type = 'active',
  text,
  textKey,
  className,
  ...htmlProps
}) => {
  const localizedText = useLocalizedValue(text, textKey);

  return (
    <UHTooltip text={localizedText}>
      <Center
        className={clsx(
          'w-12 h-12 rounded-full',
          {
            'cursor-pointer': htmlProps.onClick,
            'bg-primary hover:bg-primary-600': type === 'active',
            'bg-neutral-600 hover:bg-neutral-700': type === 'inactive',
            'bg-error hover:bg-error-600': type === 'danger',
          },
          className,
        )}
        {...htmlProps}
      >
        <UHIcon icon={icon} className={clsx(iconClassName, 'text-lg')} />
        {/* <UHText className="text-xs" text={localizedText} /> */}
      </Center>
    </UHTooltip>
  );
};

export default UHLabledIconButton;
