import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { pendingPublicSpaceJoinURL, setPendingPublicSpaceJoinURL } from 'src/services/publicSpace/publicSpaceService';
import { useTypedSelector } from 'src/store/store';
import UHButton from 'src/views/components/core/buttons/UHButton';
import ModalContainer from 'src/views/components/core/containers/ModalContainer';
import UHCard from 'src/views/components/core/containers/UHCard';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import Loader from 'src/views/components/core/loader/Loader';
import UHText from 'src/views/components/core/text/UHText';
import Center from 'src/views/components/core/transform/Center';
import CardGrid from 'src/views/components/layout/CardGrid';
import PageContainer from 'src/views/components/layout/PageContainer';
import CreateSpaceView from 'src/views/components/space/createSpace/CreateSpaceView';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import { useCreateSpace } from 'src/views/hooks/space/useCreateSpace';
import { useLocalizedKey } from 'src/views/hooks/useLocalizedString';
import SpaceTile from '../../components/space/SpaceTile';
import InvitationScreen from '../Invites/InvitationScreen';

const SpacesOverviewScreen: React.FC = () => {
  const history = useUHHistory();
  const spaces = useTypedSelector((state) => state.spaces.spaces);
  // Invites
  const invites = useTypedSelector((state) => state.invites);
  const inviteCount = invites.spaceInvites.length + invites.crewInvites.length;
  const openInvitesTextSingular = useLocalizedKey('PROFILE_INVITES_OPEN_SINGULAR', inviteCount.toString());
  const openInvitesTextPlural = useLocalizedKey('PROFILE_INVITES_OPEN_PLURAL', inviteCount.toString());
  const [showingInvites, setShowingInvites] = useState(false);
  const [showingSpaceCreate, setShowingSpaceCreate] = useState(false);

  const onEnterSpace = (spaceId: number) => history.pushPage('userMediaSelection', spaceId);

  const totalInvites = invites.spaceInvites.length + invites.crewInvites.length;
  const invitationsString = `${useLocalizedKey('PROFILE_OPTIONS_INVITATIONS')} (${totalInvites})`;

  const numPrivateSpaces = spaces.filter((s) => !s.token).length;

  // Create space
  const { onCreateSpace, creatingSpace } = useCreateSpace();

  // Join public space if coming via Login/Register from a Lobby
  if (pendingPublicSpaceJoinURL) {
    const publiSpaceURL = pendingPublicSpaceJoinURL;
    setPendingPublicSpaceJoinURL(undefined);
    return <Navigate to={publiSpaceURL} />;
  }

  return (
    <PageContainer className="flex flex-col" titleKey="SHARED_TEXT_SPACES" icon="rocket">
      {/* Spaces List */}
      <CardGrid className="auto-rows-fr">
        {/* Welcome Card */}
        {numPrivateSpaces === 0 && (
          <UHCard defaultPadding className="primary-gradient flex flex-col col-span-2">
            <UHText variant="title-xl" className="my-4 text-typoInverted" textKey="ONBOARDING_WELCOME_TITLE" />
            <UHText className="flex-1 text-typoInverted" textKey="SHARED_TEXT_WELCOME_DESCRIPTION" />
            <div className="flex mt-6 space-x-4">
              <UHButton width="md" variant="neutral" titleKey="SPACE_CREATE_TITLE" onClick={onCreateSpace} />
              {inviteCount > 0 && (
                <UHButton
                  width="md"
                  variant="neutral"
                  title={invitationsString}
                  onClick={() => setShowingInvites(true)}
                />
              )}
            </div>
          </UHCard>
        )}
        {/* Spaces */}
        {spaces.map((space) => (
          <SpaceTile
            key={space.id}
            space={space}
            onOpen={() => onEnterSpace(space.id)}
            onShowDetails={() => history.pushPage('spaceDetail', space.id)}
          />
        ))}
        {/* Create space button */}
        {numPrivateSpaces > 0 && (
          <Center className="group">
            {!creatingSpace && (
              <Center
                col
                className="w-full h-full group-hover:bg-neutral-250 bg-transparent border-2 border-neutral-300/0 group-hover:border-neutral-300/100 rounded-3xl cursor-pointer transition-all duration-200"
                onClick={onCreateSpace}
              >
                <UHIcon
                  className="mb-1 text-neutral group-hover:text-primary text-2xl transition-colors duration-200"
                  icon="plus"
                />
                <UHText
                  className="text-neutral group-hover:text-primary italic transition-colors duration-200"
                  textKey="SPACE_CREATE_TITLE"
                />
              </Center>
            )}
            {creatingSpace && (
              <Center className="transform scale-50">
                <Loader colorClass="bg-primary" />
              </Center>
            )}
          </Center>
        )}
      </CardGrid>
      {/* Invites */}
      {numPrivateSpaces > 0 && inviteCount > 0 && (
        <div
          className="primary-gradient flex items-center self-center mt-10 p-4 w-full max-w-lg rounded-xl cursor-pointer"
          onClick={() => setShowingInvites(true)}
        >
          <UHIcon icon="envelope" className="mr-3 text-primary-300 text-xl" />
          <UHText
            variant="body-sm"
            className="mr-3 text-typoInverted"
            text={inviteCount === 1 ? openInvitesTextSingular : openInvitesTextPlural}
          />
          <div className="flex-1" />
          <UHButton
            onClick={() => setShowingInvites(true)}
            height="sm"
            width="md"
            variant="neutral"
            titleKey="SHARED_TEXT_VIEW"
          />
        </div>
      )}
      {/* Invite Overlay */}
      <InvitationScreen show={showingInvites} onClose={() => setShowingInvites(false)} />
      <ModalContainer showModal={showingSpaceCreate} onClickedOutside={() => setShowingSpaceCreate(false)}>
        <CreateSpaceView
          onDismiss={() => setShowingSpaceCreate(false)}
          onCreatedSpace={(spaceId) => history.pushPage('spaceDetail', spaceId)}
        />
      </ModalContainer>
    </PageContainer>
  );
};

export default SpacesOverviewScreen;
