import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import FormButtons from '../../form/FormButtons';
import UHText from '../text/UHText';
import OverlayCard, { OverlayCardProps } from './OverlayCard';

export type ConfirmModalData = {
  headerText?: string;
  headerTextKey?: DictionaryKeyType;
  bodyText?: string;
  bodyTextKey?: DictionaryKeyType;
  backText?: string;
  backTextKey?: DictionaryKeyType;
  confirmText?: string;
  confirmTextKey?: DictionaryKeyType;
  confirmAction?: () => void;
  cancelAction?: () => void;
  withoutCancel?: boolean;
  variant?: 'default' | 'danger';
};

type ConfirmModalProps = OverlayCardProps & ConfirmModalData;

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  headerText,
  headerTextKey,
  bodyText,
  bodyTextKey,
  backText,
  backTextKey = backText === undefined ? 'SHARED_BUTTON_BACK' : undefined,
  confirmText,
  confirmTextKey = confirmText === undefined ? 'SHARED_BUTTON_CONFIRM' : undefined,
  confirmAction,
  cancelAction,
  withoutCancel = false,
  width = 'sm',
  ...htmlProps
}) => {
  const localizedHeader = useLocalizedValue(headerText, headerTextKey);
  const localizedBody = useLocalizedValue(bodyText, bodyTextKey);
  const localizedBackButton = useLocalizedValue(backText, backTextKey);
  const localizedConfirmButton = useLocalizedValue(confirmText, confirmTextKey);

  const onConfirm = () => {
    confirmAction?.();
    htmlProps.onClose?.();
  };

  const onCancel = () => {
    cancelAction?.();
    htmlProps.onClose?.();
  };

  return (
    <OverlayCard onClose={onCancel} title={localizedHeader} width={width} {...htmlProps}>
      <UHText className="mb-10 mt-4" text={localizedBody} />
      <FormButtons
        cancelText={localizedBackButton}
        submitText={localizedConfirmButton}
        onCancel={withoutCancel ? undefined : onCancel}
        onSubmit={onConfirm}
        variant={htmlProps.variant}
      />
    </OverlayCard>
  );
};

export default ConfirmModal;
