import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { allPages, Pages } from 'src/services/pages/pageService';

export type UHHistory = {
  navigate: NavigateFunction;
  location: Location;
  pushPage: (page: Pages, spaceId?: number | undefined, params?: Record<string, string>) => void;
  replacePage: (page: Pages, spaceId?: number | undefined) => void;
};

export const useUHHistory = (): UHHistory => {
  const navigate = useNavigate();
  const location = useLocation();

  const getPath = (page: Pages, spaceId: number | undefined, params?: Record<string, string>): string => {
    let url = allPages[page].url;
    if (spaceId) url = url.replaceAll(':spaceId', spaceId.toString());
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        url = url.replaceAll(`:${key}`, value);
      });
    }
    return url;
  };

  const pushPage = (page: Pages, spaceId: number | undefined = undefined, params?: Record<string, string>): void => {
    navigate(getPath(page, spaceId, params));
  };

  const replacePage = (page: Pages, spaceId: number | undefined = undefined): void => {
    navigate(getPath(page, spaceId), { replace: true });
  };

  const UHHistory: UHHistory = {
    navigate,
    location,
    pushPage,
    replacePage,
  };

  return UHHistory;
};
