import React, { useEffect } from 'react';
import { useConference } from './hooks/useConference';
import { Space } from 'src/types/spaces/SpaceType';
import ConferenceWrapper from './ConferenceWrapper';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  space: Space;
  onLeftConference: () => void;
  onConferenceEnded?: () => void;
  cameraEnabled: boolean;
  micMuted: boolean;
  jwt?: string;
  displayName?: string;
};

const ConferenceView: React.FC<Props> = ({
  space,
  onLeftConference,
  onConferenceEnded,
  cameraEnabled,
  micMuted,
  jwt,
  displayName,
  ...htmlProps
}) => {
  const conferenceData = useConference(
    space,
    onLeftConference,
    onConferenceEnded ?? onLeftConference,
    cameraEnabled,
    micMuted,
    jwt,
    displayName,
  );

  useEffect(() => {
    return conferenceData.onLeaveConference;
  }, []);

  return <ConferenceWrapper space={space} conferenceData={conferenceData} {...htmlProps} />;
};

export default ConferenceView;
