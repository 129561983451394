import clsx from 'clsx';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { resetPasswordSubmit } from 'src/services/api/userAPI';
import { ResetPasswordCodeData, resetPasswordCodeSchema } from 'src/services/forms/schemaService';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import FormikButton from 'src/views/components/core/buttons/FormikButton';
import FormikTextField from 'src/views/components/core/inputs/FormikTextField';
import UHTextField from 'src/views/components/core/inputs/UHTextField';
import UHText from 'src/views/components/core/text/UHText';
import { useLocalizedKey } from 'src/views/hooks/useLocalizedString';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  email: string;
  onCancel: () => void;
  onCompleted: () => void;
};

const ResetPasswordCodeForm: React.FC<Props> = ({ email, onCancel, onCompleted, className, ...htmlProps }) => {
  const [errorKey, setErrorKey] = useState<DictionaryKeyType>();
  const [loading, setLoading] = useState(false);

  const handleErrorCode = (code: string) => {
    if (code === 'NetworkError') setErrorKey('SHARED_ERROR_NETWORK_ERROR');
    else if (code === 'InvalidPasswordException') setErrorKey('AUTH_SCREEN_ERROR_INVALID_PASSWORD');
    else if (code === 'UsernameExistsException') setErrorKey('AUTH_SCREEN_ERROR_USERNAME_EXISTS');
    else if (code === 'CodeMismatchException') setErrorKey('AUTH_SCREEN_ERROR_INVALID_VERIFICATION_CODE');
    else if (code === 'LimitExceededException') setErrorKey('AUTH_SCREEN_ERROR_ATTEMPT_LIMIT_EXCEEDED');
    else setErrorKey('SHARED_ERROR_UNKNOWN_ERROR');
  };

  const resetErrorCode = () => setErrorKey(undefined);

  const onSubmit = async (values: ResetPasswordCodeData) => {
    setLoading(true);
    try {
      await resetPasswordSubmit(email, values.resetPasswordCode, values.password);
      onCompleted();
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code) handleErrorCode(error.code);
      setLoading(false);
    }
  };

  const localizedEnterVerificationCode = useLocalizedKey('AUTH_SCREEN_CALL_TO_ACTION_ENTER_VERIFICATION_CODE', email);

  return (
    <div
      className={clsx('flex flex-col items-center', { 'pointer-events-none select-none': loading }, className)}
      {...htmlProps}
    >
      {/* Title */}
      <UHText variant="title-xl" className="mb-12 mt-8 text-primary" textKey="AUTH_SCREEN_SET_NEW_PASSWORD" />
      {/* Explanation Text */}
      <UHText variant="body-sm" className="mb-4" text={localizedEnterVerificationCode} />
      {/* Form */}
      <Formik<ResetPasswordCodeData>
        validateOnMount={true}
        initialValues={{ resetPasswordCode: '', password: '', confirmPassword: '' }}
        validationSchema={resetPasswordCodeSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <div className="flex flex-col w-full">
              {/* TextField: E-Mail hint for the browser */}
              <UHTextField className="hidden" type="email" value={email} />
              {/* TextField: Code */}
              <FormikTextField
                className="mb-10"
                name="resetPasswordCode"
                labelKey="AUTH_SCREEN_PLACEHOLDER_VERIFICATION_CODE"
                autoComplete="one-time-code"
                errorTextKey={errorKey}
                onChangeText={resetErrorCode}
              />
              {/* TextField: Password */}
              <FormikTextField
                className="mb-10"
                name="password"
                labelKey="AUTH_SCREEN_PASSWORD_PLACEHOLDER"
                type="password"
                autoComplete="new-password"
                errorTextKey={errorKey}
                onChangeText={resetErrorCode}
              />
              {/* TextField: Confirm Password */}
              <FormikTextField
                className="mb-10"
                name="confirmPassword"
                labelKey="AUTH_SCREEN_CONFIRM_PASSWORD_PLACEHOLDER"
                type="password"
                autoComplete="new-password"
                onEnter={handleSubmit}
              />
              {/* Submit button */}
              <FormikButton className="self-center min-w-max" titleKey="AUTH_SCREEN_RESET_PASSWORD" loading={loading} />
            </div>
          );
        }}
      </Formik>
      {/* Back button */}
      <UHText className="self-center mt-8 text-primary" textKey="SHARED_BUTTON_BACK" onClick={onCancel} />
    </div>
  );
};

export default ResetPasswordCodeForm;
