import clsx from 'clsx';
import React from 'react';
import { actions } from 'src/store/store';
import UHButton from 'src/views/components/core/buttons/UHButton';
import UHText from 'src/views/components/core/text/UHText';
import { OnboardingState } from '../OnboardingScreen';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  setOnboardingState: (state: OnboardingState) => void;
};

const OnboardingIntroView: React.FC<Props> = ({ setOnboardingState, className, ...htmlProps }) => {
  return (
    <div className={clsx('flex flex-col items-center', className)} {...htmlProps}>
      <UHText variant="title-xl" className="mb-12 mt-8 text-primary" textKey="ONBOARDING_WELCOME_TITLE" />
      <UHText className="mb-8" textKey="ONBOARDING_WELCOME_TEXT_CONFIRMATION" />
      <UHText className="text-center" textKey="ONBOARDING_WELCOME_TEXT_CALL_TO_ACTION" />
      <UHButton
        className="mt-12 min-w-max"
        titleKey="ONBOARDING_WELCOME_BUTTON_START"
        onClick={() => setOnboardingState('name')}
      />
      {/* Back button */}
      <UHText className="mt-4 text-primary" textKey="SHARED_BUTTON_RETURN_TO_LOGIN" onClick={actions.signOut} />
    </div>
  );
};

export default OnboardingIntroView;
