import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { env } from 'src/services/environment/envService';
import { getUserIdFromJWT } from 'src/services/jwt/jwtService';
import Conference from 'src/services/mediasoup/Conference';
import ConferenceService from 'src/services/mediasoup/conferenceService';
import { useTypedSelector } from 'src/store/store';
import { ErrorCode, ErrorMessage, User, UserStatus } from 'src/types/conference';
import { AppError } from 'src/helpers/Errors';
import { ConversationRenderData, SessionRenderData } from 'src/types/conference/render/ConferenceViewTypes';
import { Space } from 'src/types/spaces/SpaceType';
import { MockConference, useMockConference } from './useMockConference';
import { playSound } from 'src/services/audio/audioService';
import { MediaStreamData, useMediaStreams } from 'src/views/hooks/streams/useMediaStreams';

export type ConferenceData = {
  conference: Conference;
  sessionRenderData: SessionRenderData;
  reconnecting: boolean;
  isMock: boolean;
  mockConference: MockConference;
  myUser: User;
  myUserIsInConversation: boolean;
  /** One user joins or starts the conversation with another user. The action is not additionally verified. */
  onJoinedUser: (user1: User, user2: User) => void;
  /** Leave my current conversation if any. */
  onLeaveConversation: () => void;
  /** Change my user's status. */
  onChangeStatus: (status: UserStatus) => void;
  /** Toggle user display sharing */
  onToggleDisplay: (status: boolean) => void;
  /** Switch camera on/off. */
  onStillImageOnlyMode: (value: boolean) => void;
  onMicStatusChanged: (muted: boolean) => void;
  /** Leave the conference. */
  onLeaveConference: () => void;
  mediaStreamData: MediaStreamData;
};

export const useConference = (
  space: Space,
  onLeftConference: () => void,
  onConferenceEnded: () => void,
  cameraEnabled: boolean,
  micMuted: boolean,
  jwt?: string,
  displayName?: string,
): ConferenceData => {
  // Reconnecting
  const [reconnecting, setReconnecting] = useState(true);

  // Media streams
  const mediaStreamData = useMediaStreams();

  // User
  const user = useTypedSelector((state) => state.user.user);
  const myUserId = jwt ? getUserIdFromJWT(jwt) : user.id;
  // Conference
  const conference = useRef<Conference>(new Conference()).current;

  // Conference state
  const [conferenceState, updateConferenceState] = useReducer(
    ConferenceService.conferenceReducer,
    ConferenceService.initialConferenceState,
  );
  const conferenceStateRef = useRef(conferenceState);
  const localUser = conferenceState.users.find((u: User) => u.id === conference.userId);

  const updateUserConnectionScore = (userId: string, isConnectionScoreWeak: boolean) => {
    const userFound = conferenceStateRef.current.users.find((u: User) => u.id === userId);
    if (userFound) {
      userFound.isConnectionScoreWeak = isConnectionScoreWeak;
      updateConferenceState(conferenceStateRef.current);
    }
  };

  const enterSpaceErrorCallback = (error: AppError): void => {
    console.log(`Enter space error callback`);
    error.logError();

    if (error.code === ErrorCode.INTERNAL && error.message === ErrorMessage.CONFERENCE_TRANSFERED) {
      conference.enterSpace(
        displayName ?? user.displayName,
        space.id,
        myUserId,
        mediaStreamData.localVideoStream ?? null,
        conference.stillImageOnlyMode,
        conference.micMuted,
        enterSpaceErrorCallback,
        jwt,
      );
      setReconnecting(false);
    } else {
      onConferenceEnded();
    }
  };

  /** Leave the conference. */
  const onLeaveConference = (): void => {
    conference.exitSpace();
    conference.disconnectUser();
    onLeftConference();
  };

  // Initialize conference
  useEffect(() => {
    if (mediaStreamData.isLoadingPermissions) return;
    conference.onUpdateConferenceState = updateConferenceState;
    conference.onUpdateUserConnectionScore = updateUserConnectionScore;
    conference.onUpdateReconnectFlag = setReconnecting;
    conference.enterSpace(
      displayName ?? user.displayName,
      space.id,
      myUserId,
      mediaStreamData.localVideoStream ?? null,
      !cameraEnabled,
      micMuted,
      enterSpaceErrorCallback,
      jwt,
    );

    return onConferenceEnded;
  }, [mediaStreamData.isLoadingPermissions]);

  // Update local user status when conference updates
  useEffect(() => {
    conferenceStateRef.current = conferenceState;
    if (localUser) {
      localUser.isMe = true;
      conference.userStatus = localUser.status;
    }
    // Avatars
    conferenceStateRef.current.users.forEach((u) => {
      u.avatar = space.members.find((m) => m.userId === u.id)?.avatar;
    });
    // Exit conference
    if (conferenceState.action.type === 'EXIT_CONFERENCE' && conferenceState.action.actor === myUserId)
      onConferenceEnded();
  }, [conferenceState]);

  // Mock data
  const isMock = env.DebugStreams;
  const mockConference = useMockConference();

  // Session render data
  const conferenceSessionRenderData = useMemo(() => {
    return {
      users: conferenceState.users,
      conversations: conferenceState.conversations.map((c) => {
        return {
          id: c.id,
          users: c.peerIds.flatMap((peerId) => {
            const u = conferenceState.users.find((u1) => u1.id === peerId);
            return u ? [u] : [];
          }),
        };
      }),
    };
  }, [conferenceState]);

  const sessionRenderData = isMock ? mockConference.sessionRenderData : conferenceSessionRenderData;

  // My User
  const myUser: User = sessionRenderData.users.find((u) => u.isMe) ?? {
    id: user.id,
    type: 'user',
    name: user.displayName,
    status: 'Active',
    imageOnlyMode: conference.stillImageOnlyMode,
    sharingDisplay: conference.sharingDisplay,
    micMuted: conference.micMuted,
    streams: [],
    isMe: true,
    isConnectionScoreWeak: false,
  };

  const getConversationOfUser = (userId: string): ConversationRenderData | undefined => {
    return sessionRenderData.conversations.find((c) => c.users.find((u) => u.id === userId));
  };

  /** One user joins or starts the conversation with another user. The action is not additionally verified. */
  const onJoinedUser = async (u1: User, u2: User) => {
    if (isMock) {
      mockConference.joinUser(u1, u2);
      return;
    }

    const u1Conversation = getConversationOfUser(u1.id);
    const u2Conversation = getConversationOfUser(u2.id);
    // Dragged me to other user
    if (u1.isMe) {
      if (u1Conversation) {
        await conference.leaveConversation(u1Conversation.id);
      }

      if (!u2Conversation) {
        conference.startConversation(u2.id);
      } else {
        conference.joinConversation(u2Conversation.id);
      }
    }
    // Dragged other user to me
    else if (!u2Conversation) conference.startConversation(u1.id);
    else conference.addUserToConversation(u1.id, u2Conversation.id);
  };

  /** Leave my current conversation if any. */
  const onLeaveConversation = () => {
    if (isMock) {
      if (myUser) mockConference.leaveConversation(myUser);
    } else {
      const myConversation = sessionRenderData.conversations.find((c) => c.users.some((u) => u.isMe));
      if (myConversation) conference.leaveConversation(myConversation.id);
    }
  };

  /** Change my user's status. */
  const onChangeStatus = (status: UserStatus): void => {
    if (!isMock) conference.changeStatus(status);
  };

  const onToggleDisplay = (status: boolean): void => {
    conference.toggleDisplay(status);
  };

  /** Toggle my user's camera */
  const onStillImageOnlyMode = (status: boolean): boolean => {
    conference.changeStillImageOnlyMode(status);
    mediaStreamData.onSetCameraEnabled(!status);
    return status;
  };

  const onMicStatusChanged = (muted: boolean): void => {
    conference.changeMicStatus(muted);
    playSound('sfx_enter_conversation');
  };

  useEffect(() => {
    if (mediaStreamData.localVideoStream) conference.userCameraChanged(mediaStreamData.localVideoStream);
  }, [mediaStreamData.localVideoStream]);

  useEffect(() => {
    if (mediaStreamData.localAudioStream) conference.userMicrophoneChanged(mediaStreamData.localAudioStream);
  }, [mediaStreamData.localAudioStream]);

  useEffect(() => {
    if (!mediaStreamData.localVideoStream && !reconnecting && !myUser.imageOnlyMode) onStillImageOnlyMode(true);
  }, [mediaStreamData.localVideoStream, reconnecting]);

  return {
    conference,
    sessionRenderData,
    reconnecting,
    isMock,
    mockConference,
    myUser,
    myUserIsInConversation: !!getConversationOfUser(myUser.id),
    onJoinedUser,
    onLeaveConversation,
    onChangeStatus,
    onToggleDisplay,
    onStillImageOnlyMode,
    onMicStatusChanged,
    onLeaveConference,
    mediaStreamData,
  };
};
