import { mediaDevices } from 'src/types/conference/media/mediaStreamType';
import { env } from '../environment/envService';
import hark from 'hark';
import MediaDevices from 'media-devices';

let cameraEnabledSetting = true;

export const getCameraEnabledSetting = (): boolean => {
  return cameraEnabledSetting;
};

export const setCameraEnabledSetting = (v: boolean): void => {
  cameraEnabledSetting = v;
};

export type VideoInput = {
  id: string;
  name: string;
};

export type AudioInput = {
  id: string;
  name: string;
};

let selectedVideoDevice: VideoInput | undefined;
let selectedAudioDevice: AudioInput | undefined;
let videoDevicesAvailable: VideoInput[] = [];
let audioDevicesAvailable: AudioInput[] = [];
let videoMediaStream: MediaStream | undefined;
let audioMediaStream: MediaStream | undefined;
let audioSpeechEvents: hark.Harker | undefined;
let displayMediaStream: MediaStream | undefined;

export const getSelectedVideoDevice = (): VideoInput | undefined => {
  return selectedVideoDevice;
};

export const getSelectedAudioDevice = (): AudioInput | undefined => {
  return selectedAudioDevice;
};

export const availableVideoDevices = (): VideoInput[] => {
  return videoDevicesAvailable;
};

export const availableAudioDevices = (): AudioInput[] => {
  return audioDevicesAvailable;
};

export const getUserVideoStream = (): MediaStream | undefined => {
  return videoMediaStream;
};

export const getUserAudioStream = (): MediaStream | undefined => {
  return audioMediaStream;
};

export const getAudioSpeechEvents = (): hark.Harker | undefined => {
  return audioSpeechEvents;
};

/**
 * Gets the user's video media from the device.
 *
 * @async
 * @returns {PromiseLike<MediaStream>}
 */
export const getUserVideoMedia = async (device: VideoInput | undefined = undefined): Promise<MediaStream> => {
  const stream = await mediaDevices.getUserMedia({
    video: {
      deviceId: device?.id,
      width: 1280,
      height: 720,
      facingMode: 'user',
    },
  });

  if (typeof stream === 'boolean') {
    throw new Error('Could not get user video media');
  }

  return stream;
};

/**
 * Gets the user's audio media from the device.
 *
 * @async
 * @returns {PromiseLike<MediaStream>}
 */
export const getUserAudioMedia = async (device: AudioInput | undefined = undefined): Promise<MediaStream> => {
  const stream = await mediaDevices.getUserMedia({
    audio: { deviceId: device?.id, echoCancellation: true, noiseSuppression: true },
  });

  if (typeof stream === 'boolean') {
    throw new Error('Could not get user audio media');
  }

  return stream;
};

/**
 * Gets the user's display media.
 *
 * @async
 * @returns {PromiseLike<MediaStream>}
 */
export const getUserDisplayMedia = async (): Promise<MediaStream> => {
  const stream = await mediaDevices.getDisplayMedia({
    audio: false,
    video: {
      displaySurface: 'monitor',
      logicalSurface: true,
      cursor: true,
      width: { max: 1920 },
      height: { max: 1080 },
      frameRate: { max: 30 },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
  });

  if (typeof stream === 'boolean') {
    throw new Error('Could not get user display media');
  }

  return stream;
};

export const loadUserVideoStream = async (device: VideoInput | undefined = undefined): Promise<MediaStream> => {
  if (videoMediaStream && (selectedVideoDevice?.id === device?.id || device === undefined)) return videoMediaStream;
  if (videoMediaStream) cleanupUserVideoStream();

  if (device) selectedVideoDevice = device;
  const loadedVideoStream = env.DebugStreams ? new MediaStream() : await getUserVideoMedia(selectedVideoDevice);
  if (videoMediaStream) cleanupUserVideoStream();
  videoMediaStream = loadedVideoStream;
  return videoMediaStream;
};

export const loadUserAudioStream = async (
  device: AudioInput | undefined = undefined,
): Promise<MediaStream | undefined> => {
  if (audioMediaStream && (selectedAudioDevice?.id === device?.id || device === undefined)) return audioMediaStream;
  if (audioMediaStream) cleanupUserAudioStream();

  if (device) selectedAudioDevice = device;
  const loadedAudioStream = env.DebugStreams ? new MediaStream() : await getUserAudioMedia(selectedAudioDevice);
  if (audioMediaStream) cleanupUserAudioStream();
  audioMediaStream = loadedAudioStream;
  audioSpeechEvents = hark(loadedAudioStream, {});
  return audioMediaStream;
};

export const loadUserDisplayStream = async (): Promise<MediaStream> => {
  if (displayMediaStream) return displayMediaStream;

  const loadedDisplayStream = env.DebugStreams ? new MediaStream() : await getUserDisplayMedia();
  if (displayMediaStream) cleanupUserDisplayStream();

  displayMediaStream = loadedDisplayStream;
  return displayMediaStream;
};

export const cleanupUserVideoStream = (): void => {
  if (videoMediaStream) {
    videoMediaStream?.getTracks().forEach((track) => {
      track.stop();
    });
    videoMediaStream = undefined;
  }
};

export const cleanupUserAudioStream = (): void => {
  if (audioMediaStream) {
    audioSpeechEvents?.stop();
    audioMediaStream?.getTracks().forEach((track) => {
      track.stop();
    });
    audioMediaStream = undefined;
    audioSpeechEvents = undefined;
  }
};

export const cleanupUserDisplayStream = (): void => {
  if (displayMediaStream) {
    displayMediaStream?.getTracks().forEach((track) => {
      track.stop();
    });
    displayMediaStream = undefined;
  }
};

export const cleanupUserStreams = (): void => {
  cleanupUserVideoStream();
  cleanupUserAudioStream();
  cleanupUserDisplayStream();
};

export const getAvailableVideoInputs = async (): Promise<VideoInput[]> => {
  const videoInputs: VideoInput[] = [];
  const devices = await MediaDevices.enumerateDevices();
  devices.forEach((device, index) => {
    if (device.kind === 'videoinput' && device.deviceId) {
      videoInputs.push({
        id: device.deviceId,
        name:
          device.label ?? videoDevicesAvailable.find((v) => v.id === device.deviceId)?.name ?? `Camera ${index + 1}`,
      });
    }
  });
  videoDevicesAvailable = videoInputs;
  return videoInputs;
};

export const getAvailableAudioInputs = async (): Promise<AudioInput[]> => {
  const audioInputs: AudioInput[] = [];
  const devices = await MediaDevices.enumerateDevices();
  devices.forEach((device, index) => {
    if (device.kind === 'audioinput' && device.deviceId) {
      audioInputs.push({
        id: device.deviceId,
        name:
          device.label ??
          audioDevicesAvailable.find((v) => v.id === device.deviceId)?.name ??
          `Microphone ${index + 1}`,
      });
    }
  });
  audioDevicesAvailable = audioInputs;
  return audioInputs;
};
