import io, { ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import { env } from '../environment/envService';
import socketPromise from './socketPromise';

export default class Signaling {
  socket: Socket | undefined;

  connect = (spaceId: number): void => {
    const opts: Partial<ManagerOptions & SocketOptions> = {
      path: '/server',
      transports: ['websocket'],
      query: {
        spaceId: spaceId.toString(),
      },
      timeout: 10000,
    };
    const serverUrl = env.ConferenceEndpoint;

    this.socket = io(serverUrl, opts);
    this.socketPromiseRequest = socketPromise(this.socket);

    this.socket.on('error', (err) => {
      console.error(`error: ${err.message}`);
    });
  };

  disconnect = (): void => {
    this.socket?.disconnect();
  };

  socketPromiseRequest: ((type: string, data: unknown) => Promise<unknown>) | undefined;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  requestPromise = async (key: string, value?: any): Promise<unknown> => {
    // console.log(`uniqueId: ${getUniqueId()}`);
    return this.socketPromiseRequest?.(key, value ?? null);
  };

  /* estlint-enable @typescript-eslint/explicit-module-boundary-types */
  request = (key: string, value?: unknown): any => {
    // console.log(`Send ${key}: ${value}`);
    this.socket?.emit(key, value);
  };
  /* eslint-enable @typescript-eslint/no-explicit-any */
}
