import { ConferenceState, User, UserStream, ConferenceAction } from 'src/types/conference';

const initialConferenceState: ConferenceState = {
  users: [],
  conversations: [],
  action: {
    id: 0,
    type: ConferenceAction.STATUS_CHANGE,
    actor: '',
    data: null,
  },
};

/**
 * Custom user copy method to ensure we retain mediastream objects
 *
 * @param conferenceState Current Conference State
 * @param users List of users from the new state
 * @returns List of users in the state, keeping stream objects
 */
export const customUserCopy = (conferenceState: ConferenceState, users: User[]): User[] => {
  return users.map((user: User) => {
    let userFromPreviousState: User | undefined = conferenceState.users.find((u: User) => u.id === user.id);
    if (userFromPreviousState) userFromPreviousState = { ...userFromPreviousState };
    const existingUser = userFromPreviousState;

    // User was in conference before
    if (existingUser) {
      // Copy Status
      existingUser.status = user.status;
      existingUser.imageOnlyMode = user.imageOnlyMode;
      existingUser.sharingDisplay = user.sharingDisplay;
      existingUser.micMuted = user.micMuted;

      // Copy Streams
      user.streams.forEach((userStream: UserStream) => {
        let existingUserStream: UserStream | undefined;
        if (userStream.kind === 'video') {
          existingUserStream = userFromPreviousState?.streams.find(
            (s: UserStream) => userStream.kind === s.kind && userStream.appData?.display === s.appData?.display,
          );
        } else if (userStream.kind === 'audio') {
          existingUserStream = userFromPreviousState?.streams.find((s: UserStream) => userStream.kind === s.kind);
        }

        // New stream
        if (!existingUserStream) {
          existingUser.streams.push(userStream);
        }
        // Changed stream
        else if (
          !existingUserStream.stream ||
          (userStream.stream && existingUserStream.stream.id !== userStream.stream.id)
        ) {
          existingUserStream.stream = userStream.stream;
        }
      });

      return existingUser;
    }
    return user;
  });
};

/**
 * Reducer for the Conference State
 *
 * @param state Conference State currently in the Conference Screen
 * @param updatedArg Update to the Conference State
 * @returns {ConferenceState} Updated Conference State
 */

export const conferenceReducer = (state: ConferenceState, updatedArg: ConferenceState): ConferenceState => {
  // if the type of update argument is an object
  if (updatedArg.constructor === Object) {
    return {
      action: updatedArg.action,
      users: customUserCopy(state, updatedArg.users),
      conversations: updatedArg.conversations,
    };
  }

  return { ...state };
};

const ConferenceService = {
  initialConferenceState,
  conferenceReducer,
  customUserCopy,
};

export default ConferenceService;
