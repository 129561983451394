import { DictionaryData } from 'src/types/localization/dictionaryType';
import { fetchDictionary, fetchLanguages } from 'src/services/api/localizationAPI';
import LocalizationService from 'src/services/localization/localizationService';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { actions, RootState } from '../store';
import { LanguagesDict } from './types';

export const fetchDictionaryThunk = createAsyncThunk<DictionaryData, string | undefined, { state: RootState }>(
  '@@localization/fetchDictionary',
  async (code: string | undefined, { getState }) => {
    const language = code ?? getState().user.user.languageCode ?? 'en';
    const timestamp = LocalizationService.getLastTimestamp(language) ?? 0;
    const data: DictionaryData = { language, timestamp, dictionary: getState().localization.dictionary };

    // Only load new dictionary if its different from the previously loaded dictionary
    if (getState().localization.currentLanguageCode !== language) {
      actions.fetchAnnouncements(language);
      data.dictionary = await fetchDictionary(language, timestamp);
    }

    return data;
  },
);

export const fetchLanguagesThunk = createAsyncThunk(
  '@@localization/fetchLanguages',
  async (): Promise<LanguagesDict> => {
    return fetchLanguages();
  },
);
