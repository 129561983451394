import clsx from 'clsx';
import React, { useState } from 'react';
import { changePassword } from 'src/services/api/userAPI';
import { ChangePasswordData, changePasswordSchema } from 'src/services/forms/schemaService';
import { useTypedSelector } from 'src/store/store';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import OverlayCard, { OverlayCardProps } from 'src/views/components/core/containers/OverlayCard';
import FormikTextField from 'src/views/components/core/inputs/FormikTextField';
import UHTextField from 'src/views/components/core/inputs/UHTextField';
import UHForm from 'src/views/components/form/UHForm';

type Props = OverlayCardProps;

const ChangePasswordForm: React.FC<Props> = ({ className, width = 'sm', ...htmlProps }) => {
  const [errorKey, setErrorKey] = useState<DictionaryKeyType>();
  const [loading, setLoading] = useState(false);
  const email = useTypedSelector((state) => state.user.user.email);

  const handleErrorCode = (code: string) => {
    if (code === 'NetworkError') setErrorKey('SHARED_ERROR_NETWORK_ERROR');
    else if (code === 'InvalidPasswordException') setErrorKey('AUTH_SCREEN_ERROR_INVALID_PASSWORD');
    else if (code === 'NotAuthorizedException') setErrorKey('AUTH_SCREEN_ERROR_INVALID_PASSWORD');
    else if (code === 'LimitExceededException') setErrorKey('AUTH_SCREEN_ERROR_ATTEMPT_LIMIT_EXCEEDED');
    else setErrorKey('SHARED_ERROR_UNKNOWN_ERROR');
  };

  const resetErrorCode = () => setErrorKey(undefined);

  const onSubmit = async (values: ChangePasswordData) => {
    setLoading(true);
    try {
      await changePassword(values.oldPassword, values.password);
      htmlProps.onClose?.();
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code) handleErrorCode(error.code);
      setLoading(false);
    }
  };

  return (
    <OverlayCard
      width={width}
      titleKey="CHANGE_PASSWORD_TITLE"
      className={clsx('flex flex-col items-center', { 'pointer-events-none select-none': loading }, className)}
      {...htmlProps}
    >
      <UHForm<ChangePasswordData>
        data={{ oldPassword: '', password: '', confirmPassword: '' }}
        schema={changePasswordSchema}
        onSubmit={onSubmit}
        onCancel={htmlProps.onClose}
      >
        {/* TextField: E-Mail hint for the browser */}
        <UHTextField className="hidden" type="email" value={email} />
        {/* TextField: Old password */}
        <FormikTextField
          className="mb-10"
          name="oldPassword"
          labelKey="CHANGE_PASSWORD_LABEL_OLD_PASSWORD"
          type="password"
          autoComplete="password"
          errorTextKey={errorKey}
          onChangeText={resetErrorCode}
        />
        {/* TextField: Password */}
        <FormikTextField
          className="mb-10"
          name="password"
          labelKey="CHANGE_PASSWORD_LABEL_NEW_PASSWORD"
          type="password"
          autoComplete="new-password"
          onChangeText={resetErrorCode}
        />
        {/* TextField: Confirm Password */}
        <FormikTextField
          className="mb-10"
          name="confirmPassword"
          labelKey="AUTH_SCREEN_CONFIRM_PASSWORD_PLACEHOLDER"
          type="password"
          autoComplete="new-password"
          submitOnEnter
        />
      </UHForm>
    </OverlayCard>
  );
};

export default ChangePasswordForm;
