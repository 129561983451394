import React from 'react';
import UHText from 'src/views/components/core/text/UHText';
import QuestionAnswer from './components/QuestionAnswer';

const SupportScreen: React.FC = () => (
  <main className="bg-card">
    {/* Body with sections */}
    <div className="page-width flex flex-col items-center">
      <div className="flex flex-col mb-32 mt-16 max-w-2xl">
        <UHText tag="h1" variant="listTitle" textKey="WEBSITE.SUPPORT_TITLE" className="mb-4" />
        {/* Text Content */}
        <div className="flex flex-col space-y-8">
          <div>
            <UHText tag="h2" variant="body" className="mb-2 font-bold" textKey="WEBSITE.SUPPORT_FAQ_TITLE" />
            <div className="flex flex-col space-y-6">
              <QuestionAnswer questionKey="WEBSITE.SUPPORT_QUESTION1" answerKey="WEBSITE.SUPPORT_ANSWER1" />
              <QuestionAnswer questionKey="WEBSITE.SUPPORT_QUESTION2" answerKey="WEBSITE.SUPPORT_ANSWER2" />
              <QuestionAnswer questionKey="WEBSITE.SUPPORT_QUESTION3" answerKey="WEBSITE.SUPPORT_ANSWER3" />
            </div>
          </div>
          <div>
            <UHText tag="h3" variant="body" className="mb-2 font-bold" textKey="WEBSITE.SUPPORT_CONTACT_TITLE" />
            <UHText tag="p" variant="body" className="text-neutral" textKey="WEBSITE.SUPPORT_CONTACT_TEXT" />
            <div className="flex flex-row space-x-2">
              <UHText tag="p" variant="body" textKey="WEBSITE.SUPPORT_CONTACT_CTA" />
              <a href="mailto:support@nevolane.com" className="cursor-pointer">
                <UHText tag="p" variant="body" className="text-primary cursor-pointer" text="support@nevolane.com" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
);

export default SupportScreen;
