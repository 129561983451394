import { useFormikContext } from 'formik';
import React from 'react';
import TextButton, { UHButtonProps } from './UHButton';

const FormikButton: React.FC<UHButtonProps> = (props) => {
  const { submitForm, isValid } = useFormikContext();
  return <TextButton {...props} onClick={submitForm} variant={isValid ? props.variant : 'disabled'} type="submit" />;
};

export default FormikButton;
