import clsx from 'clsx';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { clamp01 } from 'src/services/math/utils';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  translation?: number;
  distance?: number;
  bottomOffset?: number;
  invert?: boolean;
}

const FadeInUpScroll: React.FC<Props> = ({
  translation = 100,
  distance = 300,
  bottomOffset = 150,
  invert = false,
  className,
  children,
  ...htmlProps
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [percentage, setPercentage] = useState(0);
  distance = Math.min(window.innerHeight / 4, distance);
  translation = Math.min(window.innerHeight / 12, translation);
  bottomOffset = Math.min(window.innerHeight / 3, bottomOffset);

  const onScroll = () => {
    const topPosition = ref.current?.getBoundingClientRect().y ?? 0;
    const scrollPosition = window.scrollY + window.innerHeight;
    let p = clamp01((scrollPosition - topPosition - bottomOffset) / distance) ** 0.5;
    if (invert) p = 1 - p;
    setPercentage(p);
  };

  useLayoutEffect(() => {
    onScroll();
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
    };
  }, []);

  return (
    <div
      className={clsx(className, { 'pointer-events-none': percentage < 0.1 })}
      style={{ ...htmlProps.style, opacity: percentage, transform: `translateY(${(1 - percentage) * translation}px)` }}
      ref={ref}
      {...(htmlProps as Record<string, unknown>)}
    >
      {children}
    </div>
  );
};

export default FadeInUpScroll;
