import clsx from 'clsx';
import React from 'react';
import { getUserInitials } from 'src/services/contacts/userProfileService';
import { env } from 'src/services/environment/envService';
import { useTypedSelector } from 'src/store/store';
import UHImage from '../core/image/UHImage';
import UHText, { UHTextProps } from '../core/text/UHText';
import Center from '../core/transform/Center';

type SizeVariant = 'default' | 'large';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  showName?: boolean;
  size?: SizeVariant;
};

const ProfileIcon: React.FC<Props> = ({ className, showName = true, size = 'default', ...htmlProps }) => {
  const user = useTypedSelector((state) => state.user.user);
  const initials = getUserInitials(user);

  const textVariant: Record<SizeVariant, UHTextProps['variant']> = {
    default: 'title-sm',
    large: 'title-xl',
  };

  return (
    <div
      className={clsx('flex items-center select-none', { 'cursor-pointer': htmlProps.onClick }, className)}
      {...htmlProps}
    >
      {user.avatar && (
        <UHImage
          className={clsx('transitionfix flex items-center justify-center w-8 h-8 bg-neutral-300 rounded-full', {
            'w-16 h-16': size === 'large',
          })}
          src={`${env.ImageCDN}/${user.avatar}${size === 'default' ? '-xs' : '-sm'}`}
          key={user.avatar}
        />
      )}
      {!user.avatar && (
        <Center
          className={clsx('flex items-center justify-center w-8 h-8 bg-secondary rounded-full overflow-hidden', {
            'w-16 h-16': size === 'large',
          })}
        >
          <UHText className="text-typoInverted whitespace-nowrap" variant={textVariant[size]} text={initials} />
        </Center>
      )}
      {showName && <UHText className="ml-3 text-primary" variant="title-sm" text={user.displayName} />}
    </div>
  );
};

export default ProfileIcon;
