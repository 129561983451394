import React, { useState } from 'react';
import { AuthState } from '../AuthScreen';
import ResetPasswordCodeForm from './ResetPasswordCodeForm';
import ResetPasswordEmailForm from './ResetPasswordEmailForm';

type ResetPasswortState = 'enterEmail' | 'enterCode';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  setAuthState: (state: AuthState) => void;
};

const ResetPasswordForm: React.FC<Props> = ({ setAuthState, ...htmlProps }) => {
  const [email, setEmail] = useState<string>();
  const resetPasswordState: ResetPasswortState = email ? 'enterCode' : 'enterEmail';

  if (resetPasswordState === 'enterCode' && email)
    return (
      <ResetPasswordCodeForm
        onCompleted={() => setAuthState('login')}
        email={email}
        onCancel={() => setAuthState('login')}
        {...htmlProps}
      />
    );
  return <ResetPasswordEmailForm onCompleted={setEmail} onCancel={() => setAuthState('login')} {...htmlProps} />;
};

export default ResetPasswordForm;
