import clsx from 'clsx';
import React, { useState } from 'react';
import useOnClickedOutside from 'src/views/hooks/useOnClickedOutside';
import DropdownButton, { DropdownButtonProps } from '../buttons/DropdownButton';
import DropdownContainer from '../containers/DropdownContainer';
import UHIcon from '../icon/UHIcon';
import UHText from '../text/UHText';

export type SingleSelectionDropdownProps = React.HTMLAttributes<HTMLDivElement> & {
  buttonProps?: DropdownButtonProps;
  /** Options for the dropdown */
  options: string[];
  /** The index of the default selection */
  initialValue?: number;
  /** When set, the selection can only be changed through this field */
  value?: number;
  /** Whether no item can be selected */
  allowNoSelection?: boolean;
  /** Callback when the selection changed */
  onChangedValue?: (selection: number) => void;
  /** Callback when the element looses focus */
  onBlur?: () => void;
};

/** Dropdown button that toggles an overlay.
 * It allows the user to select a single item from the list.
 */
const SingleSelectionDropdown: React.FC<SingleSelectionDropdownProps> = ({
  buttonProps,
  options,
  initialValue = -1,
  value,
  allowNoSelection = false,
  onChangedValue,
  onBlur,
  className,
  ...htmlProps
}) => {
  const [open, setOpen] = useState(false);
  const [selectedOptionState, setSelectedOptionState] = useState(
    allowNoSelection ? initialValue : Math.max(0, initialValue),
  );
  const selectedOption = value ?? selectedOptionState;

  const onSetOpen = (o: boolean) => {
    setOpen(o);
    if (open && !o) onBlur?.();
  };

  /** Toggles the visibility of the dropdown menu */
  const toggleDropdownMenu = () => {
    onSetOpen(!open);
  };

  // On clicked outside
  const dropdownRef = useOnClickedOutside<HTMLDivElement>(() => {
    onSetOpen(false);
  });

  /**
   * Inverts the selection of the option at index
   * @param index The option index that was selected
   */
  const changedSelection = (index: number) => {
    if (allowNoSelection) setSelectedOptionState((state) => (state === index ? -1 : index));
    else setSelectedOptionState(index);
    if (allowNoSelection || selectedOption !== index) onChangedValue?.(index);
    onSetOpen(false);
  };

  return (
    <div ref={dropdownRef} className={clsx('relative min-w-0', className)} {...htmlProps}>
      {/* Dropdown Button */}
      <DropdownButton {...buttonProps} className="w-full" onClick={toggleDropdownMenu} />
      {/* Dropdown container */}
      <DropdownContainer dropdownOpen={open && options.length > 0} className="origin-top">
        {options.map((option, index) => {
          return (
            <div
              className={clsx('group flex flex-nowrap items-center justify-between p-3 cursor-pointer', {
                'bg-neutral-100': index % 2 === 1,
              })}
              onClick={() => changedSelection(index)}
              key={index}
            >
              {/* Title */}
              <UHText
                text={option}
                className={clsx('align-middle mr-3 cursor-pointer', {
                  'text-neutral': selectedOption !== index,
                  'text-primary': selectedOption === index,
                })}
              />
              {/* Checkmark icon */}
              <UHIcon
                className={clsx('align-middle -my-2 mr-3 text-xl cursor-pointer', {
                  'text-transparent group-hover:text-neutral': selectedOption !== index,
                  'text-primary': selectedOption === index,
                })}
                icon="check"
              />
            </div>
          );
        })}
      </DropdownContainer>
    </div>
  );
};

export default SingleSelectionDropdown;
