import { ErrorCode } from '../types/conference';

export class AppError extends Error {
  private _code: ErrorCode;

  private _stack?: string;

  constructor(message: string, code: ErrorCode) {
    super(message);
    this._code = code;

    if (Object.prototype.hasOwnProperty.call(Error, 'captureStackTrace')) {
      // Just in V8.
      Error.captureStackTrace(this, AppError);
    } else {
      this.stack = new Error(message).stack;
    }
  }

  public get code(): ErrorCode {
    return this._code;
  }

  public get stack(): string | undefined {
    return this._stack;
  }

  public set stack(v: string | undefined) {
    this._stack = v;
  }

  public logError(): void {
    console.error(`[${this.code}] ${this.message}`);
  }
}

const confirmInternalError = <T>(error: T): AppError | undefined => {
  if (
    error instanceof TypeError ||
    error instanceof RangeError ||
    error instanceof ReferenceError ||
    error instanceof SyntaxError ||
    error instanceof URIError
  ) {
    return new AppError(error.message, ErrorCode.INTERNAL);
  }
  return undefined;
};

const isAppError = (error: unknown): error is AppError => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    '_code' in error &&
    typeof (error as Record<string, unknown>).message === 'string' &&
    typeof (error as Record<string, unknown>).code === 'string'
  );
};

const toAppError = (maybeError: unknown, code: ErrorCode): AppError => {
  const internalError = confirmInternalError(maybeError);

  if (internalError) return internalError;

  if (isAppError(maybeError)) return maybeError;

  try {
    return new AppError(JSON.stringify(maybeError), code);
  } catch (error) {
    return new AppError(String(maybeError), code);
  }
};

export const getAppError = (error: unknown, code: ErrorCode = ErrorCode.UNKNOWN): AppError => {
  return toAppError(error, code);
};
