import clsx from 'clsx';
import React from 'react';
import { useMediaTrackState } from 'src/views/hooks/streams/useMediaTrackState';
import UHText from '../core/text/UHText';
import UHProgressBar from '../core/progress/UHProgressBar';
import { useAudioTrackEvents } from 'src/views/hooks/streams/useAudioTrackEvents';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  audioStream?: MediaStream;
};

const MediaDebugAudioStreamView: React.FC<Props> = ({ audioStream, className, ...htmlProps }) => {
  const audioTrack = audioStream?.getAudioTracks()[0];
  const audioTrackState = useMediaTrackState(audioTrack);
  const audioVolume = useAudioTrackEvents(audioStream);

  return (
    <div className={clsx(className)} {...htmlProps}>
      {audioTrack && (
        <div>
          <UHText variant="title" text={`${audioTrack.kind}`} />
          <UHText text={`Track status: ${audioTrackState.readyState}`} />
          <UHText text={`Muted: ${audioTrackState.muted}`} />
          <UHProgressBar progress={audioVolume} />
        </div>
      )}
    </div>
  );
};

export default MediaDebugAudioStreamView;
