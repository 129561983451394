import { useTypedSelector } from 'src/store/store';
import { Crew } from 'src/types/crews/CrewType';
import { Space } from 'src/types/spaces/SpaceType';

interface EntityPrivileges {
  isOwner: boolean;
  hasAdminPrivileges: boolean;
}

export const usePrivileges = (entity: Space | Crew | undefined): EntityPrivileges => {
  const userId = useTypedSelector((state) => state.user.user.id);

  if (!entity) {
    return {
      isOwner: false,
      hasAdminPrivileges: false,
    };
  }

  const thisMember = entity.members?.find((m) => m.userId === userId);

  return {
    isOwner: thisMember?.role === 'owner',
    hasAdminPrivileges: thisMember?.role === 'admin' || thisMember?.role === 'owner',
  };
};
