/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Credentials } from 'src/types/users/userType';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { deviceLanguage } from '../device/deviceLocalization';
import { UHAPI } from '.';

export const signUp = async (credentials: Credentials): Promise<CognitoUser> => {
  const { user } = await Auth.signUp({
    username: credentials.username,
    password: credentials.password,
    attributes: {
      locale: deviceLanguage ?? 'en',
    },
  });
  return user;
};

export const verifyEmail = async (email: string, code: string): Promise<boolean> => {
  try {
    await Auth.confirmSignUp(email, code);
    return true;
  } catch (error) {
    return false;
  }
};

export const signIn = async (credentials: Credentials): Promise<CognitoUser> => {
  const user = await Auth.signIn({ username: credentials.username, password: credentials.password });
  return user;
};

export const tryAutoLogin = async (): Promise<CognitoUser> => {
  const user = await Auth.currentAuthenticatedUser();
  return user;
};

export const signOut = async (): Promise<void> => {
  await Auth.signOut();
};

export const resetPassword = async (username: Credentials['username']) => {
  const response = await Auth.forgotPassword(username);
  return response;
};

export const resetPasswordSubmit = async (
  username: Credentials['username'],
  code: string,
  password: Credentials['password'],
): Promise<string> => {
  const response = await Auth.forgotPasswordSubmit(username, code, password);
  return response;
};

export const changePassword = async (
  oldPassword: Credentials['password'],
  newPassword: Credentials['password'],
): Promise<string> => {
  const currentUser = await Auth.currentAuthenticatedUser();
  return Auth.changePassword(currentUser, oldPassword, newPassword);
};

export const deleteAccount = async (): Promise<void> => {
  try {
    await UHAPI.del({ path: '/user' });
    await signOut();
  } catch (error) {}
};
