import React, { useRef } from 'react';
import UHText from 'src/views/components/core/text/UHText';
import UHImage from 'src/views/components/core/image/UHImage';
import FadeInUpScroll from 'src/views/components/animations/FadeInUpScroll';
import Center from 'src/views/components/core/transform/Center';
import UHButton from 'src/views/components/core/buttons/UHButton';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import { allPages } from 'src/services/pages/pageService';
import ClaimSectionV2 from './components/ClaimSectionV2';
import LandingSectionTitle from './components/LandingSectionTitle';
import ClaimCard from './components/ClaimCard';
import ReviewEntry from './components/ReviewEntry';
import UHVideoPlayer from 'src/views/components/core/video/UHVideoPlayer';
import { calendlyLink, trailerLink } from './config/config';
import { UHAnalytics } from 'src/services/analytics/analyticEventsService';
import { useCurrentBreakpoint } from 'src/views/hooks/useCurrentBreakpoint';
import { openLink } from 'src/services/url/linkService';
import { TwitterSection } from './components/TwitterSection';
import { useTypedSelector } from 'src/store/store';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import { scrollToTopOfTarget } from 'src/services/scrolling/scrollService';
import Fade from 'src/views/components/animations/Fade';
import clsx from 'clsx';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import HoverTransformContainer from 'src/views/components/animations/HoverTransformContainer';
// import DemoView from './demo/DemoView';

type FeatureSection = {
  titleKey: DictionaryKeyType;
  descriptionKey: DictionaryKeyType;
  videoUrl: string;
};

const featureSections: FeatureSection[] = [
  {
    titleKey: 'WEBSITE.LANDING_FEATURE_1_TITLE',
    descriptionKey: 'WEBSITE.LANDING_FEATURE_1_DESCRIPTION',
    videoUrl: '/assets/videos/WebsiteAnimation/demo-feature-1.mp4',
  },
  {
    titleKey: 'WEBSITE.LANDING_FEATURE_2_TITLE',
    descriptionKey: 'WEBSITE.LANDING_FEATURE_2_DESCRIPTION',
    videoUrl: '/assets/videos/WebsiteAnimation/demo-feature-2.mp4',
  },
  {
    titleKey: 'WEBSITE.LANDING_FEATURE_3_TITLE',
    descriptionKey: 'WEBSITE.LANDING_FEATURE_3_DESCRIPTION',
    videoUrl: '/assets/videos/WebsiteAnimation/demo-feature-3.mp4',
  },
];

const LandingScreenV2: React.FC = () => {
  const history = useUHHistory();
  const languageCode = useTypedSelector((state) => state.localization.currentWebpageLanguageCode);
  const featureSection = useRef(null);

  const onSignup = () => {
    UHAnalytics.goToSignUp();
    history.navigate(allPages.auth.url, { state: { signUp: true } });
  };

  const onBookADemo = () => {
    UHAnalytics.bookADemo();
    openLink(calendlyLink, history, true);
  };

  const currentBreakpoint = useCurrentBreakpoint();
  const isMobile = ['xs'].includes(currentBreakpoint);

  return (
    <main className="bg-card">
      {/* <TestBubbleContainer className="h-192" /> */}
      {/* Landing Section */}
      {/* <div className="flex flex-col items-center mt-12">
        <div className="flex justify-center mb-6 space-x-2 sm:justify-start">
          <UHText tag="h1" className="inline text-primary font-sans text-4xl font-light" text="Nevolane " />
          <UHText tag="h1" className="inline text-primary font-sans text-4xl font-semibold" text="Space" />
        </div>
        <UHText tag="h2" className="text-center font-medium text-2xl sm:text-left" textKey="WEBSITE.LANDING_SLOGAN" />
        <DemoView className="w-[88rem] h-[44rem] m-auto mb-16 mt-10" />
      </div> */}
      {/* Body with sections */}
      <div className="flex flex-col mb-32">
        {/* Landing Section */}
        <div
          className="page-width z-1 lg:space-x-8 relative flex flex-col lg:flex-row items-center lg:py-16 pt-20 pb-20 w-full -mt-20"
          style={{ minHeight: '100svh' }}
        >
          {/* Title */}
          <div
            className="lg:flex-1 text-center font-bold font-sans text-5xl flex flex-col items-center space-y-12 lg:space-y-16 mb-10 lg:mb-0 order-1 lg:order-none"
            style={{ lineHeight: 1.2 }}
          >
            {/* Slogan */}
            <div className="flex flex-col">
              <UHText tag="h2" className="text-center whitespace-pre-line" textKey="WEBSITE.LANDING_SLOGAN" />
            </div>
            {/* Get started button */}
            <UHButton className="mt-6" titleKey="WEBSITE.SHARED_BUTTON_GET_STARTED" onClick={onSignup} />
          </div>
          {/* Video */}
          <Center className="lg:flex-[1.7] my-10 aspect-w-3 aspect-h-2 self-stretch lg:self-center lg:aspect-none">
            <HoverTransformContainer className="rounded-3xl border-background border-4 overflow-hidden">
              <Fade distance={0} delay={100}>
                <video
                  className="transitionfix  lg:object-contain object-cover h-full w-full"
                  controls={false}
                  autoPlay
                  playsInline
                  loop
                  muted
                  src="/assets/videos/WebsiteAnimation/demo.mp4"
                />
              </Fade>
            </HoverTransformContainer>
          </Center>
          {/* Explore arrow */}
          <Center className="absolute bottom-8 left-0 right-0">
            <FadeInUpScroll invert translation={0} bottomOffset={300}>
              <div
                className="flex flex-col items-center text-primary-300 cursor-pointer animate-bounce-smooth"
                onClick={() => {
                  scrollToTopOfTarget(featureSection.current);
                }}
              >
                <UHText className="text-xl" textKey="WEBSITE.LANDING_EXPLORE" />
                <UHIcon className="text-2xl -mt-2" icon="angle-down" />
              </div>
            </FadeInUpScroll>
          </Center>
        </div>
        {/* Features section */}
        <div ref={featureSection} className="lg:space-y-0 space-y-14 -mt-10">
          {featureSections.map((section, index) => {
            return (
              <div key={index} className="flex lg:flex-row flex-col page-width items-center">
                <div className={clsx('flex-[1] order-none', { 'lg:order-1': index % 2 === 0 })}>
                  <video
                    className={clsx('transitionfix h-full w-full -my-10 lg:my-0', { '-mb-4': index === 2 })}
                    controls={false}
                    autoPlay
                    playsInline
                    loop
                    muted
                    src={section.videoUrl}
                  />
                </div>
                <div className="flex flex-col space-y-6 flex-[1] z-1 lg:my-20">
                  <UHText
                    tag="h3"
                    className="whitespace-pre-line font-bold text-4xl lg:text-5xl font-sans"
                    textKey={section.titleKey}
                    style={{ lineHeight: 1.3 }}
                  />
                  <UHText
                    className="whitespace-pre-line text-xl lg:text-2xl text-neutral-700"
                    textKey={section.descriptionKey}
                    style={{ lineHeight: 1.6 }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {/* Claims section */}
        {/* <div ref={claimSection} className="page-width flex flex-col items-center mt-20">
          <LandingSectionTitle textKey="WEBSITE.LANDING_SECTION_CLAIM_TITLE" />
          <div className="flex flex-col space-y-16 md:flex-row md:justify-between md:space-y-0">
            <ClaimSectionV2
              imageSrc="images/landing/claims/talking-heads.png"
              titleKey="WEBSITE.LANDING_CLAIM1_TITLE"
              textKey="WEBSITE.LANDING_CLAIM1_DESCRIPTION"
              animationOffset={100}
              className="flex-1"
            />
            <ClaimSectionV2
              imageSrc="images/landing/claims/shuttle.png"
              titleKey="WEBSITE.LANDING_CLAIM2_TITLE"
              textKey="WEBSITE.LANDING_CLAIM2_DESCRIPTION"
              animationOffset={isMobile ? 100 : 200}
              className="flex-1"
            />
            <ClaimSectionV2
              imageSrc="images/landing/claims/dancing-people.png"
              titleKey="WEBSITE.LANDING_CLAIM3_TITLE"
              textKey="WEBSITE.LANDING_CLAIM3_DESCRIPTION"
              animationOffset={isMobile ? 100 : 300}
              className="flex-1"
            />
          </div>
        </div> */}
        {/* The best of both worlds section */}
        <div className="page-width flex flex-col items-center mt-32 w-full">
          <LandingSectionTitle textKey="WEBSITE.LANDING_SECTION_BOTH_WORLDS_TITLE" />
          <div className="flex items-center justify-between">
            {/* Office */}
            <ClaimSectionV2
              imageSrc="images/landing/two-worlds/icon-office.png"
              textKey="WEBSITE.LANDING_SECTION_BOTH_WORLDS_OFFICE"
              className="flex-1 max-w-xs"
              imageHeight={150}
              animationOffset={200}
            />
            {/* Middle icon */}
            <FadeInUpScroll className="hidden mx-4 sm:block" bottomOffset={400}>
              <UHImage fillType="contain" src="images/landing/two-worlds/icon-handshake.png" style={{ height: 200 }} />
            </FadeInUpScroll>
            {/* Homeoffice */}
            <ClaimSectionV2
              imageSrc="images/landing/two-worlds/icon-home.png"
              textKey="WEBSITE.LANDING_SECTION_BOTH_WORLDS_HOMEOFFICE"
              className="flex-1 max-w-xs"
              imageHeight={150}
              animationOffset={200}
            />
          </div>
          {/* Claims */}
          <div className="bg mt-12 w-max max-w-full space-y-4">
            <ClaimCard textKey="WEBSITE.LANDING_SECTION_BOTH_WORLDS_CLAIM1" />
            <ClaimCard textKey="WEBSITE.LANDING_SECTION_BOTH_WORLDS_CLAIM2" />
            <ClaimCard textKey="WEBSITE.LANDING_SECTION_BOTH_WORLDS_CLAIM3" />
            <ClaimCard textKey="WEBSITE.LANDING_SECTION_BOTH_WORLDS_CLAIM4" />
            <ClaimCard textKey="WEBSITE.LANDING_SECTION_BOTH_WORLDS_CLAIM5" />
          </div>
        </div>
        {/* CTA Buttons section */}
        <div className="page-width flex flex-col mt-16 space-y-4">
          <FadeInUpScroll>
            <UHButton
              className="mb-4 w-full transform scale-125 sm:mb-0 sm:scale-100"
              titleKey="WEBSITE.SHARED_BUTTON_CREATE_ACCOUNT"
              onClick={onSignup}
            />
          </FadeInUpScroll>
          <FadeInUpScroll>
            <UHButton
              variant="secondary"
              className="w-full transform scale-125 sm:scale-100"
              titleKey="WEBSITE.SHARED_BUTTON_BOOK_A_DEMO"
              onClick={onBookADemo}
            />
          </FadeInUpScroll>
        </div>
        {/* Quality section */}
        <div className="page-width flex flex-col items-center mt-32">
          <LandingSectionTitle textKey="WEBSITE.LANDING_SECTION_QUALITY_TITLE" />
          <div className="flex flex-col mx-0 max-w-xs space-y-16 sm:mx-14 sm:max-w-none md:flex-row md:justify-between md:space-y-0">
            {/* Claim 1 Section */}
            <ClaimSectionV2
              imageSrc="images/landing/quality/icon-ch.png"
              textKey="WEBSITE.LANDING_SECTION_QUALITY_CLAIM1"
              imageHeight={110}
              imageBottomSpace={10}
              animationOffset={100}
              className="flex-1"
            />
            {/* Claim 2 Section */}
            <ClaimSectionV2
              imageSrc="images/landing/quality/icon-eu.png"
              textKey="WEBSITE.LANDING_SECTION_QUALITY_CLAIM2"
              imageHeight={110}
              animationOffset={isMobile ? 100 : 200}
              imageBottomSpace={10}
              className="flex-1"
            />
            {/* Claim 3 Section */}
            <ClaimSectionV2
              imageSrc="images/landing/quality/icon-secureComm.png"
              textKey="WEBSITE.LANDING_SECTION_QUALITY_CLAIM3"
              imageHeight={110}
              animationOffset={isMobile ? 100 : 300}
              imageBottomSpace={10}
              className="flex-1"
            />
          </div>
        </div>
        {/* Review section */}
        <div className="page-width flex flex-col items-center mt-32">
          <LandingSectionTitle textKey="WEBSITE.LANDING_SECTION_REVIEW_TITLE" />
          <div className="flex flex-col max-w-4xl space-y-16">
            <ReviewEntry
              imageSrc="images/landing/testimonials/testimonial-phuc.jpeg"
              textKey="WEBSITE.LANDING_SECTION_REVIEW_TEXT1"
              author="Le Thien Phuc Tran - Online Marketing Specialist"
              company="xDelete, Singapore"
            />
            <ReviewEntry
              imageSrc="images/landing/testimonials/testimonial-maria.jpeg"
              textKey="WEBSITE.LANDING_SECTION_REVIEW_TEXT2"
              author="Maria Giulia Nasi - Senior Manager Strategy & Marketing"
              company="DLE Group AG, Germany"
              reverse
            />
          </div>
        </div>
        {/* CTA section */}
        <Center col className="mt-32 py-24 bg-primary-200">
          <div className="page-width flex flex-col items-center">
            <LandingSectionTitle textKey="WEBSITE.LANDING_CTA2_TITLE" subtitleKey="WEBSITE.LANDING_CTA2_DESCRIPTION" />
            <div className="flex flex-col w-max space-y-4">
              <FadeInUpScroll>
                <UHButton
                  className="mb-4 w-full transform scale-125 sm:mb-0 sm:scale-100"
                  titleKey="WEBSITE.SHARED_BUTTON_CREATE_ACCOUNT"
                  onClick={onSignup}
                />
              </FadeInUpScroll>
              <FadeInUpScroll>
                <UHButton
                  variant="secondary"
                  className="w-full transform scale-125 sm:scale-100"
                  titleKey="WEBSITE.SHARED_BUTTON_BOOK_A_DEMO"
                  onClick={onBookADemo}
                />
              </FadeInUpScroll>
            </div>
          </div>
        </Center>
        {/* Video Section */}
        <FadeInUpScroll>
          <Center>
            <UHVideoPlayer
              onPlay={() => UHAnalytics.viewedTrailer()}
              className="transitionfix mx-4 my-20 w-full max-w-4xl rounded-lg overflow-hidden sm:mx-8"
              url={trailerLink}
            />
          </Center>
        </FadeInUpScroll>
        {/* Twitter Section */}
        <FadeInUpScroll>
          <TwitterSection languageCode={languageCode} className="-mb-8" />
        </FadeInUpScroll>
      </div>
    </main>
  );
};

export default LandingScreenV2;
