import clsx from 'clsx';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import UHIcon from '../../core/icon/UHIcon';
import UHText from '../../core/text/UHText';
import UHTooltip from '../../tooltip/UHTooltip';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  icon: string;
  text?: string;
  textKey?: DictionaryKeyType;
  path: string;
  collapsed?: boolean;
};

const DrawerEntry: React.FC<Props> = ({ icon, text, textKey, path, collapsed = false, className, ...htmlProps }) => {
  const history = useUHHistory();
  const location = useLocation();
  const selected = matchPath(location.pathname.replaceAll(':spaceId', '*'), path);
  return (
    <UHTooltip text={collapsed ? text : undefined} textKey={collapsed ? textKey : undefined} position="right">
      <div
        className={clsx(
          'flex items-center px-4 h-12 hover:bg-neutral-200 rounded-lg cursor-pointer space-x-2 transition-colors duration-200',
          { 'bg-neutral-200': selected, 'justify-center w-full': collapsed },
          className,
        )}
        onClick={() => history.navigate(path)}
        {...htmlProps}
      >
        <UHIcon icon={icon} className="text-primary text-xl" />
        {!collapsed && <UHText variant="title-sm" className="text-primary" text={text} textKey={textKey} />}
      </div>
    </UHTooltip>
  );
};

export default DrawerEntry;
