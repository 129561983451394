import clsx from 'clsx';
import React, { useState } from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import UHText from '../text/UHText';

export type TextAreaProps = React.HTMLAttributes<HTMLTextAreaElement> & {
  /** Identifier of the element */
  name?: string;
  /** Textfield's label */
  label?: string;
  labelKey?: DictionaryKeyType;
  errorText?: string;
  errorTextKey?: DictionaryKeyType;
  /** Initial Value */
  initialValue?: string;
  autoComplete?: string;
  /** Controlled value */
  value?: string;
  /** Convenience function called on input change */
  onChangeText?: (text: string) => void;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onEnter?: () => void;
  /** Bottom margin */
  mb?: 'default' | 'none';
  /** Custom Styles */
  className?: string;
};

const UHTextArea: React.FC<TextAreaProps> = ({
  name,
  label,
  labelKey,
  errorText,
  errorTextKey,
  autoComplete,
  value,
  initialValue,
  onChangeText,
  onChange,
  onBlur,
  onFocus,
  onEnter,
  mb = 'default',
  className,
  ...inputProps
}) => {
  const localizedLabel = useLocalizedValue(label, labelKey);
  const localizedError = useLocalizedValue(errorText, errorTextKey);
  const [isFocused, setIsFocused] = useState(false);
  const labelOnTop = isFocused || value !== '';

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      onEnter?.();
    }
  };

  const onLocalChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(e);
    onChangeText?.(e.target.value);
  };

  const onLocalFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const onLocalBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  return (
    <div className={clsx('relative', { 'mb-10': mb === 'default' }, className)}>
      <textarea
        style={{ height: 136, resize: 'none' }}
        id={name}
        autoComplete={autoComplete}
        value={value}
        defaultValue={initialValue}
        onChange={onLocalChange}
        onFocus={onLocalFocus}
        onBlur={onLocalBlur}
        onKeyDown={handleKeyDown}
        className="p-3 pt-6 w-full h-14 border border-neutral-300 focus:border-primary rounded-xl outline-none"
        {...inputProps}
      />
      {/* Overlay to behind floating label to prevent overlap when scrolling */}
      <div className="absolute left-px right-px top-px h-6 bg-background rounded-t-xl" />
      {/* Floating label */}
      <UHText
        className={clsx(
          'absolute left-4 top-4 text-neutral pointer-events-none transform origin-left transition-all duration-200 ease-in-out',
          { 'opacity-75 -translate-y-2.5 -translate-x-1 scale-75': labelOnTop },
        )}
        variant="body"
        text={localizedLabel}
      />
      {/* Error label */}
      <UHText
        variant="body-xs"
        className="line-clamp-2 wrap absolute left-4 right-0 top-full pt-0.5 text-error-600"
        text={localizedError}
      />
    </div>
  );
};

export default UHTextArea;
