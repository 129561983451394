import clsx from 'clsx';
import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  /** The relative size to its parent */
  size?: number;
  rotation?: number;
  scale?: number;
  /** The relative position in its parent */
  position?: { x: number; y: number };
  center?: boolean;
};

const Absolute: React.FC<Props> = ({
  size = 1,
  rotation = 0,
  scale = 1,
  position = { x: 0.5, y: 0.5 },
  center = false,
  className,
  style,
  children,
  ...htmlProps
}) => {
  return (
    <div
      className={clsx('absolute', { 'flex items-center justify-center': center }, className)}
      style={{
        width: `${size * 100}%`,
        height: `${size * 100}%`,
        top: `${(position.y - size / 2) * 100}%`,
        left: `${(position.x - size / 2) * 100}%`,
        transform: `rotateZ(${rotation}deg) scale(${scale})`,
        ...style,
      }}
      {...htmlProps}
    >
      {children}
    </div>
  );
};

export default Absolute;
