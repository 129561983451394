import { useEffect, useRef, useState } from 'react';

export const useMouseMovedRecently = (timeout = 2000): { onMouseMoved: () => void; mouseMovedRecently: boolean } => {
  const [mouseMovedRecently, setMouseMovedRecently] = useState(false);
  const mouseMovedTimer = useRef<NodeJS.Timeout>();

  const onMouseMoved = () => {
    if (mouseMovedTimer.current) clearTimeout(mouseMovedTimer.current);
    setMouseMovedRecently(true);
    mouseMovedTimer.current = setTimeout(() => setMouseMovedRecently(false), timeout);
  };

  useEffect(() => {
    return () => {
      if (mouseMovedTimer.current) clearTimeout(mouseMovedTimer.current);
    };
  }, []);

  return { onMouseMoved, mouseMovedRecently };
};
