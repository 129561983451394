import clsx from 'clsx';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { resetPassword } from 'src/services/api/userAPI';
import { ResetPasswordEmailData, forgotPasswordEmailSchema } from 'src/services/forms/schemaService';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import FormikButton from 'src/views/components/core/buttons/FormikButton';
import FormikTextField from 'src/views/components/core/inputs/FormikTextField';
import UHText from 'src/views/components/core/text/UHText';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  onCancel: () => void;
  onCompleted: (email: string) => void;
};

const ResetPasswordEmailForm: React.FC<Props> = ({ onCancel, onCompleted, className, ...htmlProps }) => {
  const [errorKey, setErrorKey] = useState<DictionaryKeyType>();
  const [loading, setLoading] = useState(false);

  const handleErrorCode = (code: string) => {
    if (code === 'NetworkError') setErrorKey('SHARED_ERROR_NETWORK_ERROR');
    else if (code === 'InvalidPasswordException') setErrorKey('AUTH_SCREEN_ERROR_INVALID_PASSWORD');
    else if (code === 'UsernameExistsException') setErrorKey('AUTH_SCREEN_ERROR_USERNAME_EXISTS');
    else setErrorKey('SHARED_ERROR_UNKNOWN_ERROR');
  };

  const resetErrorCode = () => setErrorKey(undefined);

  const onSubmit = async (values: ResetPasswordEmailData) => {
    setLoading(true);
    try {
      const email = values.email?.trim();
      await resetPassword(email);
      onCompleted(email);
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code) handleErrorCode(error.code);
      setLoading(false);
    }
  };

  return (
    <div
      className={clsx('flex flex-col items-center', { 'pointer-events-none select-none': loading }, className)}
      {...htmlProps}
    >
      {/* Title */}
      <UHText variant="title-xl" className="mb-12 mt-8 text-primary" textKey="AUTH_SCREEN_FORGOT_PASSWORD" />
      {/* Explanation Text */}
      <UHText variant="body-sm" className="mb-4" textKey="AUTH_SCREEN_CALL_TO_ACTION_ENTER_EMAIL_TO_RESET_PASSWORD" />
      {/* Form */}
      <Formik<ResetPasswordEmailData>
        validateOnMount={true}
        initialValues={{ email: '' }}
        validationSchema={forgotPasswordEmailSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <div className="flex flex-col w-full">
              {/* TextField: E-Mail */}
              <FormikTextField
                className="mb-10"
                name="email"
                labelKey="SHARED_TEXT_EMAIL"
                type="email"
                onEnter={handleSubmit}
                errorTextKey={errorKey}
                onChangeText={resetErrorCode}
              />
              {/* Submit button */}
              <FormikButton
                className="self-center mt-12 min-w-max"
                titleKey="AUTH_SCREEN_RESET_PASSWORD"
                loading={loading}
              />
            </div>
          );
        }}
      </Formik>
      {/* Back button */}
      <UHText className="self-center mt-4 text-primary" textKey="SHARED_BUTTON_BACK" onClick={onCancel} />
    </div>
  );
};

export default ResetPasswordEmailForm;
