import { Space } from 'src/types/spaces/SpaceType';
import { User } from 'src/types/users/userType';
import { getStaticData } from '../staticData/staticDataService';

export const areMySpacesDisabled = (user: User, spaces: Space[]): boolean => {
  const subscription = getStaticData().personalSubscriptions.find((sub) => sub.tier === user.subscription);
  if (!subscription) return true;
  return subscription.restrictions.spaces < spaces.length;
};

export const hasSpaceTooManyMembers = (user: User, space: Space): boolean => {
  if (space.ownerId !== user.id) return false;
  const subscription = getStaticData().personalSubscriptions.find((sub) => sub.tier === user.subscription);
  if (!subscription) return true;
  return subscription.restrictions.spaceMembers < space.members.length + space.invites.length;
};

export const isSpaceDisabled = (user: User, space: Space, spaces: Space[]): boolean => {
  const mySpacesDisabled = areMySpacesDisabled(user, spaces);
  const mySpaceDisabledMembers = hasSpaceTooManyMembers(user, space);
  const spaceDisabled = mySpaceDisabledMembers || mySpacesDisabled || space.disabled;
  return spaceDisabled;
};
