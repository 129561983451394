import React, { useEffect, useRef, useState } from 'react';
import { env } from 'src/services/environment/envService';
import { cleanupUserStreams } from 'src/services/mediaStreams/userStreamService';
import { allPages } from 'src/services/pages/pageService';
import { useTypedSelector } from 'src/store/store';
import { Space } from 'src/types/spaces/SpaceType';
import UHButton from 'src/views/components/core/buttons/UHButton';
import UHCard from 'src/views/components/core/containers/UHCard';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import UHImage from 'src/views/components/core/image/UHImage';
import SingleSelectionDropdown from 'src/views/components/core/inputs/SingleSelectionDropdown';
import UHText from 'src/views/components/core/text/UHText';
import Center from 'src/views/components/core/transform/Center';
import VideoStreamView from 'src/views/components/core/video/VideoStreamView';
import SpaceImage from 'src/views/components/space/image/SpaceImage';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import { usePrivileges } from 'src/views/hooks/usePrivileges';
import { isSpaceDisabled } from 'src/services/subscriptions/restrictionsService';
import { useMediaStreams } from 'src/views/hooks/streams/useMediaStreams';
import { useParams } from 'react-router-dom';
import Absolute from 'src/views/components/core/transform/Absolute';
import MediaDebugView from 'src/views/components/media-stream/MediaDebugView';
import { useHasRole } from 'src/views/hooks/auth/useHasRole';
import { Role } from 'src/types/users/userType';

type UserMediaScreenLocation = {
  publicSpace?: Space;
  token?: string;
  displayName?: string;
};

const UserMediaScreen: React.FC = () => {
  const history = useUHHistory();
  const spaceId = parseInt(useParams().spaceId ?? '-1');
  const userMediaScreenLocation = history.location.state as UserMediaScreenLocation | undefined;
  const enableDebugInfo = useHasRole([Role.COMMUNITY_MANAGER, Role.EXECUTIVE, Role.ADMIN]);

  const user = useTypedSelector((state) => state.user.user);

  const spaces = useTypedSelector((state) => state.spaces.spaces);
  const localSpace = spaces.find((s) => s.id === spaceId);
  const space = localSpace ?? userMediaScreenLocation?.publicSpace;
  const privileges = usePrivileges(space);
  const mediaStreamData = useMediaStreams();
  const {
    cameras,
    microphones,
    localVideoStream,
    cameraEnabled,
    onSetCameraEnabled,
    microphoneEnabled,
    isLoadingPermissions,
    selectedCameraIndex,
    selectedMicrophoneIndex,
    onSetSelectedCameraIndex,
    onSetSelectedMicrophoneIndex,
  } = mediaStreamData;

  const [showMediaDebugOverlay, setShowMediaDebugOverlay] = useState(false);

  const noCameras = cameras.length === 0;
  const noMicrophones = microphones.length === 0;

  const videoRef = useRef<HTMLVideoElement>(null);

  /** Back to spaces overview */
  const navigateBack = () => {
    cleanupUserStreams();
    history.replacePage('spacesOverview');
  };

  const navigateToSpaceDetail = () => {
    cleanupUserStreams();
    history.replacePage('spaceDetail', space?.id);
  };

  // Go back if space could not be loaded
  if (!space) {
    navigateBack();
    return null;
  }

  if (space.disabled || (!userMediaScreenLocation?.publicSpace && isSpaceDisabled(user, space, spaces))) {
    navigateToSpaceDetail();
    return null;
  }

  /** Enter conference view */
  const enterConferenceAction = (): void => {
    history.navigate(allPages.conference.url.replaceAll(':spaceId', spaceId.toString()), {
      state: {
        cameraEnabled,
        micMuted: !microphoneEnabled,
        displayName: userMediaScreenLocation?.displayName,
        token: privileges.isOwner ? undefined : userMediaScreenLocation?.token,
      },
    });
  };

  useEffect(() => {
    if (videoRef.current && localVideoStream) videoRef.current.srcObject = localVideoStream;
  }, [localVideoStream]);

  const videoSize = Math.min(288, window.screen.height / 3.5);

  return (
    <Center className="w-full h-full">
      {space && (
        <UHCard className="flex flex-col items-center select-none">
          {/* Space image */}
          <SpaceImage variant="lg" className="m-4 w-104" space={space} />
          <div className="flex flex-col items-center pb-6" style={{ marginTop: `-${0.5 * videoSize}px` }}>
            <div className="relative z-10 bg-neutral-100 border-16 border-background rounded-full">
              {/* Camera view */}
              {!env.DebugStreams && cameraEnabled && (
                <VideoStreamView
                  style={{ width: `${videoSize}px`, height: `${videoSize}px` }}
                  mirror
                  muted
                  videoStream={localVideoStream}
                  className="bg-neutral-300 rounded-full overflow-hidden"
                />
              )}
              {/* Debug stream */}
              {env.DebugStreams && cameraEnabled && (
                <UHImage
                  style={{ width: `${videoSize}px`, height: `${videoSize}px` }}
                  src={`/images/portraits/portrait-${1}.jpg`}
                  className="bg-primary rounded-full overflow-hidden"
                />
              )}
              {/* Camera turned off (only display name) */}
              {!cameraEnabled && (
                <Center
                  style={{ width: `${videoSize}px`, height: `${videoSize}px` }}
                  className="bg-primary rounded-full overflow-hidden"
                >
                  {user.avatar && (
                    <UHImage
                      style={{ width: `${videoSize}px`, height: `${videoSize}px` }}
                      src={`${env.ImageCDN}/${user.avatar}`}
                      className="transitionfix bg-primary rounded-full overflow-hidden"
                    />
                  )}
                  {!user.avatar && (
                    <UHText
                      className="line-clamp-2 wrap text-md text-center text-typoInverted"
                      text={userMediaScreenLocation?.displayName ?? user.displayName}
                      style={{ maxWidth: '65%' }}
                    />
                  )}
                </Center>
              )}
              {/* Camera toggle button */}
              {!noCameras && (
                <Center
                  className="absolute w-16 h-16 bg-background border-4 border-primary rounded-full shadow cursor-pointer"
                  style={{ bottom: '2%', right: '2%' }}
                  onClick={() => onSetCameraEnabled(!cameraEnabled)}
                >
                  <UHIcon className="text-primary text-3xl" icon={cameraEnabled ? 'video' : 'video-slash'} />
                </Center>
              )}
            </div>
            {/* Camera selection */}
            <SingleSelectionDropdown
              className="mb-2 mt-3 w-56"
              buttonProps={{
                icon: 'video',
                titleKey: noCameras ? 'SHARED_TEXT_NOT_AVAILABLE' : undefined,
                title: cameras[selectedCameraIndex]?.name,
                infoTitle: 'Camera',
                size: 'small',
                disabled: noCameras,
              }}
              options={cameras.map((v) => v.name)}
              value={selectedCameraIndex}
              onChangedValue={(selection: number) => {
                onSetSelectedCameraIndex(selection);
                onSetCameraEnabled(true);
              }}
            />
            {/* Microphone selection */}
            <SingleSelectionDropdown
              className="mb-3 w-56"
              buttonProps={{
                icon: 'microphone',
                titleKey: noMicrophones ? 'SHARED_TEXT_NOT_AVAILABLE' : undefined,
                title: microphones[selectedMicrophoneIndex]?.name,
                infoTitle: 'Microphone',
                size: 'small',
                disabled: noMicrophones,
              }}
              options={microphones.map((v) => v.name)}
              value={selectedMicrophoneIndex}
              onChangedValue={onSetSelectedMicrophoneIndex}
            />
            {/* No Devices Available */}
            {!isLoadingPermissions && noMicrophones && (
              <UHText
                variant="body-sm"
                className="mb-6 mt-4 max-w-sm text-center text-error"
                textKey="MICROPHONE_DEVICE_NOT_DETECTED"
              />
            )}
            {/* Info text */}
            {(isLoadingPermissions || !noMicrophones) && (
              <UHText
                variant="body-sm"
                className="mb-6 mt-4 max-w-sm text-center text-neutral"
                textKey="ENTER_SPACE_TEXT_USER_SELECTION_ALERT"
              />
            )}
            {/* Enter button */}
            <UHButton
              titleKey={privileges.isOwner && space.token ? 'PUBLIC_SPACE_START' : 'ENTER_SPACE_BUTTON_ENTER_SPACE'}
              onClick={enterConferenceAction}
              loading={isLoadingPermissions}
              variant={noMicrophones && !isLoadingPermissions ? 'disabled' : 'default'}
            />
            <UHText className="mt-4 text-center text-primary" textKey="SHARED_BUTTON_CANCEL" onClick={navigateBack} />
          </div>
        </UHCard>
      )}
      {/* Debug overlay button */}
      {enableDebugInfo && (
        <UHIcon
          className="fixed top-9 right-9 text-xs text-neutral-300"
          icon="info"
          onClick={() => setShowMediaDebugOverlay(true)}
        />
      )}
      {/* Debug overlay */}
      {showMediaDebugOverlay && enableDebugInfo && (
        <Absolute className="z-100">
          <MediaDebugView mediaStreamData={mediaStreamData} onClose={() => setShowMediaDebugOverlay(false)} />
        </Absolute>
      )}
    </Center>
  );
};

export default UserMediaScreen;
