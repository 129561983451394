export const scrollToTopOfTarget = (
  element: Element | null | undefined,
  offset = 32,
  behavior: ScrollBehavior = 'smooth',
): void => {
  if (!element) return;
  const navBarHeight = 64;
  const totalOffset = navBarHeight + offset;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - totalOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior,
  });
};
