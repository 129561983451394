import clsx from 'clsx';
import React from 'react';
import { UHAnalytics } from 'src/services/analytics/analyticEventsService';
import { env } from 'src/services/environment/envService';
import { allPages } from 'src/services/pages/pageService';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import UHButton from '../core/buttons/UHButton';
import UHText from '../core/text/UHText';

type Props = React.HTMLAttributes<HTMLDivElement>;

const Navbar: React.FC<Props> = ({ className, ...htmlProps }) => {
  const history = useUHHistory();

  const onLogin = () => {
    UHAnalytics.goToSignIn();
    history.pushPage('auth');
  };

  const onSignup = () => {
    UHAnalytics.goToSignUp();
    history.navigate(allPages.auth.url, { state: { signUp: true } });
  };

  const goToBlog = () => {
    UHAnalytics.goToBlog();
    history.navigate(allPages.blog.url);
  };

  const goToApiInfo = () => {
    UHAnalytics.goToApiInfo();
    history.navigate(allPages.apiInfo.url);
  };

  return (
    <nav
      className={clsx('sticky z-30 top-0 flex flex-col items-center w-full h-20 bg-background shadow-md', className)}
      {...htmlProps}
    >
      <div className="page-width flex items-center justify-between w-full h-full">
        <div onClick={() => history.pushPage('landing')} className="cursor-pointer">
          <UHText className="inline text-primary font-sans text-xl font-light" text="Nevolane " />
          <UHText className="inline text-primary font-sans text-xl font-semibold" text="Space" />
        </div>
        <div className="flex-1" />
        <div className="flex items-center justify-end">
          {/* TODO: Localize */}
          <UHText className="mr-8 text-primary sm:block hidden" text="API" onClick={goToApiInfo} />
          <UHText className="mr-8 text-primary sm:block hidden" text="Blog" onClick={goToBlog} />
          {env.LoginOnLandingPage && (
            <div className="flex items-center ml-4">
              <UHText
                className="mr-8 text-primary sm:block hidden"
                textKey="WEBSITE.SHARED_LABEL_LOGIN"
                onClick={onLogin}
              />
              <UHButton width="auto" titleKey="WEBSITE.SHARED_BUTTON_CREATE_ACCOUNT" onClick={onSignup} />
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
