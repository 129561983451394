export enum SocketEvents {
  ENTER_SPACE = 'ENTER_SPACE',
  ENTERED_SPACE = 'ENTERED_SPACE',
  CONNECT_TRANSPORT = 'CONNECT_TRANSPORT',
  PRODUCE = 'PRODUCE',
  CONSUME = 'CONSUME',
  RESUME = 'RESUME',
  CREATE_CONSUMERS = 'CREATE_CONSUMERS',
  RESUME_CONSUMER = 'RESUME_CONSUMER',
  CLOSE_CONSUMERS = 'CLOSE_CONSUMERS',
  CREATE_PRODUCERS = 'CREATE_PRODUCERS',
  RESUME_PRODUCERS = 'RESUME_PRODUCERS',
  PAUSE_PRODUCERS = 'PAUSE_PRODUCERS',
  CLOSE_PRODUCERS = 'CLOSE_PRODUCERS',
  START_CONVERSATION = 'START_CONVERSATION',
  JOIN_CONVERSATION = 'JOIN_CONVERSATION',
  JOIN_USER = 'JOIN_USER',
  LEAVE_CONVERSATION = 'LEAVE_CONVERSATION',
  CHANGE_STATUS = 'CHANGE_STATUS',
  STILL_IMAGE_MODE = 'STILL_IMAGE_MODE',
  SHARING_DISPLAY = 'SHARING_DISPLAY',
  MIC_STATUS_CHANGED = 'MIC_STATUS_CHANGED',
  CONFERENCE_UPDATE = 'CONFERENCE_UPDATE',
  STREAM_UPDATE = 'STREAM_UPDATE',
  CONFERENCE_TRANSFERRED = 'CONFERENCE_TRANSFERRED',
  PEER_MESSAGE = 'PEER_MESSAGE',
}
