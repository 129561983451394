import clsx from 'clsx';
import React from 'react';
import { useTypedSelector } from 'src/store/store';
import Drawer from './drawer/Drawer';

type Props = React.HTMLAttributes<HTMLDivElement>;

const MainLayout: React.FC<Props> = ({ className, children, ...htmlProps }) => {
  const isAuthenticated = !!useTypedSelector((state) => state.user.cognitoUser);

  return (
    <div className={clsx('flex flex-col w-screen h-screen bg-background', className)} {...htmlProps}>
      {/* Full page */}
      <div className="relative flex flex-1 flex-col overflow-hidden sm:flex-row sm:p-4">
        {/* Drawer */}
        {isAuthenticated && <Drawer className="z-1 order-2 sm:order-1" />}
        {/* Main content background */}
        <div className="main-gradient flex-1 order-1 h-full rounded-b-5xl overflow-x-hidden overflow-y-auto sm:order-2 sm:rounded-5xl">
          {/* Main content */}
          <div className="flex flex-col items-center h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
