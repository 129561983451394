import { UpdatableUserSettings, UserSettings } from 'src/types/users/userType';
import { UHAPI } from '.';
import { countryCode, deviceLanguage } from '../device/deviceLocalization';

export const fetchUserSettings = async (): Promise<UserSettings> => {
  const settings = await UHAPI.get<UserSettings>({ path: `/user` });
  settings.locationCode = settings.locationCode ?? countryCode ?? 'ch';
  settings.languageCode = settings.languageCode ?? deviceLanguage ?? 'en';
  return settings;
};

export const updateUserSettings = async (settings: UpdatableUserSettings): Promise<UserSettings> => {
  return UHAPI.patch({ path: `/user`, body: settings });
};

export const getLanguages = async (): Promise<Record<string, string>> => {
  const result = await UHAPI.get<{ languages: Record<string, string> }>({ path: '/languages' });
  return result.languages;
};

export type TermsOfUseType = {
  content: unknown;
  language: string;
  version: string;
};

export const getTerms = async (language: string): Promise<TermsOfUseType> => {
  const data = await UHAPI.get<{ termsOfUse: TermsOfUseType }>({ path: `/public/terms/${language}` });
  return data.termsOfUse;
};
