import clsx from 'clsx';
import React, { HtmlHTMLAttributes } from 'react';
import UHImage from 'src/views/components/core/image/UHImage';
import UHText from 'src/views/components/core/text/UHText';
import { BlogEntry } from 'src/types/blog/blogTypes';

type Props = HtmlHTMLAttributes<HTMLDivElement> & {
  article: BlogEntry;
};

export const ArticleCard: React.FC<Props> = ({ article, onClick, className, ...props }) => {
  return (
    <div
      className={clsx(
        'flex flex-col p-4 w-full bg-background rounded-xl border border-neutral-250 shadow-sm hover:shadow-md space-y-4 cursor-pointer group transform hover:-translate-y-1 transition-all',
        className,
      )}
      onClick={onClick}
      {...props}
    >
      <div className="rounded-md overflow-hidden bg-neutral-200 w-full aspect-w-3 aspect-h-2">
        {article.image && <UHImage src={article.image} className="w-full bg-cover bg-neutral-200" />}
      </div>
      <div className="px-2 pt-4">
        <UHText
          text={new Date(article.publishedAt).toLocaleDateString('de-DE', { dateStyle: 'medium' })}
          className="text-sm text-neutral-400 pb-2"
        />
        <UHText text={article.title} onClick={onClick} variant="title" className="h-24" />
        <UHText text={article.summary} className="line-clamp-5 h-[120px]" />
        <UHText
          text="Continue ->"
          className="cursor-pointer text-end text-primary font-medium pt-6 pb-2 opacity-0 group-hover:opacity-100 transition-opacity"
        />
      </div>
    </div>
  );
};
