import { useSpring } from '@react-spring/core';
import React from 'react';
import { animated } from '@react-spring/web';

const configForType = {
  default: { mass: 1, tension: 300, friction: 20 },
  slow: { mass: 1, tension: 200, friction: 30 },
  fast: { mass: 1, tension: 500, friction: 50 },
  elastic: { mass: 1, tension: 200, friction: 10 },
};

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  /** The rotation in degrees that should be applied to the children */
  rotation?: number;
  type?: 'default' | 'slow' | 'fast' | 'elastic';
};

const RotationContainer: React.FC<Props> = ({ rotation = 0, type = 'default', children, ...htmlProps }) => {
  const spring = useSpring({
    transform: `rotate(${rotation}deg)`,
    config: configForType[type],
  });

  return (
    <animated.div {...(htmlProps as Record<string, unknown>)} style={{ ...(htmlProps.style ?? {}), ...spring }}>
      {children}
    </animated.div>
  );
};

export default RotationContainer;
