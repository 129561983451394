export type MemberRole = 'owner' | 'admin' | 'member' | 'invited' | 'none';

/**
 * The space result expected from the server
 */
export type SpaceMember = {
  id: number;
  displayName: string;
  avatar?: string;
  role: MemberRole;
  email: string;
  isActive: boolean;
  userId?: string | null;
  inviteId?: number;
};

export type SpaceInvitedMember = {
  id: number;
  invitingUserId: string;
  invitedUser?: {
    email: string;
    avatar: string | null;
    displayName: string | null;
  };
};

export const spaceMemberFromInvitedMember = (v: SpaceInvitedMember): SpaceMember => {
  return {
    id: -1,
    displayName: v.invitedUser?.displayName ?? '',
    role: 'invited',
    avatar: v.invitedUser?.avatar ?? undefined,
    email: v.invitedUser?.email ?? '',
    isActive: false,
    inviteId: v.id,
  };
};

export type Space = {
  id: number;
  name: string;
  description: string;
  imageId: number;
  active: boolean;
  ownerId: string;
  crewId: number | null;
  members: SpaceMember[];
  token?: string;
  customImage?: string;
  disabled: boolean;
  invites: SpaceInvitedMember[];
  pushNotifications: boolean;
};

export type SpaceInviteType = {
  spaceId: number;
  usersToInvite: string[];
};

export type SpaceUpdateType = {
  name: string;
  description: string;
  imageId: number;
  crewId?: number;
  customImage?: string | null;
};

export type SpaceTokenRegenerateType = {
  token: string;
};

export type SpaceMemberUpdateType = { spaceId: number; memberId: number; role: MemberRole };

export type SpaceMemberSettingsUpdateType = { spaceId: number; pushNotifications: boolean };

export type SpaceMemberIsActiveUpdateType = { spaceId: number; userId: string; isActive: boolean };
