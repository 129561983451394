import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import UHIcon from '../icon/UHIcon';
import UHText from '../text/UHText';

export type TagProps = React.HTMLAttributes<HTMLDivElement> & {
  selected?: boolean;
  text?: string;
  textKey?: DictionaryKeyType;
  onRemove?: () => void;
};

const Tag: React.FC<TagProps> = ({ selected = true, text, textKey, onRemove, className, ...htmlProps }) => {
  const localizedText = useLocalizedValue(text, textKey);
  return (
    <div
      className={clsx(
        'flex items-center px-3 py-1 rounded-full',
        { 'bg-primary-200': selected, 'bg-neutral-200': !selected, 'cursor-pointer': htmlProps.onClick },
        className,
      )}
      {...htmlProps}
    >
      <UHText className="line-clamp-1 flex-shrink text-primary-700 break-all" text={localizedText} />
      {onRemove && <UHIcon className="flex-shrink-0 ml-2" icon="xmark" onClick={onRemove} />}
    </div>
  );
};

export default Tag;
