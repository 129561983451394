import { createAsyncThunk } from '@reduxjs/toolkit';
import { Credentials, UpdatableUserSettings, User, UserSettings } from 'src/types/users/userType';
import { tryAutoLogin, signUp, signIn, signOut, deleteAccount } from 'src/services/api/userAPI';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { fetchUserSettings, updateUserSettings } from 'src/services/api/settingsAPI';
import { updatePersonalSubscription } from 'src/services/api/subscriptionAPI';
import { actions, RootState } from '../store';
import { uploadFile, removeAvatar } from 'src/services/api/avatarAPI';

export const updateUserSettingsThunk = createAsyncThunk<UserSettings, UpdatableUserSettings, { state: RootState }>(
  '@@user/updateUserSettingsThunk',
  async (settings: UpdatableUserSettings, { getState }): Promise<UserSettings> => {
    const currentSettings = getState().user.user;
    if (currentSettings.languageCode !== settings.languageCode) actions.fetchDictionary(settings.languageCode);
    const updatedSettings = await updateUserSettings(settings);
    return updatedSettings;
  },
);

export const updateAvatarThunk = createAsyncThunk<UserSettings, string | ArrayBuffer | null, { state: RootState }>(
  '@@user/updateAvatarThunk',
  async (imageBinary: string | ArrayBuffer | null): Promise<UserSettings> => {
    const updatedSettings = await uploadFile(imageBinary);
    return updatedSettings;
  },
);

export const removeAvatarThunk = createAsyncThunk('@@user/removeAvatarThunk', async (): Promise<UserSettings> => {
  const updatedSettings = await removeAvatar();
  return updatedSettings;
});

export const fetchUserSettingsThunk = createAsyncThunk('@@user/fetchUserData', async (): Promise<UserSettings> => {
  return fetchUserSettings();
});

export const updatePersonalSubscriptionThunk = createAsyncThunk(
  '@@user/updatePersonalSubscription',
  async (tier: string): Promise<UserSettings> => {
    return updatePersonalSubscription(tier);
  },
);

export const signUpThunk = createAsyncThunk('@@user/signUp', async (credentials: Credentials): Promise<CognitoUser> => {
  return signUp(credentials);
});

const getUserFromCognitoUser = async (cognitoUser: CognitoUser): Promise<User> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cognitoAttributes = (cognitoUser as any)?.attributes;
  const userSettings = await fetchUserSettings();
  const user: User = {
    email: cognitoAttributes.email,
    ...userSettings,
  };
  return user;
};

export const initUserDataThunk = createAsyncThunk<User, void, { state: RootState }>(
  '@@user/initUserData',
  async (_, { getState }) => {
    const { cognitoUser } = getState().user;
    if (!cognitoUser) throw new Error('User not logged in.');
    return getUserFromCognitoUser(cognitoUser);
  },
);

export const tryAutoLoginThunk = createAsyncThunk('@@user/autoLogin', async (): Promise<CognitoUser> => {
  return tryAutoLogin();
});

export const signInThunk = createAsyncThunk('@@user/signIn', async (credentials: Credentials): Promise<CognitoUser> => {
  return signIn(credentials);
});

export const initThunk = createAsyncThunk('@@user/init', async () => {
  await Promise.all([
    actions.initUserData(),
    // actions.fetchCrews(),
    actions.fetchSpaces(),
    actions.fetchInvites(),
    actions.fetchLanguages(),
  ]);
  try {
    await actions.createPublicSpace();
  } catch (error) {}
  actions.fetchAnnouncements();
  await actions.fetchDictionary();
});

export const autoLoginInitThunk = createAsyncThunk('@@user/autoLoginInit', async () => {
  await actions.tryAutoLogin();
  try {
    await actions.init();
  } catch (error) {
    await actions.signOut();
  }
});

export const signInInitThunk = createAsyncThunk('@@user/signInInit', async (credentials: Credentials) => {
  await actions.signIn(credentials);
  actions.init();
});

export const signOutThunk = createAsyncThunk('@@user/signOut', async (): Promise<void> => {
  await signOut();
});

export const deleteAccountThunk = createAsyncThunk('@@user/deleteAccount', async (): Promise<void> => {
  await deleteAccount();
});
