import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'src/App';
import { memberRoleLocalizationKeys } from 'src/services/localization/dictionariesForTypes';
import { actions } from 'src/store/store';
import { MemberRole, Space, SpaceMember } from 'src/types/spaces/SpaceType';
import { useLocalizedString } from 'src/views/hooks/useLocalizedString';
import OverlayCard, { OverlayCardProps } from '../../core/containers/OverlayCard';
import SingleSelectionDropdown from '../../core/inputs/SingleSelectionDropdown';
import UHText from '../../core/text/UHText';
import FormButtons from '../../form/FormButtons';

const availableRoles: MemberRole[] = ['admin', 'member'];

type Props = OverlayCardProps & {
  space: Space;
  spaceMember: SpaceMember;
};

const SpaceMemberForm: React.FC<Props> = ({ space, spaceMember, width = 'sm', ...htmlProps }) => {
  const [loading, setLoading] = useState(false);
  const { setConfirmData } = useContext(AppContext);

  // Selected role
  const [selectedRoleIndex, setSelectedRoleIndex] = useState(availableRoles.indexOf(spaceMember.role));

  // Localize roles
  const localizedRoles = useLocalizedString(...availableRoles.map((role) => memberRoleLocalizationKeys[role]));

  // Remove member
  const removeMember = async () => {
    setLoading(true);
    try {
      await actions.removeSpaceUser({ memberId: spaceMember.id, space });
      htmlProps.onClose?.();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onTryRemoveMember = () => {
    setConfirmData({
      headerTextKey: 'SPACE_REMOVE_USER',
      confirmAction: removeMember,
      variant: 'danger',
    });
  };

  // Change members role
  const onSubmit = async () => {
    if (selectedRoleIndex !== availableRoles.indexOf(spaceMember.role)) {
      const role = availableRoles[selectedRoleIndex];
      if (role) {
        setLoading(true);
        try {
          await actions.updateSpaceUser({
            spaceId: space.id,
            memberId: spaceMember.id,
            role,
          });
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    }
    htmlProps.onClose?.();
  };

  useEffect(() => {
    if (htmlProps.show) {
      setSelectedRoleIndex(availableRoles.indexOf(spaceMember.role));
    }
  }, [htmlProps.show]);

  return (
    <OverlayCard
      titleKey="SPACE_MANAGEMENT_TITLE_EDIT_USER"
      width={width}
      {...htmlProps}
      loading={htmlProps.loading || loading}
    >
      {/* Member name */}
      <UHText variant="title" className="self-start mb-4 text-primary-700" text={spaceMember.displayName} />
      {/* Role selection */}
      <SingleSelectionDropdown
        className="mb-14 w-full"
        options={localizedRoles}
        value={selectedRoleIndex}
        onChangedValue={setSelectedRoleIndex}
        buttonProps={{ title: localizedRoles[selectedRoleIndex] ?? localizedRoles[1] }}
      />
      {/* Submit */}
      <FormButtons onSubmit={onSubmit} submitTextKey="SHARED_BUTTON_SAVE" />
      {/* Remove member */}
      <UHText textKey="SPACE_REMOVE_USER" className="mt-4 text-error" onClick={onTryRemoveMember} />
    </OverlayCard>
  );
};

export default SpaceMemberForm;
