import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { LoginFormData } from 'src/services/forms/schemaService';
import { actions } from 'src/store/store';
import UHText from 'src/views/components/core/text/UHText';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import { useLocalizedKey } from 'src/views/hooks/useLocalizedString';
import { AuthState } from 'src/views/screens/Auth/AuthScreen';

type ResendMailState = 'none' | 'loading' | 'sent' | 'error';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  loginFromData: LoginFormData;
  setAuthState: (state: AuthState) => void;
};

const VerificationView: React.FC<Props> = ({ loginFromData, setAuthState, className, ...htmlProps }) => {
  const history = useUHHistory();
  const [resendState, setResendState] = useState<ResendMailState>('none');

  const localizedVerificationLinkMessage = useLocalizedKey('AUTH_SCREEN_CONFIRMATION_LINK_SENT', loginFromData.email);

  const resendEmail = async (): Promise<void> => {
    setResendState('loading');
    Auth.resendSignUp(loginFromData.email)
      .then(() => setResendState('sent'))
      .catch(() => setResendState('error'));
  };

  const goBackToLogin = async () => {
    await actions.signOut;
    setAuthState('login');
  };

  useEffect(() => {
    const checkVerifiedInterval = setInterval(async (): Promise<void> => {
      try {
        await actions.signIn({ username: loginFromData.email, password: loginFromData.password });
        clearInterval(checkVerifiedInterval);
        history.pushPage('spacesOverview');
        actions.init();
      } catch (e) {
        // do nothing
      }
    }, 5000);
    return () => clearInterval(checkVerifiedInterval);
  }, []);

  return (
    <div className={clsx('flex flex-col', className)} {...htmlProps}>
      <UHText variant="title-xl" className="self-center mb-12 mt-8 text-primary" textKey="AUTH_SCREEN_VERIFY_EMAIL" />
      {/* Explanation text */}
      <UHText variant="body-sm" className="mb-4" text={localizedVerificationLinkMessage} />
      {/* Call to action text */}
      <UHText variant="body-sm" className="mb-4" textKey="AUTH_SCREEN_CALL_TO_ACTION_FOLLOW_LINK" />
      {/* Did not receive email text */}
      <UHText variant="body-sm" className="mb-4" textKey="AUTH_SCREEN_DID_NOT_RECEIVE_LINK" />
      {/* Resend button */}
      {resendState === 'none' && (
        <UHText variant="body-sm" className="text-primary" textKey="AUTH_SCREEN_RESEND_LINK" onClick={resendEmail} />
      )}
      {resendState === 'loading' && (
        <UHText variant="body-sm" className="text-neutral" textKey="AUTH_SCREEN_SENDING_EMAIL" />
      )}
      {resendState === 'sent' && <UHText variant="body-sm" className="text-success" textKey="AUTH_SCREEN_EMAIL_SENT" />}
      {resendState === 'error' && (
        <UHText variant="body-sm" className="text-error" textKey="SHARED_ERROR_UNKNOWN_ERROR" />
      )}
      {/* Back button */}
      <UHText className="self-center mt-10 text-primary" textKey="SHARED_BUTTON_BACK" onClick={goBackToLogin} />
    </div>
  );
};

export default VerificationView;
