import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { getStaticData } from '../staticData/staticDataService';

export type Price = {
  currency?: string;
  absolute?: number;
  monthly?: number;
  yearly?: number;
};

export type SubscriptionPeriod = 'monthly' | 'yearly';

export type Subscription = {
  tier: string;
  title: DictionaryKeyType;
  description: DictionaryKeyType[];
  tag?: DictionaryKeyType;
  restrictions: SubscriptionRestriction;
  price?: Price;
};

export type SubscriptionRestriction = {
  spaces: number;
  spaceMembers: number;
};

export const getPersonalSubscriptionForTier = (tier = 'P0'): Subscription => {
  const { personalSubscriptions } = getStaticData();
  return personalSubscriptions.find((s) => s.tier === tier) ?? personalSubscriptions[0];
};

export const getCrewSubscriptionForTier = (tier = 'C1'): Subscription => {
  const { crewSubscriptions } = getStaticData();
  return crewSubscriptions.find((s) => s.tier === tier) ?? crewSubscriptions[0];
};
