import { useCurrentBreakpoint } from '../useCurrentBreakpoint';

export type CardWidth = 'content' | 'sm' | 'md' | 'auto';

export const useCardWidth = (preferredWidth: CardWidth = 'auto'): CardWidth => {
  let width = preferredWidth;
  const breakpoint = useCurrentBreakpoint();
  const small = ['xs', 'sm', 'md'].includes(breakpoint);
  if (width === 'auto') width = small ? 'sm' : 'md';
  if (width === 'md' && small) width = 'sm';
  return width;
};
