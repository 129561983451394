import React from 'react';
import { useTypedSelector } from 'src/store/store';
import UHRichText, { RichText } from 'src/views/components/core/text/UHRichText';
import privacyPolicyData from './privacy_policy.json';

const DataPrivacyScreen: React.FC = () => {
  const selectedLanguageCode = useTypedSelector((state) => state.localization.currentWebpageLanguageCode);
  const localizedPrivacyPolicy =
    (privacyPolicyData.languages as never as Record<string, { content: RichText }>)[selectedLanguageCode] ??
    privacyPolicyData.languages['en'];
  return (
    <main className="bg-card">
      {/* Body with sections */}
      <div className="page-width flex flex-col items-center">
        <div className="flex flex-col mb-32 mt-16 max-w-2xl">
          <UHRichText content={localizedPrivacyPolicy.content} />
        </div>
      </div>
    </main>
  );
};

export default DataPrivacyScreen;
