import { Announcement } from './AnnouncementType';
import { StorageService } from '../storage/StorageService';

const setReadAnnouncementIds = (ids: number[]): void => {
  StorageService.setItem('readAnnouncements', ids);
};

export const getReadAnnouncementIds = (): number[] => {
  try {
    return StorageService.getItem('readAnnouncements') ?? [];
  } catch {
    return [];
  }
};

export const markAnnouncementAsRead = (announcement: Announcement): void => {
  const readIds = getReadAnnouncementIds();
  if (!readIds.includes(announcement.id)) {
    setReadAnnouncementIds(readIds.concat(announcement.id));
  }
};
