import clsx from 'clsx';
import React from 'react';
import DropdownContainer, { DropdownContainerProps } from '../containers/DropdownContainer';
import UHIcon from '../icon/UHIcon';
import UHText, { UHTextProps } from '../text/UHText';

type Props = DropdownContainerProps & {
  options: UHTextProps[];
  value?: number;
};

const SelectionDropdown: React.FC<Props> = ({ options, value, className, ...htmlProps }) => {
  const onClickedOption = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, option: UHTextProps) => {
    option.onClick?.(e);
    htmlProps.setDropdownOpen?.(false);
  };

  return (
    <DropdownContainer className={clsx('w-max', className)} {...htmlProps}>
      {options.map((option, index) => {
        return (
          <div
            className={clsx('group flex flex-nowrap items-center justify-between p-3 cursor-pointer', {
              'bg-neutral-100': index % 2 === 1,
            })}
            onClick={(e) => onClickedOption(e, option)}
            key={index}
          >
            {/* Title */}
            <UHText
              {...option}
              onClick={undefined}
              className={clsx('align-middle mr-3 cursor-pointer', option.className, {
                'group-hover:text-primary': !option.className,
                'text-neutral': value !== index,
                'text-primary': value === index,
              })}
            />
            {/* Checkmark icon */}
            {value !== undefined && (
              <UHIcon
                className={clsx('align-middle -my-2 mr-3 text-xl cursor-pointer', {
                  'text-transparent group-hover:text-neutral': value !== index,
                  'text-primary': value === index,
                })}
                icon="check"
              />
            )}
          </div>
        );
      })}
    </DropdownContainer>
  );
};

export default SelectionDropdown;
