import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import SignUpForm from './components/SignUpForm';
import { useTryAutologin } from 'src/views/hooks/auth/useTryAutologin';
import { allPages } from 'src/services/pages/pageService';
import LoadingScreen from '../Loading/LoadingScreen';
import Center from 'src/views/components/core/transform/Center';
import UHText from 'src/views/components/core/text/UHText';
import ResetPasswordForm from './components/ResetPasswordForm';
import VerificationView from './components/VerificationView';
import { LoginFormData } from 'src/services/forms/schemaService';
import { actions, useTypedSelector } from 'src/store/store';
import { setPendingPublicSpaceJoinURL } from 'src/services/publicSpace/publicSpaceService';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import { UHAnalytics } from 'src/services/analytics/analyticEventsService';

export type AuthState = 'login' | 'signup' | 'resetPassword' | 'verification';

const AuthScreen: React.FC = () => {
  const { isTryingAutologin, isAuthenticated } = useTryAutologin();
  const languageCode = useTypedSelector((state) => state.localization.currentWebpageLanguageCode);
  const history = useUHHistory();

  // Load language
  const [loadingLanguage, setLoadingLanguage] = useState(true);

  const loadLanguage = async () => {
    setLoadingLanguage(true);
    await actions.fetchDictionary(languageCode);
    setLoadingLanguage(false);
  };

  useEffect(() => {
    loadLanguage();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [authState, setAuthState] = useState<AuthState>(!!(history.location.state as any)?.signUp ? 'signup' : 'login');

  // Used for the verificationScreen
  const [loginFormData, setLoginFormData] = useState<LoginFormData>({ email: '', password: '' });

  useEffect(() => {
    if (!isTryingAutologin && !loadingLanguage && !isAuthenticated) {
      if (authState === 'signup') UHAnalytics.showSignUp();
      if (authState === 'login') UHAnalytics.showLogin();
    }
  }, [authState, isTryingAutologin, loadingLanguage, isAuthenticated]);

  if (isTryingAutologin || loadingLanguage) return <LoadingScreen className="w-screen h-screen" />;
  if (isAuthenticated) return <Navigate to={allPages.spacesOverview.url} />;

  return (
    <Center className="main-gradient w-screen h-screen" col>
      {/* Nevolane title */}
      <UHText
        className="hidden mb-14 text-primary-700 sm:block"
        variant="title-xl"
        text="Nevolane Space"
        onClick={() => {
          setPendingPublicSpaceJoinURL(undefined);
          history.pushPage('landing');
        }}
      />
      {/* Card */}
      <div
        className="flex flex-col justify-center px-4 py-10 w-96 bg-background border border-background rounded-3xl shadow-3xl"
        style={{ maxWidth: '98vw' }}
      >
        {/* Content depending on auth state */}
        {authState === 'signup' && <SignUpForm setAuthState={setAuthState} setLoginFormData={setLoginFormData} />}
        {authState === 'login' && <LoginForm setAuthState={setAuthState} setLoginFormData={setLoginFormData} />}
        {authState === 'resetPassword' && <ResetPasswordForm setAuthState={setAuthState} />}
        {authState === 'verification' && <VerificationView setAuthState={setAuthState} loginFromData={loginFormData} />}
      </div>
    </Center>
  );
};

export default AuthScreen;
