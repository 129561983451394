import clsx from 'clsx';
import React, { useState } from 'react';
import { loadUserDisplayStream } from 'src/services/mediaStreams/userStreamService';
import RoundedDropdownButton from 'src/views/components/core/buttons/RoundedDropdownButton';
import UHLabledIconButton from 'src/views/components/core/buttons/UHLabledIconButton';
import SelectionDropdown from 'src/views/components/core/inputs/SelectionDropdown';
import Center from 'src/views/components/core/transform/Center';
import { ConferenceData } from '../hooks/useConference';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  conferenceData: ConferenceData;
};

const ConferenceMenu: React.FC<Props> = ({ conferenceData, className, ...htmlProps }) => {
  const user = conferenceData.myUser;

  // Stream data
  const {
    cameras,
    microphones,
    selectedCameraIndex,
    selectedMicrophoneIndex,
    onSetSelectedCameraIndex,
    onSetSelectedMicrophoneIndex,
  } = conferenceData.mediaStreamData;

  const noMicrophones = microphones.length === 0;
  const noCameras = cameras.length === 0;

  // Device selection dropdowns
  const [audioSelectionOpen, setAudioSelectionOpen] = useState(false);
  const [videoSelectionOpen, setVideoSelectionOpen] = useState(false);

  return (
    <div className={clsx('relative flex justify-between space-x-3', className)} {...htmlProps}>
      {/* Leave Conference */}
      <UHLabledIconButton
        className="text-typoInverted transform rotate-180"
        icon="arrow-right-from-bracket"
        iconClassName="text-typoInverted"
        onClick={conferenceData.onLeaveConference}
        type="danger"
        textKey="CONFERENCE_LEAVE"
      />
      {/* Mic Toggle */}
      {user.status === 'Active' && conferenceData.myUserIsInConversation && (
        <div className="relative">
          <UHLabledIconButton
            className="text-typoInverted"
            icon={user.micMuted ? 'microphone-slash' : 'microphone'}
            iconClassName="text-typoInverted"
            onClick={noMicrophones ? undefined : () => conferenceData.onMicStatusChanged(!user.micMuted)}
            type={user.micMuted || noMicrophones ? 'inactive' : 'active'}
            textKey={
              noMicrophones
                ? 'SHARED_TEXT_NOT_AVAILABLE'
                : user.micMuted
                ? 'CONFERENCE_TURN_MIC_ON'
                : 'CONFERENCE_TURN_MIC_OFF'
            }
          />
          {microphones.length > 1 && (
            <RoundedDropdownButton
              onClick={
                noMicrophones
                  ? undefined
                  : () => {
                      if (!user.micMuted) {
                        setAudioSelectionOpen(!audioSelectionOpen);
                      }
                    }
              }
              open={!noMicrophones && audioSelectionOpen}
              variant={noMicrophones ? 'warning' : user.micMuted ? 'inactive' : 'default'}
            />
          )}
          <Center className="absolute bottom-full left-0 right-0">
            <div className="absolute bottom-full flex justify-center">
              <SelectionDropdown
                position="block"
                className="mb-4 origin-bottom"
                dropdownOpen={audioSelectionOpen && microphones.length > 1}
                setDropdownOpen={setAudioSelectionOpen}
                options={microphones.map((v, i) => ({
                  text: v.name,
                  onClick: () => onSetSelectedMicrophoneIndex(i),
                }))}
                value={selectedMicrophoneIndex}
                direction="up"
              />
            </div>
          </Center>
        </div>
      )}
      {/* Camera Toggle */}
      {user.status === 'Active' && (
        <div className="relative">
          <UHLabledIconButton
            className="text-typoInverted"
            icon={user.imageOnlyMode ? 'video-slash' : 'video'}
            iconClassName="text-typoInverted"
            onClick={
              noCameras && user.imageOnlyMode
                ? undefined
                : () => conferenceData.onStillImageOnlyMode(!user.imageOnlyMode)
            }
            type={user.imageOnlyMode ? 'inactive' : 'active'}
            textKey={
              noCameras
                ? 'SHARED_TEXT_NOT_AVAILABLE'
                : user.imageOnlyMode
                ? 'CONFERENCE_TURN_CAMERA_ON'
                : 'CONFERENCE_TURN_CAMERA_OFF'
            }
          />
          {cameras.length > 1 && (
            <RoundedDropdownButton
              onClick={() => setVideoSelectionOpen(!videoSelectionOpen)}
              open={videoSelectionOpen}
              variant="default"
            />
          )}
          <Center className="absolute bottom-full left-0 right-0">
            <div className="absolute bottom-full flex justify-center">
              <SelectionDropdown
                position="block"
                className="mb-4 origin-bottom"
                dropdownOpen={videoSelectionOpen && cameras.length > 1}
                setDropdownOpen={setVideoSelectionOpen}
                options={cameras.map((v, i) => ({
                  text: v.name,
                  onClick: () => onSetSelectedCameraIndex(i),
                }))}
                value={selectedCameraIndex}
                direction="up"
              />
            </div>
          </Center>
        </div>
      )}
      {/* Display Toggle */}
      {user.status === 'Active' && conferenceData.myUserIsInConversation && (
        <UHLabledIconButton
          className="text-typoInverted"
          icon={user.sharingDisplay ? 'rectangle-xmark' : 'display'}
          iconClassName="text-typoInverted"
          onClick={async () => {
            await loadUserDisplayStream();
            conferenceData.onToggleDisplay(!user.sharingDisplay);
          }}
          type={user.sharingDisplay ? 'inactive' : 'active'}
          textKey={user.sharingDisplay ? 'CONFERENCE_SHARE_DISPLAY_OFF' : 'CONFERENCE_SHARE_DISPLAY_ON'}
        />
      )}
      {/* Toggle Status */}
      <UHLabledIconButton
        className="text-typoInverted"
        icon={user.status === 'Active' ? 'user-check' : 'user-clock'}
        iconClassName="text-typoInverted"
        onClick={() => conferenceData.onChangeStatus(user.status === 'Active' ? 'Break' : 'Active')}
        type={user.status === 'Active' ? 'active' : 'inactive'}
        textKey={
          user.status === 'Active' ? 'CONFERENCE_CHANGE_STATUS_UNAVAILABLE' : 'CONFERENCE_CHANGE_STATUS_AVAILABLE'
        }
      />
    </div>
  );
};

export default ConferenceMenu;
