import clsx from 'clsx';
import React from 'react';
import tailwindConfig from 'src/tailwind.config';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import UHIcon from '../icon/UHIcon';
import UHText from './UHText';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  text?: string;
  textKey?: DictionaryKeyType;
};

const UHArrowLink: React.FC<Props> = ({ text, textKey, className, style, ...htmlProps }) => {
  const localizedText = useLocalizedValue(text, textKey);
  return (
    <div
      className={clsx('group flex items-center w-max cursor-pointer space-x-1', className)}
      style={{ textDecorationColor: tailwindConfig.theme.colors.primary['DEFAULT'], ...style }}
      {...htmlProps}
    >
      <UHIcon className="mr-1 text-primary text-lg" icon="arrow-right" />
      <UHText variant="body-sm" className="text-primary group-hover:underline" text={localizedText} />
    </div>
  );
};

export default UHArrowLink;
