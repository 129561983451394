import React, { useState, useEffect } from 'react';
import StripeCore from 'stripe';
import { useTypedSelector } from 'src/store/store';
import { cancelDowngrade, getPaymentMethods, getSubscriptions } from '../../../services/api/subscriptionAPI';
import UHButton from 'src/views/components/core/buttons/UHButton';
import UHText from 'src/views/components/core/text/UHText';
import PageContainer from 'src/views/components/layout/PageContainer';
import { UserSubscription } from 'src/types/subscriptions/downgradeTier';
import { getInvoices, getUpcomingInvoice } from 'src/services/api/invoicesAPI';
import CardGrid from 'src/views/components/layout/CardGrid';
import UHCard from 'src/views/components/core/containers/UHCard';
import { getPersonalSubscriptionForTier } from 'src/services/subscriptions/subscriptionService';
import { useLocalizedKey, useLocalizedString } from 'src/views/hooks/useLocalizedString';
import UHProgressBar from 'src/views/components/core/progress/UHProgressBar';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import { format, fromUnixTime, parseISO } from 'date-fns';
import ManageSubscriptionView from './components/ManageSubscriptionView';
import PriceInfo from './components/PriceInfo';
import { changeSubscriptionTier, managePaymentMethod } from 'src/services/subscriptions/stripeService';
import { isBefore } from 'date-fns/esm';
import { useCurrentBreakpoint } from 'src/views/hooks/useCurrentBreakpoint';
import { GetUpcomingInvoice } from 'src/types/subscriptions/upcomingInvoice';
import PaymentCardBrand from './components/PaymentCardBrand';
import clsx from 'clsx';
import Loader from 'src/views/components/core/loader/Loader';
import UHTextButton from 'src/views/components/core/buttons/UHTextButton';
import { loadProductsData } from 'src/services/staticData/staticDataService';

const SubscriptionScreen: React.FC = () => {
  const user = useTypedSelector((state) => state.user.user);
  const chosenSubscriptionPeriod = user.subscriptionPeriod;
  const spaces = useTypedSelector((state) => state.spaces.spaces);
  const currentSubscription = getPersonalSubscriptionForTier(user.subscription);
  const [manageSubscriptionShown, setManageSubscriptionShown] = useState(false);

  const [subscriptions, setSubscriptions] = useState<UserSubscription[]>([]);
  const [invoices, setInvoices] = useState<StripeCore.Invoice[]>([]);
  const [upcomingInvoice, setUpcomingInvoice] = useState<GetUpcomingInvoice>({});

  const [paymentMethods, setPaymentMethods] = useState<StripeCore.PaymentMethod[]>([]);
  const paymentCard = paymentMethods[0]?.card;

  const [loadingInitialData, setLoadingInitialData] = useState(true);
  const [loadingTierChange, setLoadingTierChange] = useState<string>();
  const [loadingReactivateSubscription, setLoadingReactivateSubscription] = useState(false);

  const onChangeToTier = async (tier: string, period: string) => {
    setLoadingTierChange(tier);
    const newSubscriptions = await changeSubscriptionTier(
      currentSubscription.tier,
      tier,
      period,
      chosenSubscriptionPeriod ?? '',
      hasDowngraded,
    );
    if (newSubscriptions) setSubscriptions(newSubscriptions);
    await loadUpcomingInvoice();
    setManageSubscriptionShown(false);
    setLoadingTierChange(undefined);
  };

  const onReactivateCancelledSubscription = async () => {
    setLoadingReactivateSubscription(true);

    const newSubscription = await cancelDowngrade();
    await loadUpcomingInvoice();
    setSubscriptions(newSubscription);
    setManageSubscriptionShown(false);
    setLoadingReactivateSubscription(false);
  };

  const loadInvoices = async () => setInvoices(await getInvoices());
  const loadPaymentMethods = async () => setPaymentMethods(await getPaymentMethods());
  const loadSubscriptions = async () => setSubscriptions(await getSubscriptions());
  const loadUpcomingInvoice = async () => setUpcomingInvoice(await getUpcomingInvoice());
  const loadSubscriptionData = async () => {
    await Promise.all([
      loadProductsData(),
      loadSubscriptions(),
      loadInvoices(),
      loadPaymentMethods(),
      loadUpcomingInvoice(),
    ]);
  };

  useEffect(() => {
    const loadData = async () => {
      setLoadingInitialData(true);
      await loadSubscriptionData();
      setLoadingInitialData(false);
    };
    loadData();
  }, []);

  const [t_of, t_users, t_space, t_spaces, t_invoice] = useLocalizedString(
    'SHARED_TEXT_OF',
    'SHARED_TEXT_MEMBERS',
    'SHARED_TEXT_SPACE',
    'SHARED_TEXT_SPACES',
    'SUBSCRIPTION_INVOICES_INVOICE',
  );

  // Downgrade info
  const hasCancelledSubscription = subscriptions.length === 1 && subscriptions[0].status === 'CANCELLED';
  const downgradeUserSubscription = subscriptions.find((value) => isBefore(new Date(), parseISO(value.startDate)));
  const cancellationDate = hasCancelledSubscription ? subscriptions[0]?.endDate : downgradeUserSubscription?.startDate;
  const downgradeSubscription = hasCancelledSubscription
    ? getPersonalSubscriptionForTier('P0')
    : downgradeUserSubscription
    ? getPersonalSubscriptionForTier(downgradeUserSubscription.tier)
    : undefined;
  const downgradeSubscriptionTitle = useLocalizedKey(downgradeSubscription?.title);
  const downgradeSubscriptionDate = cancellationDate ? format(parseISO(cancellationDate), 'dd.MM.yyyy') : '';
  const downgradeInfo = useLocalizedKey(
    'SUBSCRIPTION_DOWNGRADE_INFO',
    downgradeSubscriptionTitle,
    downgradeSubscriptionDate,
  );

  let nextPaymentTitle = useLocalizedKey('SUBSCRIPTION_PAYMENTS_NEXT');
  if (upcomingInvoice.due && !loadingInitialData)
    nextPaymentTitle = `${nextPaymentTitle} (${format(fromUnixTime(upcomingInvoice.due), 'dd.MM.yyyy')})`;

  const hasDowngraded = hasCancelledSubscription || (subscriptions.length > 1 && !!downgradeUserSubscription);

  const breakPoint = useCurrentBreakpoint();

  const hasTooManySpaces = spaces.length > currentSubscription.restrictions.spaces;

  const loading = loadingReactivateSubscription || !!loadingTierChange;

  return (
    <PageContainer titleKey="PROFILE_OPTIONS_SUBSCRIPTIONS" icon="star">
      <CardGrid maxCols={2}>
        {/* Current subscription card */}
        <UHCard className="flex flex-col col-span-2 xl:col-span-1" titleKey="SUBSCRIPTION_TITLE" defaultPadding>
          {/* Title, price and downgrade info */}
          <div className="flex items-start justify-between space-x-2">
            <div>
              {/* Tier title */}
              <UHText className="mb-2 text-primary-700" variant="title" textKey={currentSubscription.title} />
              {/* Cancel downgrade */}
              {hasDowngraded && (
                <div>
                  <UHText className="text-error-300" text={downgradeInfo} />
                  <UHTextButton
                    loading={loadingReactivateSubscription}
                    underline
                    textKey="SUBSCRIPTION_DOWNGRADE_CANCEL"
                    onClick={onReactivateCancelledSubscription}
                  />
                </div>
              )}
            </div>
            {/* Price Info */}
            <PriceInfo
              chosenPeriod={chosenSubscriptionPeriod}
              price={currentSubscription.price}
              subscriptionPeriod="monthly"
              variant="lg"
            />
          </div>
          {/* Spacing */}
          <div className="flex-1" />
          {/* Progress and Manage */}
          <div className="flex items-end justify-between mt-6 space-x-4">
            {/* Progress */}
            <div className="w-full max-w-md">
              <UHText
                className={clsx('inline', { 'text-error': hasTooManySpaces })}
                variant="body-sm"
                text={`${spaces.length} ${t_of} ${currentSubscription.restrictions.spaces} ${t_spaces} `}
              />
              <UHText
                className="inline text-neutral"
                variant="body-sm"
                text={`(${currentSubscription.restrictions.spaceMembers} ${t_users} / ${t_space})`}
              />
              <div className="relative">
                <UHProgressBar
                  className="mt-2 w-full"
                  progress={spaces.length / currentSubscription.restrictions.spaces}
                />
              </div>
            </div>
            {/* Manage button */}
            <UHButton
              className="flex-1"
              titleKey="SHARED_BUTTON_MANAGE"
              width="auto"
              onClick={() => setManageSubscriptionShown(true)}
            />
          </div>
          {hasTooManySpaces && (
            <div className="flex items-center mt-3">
              <UHIcon icon="circle-exclamation" className="mr-2 text-error text-2xl" />
              <UHText variant="body-sm" className="text-error" textKey="SPACE_LOCKED_DESCRIPTION" />
            </div>
          )}
        </UHCard>
        {/* Payment card */}
        <UHCard className="col-span-2 xl:col-span-1" titleKey="SUBSCRIPTION_PAYMENTS_TITLE" defaultPadding>
          {/* Next payment */}
          <UHText className="text-neutral" variant="body-sm" text={nextPaymentTitle} />
          <div className="flex items-center justify-between">
            {loadingInitialData && <Loader className="-ml-4 transform scale-50" colorClass="bg-neutral-400" />}
            {!upcomingInvoice.amount && !loadingInitialData && (
              <UHText variant="body-lg" textKey="SUBSCRIPTION_PAYMENTS_NONE" />
            )}
            {!!upcomingInvoice.amount && !loadingInitialData && (
              <PriceInfo
                price={{ absolute: upcomingInvoice.amount, currency: upcomingInvoice.currency }}
                variant="lg"
              />
            )}
            {!loadingInitialData && (
              <UHText
                variant="body-lg"
                textKey={
                  hasDowngraded
                    ? getPersonalSubscriptionForTier(downgradeUserSubscription?.tier).title
                    : currentSubscription.title
                }
              />
            )}
          </div>

          {/* Payment method */}
          <UHText className="mt-8 text-neutral" variant="body-sm" textKey="SUBSCRIPTION_PAYMENTS_METHOD" />
          <div className="flex items-end justify-between mt-2 space-x-4">
            {/* Card info */}
            <div className="flex items-center self-stretch p-3 w-full max-w-md border border-neutral-300 rounded-xl">
              {loadingInitialData && <Loader className="-ml-4 -my-1 transform scale-50" colorClass="bg-neutral-400" />}
              {!paymentCard && !loadingInitialData && (
                <UHText className="text-neutral" textKey="SUBSCRIPTION_PAYMENTS_METHOD_NONE" />
              )}
              {paymentCard && !loadingInitialData && (
                <div className="flex flex-1 items-start justify-between">
                  <div>
                    <UHText
                      className="inline-block mr-1 transform -translate-y-px"
                      text={`\u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 `}
                    />
                    <UHText className="inline-block" text={`${paymentCard.last4}`} />
                  </div>
                  <PaymentCardBrand paymentCard={paymentCard} className="self-stretch -mr-1 -my-1" />
                </div>
              )}
            </div>
            {/* Manage button */}
            <UHButton
              variant={loadingInitialData ? 'disabled' : 'secondary'}
              className="flex-1"
              titleKey="SHARED_BUTTON_MANAGE"
              width="auto"
              onClick={loadingInitialData ? undefined : managePaymentMethod}
            />
          </div>
          {/* Spaceing to match height of other card */}
          {hasTooManySpaces && !['xs', 'sm', 'md', 'lg'].includes(breakPoint) && (
            <div className="flex items-center mt-3 invisible">
              <UHIcon icon="circle-exclamation" className="mr-2 text-error text-2xl" />
              <UHText variant="body-sm" className="text-error" textKey="SPACE_LOCKED_DESCRIPTION" />
            </div>
          )}
        </UHCard>
        {/* Invoices card */}
        {invoices.length > 0 && (
          <UHCard className="flex flex-col col-span-2 space-y-2" titleKey="SUBSCRIPTION_INVOICES_TITLE" defaultPadding>
            {invoices.map((invoice) => {
              const fileName = `${t_invoice}-${invoice.number}`;
              return (
                <div
                  key={invoice.id}
                  className="flex items-center justify-between p-3 w-full h-14 border border-neutral-300 rounded-xl"
                >
                  {/* File name */}
                  <div className="flex items-center">
                    <UHIcon className="mr-3 text-neutral text-2xl" icon="file-lines" />
                    <UHText className="line-clamp-1" text={fileName} />
                  </div>
                  {/* Date */}
                  <UHText
                    className="hidden text-neutral md:block"
                    text={format(fromUnixTime(invoice.created), 'dd.MM.yyyy')}
                  />
                  {/* Download button */}
                  <a className="cursor-pointer" download href={invoice.invoice_pdf ?? ''}>
                    <UHButton
                      iconClass="text-lg"
                      icon="download"
                      variant="plain"
                      titleKey={['xs'].includes(breakPoint) ? undefined : 'SHARED_BUTTON_DOWNLOAD'}
                      width="auto"
                      height="sm"
                      className="pointer-events-none"
                    />
                  </a>
                </div>
              );
            })}
          </UHCard>
        )}
      </CardGrid>
      <ManageSubscriptionView
        loading={loading}
        show={manageSubscriptionShown}
        onClose={() => setManageSubscriptionShown(false)}
        onChangeToTier={onChangeToTier}
        hasDowngrade={!!downgradeSubscription}
        downgradeToTier={downgradeSubscription?.tier}
        downgradeToTierAt={downgradeSubscriptionDate}
        onReactivateCancelledSubscription={onReactivateCancelledSubscription}
        loadingTierChange={loadingTierChange}
        loadingReactivateSubscription={loadingReactivateSubscription}
      />
    </PageContainer>
  );
};

export default SubscriptionScreen;
