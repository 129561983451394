import clsx from 'clsx';
import React from 'react';
import RotationContainer from '../../animations/RotationContainer';
import UHIcon from '../icon/UHIcon';
import Center from '../transform/Center';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  open?: boolean;
  variant?: 'default' | 'inactive' | 'warning';
};

const RoundedDropdownButton: React.FC<Props> = ({ open, variant, className, ...htmlProps }) => {
  return (
    <Center
      className={clsx(
        'absolute -bottom-1 -right-1 w-5 h-5 border border-neutral-250 rounded-full cursor-pointer ring-2 ring-neutral-250',
        {
          'bg-neutral': variant === 'inactive',
          'bg-primary': variant === 'default',
          'bg-warning-500': variant === 'warning',
        },
        className,
      )}
      {...htmlProps}
    >
      <RotationContainer className="flex items-center justify-center" rotation={open ? -180 : 0} type="fast">
        <UHIcon className="text-typoInverted text-xxs" icon={variant === 'warning' ? 'exclamation' : 'chevron-down'} />
      </RotationContainer>
    </Center>
  );
};

export default RoundedDropdownButton;
