import { useState } from 'react';
import { User, UserStatus } from 'src/types/conference';
import { ConversationRenderData, SessionRenderData } from 'src/types/conference/render/ConferenceViewTypes';

const mockNames = ['Hans', 'Mathilde', 'Christian', 'Chip', 'Tjaart', 'Bernhard', 'Tienie'];
const mockUsers: User[] = [];

const getInitialMockData = (): SessionRenderData => {
  return {
    users: [...mockUsers],
    conversations: [],
  };
};

let idCounter = 10;
const getUniqueId = (): string => {
  idCounter += 1;
  return `${idCounter}`;
};

export type MockConference = {
  sessionRenderData: SessionRenderData;
  addUser: (user: User | undefined) => void;
  removeUser: (user: User) => void;
  setUserStatus: (user: User, userStatus: UserStatus) => void;
  leaveConversation: (user: User) => void;
  joinUser: (user1: User, user2: User) => void;
};

export const useMockConference = (): MockConference => {
  const [mockData, setMockData] = useState(getInitialMockData());

  const addUser = (user: User | undefined = undefined): void => {
    const id = getUniqueId();
    const newUser: User = user ?? {
      id,
      type: 'user',
      name: `Waldi${id}`,
      status: 'Active',
      imageOnlyMode: false,
      sharingDisplay: false,
      micMuted: false,
      streams: [],
      isConnectionScoreWeak: false,
    };

    setMockData((mockData) => ({
      ...mockData,
      users: [...mockData.users, newUser],
    }));
  };

  const removeUser = (user: User): void => {
    setMockData((mockData) => {
      if (user.isMe) return mockData;
      const newUsers = [...mockData.users];
      const userIndex = newUsers.findIndex((u) => u.id === user.id);
      if (userIndex === -1) return mockData;

      newUsers.splice(userIndex, 1);

      let newConversations = [...mockData.conversations];
      // remove the selected user from all conversations
      newConversations = newConversations.map((c) => {
        return {
          ...c,
          users: c.users.filter((u) => u.id !== user.id),
        };
      });
      // close all conversations that contain less than 2 users
      newConversations = newConversations.filter((conversation) => conversation.users.length >= 2);

      return {
        ...mockData,
        users: newUsers,
        conversations: newConversations,
      };
    });
  };

  const setUserStatus = (user: User, userStatus: UserStatus): void => {
    setMockData((mockData) => {
      if (user.isMe) return mockData;
      const newUsers = [...mockData.users];
      const userIndex = newUsers.findIndex((u) => u.id === user.id);
      if (userIndex === -1) return mockData;
      newUsers[userIndex].status = userStatus;

      return {
        ...mockData,
        users: newUsers,
      };
    });
  };

  const leaveConversation = (user: User) => {
    setMockData((mockData) => {
      const convIndex = mockData.conversations.findIndex((c) => c.users.find((u) => u.id === user.id) !== undefined);
      if (convIndex === -1) return mockData;
      if (mockData.conversations[convIndex].users.length <= 2) {
        return {
          ...mockData,
          conversations: [
            ...mockData.conversations.slice(0, convIndex),
            ...mockData.conversations.slice(convIndex + 1),
          ],
        };
      }
      return {
        ...mockData,
        conversations: [
          ...mockData.conversations.slice(0, convIndex),
          {
            ...mockData.conversations[convIndex],
            users: mockData.conversations[convIndex].users.filter((u) => u.id !== user.id),
          },
          ...mockData.conversations.slice(convIndex + 1),
        ],
      };
    });
  };

  const joinUser = (user1: User, user2: User) => {
    setMockData((mockData) => {
      if (user1.id === user2.id) return mockData;
      const conv1Index = mockData.conversations.findIndex((c) => c.users.find((u) => u.id === user1.id) !== undefined);
      let conv2Index = mockData.conversations.findIndex((c) => c.users.find((u) => u.id === user2.id) !== undefined);
      if (conv1Index === conv2Index && conv1Index !== -1) return mockData;
      let newConversations = [...mockData.conversations];
      if (conv1Index !== -1) {
        if (newConversations[conv1Index].users.length <= 2) {
          newConversations = [...newConversations.slice(0, conv1Index), ...newConversations.slice(conv1Index + 1)];
        } else {
          newConversations = [
            ...newConversations.slice(0, conv1Index),
            {
              ...newConversations[conv1Index],
              users: newConversations[conv1Index].users.filter((u) => u.id !== user1.id),
            },
            ...newConversations.slice(conv1Index + 1),
          ];
        }
      }

      conv2Index = newConversations.findIndex((c) => c.users.find((u) => u.id === user2.id) !== undefined);
      if (conv2Index !== -1) {
        newConversations[conv2Index] = {
          ...newConversations[conv2Index],
          users: [...newConversations[conv2Index].users, user1],
        };
      } else {
        const id = getUniqueId();
        const newConversation: ConversationRenderData = {
          id,
          users: [user1, user2],
        };
        newConversations = [...newConversations, newConversation];
      }

      return {
        ...mockData,
        conversations: newConversations,
      };
    });
  };

  return { sessionRenderData: mockData, addUser, removeUser, setUserStatus, leaveConversation, joinUser };
};
