const tailwindConfig = {
  content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  theme: {
    fontFamily: {
      sans: ['Poppins'],
      body: ['Inter'],
    },
    colors: {
      transparent: 'transparent',
      black: 'black',
      primary: {
        100: '#f3f9ff',
        200: '#d4e5f7',
        300: '#7baee8',
        DEFAULT: '#3b78bf',
        700: '#164174',
      },
      secondary: {
        200: '#7debbd',
        DEFAULT: '#22be7c',
        700: '#097246',
      },
      background: '#fff',
      neutral: {
        100: '#f8fbfd',
        200: '#f4f5f8',
        250: '#e9f1f2',
        300: '#dfe4ed',
        400: '#ccd3de',
        DEFAULT: '#afb5bf',
        700: '#656a72',
        900: '#081c40',
      },
      typo: '#081c40',
      typoInverted: '#ffffff',
      success: {
        100: '#dbf0d9',
        200: '#b7e1b4',
        300: '#93d38e',
        400: '#6fc469',
        DEFAULT: '#4bb543',
        600: '#3c9136',
        700: '#2d6d28',
        800: '#1e481b',
        900: '#0f240d',
      },
      warning: {
        100: '#feedcd',
        200: '#feda9c',
        300: '#fdc86a',
        400: '#fdb539',
        500: '#fca307',
        600: '#ca8206',
        700: '#976204',
        800: '#654103',
        900: '#322101',
      },
      error: {
        50: '#fef2f2',
        100: '#fee2e2',
        200: '#fecaca',
        300: '#fca5a5',
        400: '#f87171',
        DEFAULT: '#ef4444',
        600: '#dc2626',
        700: '#b91c1c',
        800: '#991b1b',
        900: '#7f1d1d',
      },
    },
    zIndex: {
      0: 0,
      1: 1,
      2: 2,
      3: 3,
      10: 10,
      20: 20,
      30: 30,
      40: 40,
      50: 50,
      50: 50,
      75: 75,
      100: 100,
      auto: 'auto',
    },
    extend: {
      fontSize: {
        xxs: '.6rem',
      },
      spacing: {
        18: '4.5rem',
        104: '26rem',
        128: '32rem',
        192: '48rem',
        256: '64rem',
      },
      boxShadow: {
        md: '0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.03);',
        hard: '0 5px 0 0 rgba(0, 0, 0, 0.12)',
        '3xl': '0 0 80px 0 rgba(0, 0, 0, 0.06)',
      },
      borderRadius: {
        '4xl': '2rem',
        '5xl': '2.5rem',
        '6xl': '3rem',
        '7xl': '3.5rem',
        '8xl': '4rem',
      },
      borderWidth: {
        16: '16px',
      },
      minHeight: {
        20: '5rem',
        72: '18rem',
        96: '24rem',
        120: '30rem',
      },
    },
  },
  plugins: [require('@tailwindcss/forms'), require('@tailwindcss/line-clamp'), require('@tailwindcss/aspect-ratio')],
};

export default tailwindConfig;
