import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import Loader from '../loader/Loader';
import UHText from '../text/UHText';

type TextButtonProps = React.HTMLAttributes<HTMLInputElement> & {
  text?: string;
  textKey?: DictionaryKeyType;
  underline?: boolean;
  color?: 'default' | 'background' | 'error';
  loading?: boolean;
};

const UHTextButton: React.FC<TextButtonProps> = ({
  text,
  textKey,
  underline = false,
  color = 'default',
  loading,
  className,
  ...htmlProps
}) => {
  if (loading)
    return (
      <div className={clsx('w-6 h-6', className)} {...htmlProps}>
        <Loader
          className="transform scale-50"
          colorClass={clsx({
            'bg-neutral-400': color === 'default',
            'bg-typoInverted': color === 'background',
            'bg-error': color === 'error',
          })}
        />
      </div>
    );
  return (
    <UHText
      className={clsx(className, 'w-max', {
        'cursor-pointer': htmlProps.onClick,
        underline: underline,
        'text-primary': color === 'default',
        'text-typoInverted': color === 'background',
        'text-error': color === 'error',
      })}
      text={text}
      textKey={textKey}
      {...htmlProps}
    />
  );
};

export default UHTextButton;
