import React from 'react';
import { SpaceNameData, spaceNameSchema } from 'src/services/forms/schemaService';
import OverlayCard, { OverlayCardProps } from '../../core/containers/OverlayCard';
import FormikTextArea from '../../core/inputs/FormikTextArea';
import FormikTextField from '../../core/inputs/FormikTextField';
import UHForm, { UHFormProps } from '../../form/UHForm';

type Props = OverlayCardProps & {
  formProps?: Partial<UHFormProps<SpaceNameData>>;
  data: SpaceNameData;
  onChangedData: (data: SpaceNameData) => void;
};

const SpaceNameForm: React.FC<Props> = ({ data, onChangedData, width = 'md', formProps, ...htmlProps }) => {
  return (
    <OverlayCard className="min-h-120" width={width} titleKey="SPACE_CREATE_LABEL_SPACE_NAME" {...htmlProps}>
      {/* Form */}
      <UHForm<SpaceNameData>
        {...formProps}
        loading={htmlProps.loading}
        data={data}
        schema={spaceNameSchema}
        onCancel={htmlProps.onClose}
        onSubmit={onChangedData}
      >
        <FormikTextField mb="none" className="mb-6" name="name" labelKey="SPACE_CREATE_LABEL_SPACE_NAME" />
        <FormikTextArea className="mb-4" name="description" labelKey="SPACE_CREATE_LABEL_DESCRIPTION" />
      </UHForm>
    </OverlayCard>
  );
};

export default SpaceNameForm;
