/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */

import { Vector } from 'src/services/math/types';
import { clamp, mirror } from 'src/services/math/utils';
import { BubbleRenderData } from 'src/types/conference/render/ConferenceViewTypes';

export const resolveBorderCollision = (bubble: BubbleRenderData, dimension: Vector): void => {
  const aspect = dimension.x / dimension.y;
  bubble.pos.x = mirror(bubble.pos.x, bubble.radius * bubble.scale, aspect - bubble.radius * bubble.scale);
  bubble.pos.y = mirror(bubble.pos.y, bubble.radius * bubble.scale, 1 - bubble.radius * bubble.scale);
};

export const resolveCollision = (bubble1: BubbleRenderData, bubble2: BubbleRenderData, dimension: Vector): void => {
  // Vector between the bubble centers. Added randomness in case bubbles overlap perfectly
  let dir = Vector.subtract(bubble2.pos, bubble1.pos);
  if (dir.length() < 0.0001) dir = dir.add(Vector.random(0.001));
  // Distance between bubble centers
  const centerDistance = dir.length();
  // Normalize direction vector
  dir = dir.divide(centerDistance);
  // Distance between bubble borders
  const borderDistance = centerDistance - (bubble1.radius * bubble1.scale + bubble2.radius * bubble2.scale);
  if (borderDistance < -0.001) {
    // console.log(`Colliding: ${bubble1.content.id}, ${bubble2.content.id}`);
    // Bubbles are colliding
    // Resolve bubble collision
    bubble1.pos = Vector.add(Vector.multiply(borderDistance * 0.5, dir), bubble1.pos);
    bubble2.pos = Vector.add(Vector.multiply(-borderDistance * 0.5, dir), bubble2.pos);
    // Resolve border collision
    resolveBorderCollision(bubble1, dimension);
    resolveBorderCollision(bubble2, dimension);
  }
};

export const resolveCollisions = (bubbles: BubbleRenderData[], dimension: Vector): BubbleRenderData[] => {
  for (let i = 0; i < bubbles.length; i++) {
    const bubble1 = bubbles[i];
    // Move away from other bubbles
    for (let k = 0; k < bubbles.length; k++) {
      if (i === k) continue;
      const bubble2 = bubbles[k];
      resolveCollision(bubble1, bubble2, dimension);
    }
  }
  for (let i = 0; i < bubbles.length; i++) {
    const bubble = bubbles[i];
    resolveBorderCollision(bubble, dimension);
  }
  return bubbles;
};

export const centerBubbles = (bubbles: BubbleRenderData[], dimension: Vector): BubbleRenderData[] => {
  const aspect = dimension.x / dimension.y;
  // Get bounding box
  const minX = Math.min(...bubbles.map((b) => b.pos.x - b.radius * b.scale));
  const maxX = Math.max(...bubbles.map((b) => b.pos.x + b.radius * b.scale));
  const minY = Math.min(...bubbles.map((b) => b.pos.y - b.radius * b.scale));
  const maxY = Math.max(...bubbles.map((b) => b.pos.y + b.radius * b.scale));
  // Get center offset vector
  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;
  const centerXOffset = centerX - aspect / 2;
  const centerYOffset = centerY - 0.5;
  const correctionVector = new Vector(-centerXOffset, -centerYOffset);
  // Correct bubbles by vector
  bubbles.forEach((b) => {
    b.pos = Vector.add(b.pos, correctionVector);
  });
  return bubbles;
};

export const bubbleUpdateStep = (
  oldBubbles: BubbleRenderData[],
  dimension: Vector,
  temperature: number,
): BubbleRenderData[] => {
  // Force is only applied to bubbles with a gap smaller than requiredBorderGap
  const requiredBorderGap = 0.1;
  const requiredBorderGapConference = 0.2;
  // Container aspect: width = aspect; height = 1
  const aspect = dimension.x / dimension.y;
  // eslint-disable-next-line no-param-reassign
  temperature *= Math.min(aspect, 1);
  const newBubbles: BubbleRenderData[] = [];
  for (let i = 0; i < oldBubbles.length; i++) {
    const bubble = oldBubbles[i];
    let sumForces = Vector.zero();
    // Move away from other bubbles
    for (let k = 0; k < oldBubbles.length; k++) {
      if (i === k) continue;
      const otherBubble = oldBubbles[k];
      // Vector between the bubble centers. Added randomness in case bubbles overlap perfectly
      let dir = Vector.subtract(otherBubble.pos, bubble.pos).add(Vector.random(0.001));
      // Distance between bubble centers
      const centerDistance = dir.length();
      // Normalize direction vector
      dir = dir.divide(centerDistance);
      // Distance between bubble borders
      const borderDistance = centerDistance - (bubble.radius * bubble.scale + otherBubble.radius * otherBubble.scale);
      let force: number;
      // Large force when bubbles overlap
      if (borderDistance < 0) force = Math.max(2, -borderDistance * 0.5);
      // Attract bubbles in the same conversation
      else if (bubble.conversationId !== null && bubble.conversationId === otherBubble.conversationId) force = -4;
      // Force if not in the same conversation
      else if (bubble.conversationId !== otherBubble.conversationId)
        force = 20 * (Math.max(requiredBorderGapConference - borderDistance, 0) / requiredBorderGapConference) ** 4;
      // Force if not in a conversation
      else force = 20 * (Math.max(requiredBorderGap - borderDistance, 0) / requiredBorderGap) ** 4;
      // console.log(`Force: ${bubble.content.id}, ${otherBubble.content.id}: ${force}`);
      sumForces = sumForces.subtract(dir.scale(force * temperature));
    }

    // Force to center (stronger for fewer bubbles)
    const centerForce = clamp(400 / (oldBubbles.length + 0.01) ** 2, 0.1, 100);
    let dir = Vector.subtract(new Vector(0.5 * aspect, 0.5), bubble.pos).add(Vector.random(0.01));
    const centerDistance = dir.length();
    dir = dir.divide(centerDistance);
    const fCenter = Math.min(centerForce * temperature * centerDistance ** 2, centerDistance);
    sumForces = sumForces.add(dir.scale(fCenter));

    // Update position
    const newPosition = Vector.add(sumForces.scale(bubble.scale * 2), bubble.pos);
    if (isNaN(newPosition.x)) newPosition.x = aspect / 2 + Math.random() * 0.01;
    if (isNaN(newPosition.y)) newPosition.y = 0.5 + Math.random() * 0.01;
    // newPosition.x = mirror(newPosition.x, bubble.radius * bubble.scale, aspect - bubble.radius * bubble.scale);
    // newPosition.y = mirror(newPosition.y, bubble.radius * bubble.scale, 1 - bubble.radius * bubble.scale);
    newBubbles.push({
      pos: newPosition,
      radius: bubble.radius,
      scale: bubble.scale,
      content: bubble.content,
      conversationId: bubble.conversationId,
      type: bubble.type,
    });
  }
  return newBubbles;
};

const getMaxAreaForBubbleCount = (): number => {
  return 3.5;
};

/** Update bubble scales. */
export const correctBubbleScales = (oldBubbles: BubbleRenderData[], dimension: Vector): BubbleRenderData[] => {
  if (dimension.y === 0) return oldBubbles;
  // Aspect * 1
  const canvasArea = dimension.x / dimension.y;
  const bubbleAreaSum = oldBubbles.map((c) => c.radius ** 2 * Math.PI).reduce((a1, a2) => a1 + a2, 0);
  let scale = Math.sqrt(canvasArea / (bubbleAreaSum * getMaxAreaForBubbleCount()));

  // Max scale based on max bubble radius and dimensions
  const maxBubbleRadius = Math.max(0, ...oldBubbles.map((c) => c.radius));
  const maxAllowedBubbleDiameterInPixels = 400;
  const maxAllowedRadiusBecauseOfSize = (maxAllowedBubbleDiameterInPixels / dimension.y) * 0.5;
  const maxAllowedRadiusBecauseOfAspect = Math.min(dimension.x / dimension.y, 1) * 0.9 * 0.5;
  const maxAllowedRadius = Math.min(maxAllowedRadiusBecauseOfSize, maxAllowedRadiusBecauseOfAspect);
  const maxAllowedScale = maxBubbleRadius === 0 ? 0 : maxAllowedRadius / maxBubbleRadius;
  scale = Math.min(scale, maxAllowedScale);

  const newBubbles = oldBubbles.map((c) => {
    return { ...c, radius: c.radius, scale };
  });
  return newBubbles;
};

/** Resolve bubble positions. */
export const correctBubbles = (oldBubbles: BubbleRenderData[], dimensions: Vector, steps = 100): BubbleRenderData[] => {
  let newBubbles = oldBubbles;
  for (let i = steps; i > 0; i--) {
    newBubbles = bubbleUpdateStep(newBubbles, dimensions, 0.001 + (i / steps) * 0.005);
    newBubbles = resolveCollisions(newBubbles, dimensions);
  }
  newBubbles = centerBubbles(newBubbles, dimensions);
  return newBubbles;
};
