import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { configureAmplify } from 'src/services/aws-amplify/aws-exports';
import AuthScreen from './views/screens/Auth/AuthScreen';
import { DictionaryType } from './types/localization/dictionaryType';
import { useTypedSelector } from './store/store';
import { env } from './services/environment/envService';
import { allPages, matchPathPages, pagesMatchForAuth, pagesMatchForBoth } from './services/pages/pageService';
import AppNavigator from './views/screens/AppNavigator/AppNavigator';
import ConfirmModal, { ConfirmModalData } from './views/components/core/containers/ConfirmModal';
import WebsiteNavigator from './views/screens/AppNavigator/WebsiteNavigator';
import LobbyScreen from './views/screens/Lobby/LobbyScreen';
import UHCookieConsent from './views/components/cookie/UHCookieConsent';
import { useUHHistory } from './views/hooks/navigation/useUHHistory';
import { initAnalytics } from './services/analytics/analyticsService';

type AppContextType = {
  setConfirmData: (data: ConfirmModalData | undefined) => void;
};

export const AppContext = React.createContext({} as AppContextType);

export const LocalizationContext: React.Context<DictionaryType> = React.createContext({} as DictionaryType);

// configure AWS amplify
configureAmplify();

// Analytics
initAnalytics();

/** The App's root component */
const App: React.FC = () => {
  const history = useUHHistory();
  const dictionary = useTypedSelector((state) => state.localization.dictionary);
  let appLanguage = useTypedSelector((state) => state.localization.currentLanguageCode);
  if (!appLanguage) appLanguage = 'en';

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [currentConfirmData, setCurrentConfirmData] = useState<ConfirmModalData | undefined>();

  const setConfirmData = (data: ConfirmModalData | undefined) => {
    setCurrentConfirmData(data);
    setIsConfirmModalOpen(!!data);
  };

  const context: AppContextType = {
    setConfirmData,
  };

  const matchForWebApp =
    matchPathPages(history.location.pathname, pagesMatchForBoth) ||
    matchPathPages(history.location.pathname, pagesMatchForAuth);

  return (
    <AppContext.Provider value={context}>
      <LocalizationContext.Provider value={dictionary}>
        <div lang={appLanguage} className="flex flex-col min-h-screen bg-background">
          {/* Webapp */}
          {env.ConferencingEnabled && matchForWebApp && <AppNavigator />}
          {(!env.ConferencingEnabled || !matchForWebApp) && (
            <Routes>
              {/* Auth */}
              {env.ConferencingEnabled && <Route path={allPages.auth.url} element={<AuthScreen />} />}
              {/* Lobby */}
              {env.ConferencingEnabled && <Route path={`${allPages.lobby.url}/:spaceId`} element={<LobbyScreen />} />}
              {/* Website */}
              <Route path="*" element={<WebsiteNavigator />} />
            </Routes>
          )}
          <ConfirmModal
            show={isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
            {...currentConfirmData}
          />
          <UHCookieConsent />
        </div>
      </LocalizationContext.Provider>
    </AppContext.Provider>
  );
};

export default App;
