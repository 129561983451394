import React from 'react';
import { useTypedSelector } from 'src/store/store';
import UHRichText, { RichText } from 'src/views/components/core/text/UHRichText';
import termsData from './terms.json';

const TermsScreen: React.FC = () => {
  const selectedLanguageCode = useTypedSelector((state) => state.localization.currentWebpageLanguageCode);
  const localizedTerms =
    (termsData.languages as never as Record<string, { content: RichText }>)[selectedLanguageCode] ??
    termsData.languages['en'];

  return (
    <main className="bg-card">
      {/* Body with sections */}
      <div className="page-width flex flex-col items-center">
        <div className="flex flex-col mb-32 mt-16 max-w-2xl">
          <UHRichText content={localizedTerms.content} />
        </div>
      </div>
    </main>
  );
};

export default TermsScreen;
