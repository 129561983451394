import { useContext, useState } from 'react';
import { AppContext } from 'src/App';
import { getPersonalSubscriptionForTier } from 'src/services/subscriptions/subscriptionService';
import { useTypedSelector, actions } from 'src/store/store';
import { Space } from 'src/types/spaces/SpaceType';
import { useUHHistory } from '../navigation/useUHHistory';

export type UseCreateSpaceType = {
  onCreateSpace: () => void;
  creatingSpace: boolean;
};

export const useCreateSpace = (): UseCreateSpaceType => {
  const { setConfirmData } = useContext(AppContext);
  const user = useTypedSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const history = useUHHistory();
  const spaces = useTypedSelector((state) => state.spaces.spaces);

  const onCreateSpace = async () => {
    // Max spaces reached
    const numAllowedSpaces = getPersonalSubscriptionForTier(user.subscription).restrictions.spaces;
    if (spaces.length >= numAllowedSpaces) {
      setConfirmData({
        headerTextKey: 'BETA_PHASE_SPACE_LIMIT_TITLE',
        bodyTextKey: 'BETA_PHASE_SPACE_LIMIT_DESCRIPTION',
        confirmTextKey: 'SHARED_TEXT_OKAY',
        withoutCancel: true,
      });
      return;
    }
    setLoading(true);
    try {
      const result = await actions.createRandomSpace();
      const spaceId = (result as unknown as { payload: Space }).payload.id;
      history.pushPage('spaceDetail', spaceId);
    } finally {
      setLoading(false);
    }
  };

  return {
    onCreateSpace,
    creatingSpace: loading,
  };
};
