import React, { useEffect, useState } from 'react';
import { useTypedSelector } from 'src/store/store';
import PageContainer from 'src/views/components/layout/PageContainer';
import { getPersonalSubscriptionForTier } from 'src/services/subscriptions/subscriptionService';
import UHText from 'src/views/components/core/text/UHText';
import NewSubscriptionCard from './components/NewSubscriptionCard';
import CardGrid from 'src/views/components/layout/CardGrid';
import UHImage from 'src/views/components/core/image/UHImage';
import Center from 'src/views/components/core/transform/Center';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import { getSubscriptions } from 'src/services/api/subscriptionAPI';

const SubscriptionCompletedScreen: React.FC = () => {
  const history = useUHHistory();
  const user = useTypedSelector((state) => state.user.user);
  const [subscriptionTier, setSubscriptionTier] = useState(user.subscription);
  const currentSubscription = getPersonalSubscriptionForTier(subscriptionTier);

  const [loadingInitialData, setLoadingInitialData] = useState(true);
  useEffect(() => {
    const loadData = async () => {
      setLoadingInitialData(true);
      const subscriptions = await getSubscriptions();
      console.log(subscriptions);
      if (subscriptions.length > 0) setSubscriptionTier(subscriptions[0].tier);
      setLoadingInitialData(false);
    };
    loadData();
  }, []);

  console.log(subscriptionTier);

  return (
    <PageContainer className="flex-1" titleKey="PROFILE_OPTIONS_SUBSCRIPTIONS" icon="star">
      {!loadingInitialData && (
        <Center className="-mt-12 h-full">
          <CardGrid>
            <div className="z-1 flex flex-col justify-center mb-8 space-y-4 lg:mb-0 lg:pl-14">
              <UHText className="text-primary-700" variant="title-sm" textKey="SUBSCRIPTION_WELCOME" />
              <UHText className="text-primary" variant="title-2xl" textKey={currentSubscription.title} />
            </div>
            <div className="hidden flex-col justify-end -ml-16 xl:flex">
              <UHImage fillType="contain" className="-ml-4 -my-8" src="/assets/images/Illustrations/RocketLaunch.png" />
            </div>
            <NewSubscriptionCard
              subscription={currentSubscription}
              onContinue={() => history.pushPage('spacesOverview')}
            />
          </CardGrid>
        </Center>
      )}
    </PageContainer>
  );
};

export default SubscriptionCompletedScreen;
