import React, { useState } from 'react';
import Center from 'src/views/components/core/transform/Center';
import UHText from 'src/views/components/core/text/UHText';
import OnboardingIntroView from './components/OnboardingIntroView';
import OnboardingNameForm from './components/OnboardingNameForm';
import { OnboardingNameData } from 'src/services/forms/schemaService';
import OnboardingTermsView from './components/OnboardingTermsView';
import { useTypedSelector } from 'src/store/store';

export type OnboardingState = 'intro' | 'name' | 'subscription' | 'terms' | 'complete';

const OnboardingScreen: React.FC = () => {
  const [onboardingState, setOnboardingState] = useState<OnboardingState>('intro');
  const user = useTypedSelector((state) => state.user.user);
  const [nameData, setNameData] = useState<OnboardingNameData>({ firstName: '', lastName: '', displayName: '' });

  return (
    <Center className="main-gradient w-screen h-screen" col>
      {/* Card */}
      <UHText className="mb-14 text-primary-700" variant="title-xl" text="Nevolane Space" />
      <div className="flex flex-col justify-center px-4 py-10 w-96 bg-background border border-background rounded-3xl shadow-3xl">
        {/* Content depending on onboarding state */}
        {onboardingState === 'intro' && <OnboardingIntroView setOnboardingState={setOnboardingState} />}
        {onboardingState === 'name' && (
          <OnboardingNameForm setOnboardingState={setOnboardingState} nameData={nameData} setNameData={setNameData} />
        )}
        {onboardingState === 'terms' && (
          <OnboardingTermsView
            setOnboardingState={setOnboardingState}
            userData={{
              locationCode: user.locationCode,
              languageCode: user.languageCode,
              emailNotifications: user.emailNotifications,
              pushNotificationsSpaceInvite: user.pushNotificationsSpaceInvite,
              pushNotificationsSpaceEvents: user.pushNotificationsSpaceEvents,
              ...nameData,
            }}
          />
        )}
      </div>
    </Center>
  );
};

export default OnboardingScreen;
