import clsx from 'clsx';
import React from 'react';
import { DisplayNameData, displayNameSchema } from 'src/services/forms/schemaService';
import { allPages } from 'src/services/pages/pageService';
import { setPendingPublicSpaceJoinURL } from 'src/services/publicSpace/publicSpaceService';
import { Space } from 'src/types/spaces/SpaceType';
import UHButton from 'src/views/components/core/buttons/UHButton';
import FormikTextField from 'src/views/components/core/inputs/FormikTextField';
import UHText from 'src/views/components/core/text/UHText';
import Center from 'src/views/components/core/transform/Center';
import UHForm from 'src/views/components/form/UHForm';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import { useLocalizedKey } from 'src/views/hooks/useLocalizedString';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  space: Space;
  token: string;
};

const EnterLobbyView: React.FC<Props> = ({ space, token, className, ...htmlProps }) => {
  const history = useUHHistory();

  const onLogin = () => {
    setPendingPublicSpaceJoinURL(`${history.location.pathname}?id=${token}`);
    history.pushPage('auth');
  };

  const onEnterSpace = (data: DisplayNameData) => {
    history.navigate(allPages.userMediaSelection.url.replaceAll(':spaceId', space.id.toString()), {
      state: {
        displayName: data.displayName,
        publicSpace: space,
        token,
      },
    });
  };

  const description = useLocalizedKey('LOBBY_DESCRIPTION', space.name);

  return (
    <Center col className={clsx(className)} {...htmlProps}>
      {/* Nevolane title */}
      <UHText
        className="hidden mb-14 text-primary-700 sm:block"
        variant="title-xl"
        text="Nevolane Space"
        onClick={() => history.pushPage('landing')}
      />
      {/* Card */}
      <div
        className="flex flex-col items-center justify-center px-4 py-12 w-96 bg-background border border-background rounded-3xl shadow-3xl"
        style={{ maxWidth: '98vw' }}
      >
        <UHText variant="title-xl" className="mb-2 text-primary" textKey="LOBBY_TITLE" />
        <UHText variant="body-sm" className="mb-4" text={description} />
        {/* Form */}
        <UHForm<DisplayNameData>
          data={{ displayName: '' }}
          schema={displayNameSchema}
          onSubmit={onEnterSpace}
          submitTextKey="LOBBY_ENTER_AS_GUEST"
          className="self-stretch"
        >
          <UHText variant="body-sm" textKey="LOBBY_ENTER_NAME" className="mb-2" />
          <FormikTextField name="displayName" labelKey="PROFILE_SETTINGS_TITLE_NAME" />
        </UHForm>
        <div className="self-stretch my-6 h-px bg-neutral" />
        <UHText variant="body-sm" textKey="LOBBY_OR_LOGIN" className="self-start mb-4" />
        <UHButton titleKey="SHARED_BUTTON_LOGIN" onClick={onLogin} />
      </div>
    </Center>
  );
};

export default EnterLobbyView;
