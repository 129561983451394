import React from 'react';
import { DisplayNameData, displayNameSchema } from 'src/services/forms/schemaService';
import OverlayCard, { OverlayCardProps } from 'src/views/components/core/containers/OverlayCard';
import FormikTextField from 'src/views/components/core/inputs/FormikTextField';
import UHForm from 'src/views/components/form/UHForm';

type Props = OverlayCardProps & {
  data: DisplayNameData;
  onChangedData: (data: DisplayNameData) => void;
};

const DisplayNameForm: React.FC<Props> = ({ data, onChangedData, width = 'sm', ...htmlProps }) => {
  const onSubmit = (data: DisplayNameData) => {
    onChangedData(data);
    htmlProps.onClose?.();
  };

  return (
    <OverlayCard width={width} titleKey="PROFILE_SETTINGS_PLACEHOLDER_DISPLAY_NAME" {...htmlProps}>
      {/* Form */}
      <UHForm<DisplayNameData> data={data} schema={displayNameSchema} onSubmit={onSubmit} onCancel={htmlProps.onClose}>
        <FormikTextField name="displayName" labelKey="PROFILE_SETTINGS_PLACEHOLDER_DISPLAY_NAME" />
      </UHForm>
    </OverlayCard>
  );
};

export default DisplayNameForm;
