import { createContext, useContext } from 'react';
import { CardWidth, useCardWidth } from './useCardWidth';

export type OverlayCardContextType = {
  width: CardWidth;
};

export const OverlayCardContext = createContext<OverlayCardContextType | null>(null);

export const useOverlayCardWidth = (): CardWidth => {
  const ctx = useContext(OverlayCardContext);
  const cardWidth = useCardWidth();
  return ctx?.width ?? cardWidth;
};
