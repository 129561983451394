/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from 'aws-amplify';
import { env } from 'src/services/environment/envService';
import { UHError } from '../../types/error/ErrorTypes';

export type APIError = { code: string; message: string };

export type APIResult<T> = {
  data: T;
  error: APIError | null;
  success: boolean;
};

export type APICallParams = {
  path: string;
  body?: unknown;
};

export const getError = (error: APIError | null): UHError => {
  return new UHError(error?.message, error?.code);
};

const apiCall = async <T>(data: APICallParams, method: 'get' | 'post' | 'put' | 'patch' | 'del'): Promise<T> => {
  const init: any = {};
  if (data.body) init.body = data.body;
  let response: APIResult<T> | undefined;

  try {
    if (method === 'get') response = await API.get(env.AWSAPIName || 'uhura-api', data.path, init);
    else if (method === 'post') response = await API.post(env.AWSAPIName || 'uhura-api', data.path, init);
    else if (method === 'put') response = await API.put(env.AWSAPIName || 'uhura-api', data.path, init);
    else if (method === 'patch') response = await API.patch(env.AWSAPIName || 'uhura-api', data.path, init);
    else if (method === 'del') response = await API.del(env.AWSAPIName || 'uhura-api', data.path, init);
  } catch (e: any) {
    console.log('Error on', data.path, init);
    // console.log(JSON.stringify(e));
    if (e?.response?.data?.error) throw getError(e.response.data.error);
    else throw e;
  }

  if (response) {
    if (response.success) {
      // console.log(response.data);
      return response.data;
    }
    throw getError(response.error);
  } else {
    throw new Error('Unknown error');
  }
};

const get = async <T>(data: APICallParams): Promise<T> => apiCall(data, 'get');
const post = async <T>(data: APICallParams): Promise<T> => apiCall(data, 'post');
const put = async <T>(data: APICallParams): Promise<T> => apiCall(data, 'put');
const patch = async <T>(data: APICallParams): Promise<T> => apiCall(data, 'patch');
const del = async <T>(data: APICallParams): Promise<T> => apiCall(data, 'del');

export const UHAPI = {
  get,
  post,
  put,
  patch,
  del,
};
