import clsx from 'clsx';
import React, { useState } from 'react';
import { getStaticData } from 'src/services/staticData/staticDataService';
import { getPersonalSubscriptionForTier, SubscriptionPeriod } from 'src/services/subscriptions/subscriptionService';
import { useTypedSelector } from 'src/store/store';
import UHButton from 'src/views/components/core/buttons/UHButton';
import OverlayCard, { OverlayCardProps } from 'src/views/components/core/containers/OverlayCard';
import UHText from 'src/views/components/core/text/UHText';
import { useCurrentBreakpoint } from 'src/views/hooks/useCurrentBreakpoint';
import SubscriptionOption from './SubscriptionOption';

type Props = OverlayCardProps & {
  onChangeToTier: (tier: string, period: string) => void;
  downgradeToTier?: string;
  hasDowngrade: boolean;
  downgradeToTierAt?: string;
  onReactivateCancelledSubscription: () => void;
  loadingTierChange?: string;
  loadingReactivateSubscription?: boolean;
};

const ManageSubscriptionView: React.FC<Props> = ({
  className,
  onChangeToTier,
  downgradeToTier,
  hasDowngrade,
  downgradeToTierAt,
  onReactivateCancelledSubscription,
  width = 'content',
  loadingTierChange,
  loadingReactivateSubscription,
  ...htmlProps
}) => {
  const currentSubscriptionTier =
    useTypedSelector((state) => state.user.user.subscription) ?? getPersonalSubscriptionForTier().tier;
  const availableSubscriptions = getStaticData().personalSubscriptions;
  const currentSubscriptionPeriod = useTypedSelector((state) => state.user.user.subscriptionPeriod) ?? 'monthly';
  const [selectedPeriod, setSelectedPeriod] = useState<SubscriptionPeriod>(currentSubscriptionPeriod);
  const currentSubscriptionIndex = availableSubscriptions.findIndex((v) => v.tier === currentSubscriptionTier);
  const breakpoint = useCurrentBreakpoint();
  const landscape = ['xs', 'sm', 'md'].includes(breakpoint);

  return (
    <OverlayCard width={width} titleKey="SUBSCRIPTION_TITLE" className={clsx(className)} {...htmlProps}>
      <div className="flex mb-4">
        {/* Help text */}
        {/* <UHText className="mr-4 max-w-xs lg:max-w-lg" textKey="SUBSCRIPTION_PERSONAL_CHOOSE" /> */}
        {/* Spacing */}
        <div className="flex-1" />
        {/* Monthly */}
        <UHButton
          variant={selectedPeriod === 'monthly' ? 'selected' : 'unselected'}
          className="mr-2"
          height="sm"
          width="md"
          titleKey="SHARED_TEXT_MONTHLY"
          onClick={() => setSelectedPeriod('monthly')}
        />
        {/* Yearly */}
        <UHButton
          variant={selectedPeriod === 'yearly' ? 'selected' : 'unselected'}
          height="sm"
          width="md"
          titleKey="SHARED_TEXT_YEARLY"
          onClick={() => setSelectedPeriod('yearly')}
        />
      </div>
      {/* Help text */}
      <UHText className="mb-4 mr-4 max-w-full lg:hidden" textKey="SUBSCRIPTION_PERSONAL_CHOOSE" />
      {/* Options */}
      <div className="grid gap-4 grid-cols-1 justify-items-center mb-12 lg:gap-8 lg:grid-cols-3">
        {getStaticData().personalSubscriptions.map((subscription, index) => {
          return (
            <SubscriptionOption
              key={index}
              subscription={subscription}
              currentSubscriptionTier={currentSubscriptionTier}
              subscriptionPeriod={selectedPeriod}
              hasDowngrade={hasDowngrade}
              selected={currentSubscriptionTier === subscription.tier && currentSubscriptionPeriod === selectedPeriod}
              isForbidden={
                currentSubscriptionIndex < index &&
                selectedPeriod === 'monthly' &&
                currentSubscriptionPeriod === 'yearly'
              }
              isDowngrade={
                currentSubscriptionIndex > index || (currentSubscriptionIndex === index && selectedPeriod === 'monthly')
              }
              onCancel={() => onChangeToTier('P0', 'monthly')}
              onChoose={() => onChangeToTier(subscription.tier, selectedPeriod)}
              onChangeToYearly={() => setSelectedPeriod('yearly')}
              downgradeAt={
                subscription.tier === downgradeToTier &&
                !(currentSubscriptionTier === subscription.tier && selectedPeriod === 'yearly')
                  ? downgradeToTierAt
                  : undefined
              }
              onReactivateCancelledSubscription={onReactivateCancelledSubscription}
              variant={landscape ? 'landscape' : 'portrait'}
              loadingTierChange={loadingTierChange}
              loadingReactivateSubscription={loadingReactivateSubscription}
            />
          );
        })}
      </div>
      {/* Help text */}
      <UHText className="hidden mr-4 max-w-full text-neutral-700 lg:block" textKey="SUBSCRIPTION_PERSONAL_CHOOSE" />
    </OverlayCard>
  );
};

export default ManageSubscriptionView;
