import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { env } from 'src/services/environment/envService';
import { User } from 'src/types/conference';
import { isAudioStream, isVideoStream } from 'src/types/conference/UserStreamType';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import UHImage from 'src/views/components/core/image/UHImage';
import UHText from 'src/views/components/core/text/UHText';
import Absolute from 'src/views/components/core/transform/Absolute';
import Center from 'src/views/components/core/transform/Center';
import VideoStreamView from 'src/views/components/core/video/VideoStreamView';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  user: User;
  conversationId: string | null;
  myUserConversationId?: string | null;
  /** The size is used to determine fontsizes in the component */
  size?: number;
  /** Shows only a placeholder instead of the user content e.g. when dragging the component */
  onlyPlaceHolder?: boolean;
  /** Whether to only show an image or the stream */
  myImageOnlyMode?: boolean;
  variant?: 'default' | 'plain';
};

const UserBubble: React.FC<Props> = ({
  user,
  conversationId,
  myUserConversationId,
  size = 100,
  onlyPlaceHolder,
  myImageOnlyMode,
  variant = 'default',
  className,
  ...htmlProps
}) => {
  const videoStream = user?.streams.find((val) => isVideoStream(val))?.stream;
  const audioStream = user?.streams.find((val) => isAudioStream(val))?.stream;
  const videoTrack = videoStream?.getVideoTracks()[0];
  const isOnBreak = user.status === 'Break';
  const [, setLoading] = useState(videoTrack?.muted ?? true);
  const mockUserId = useRef(env.DebugStreams ? 1 + (parseInt(user.id) % 25) : 0).current;
  const inConversation = !!conversationId;
  const imageOnly = myImageOnlyMode || user.imageOnlyMode;

  const onMute = () => {
    setLoading(true);
  };

  const onUnMute = () => {
    setLoading(false);
  };

  useEffect(() => {
    setLoading(videoTrack?.muted ?? true);
    videoTrack?.addEventListener('ended', onMute);
    videoTrack?.addEventListener('mute', onMute);
    videoTrack?.addEventListener('unmute', onUnMute);
    return () => {
      videoTrack?.removeEventListener('ended', onMute);
      videoTrack?.removeEventListener('mute', onMute);
      videoTrack?.removeEventListener('unmute', onUnMute);
    };
  }, [videoTrack]);

  const small = size <= 80;

  const showBadConnection = !env.DebugStreams && user.isConnectionScoreWeak && !isOnBreak && !imageOnly;

  return (
    <div
      className={clsx(
        'relative rounded-full',
        {
          'cursor-pointer': htmlProps.onClick,
          'border-2 border-background': inConversation && !user.sharingDisplay,
          'border-2 border-primary-300': inConversation && user.sharingDisplay,
        },
        className,
      )}
      {...htmlProps}
    >
      {/* Stream */}
      <Absolute className={clsx('rounded-full overflow-hidden', { invisible: onlyPlaceHolder })}>
        <VideoStreamView
          mirror={user.isMe}
          muted={user.isMe}
          className="absolute inset-0 bg-neutral-300"
          videoStream={videoStream}
          audioStream={audioStream}
        />
      </Absolute>
      {/* Debug Image */}
      {env.DebugStreams && !onlyPlaceHolder && (
        <Absolute className="rounded-full overflow-hidden">
          <UHImage className="absolute inset-0" src={`/images/portraits/portrait-${mockUserId}.jpg`} />
        </Absolute>
      )}
      {/* Image only overlay */}
      {imageOnly && (
        <Absolute className="flex flex-col items-center justify-center bg-primary rounded-full overflow-hidden">
          {user.avatar && (
            <Absolute className="rounded-full overflow-hidden">
              <UHImage className="absolute inset-0" src={`${env.ImageCDN}/${user.avatar}`} />
            </Absolute>
          )}
          {user.isMe && !user.avatar && (
            <UHIcon
              className={clsx('text-typoInverted', { 'text-5xl ': !small, 'text-2xl': small })}
              icon="video-slash"
            />
          )}
          {!user.isMe && !user.avatar && (
            <UHText
              className={clsx('text-typoInverted font-sans select-none', { 'text-7xl ': !small, 'text-5xl': small })}
              text={[...user.name].slice(0, 1).join('')}
            />
          )}
        </Absolute>
      )}
      {/* Break overlay */}
      {isOnBreak && (
        <Absolute className="flex flex-col items-center justify-center bg-neutral-400 rounded-full overflow-hidden">
          <UHIcon className={clsx('text-typoInverted', { 'text-5xl': !small, 'text-2xl': small })} icon="user-clock" />
        </Absolute>
      )}
      {/* Username */}
      <Absolute
        className={clsx('flex flex-col items-center justify-end', {
          'opacity-0 transition-opacity duration-150 hover:opacity-100': variant === 'plain',
        })}
      >
        <Center
          className={clsx('p-1 bg-opacity-70 rounded-lg', {
            'bg-black': !isOnBreak,
            'mb-5': variant !== 'plain',
            'mb-0': variant === 'plain',
          })}
          style={{ maxWidth: variant === 'plain' ? '110%' : '60%' }}
        >
          <UHText className="line-clamp-2 wrap text-center text-typoInverted text-xs" text={user.name + ' '} />
          {user.micMuted && myUserConversationId && myUserConversationId === conversationId && (
            <UHIcon className="line-clamp-2 wrap text-center text-typoInverted text-xs" icon="microphone-slash" />
          )}
          {showBadConnection && (
            <UHIcon className="line-clamp-2 wrap text-center text-error text-xs animate-pulse" icon="wifi" />
          )}
        </Center>
      </Absolute>
      {/* Placeholder overlay */}
      {onlyPlaceHolder && (
        <Absolute className="flex flex-col items-center justify-center bg-neutral-300 rounded-full overflow-hidden" />
      )}
    </div>
  );
};

export default UserBubble;
