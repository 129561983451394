import { Space } from 'src/types/spaces/SpaceType';
import { copyToClipboard } from '../clipboard/clipboardService';
import { isLocalhost } from '../environment/envService';

export const getPublicSpaceLink = (s: Space): string => {
  if (!s.token) return '';
  return `${isLocalhost ? '' : 'https://'}${location.hostname}${isLocalhost ? ':3000' : ''}/lobby/${s.id}?id=${
    s.token
  }`;
};

export const copySpaceLinkToClipboard = async (s: Space): Promise<void> => {
  return copyToClipboard(getPublicSpaceLink(s));
};

// This is set to a URL when the user logs in on the lobby screen such that the user can be redirected to the public space after login
export let pendingPublicSpaceJoinURL: string | undefined;

export const setPendingPublicSpaceJoinURL = (url: string | undefined): void => {
  pendingPublicSpaceJoinURL = url;
};
