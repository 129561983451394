import clsx from 'clsx';
import React from 'react';
import { SpaceInvite } from 'src/types/spaces/SpaceInviteType';
import UHButton from 'src/views/components/core/buttons/UHButton';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import Loader from 'src/views/components/core/loader/Loader';
import UHText from 'src/views/components/core/text/UHText';
import { useLocalizedKey } from 'src/views/hooks/useLocalizedString';
import { useOverlayCardWidth } from 'src/views/hooks/views/useOverlayCardWidth';

export type InviteState = 'open' | 'loading' | 'accepted' | 'declined';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  invite: SpaceInvite;
  state: InviteState;
  onDecline: (id: number) => void;
  onAccept: (id: number) => void;
};

const InviteListEntry: React.FC<Props> = ({ invite, state, onDecline, onAccept, className, ...htmlProps }) => {
  const byText = useLocalizedKey('SHARED_TEXT_BY');
  const width = useOverlayCardWidth();
  return (
    <div
      className={clsx('flex items-center px-4 py-2 border border-neutral-300 rounded-xl space-x-2', className)}
      {...htmlProps}
    >
      <div className="flex-1">
        <UHText text={invite.space.name} />
        <UHText className="text-neutral" variant="body-sm" text={`${byText} ${invite.user.displayName}`} />
      </div>
      {state === 'open' && (
        <UHButton
          iconClass="text-lg"
          variant="danger"
          width="xs"
          height="sm"
          icon="xmark"
          onClick={() => onDecline(invite.id)}
          titleKey={width === 'md' ? 'SHARED_BUTTON_DECLINE' : undefined}
        />
      )}
      {state === 'open' && (
        <UHButton
          iconClass="text-lg"
          width="xs"
          height="sm"
          icon="check"
          onClick={() => onAccept(invite.id)}
          titleKey={width === 'md' ? 'SHARED_BUTTON_ACCEPT' : undefined}
        />
      )}
      {state === 'loading' && <Loader colorClass="bg-neutral" className="transform origin-right scale-50" />}
      {state === 'accepted' && <UHIcon className="text-success text-lg" icon="check" />}
      {state === 'declined' && <UHIcon className="text-error text-lg" icon="xmark" />}
    </div>
  );
};

export default InviteListEntry;
