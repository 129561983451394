import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import UHText from '../text/UHText';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  title?: string;
  titleKey?: DictionaryKeyType;
  defaultPadding?: boolean;
};

const UHCard: React.FC<Props> = ({ title, titleKey, defaultPadding = false, className, children, ...htmlProps }) => {
  const localizedText = useLocalizedValue(title, titleKey);

  return (
    <div className={clsx('bg-background rounded-3xl shadow-md', { 'p-6': defaultPadding }, className)} {...htmlProps}>
      {localizedText && <UHText className="mb-3 text-primary" variant="title" text={localizedText} />}
      {children}
    </div>
  );
};

export default UHCard;
