import {
  SpaceInviteType,
  Space,
  SpaceUpdateType,
  SpaceMemberUpdateType,
  SpaceTokenRegenerateType,
} from 'src/types/spaces/SpaceType';
import {
  getAllSpaces,
  updateSpace,
  removeSpace,
  cancelInvite,
  leaveSpace,
  removeUserFromSpace,
  updateSpaceUser,
  inviteSpaceUsers,
  createNewSpace,
  addCrewSpaceUsers,
  addCrewMemberToSpaces,
  createPublicSpace,
  regeneratePublicSpaceToken,
  updateSpaceImage,
} from 'src/services/api/spacesAPI';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { actions, RootState } from '../store';
import { getRandomInt } from 'src/services/math/utils';

export const fetchSpacesThunk = createAsyncThunk('@@spaces/fetchSpaces', async (): Promise<Space[]> => {
  return getAllSpaces();
});

export const createSpaceThunk = createAsyncThunk(
  '@@spaces/createSpace',
  async (params: { data: SpaceUpdateType; usersToInvite: string[] }): Promise<Space> => {
    let space = await createNewSpace(params.data);
    if (params.usersToInvite.length > 0)
      space = await inviteSpaceUsers({ spaceId: space.id, usersToInvite: params.usersToInvite });
    return space;
  },
);

export const createRandomSpaceThunk = createAsyncThunk<Space, void, { state: RootState }>(
  '@@spaces/createRandomSpace',
  async (_, { getState }): Promise<Space> => {
    const spaces = getState().spaces.spaces;
    const user = getState().user.user;

    // Generate space name
    let spaceNumber = 1;
    const baseSpaceName = user.displayName ? `${user.displayName} - Space` : 'Space';
    let spaceName = baseSpaceName;
    while (true) {
      if (spaces.find((s) => s.name === spaceName)) {
        spaceNumber += 1;
        spaceName = `${baseSpaceName} ${spaceNumber}`;
      } else break;
    }

    // Create space
    const spaceData: SpaceUpdateType = { name: spaceName, description: '', imageId: getRandomInt(8) };
    const result = await actions.createSpace({ data: spaceData, usersToInvite: [] });

    return (result as unknown as { payload: Space }).payload;
  },
);

export const createPublicSpaceThunk = createAsyncThunk<
  Space | undefined,
  SpaceUpdateType | undefined,
  { state: RootState }
>('@@spaces/createPublicSpace', async (data: SpaceUpdateType | undefined, { getState }): Promise<Space | undefined> => {
  const publicSpaces = getState().spaces.spaces.filter((s) => !!s.token);
  const user = getState().user.user;
  if (publicSpaces.length > 0 || !user.onboarded) return;
  const spaceData = data ?? {
    name: user.displayName ? `${user.displayName} - Public Space` : 'Public Space',
    description: '',
    imageId: getRandomInt(8),
  };
  try {
    return createPublicSpace(spaceData);
  } catch (e) {
    console.warn('Could not create public space');
  }
});

export const regeneratePublicSpaceTokenThunk = createAsyncThunk(
  '@@spaces/regeneratePublicSpaceToken',
  async (params: { data: SpaceTokenRegenerateType }): Promise<Space> => {
    return regeneratePublicSpaceToken(params.data);
  },
);

export const updateSpaceUserThunk = createAsyncThunk(
  '@@spaces/updateSpaceUser',
  async (data: SpaceMemberUpdateType) => {
    await updateSpaceUser(data);
  },
);

export const removeSpaceUserThunk = createAsyncThunk(
  '@@spaces/removeSpaceUser',
  async (data: { space: Space; memberId: number }) => {
    await removeUserFromSpace(data.space.id, data.memberId);
  },
);

export const updateSpaceThunk = createAsyncThunk(
  '@@spaces/updateSpace',
  async (params: { id: number; data: SpaceUpdateType }): Promise<Space> => {
    return updateSpace(params.id, params.data);
  },
);

export const updateSpaceImageThunk = createAsyncThunk(
  '@@spaces/updateSpaceImage',
  async (params: { id: number; imageBinary: string | ArrayBuffer | null }): Promise<Space> => {
    return updateSpaceImage(params.id, params.imageBinary);
  },
);

export const inviteSpaceUsersThunk = createAsyncThunk(
  '@@spaces/inviteSpaceUsers',
  async (data: SpaceInviteType): Promise<Space> => {
    return inviteSpaceUsers(data);
  },
);

export const removeSpaceThunk = createAsyncThunk('@@spaces/removeSpace', async (space: Space) => {
  await removeSpace(space.id);
});

export const leaveSpaceThunk = createAsyncThunk('@@spaces/leaveSpace', async (spaceId: number) => {
  await leaveSpace(spaceId);
});

export const cancelInviteThunk = createAsyncThunk('@@spaces/cancelInvite', async (inviteId: number): Promise<Space> => {
  return cancelInvite(inviteId);
});

export const createCrewSpaceThunk = createAsyncThunk(
  '@@spaces/createCrewSpace',
  async (params: { data: SpaceUpdateType; crewMemberIds: number[] }): Promise<Space> => {
    let space = await createNewSpace(params.data);
    if (params.crewMemberIds) {
      const updatedSpace = await addCrewSpaceUsers(space.id, params.crewMemberIds);
      if (updatedSpace) space = updatedSpace;
    }
    return space;
  },
);

export const addCrewSpaceUserThunk = createAsyncThunk(
  '@@spaces/addCrewSpaceUser',
  async (data: { spaceId: number; memberIds: number[] }): Promise<Space | undefined> => {
    return addCrewSpaceUsers(data.spaceId, data.memberIds);
  },
);

export const addCrewMemberToSpacesThunk = createAsyncThunk(
  '@@spaces/addCrewMemberToSpaces',
  async (data: { memberId: number; spaceIds: number[] }): Promise<Space[]> => {
    return addCrewMemberToSpaces(data.memberId, data.spaceIds);
  },
);
