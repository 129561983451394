import { trackEvent } from './analyticsService';

let alreadyViewedTrailer = false;

export class UHAnalytics {
  // Landing
  static viewedTrailer = (): void => {
    if (alreadyViewedTrailer) return;
    alreadyViewedTrailer = true;
    trackEvent('Landing', 'Viewed Trailer');
  };

  static goToSignUp = (): void => {
    trackEvent('Landing', 'Go to sign up');
  };

  static goToSignIn = (): void => {
    trackEvent('Landing', 'Go to sign in');
  };

  static goToBlog = (): void => {
    trackEvent('Landing', 'Go to blog');
  };

  static goToApiInfo = (): void => {
    trackEvent('Landing', 'Go to api info');
  };

  static bookADemo = (): void => {
    trackEvent('Landing', 'Book a demo');
  };

  // Auth
  static showSignUp = (): void => {
    trackEvent('Auth', 'SignUpView');
  };

  static showLogin = (): void => {
    trackEvent('Auth', 'LoginView');
  };

  static signedUp = (): void => {
    trackEvent('Auth', 'Signed up');
  };

  static verifiedEmail = (): void => {
    trackEvent('Auth', 'Verified email');
  };

  static verifyEmailFailed = (): void => {
    trackEvent('Auth', 'Verification failed');
  };

  static signedIn = (): void => {
    trackEvent('Auth', 'Signed in');
  };

  // Onboarding
  static completedOnboarding = (): void => {
    trackEvent('Onboarding', 'Completed onboarding');
  };

  // Spaces Overview
  static createdSpace = (): void => {
    trackEvent('SpacesOverview', 'Created space');
  };

  // Space

  static deletedSpace = (): void => {
    trackEvent('SpaceDetail', 'Deleted space');
  };

  static leftSpace = (): void => {
    trackEvent('SpaceDetail', 'Left space');
  };

  static invitedUsersToSpace = (): void => {
    trackEvent('SpaceDetail', 'Invited users to space');
  };

  static regeneratedToken = (): void => {
    trackEvent('SpaceDetail', 'Regenerated token');
  };

  static uploadedSpaceImage = (): void => {
    trackEvent('SpaceDetail', 'Uploaded space image');
  };

  static removedSpaceUser = (): void => {
    trackEvent('SpaceDetail', 'Removed space user');
  };
}
