import { useRef, useEffect } from 'react';

export const useUpdateEffect = (effect: React.EffectCallback, deps?: React.DependencyList | undefined): void => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return effect();
    }
  }, deps);
};
