import React, { useCallback, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Vector } from 'src/services/math/types';

const useLayoutSize = (): [React.RefObject<HTMLDivElement>, Vector, boolean] => {
  const [dimensions, setDimensions] = useState(Vector.zero);
  const [loadingDimensions, setLoadingDimensions] = useState(true);

  const onResize = useCallback((width = 0, height = 0) => {
    if (width === 0 && height === 0) {
      setDimensions(new Vector(1, 1));
      setLoadingDimensions(true);
    }
    setDimensions(new Vector(width, height));
    setLoadingDimensions(false);
  }, []);

  const { ref: targetRef } = useResizeDetector({ onResize });

  return [targetRef, dimensions, loadingDimensions];
};

export default useLayoutSize;
