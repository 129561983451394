import clsx from 'clsx';
import React from 'react';
import { updateWebpageLanguage } from 'src/store/localization/slice';
import { appStoreLink, playStoreLink } from 'src/views/screens/website/Landing/config/config';
import UHText from '../core/text/UHText';
import webpageDictionary from 'src/assets/localization/website_dictionary.json';
import { useTypedSelector } from 'src/store/store';
import { useDispatch } from 'react-redux';
import { allPages } from 'src/services/pages/pageService';
import { env } from 'src/services/environment/envService';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import UHIcon from '../core/icon/UHIcon';
import { useLocalizedKey } from 'src/views/hooks/useLocalizedString';

type Props = React.HTMLAttributes<HTMLDivElement>;

const Footer: React.FC<Props> = ({ className, ...htmlProps }) => {
  const history = useUHHistory();
  const dispatch = useDispatch();
  const selectedLanguageCode = useTypedSelector((state) => state.localization.currentWebpageLanguageCode);
  const copyrightString = useLocalizedKey('WEBSITE.FOOTER_COPYRIGHT', `${new Date().getFullYear()}`);

  const onChangeLanguage = (languageCode: string) => {
    dispatch(updateWebpageLanguage(languageCode));
  };

  const onSignup = () => {
    history.navigate(allPages.auth.url, { state: { signUp: true } });
  };

  return (
    <div className={clsx(className)} {...htmlProps}>
      <div className="flex flex-col items-center py-16 w-full bg-neutral-900">
        <div className="page-width flex flex-col items-start justify-center w-full">
          <div className="flex flex-col">
            <div className="flex flex-col mb-8 space-y-0">
              <div className="mb-2 cursor-pointer" onClick={() => history.pushPage('landing')}>
                <UHText tag="h1" className="inline text-typoInverted text-2xl" text="Nevolane " />
                <UHText tag="h1" className="inline text-typoInverted text-2xl font-bold" text="Space" />
              </div>
              <div className="text-typoInverted flex space-x-3 md:space-x-2">
                <a href="https://twitter.com/nevolane_space" target="_blank" rel="noreferrer noopener">
                  <UHIcon icon="brands fa-square-twitter" className="text-5xl md:text-3xl" />
                </a>
                <a
                  href="https://www.linkedin.com/company/nevolane-business-gmbh/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <UHIcon icon="brands fa-linkedin" className="text-5xl md:text-3xl" />
                </a>
              </div>
            </div>
            <div className="flex flex-col mb-8 space-y-2">
              {env.LoginOnLandingPage && (
                <UHText
                  textKey="WEBSITE.SHARED_BUTTON_CREATE_ACCOUNT"
                  className="text-primary-300"
                  onClick={onSignup}
                />
              )}
              <a href={appStoreLink} target="_blank" rel="noreferrer noopener">
                <UHText textKey="WEBSITE.FOOTER_DOWNLOAD_IOS" className="text-primary-300" />
              </a>
              <a href={playStoreLink} target="_blank" rel="noreferrer noopener">
                <UHText textKey="WEBSITE.FOOTER_DOWNLOAD_ANDROID" className="text-primary-300" />
              </a>
            </div>
            <div className="flex flex-col mb-20 space-y-2">
              <a href="/support">
                <UHText textKey="WEBSITE.SHARED_LABEL_SUPPORT" className="text-primary-300" />
              </a>
              <a href="/privacypolicy">
                <UHText textKey="WEBSITE.SHARED_LABEL_PRIVACY_POLICY" className="text-primary-300" />
              </a>
              <a href="/terms">
                <UHText textKey="WEBSITE.SHARED_LABEL_TERMS_OF_USE" className="text-primary-300" />
              </a>
            </div>
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-row items-center justify-start">
              <a href="/impressum" className="cursor-pointer">
                <UHText textKey="WEBSITE.IMPRINT_TITLE" className="text-primary-300" />
              </a>
            </div>
            <div className="flex items-center justify-center px-4">
              <UHText text={copyrightString} className="m-0 p-0 text-center text-neutral text-sm" />
            </div>
            <div className="flex space-x-2">
              {webpageDictionary.map((dict) => {
                return (
                  <UHText
                    key={dict.language}
                    className={clsx('text-primary-300 cursor-pointer', {
                      underline: selectedLanguageCode === dict.language,
                    })}
                    text={dict.language}
                    onClick={() => onChangeLanguage(dict.language)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
