import { ValidationResult, ValidationCriterium } from 'src/types/validation/ValidationTypes';
import validator from 'validator';

export const validatePassword = (input: string): ValidationResult => {
  const criteria: ValidationCriterium[] = [
    {
      description: 'AUTH_SIGNUP_VALIDATION_LENGTH',
      isValid: !!input && input.length >= 8 && input.length <= 99,
    },
    {
      description: 'AUTH_SIGNUP_VALIDATION_CHARACTERS',
      isValid: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/g.test(input),
    },
  ];

  return {
    isValid: criteria.every((v) => v.isValid),
    criteria,
  };
};

export const validateProfileSettingsInput = (input: string): boolean => {
  return !!input && input?.length >= 3 && input?.length <= 50;
};

export const validateEmail = (input: string): boolean => {
  return !!input && validator.isEmail(input ?? '');
};
