import { Announcement } from 'src/services/announcements/AnnouncementType';
import { parseISO } from 'date-fns';
import { UHAPI } from '.';

export const fetchAnnouncements = async (code: string): Promise<Announcement[]> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const announcements = await UHAPI.get<any[]>({ path: `/public/announcements?languageCode=${code}` });
    announcements.forEach((a) => {
      a.endDate = parseISO(a.endDate);
      a.startDate = parseISO(a.startDate);
    });
    return announcements;
  } catch (error) {
    console.error('Failed loading announcements');
    return [];
  }
};
