export type UserInitialsType = {
  displayName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

export const getUserInitials = (user: UserInitialsType): string => {
  const firstName = !!user.firstName ? user.firstName : undefined;
  const lastName = !!user.lastName ? user.lastName : undefined;
  const displayName = !!user.displayName ? user.displayName : undefined;
  const email = !!user.email ? user.email : undefined;
  const initials = `${[...(firstName ?? displayName ?? email ?? '')].slice(0, 1).join('')}${[...(lastName ?? '')]
    .slice(0, 1)
    .join('')}`;
  return initials;
};
