import { useEffect, useRef, useState } from 'react';
import {
  AudioInput,
  availableAudioDevices,
  availableVideoDevices,
  cleanupUserAudioStream,
  cleanupUserVideoStream,
  getAudioSpeechEvents,
  getCameraEnabledSetting,
  getSelectedAudioDevice,
  getSelectedVideoDevice,
  getUserAudioStream,
  getUserVideoStream,
  loadUserAudioStream,
  loadUserVideoStream,
  setCameraEnabledSetting,
  VideoInput,
} from 'src/services/mediaStreams/userStreamService';
import { useAvailableInputDevices } from './useAvailableInputDevices';
import { inverseLerp } from 'src/services/math/utils';
import { clamp01 } from 'src/services/math/utils';
import { MediaStreamTrackStateType, useMediaTrackState } from './useMediaTrackState';

export type MediaStreamData = {
  localAudioStream?: MediaStream;
  localAudioTrack?: MediaStreamTrack;
  localAudioTrackSettings?: MediaTrackSettings;
  localAudioTrackConstraints?: MediaTrackConstraints;
  localAudioTrackCapabilities?: MediaTrackCapabilities;
  localAudioTrackState: MediaStreamTrackStateType;
  audioVolume: number;
  localVideoStream?: MediaStream;
  localVideoTrack?: MediaStreamTrack;
  localVideoTrackSettings?: MediaTrackSettings;
  localVideoTrackConstraints?: MediaTrackConstraints;
  localVideoTrackCapabilities?: MediaTrackCapabilities;
  localVideoTrackState: MediaStreamTrackStateType;
  cameraEnabled: boolean;
  microphoneEnabled: boolean;
  onSetCameraEnabled: (enabled: boolean) => void;
  onSetMicrophoneEnabled: (enabled: boolean) => void;
  isLoadingPermissions: boolean;
  cameras: VideoInput[];
  microphones: AudioInput[];
  selectedCameraIndex: number;
  selectedMicrophoneIndex: number;
  onSetSelectedCameraIndex: (index: number) => void;
  onSetSelectedMicrophoneIndex: (index: number) => void;
};

export const useMediaStreams = (): MediaStreamData => {
  const [cameraEnabled, setCameraEnabled] = useState(getCameraEnabledSetting());
  const [selectedCameraIndex, setSelectedCameraIndex] = useState<number>(
    Math.max(
      availableVideoDevices().findIndex((v: VideoInput) => v.id === getSelectedVideoDevice()?.id),
      0,
    ),
  );
  const targetSelectedCameraIndex = useRef(selectedCameraIndex);

  const [microphoneEnabled, setMicrophoneEnabled] = useState(true);
  const [selectedMicrophoneIndex, setSelectedMicrophoneIndex] = useState<number>(
    Math.max(
      availableAudioDevices().findIndex((v: AudioInput) => v.id === getSelectedAudioDevice()?.id),
      0,
    ),
  );
  const targetSelectedMicrophoneIndex = useRef(selectedMicrophoneIndex);

  // Audio
  const [localAudioStream, setLocalAudioStream] = useState<MediaStream | undefined>(getUserAudioStream());
  const localAudioTrack = localAudioStream?.getAudioTracks()[0];
  const localAudioTrackSettings = localAudioTrack?.getSettings();
  const localAudioTrackConstraints = localAudioTrack?.getConstraints();
  const localAudioTrackCapabilities = localAudioTrack?.getCapabilities?.();
  const [audioVolume, setAudioVolume] = useState(0);
  const localAudioTrackState = useMediaTrackState(localAudioTrack);

  // Video
  const [localVideoStream, setLocalVideoStream] = useState<MediaStream | undefined>(getUserVideoStream());
  const localVideoTrack = localVideoStream?.getVideoTracks()[0];
  const localVideoTrackSettings = localVideoTrack?.getSettings();
  const localVideoTrackConstraints = localVideoTrack?.getConstraints();
  const localVideoTrackCapabilities = localVideoTrack?.getCapabilities?.();
  const localVideoTrackState = useMediaTrackState(localVideoTrack);

  const { cameras, microphones, isLoadingPermissions } = useAvailableInputDevices();

  /** Used to unload the video if the page changed while loading the stream */
  const exitedPage = useRef(false);

  useEffect(() => {
    exitedPage.current = false;

    return () => {
      setLocalVideoStream(undefined);
      setLocalAudioStream(undefined);
      exitedPage.current = true;
    };
  }, []);

  const removeCamera = () => {
    setCameraEnabled(false);
    setCameraEnabledSetting(false);
    cleanupUserVideoStream();
    setLocalVideoStream(undefined);
  };

  const removeMic = () => {
    cleanupUserAudioStream();
    setLocalAudioStream(undefined);
  };

  const onSetCameraEnabled = async (enabled: boolean) => {
    if (cameraEnabled === enabled) return;
    if (!enabled) {
      removeCamera();
    } else {
      setLocalVideoStream(await loadUserVideoStream(cameras[targetSelectedCameraIndex.current]));
    }
    setCameraEnabled(enabled);
    setCameraEnabledSetting(enabled);
  };

  const subscribeToSpeechEvents = () => {
    const speechEvents = getAudioSpeechEvents();
    speechEvents?.on('volume_change', (v) => {
      setAudioVolume(clamp01(inverseLerp(-100, -20, v)));
    });
  };

  const onSetMicrophoneEnabled = async (enabled: boolean) => {
    if (microphoneEnabled === enabled) return;
    if (!enabled) {
      removeMic();
    } else {
      const s = await loadUserAudioStream(microphones[targetSelectedMicrophoneIndex.current]);
      subscribeToSpeechEvents();
      setLocalAudioStream(s);
    }
    setMicrophoneEnabled(enabled);
  };

  const onSetSelectedCameraIndex = (index: number) => {
    targetSelectedCameraIndex.current = index;
    setSelectedCameraIndex(index);
  };

  const onSetSelectedMicrophoneIndex = (index: number) => {
    targetSelectedMicrophoneIndex.current = index;
    setSelectedMicrophoneIndex(index);
  };

  useEffect(() => {
    if (isLoadingPermissions) return;
    (async () => {
      try {
        let videoMediaStream: MediaStream;
        let audioMediaStream: MediaStream | undefined;

        if (cameraEnabled) {
          try {
            if (exitedPage.current) {
              cleanupUserVideoStream();
            } else {
              videoMediaStream = await loadUserVideoStream(cameras[targetSelectedCameraIndex.current]);
              setLocalVideoStream(videoMediaStream);

              // video track ended - when user removed permission in browser
              videoMediaStream?.getVideoTracks()[0].addEventListener('ended', removeCamera);
            }
          } catch (error) {
            // user denied permission to access camera
            if (error instanceof DOMException && error.name === 'NotAllowedError') {
              removeCamera();
            }
          }
        } else {
          removeCamera();
        }

        if (microphoneEnabled) {
          try {
            if (exitedPage.current) {
              cleanupUserAudioStream();
            } else {
              audioMediaStream = await loadUserAudioStream(microphones[targetSelectedMicrophoneIndex.current]);
              subscribeToSpeechEvents();
              setLocalAudioStream(audioMediaStream);

              // audio track ended - when user removed permission in browser
              audioMediaStream?.getAudioTracks()[0].addEventListener('ended', removeMic);
            }
          } catch (error) {
            // user denied permission to access mic
            if (error instanceof DOMException && error.name === 'NotAllowedError') {
              removeMic();
            }
          }
        } else {
          removeMic();
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [isLoadingPermissions, cameras, microphones, selectedCameraIndex, selectedMicrophoneIndex]);

  return {
    localAudioStream,
    localAudioTrack,
    localAudioTrackSettings,
    localAudioTrackConstraints,
    localAudioTrackCapabilities,
    localAudioTrackState,
    audioVolume,
    localVideoStream,
    localVideoTrack,
    localVideoTrackSettings,
    localVideoTrackConstraints,
    localVideoTrackCapabilities,
    localVideoTrackState,
    cameraEnabled,
    onSetCameraEnabled,
    microphoneEnabled,
    onSetMicrophoneEnabled,
    isLoadingPermissions,
    cameras,
    microphones,
    selectedCameraIndex,
    selectedMicrophoneIndex,
    onSetSelectedCameraIndex,
    onSetSelectedMicrophoneIndex,
  };
};
