import clsx from 'clsx';
import React, { useRef } from 'react';
import { useDrag } from 'react-use-gesture';
import { clamp01 } from 'src/services/math/utils';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  /** Returns a value between 0 (left) and 1 (right) while the user is dragging over the container */
  onChangeValue?: (v: number) => void;
  onReleasedAtValue?: (v: number) => void;
  className?: string;
};

const DraggableSliderContainer: React.FC<Props> = ({
  onChangeValue,
  onReleasedAtValue,
  className,
  children,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const bindDrag = useDrag(
    ({ xy: [x], down }) => {
      const clientRect = containerRef.current?.getBoundingClientRect();
      const distanceFromLeft = x - (clientRect?.left ?? 0);
      const percentage = distanceFromLeft / Math.max(clientRect?.width ?? 1, 1);
      onChangeValue?.(clamp01(percentage));
      if (!down) onReleasedAtValue?.(clamp01(percentage));
    },
    { capture: true, passive: false },
  );

  return (
    <div ref={containerRef} className={clsx('cursor-move select-none', className)} {...props} {...bindDrag()}>
      {children}
    </div>
  );
};

export default DraggableSliderContainer;
