import clsx from 'clsx';
import React, { useContext } from 'react';
import { AppContext } from 'src/App';
import { getStaticData } from 'src/services/staticData/staticDataService';
import { Subscription, SubscriptionPeriod } from 'src/services/subscriptions/subscriptionService';
import UHButton from 'src/views/components/core/buttons/UHButton';
import UHTextButton from 'src/views/components/core/buttons/UHTextButton';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import UHText from 'src/views/components/core/text/UHText';
import Center from 'src/views/components/core/transform/Center';
import { useLocalizedKey } from 'src/views/hooks/useLocalizedString';
import PriceInfo from './PriceInfo';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  subscription: Subscription;
  currentSubscriptionTier: string;
  selected?: boolean;
  isDowngrade?: boolean;
  hasDowngrade: boolean;
  isForbidden?: boolean;
  subscriptionPeriod: SubscriptionPeriod;
  onChoose?: () => void;
  onCancel?: () => void;
  onChangeToYearly?: () => void;
  downgradeAt?: string;
  onReactivateCancelledSubscription: () => void;
  variant?: 'portrait' | 'landscape';
  loadingTierChange?: string;
  loadingReactivateSubscription?: boolean;
};

const SubscriptionOption: React.FC<Props> = ({
  subscription,
  currentSubscriptionTier,
  selected = false,
  isDowngrade = false,
  hasDowngrade,
  subscriptionPeriod,
  onChoose,
  onCancel,
  onChangeToYearly,
  isForbidden,
  downgradeAt,
  onReactivateCancelledSubscription,
  variant = 'portrait',
  loadingTierChange,
  loadingReactivateSubscription,
  className,
  ...htmlProps
}) => {
  const subscriptions = getStaticData().personalSubscriptions;
  const subscriptionTitle = useLocalizedKey(subscription.title);
  const downgradeInfo = useLocalizedKey('SUBSCRIPTION_DOWNGRADE_INFO', subscriptionTitle, downgradeAt ?? '');
  const { setConfirmData } = useContext(AppContext);

  const isCurrentTier = currentSubscriptionTier === subscription.tier;

  const portrait = variant === 'portrait';
  const downgradeDescription = isCurrentTier
    ? useLocalizedKey('SUBSCRIPTION_DOWNGRADE_CONFIRM_PERIOD_DESCRIPTION')
    : useLocalizedKey('SUBSCRIPTION_DOWNGRADE_CONFIRM_DESCRIPTION', subscriptionTitle);

  const p0Subscription = subscriptions.find((s) => s.tier === 'P0');
  const cancelSubscriptionTitle = useLocalizedKey(p0Subscription?.title);
  const cancelDescription = useLocalizedKey('SUBSCRIPTION_DOWNGRADE_CONFIRM_DESCRIPTION', cancelSubscriptionTitle);

  const onSelectedOption = () => {
    if (isDowngrade) {
      setConfirmData({
        headerTextKey: isCurrentTier
          ? 'SUBSCRIPTION_DOWNGRADE_PERIOD_CONFIRM_TITLE'
          : 'SUBSCRIPTION_DOWNGRADE_CONFIRM_TITLE',
        bodyText: downgradeDescription,
        confirmAction: onChoose,
      });
    } else if (!hasDowngrade) onChoose?.();
    else {
      setConfirmData({
        headerTextKey: 'SUBSCRIPTION_CANCEL_PENDING_DOWNGRADE',
        bodyTextKey: 'SUBSCRIPTION_PENDING_DOWNGRADE_DESCRIPTION',
        confirmTextKey: 'SHARED_BUTTON_CONFIRM',
        confirmAction: onChoose,
      });
    }
  };

  const onTryCancel = () => {
    setConfirmData({
      headerTextKey: 'SUBSCRIPTION_CANCEL_CONFIRM_TITLE',
      bodyText: cancelDescription,
      confirmAction: onCancel,
      variant: 'danger',
    });
  };

  const canChoose = !downgradeAt && !selected && subscription.tier !== 'P0';
  const canCancel = !hasDowngrade && !downgradeAt && selected && subscription.tier !== 'P0';

  const currentTierIndex = subscriptions.findIndex((s) => s.tier === subscription.tier);
  const loadingTierIndex = subscriptions.findIndex((s) => s.tier === loadingTierChange);
  const loadingDowngrade = loadingTierIndex >= 0 && currentTierIndex > loadingTierIndex && selected;

  return (
    <div
      className={clsx('flex flex-col items-center space-y-4', { 'w-60': portrait, 'w-full': !portrait }, className)}
      {...htmlProps}
    >
      {/* Outer container */}
      <div
        className={clsx('flex flex-col w-full', { 'py-8': !subscription.tag && !selected && portrait })}
        style={{ minHeight: portrait ? '23rem' : '18rem' }}
      >
        {/* Card */}
        <div
          className={clsx('flex flex-1 flex-col px-6 border-2 rounded-3xl', {
            'border-primary': selected,
            'border-neutral-300': !selected,
            'pb-14 pt-7': (subscription.tag || selected) && portrait,
            'pb-6 pt-8': !subscription.tag && !selected && portrait,
            'pb-4 pt-7': !portrait,
          })}
        >
          {/* Tag */}
          {(subscription.tag || isCurrentTier) && (
            <Center
              className={clsx('mb-2 px-2 w-max h-7 rounded', {
                'bg-primary-700': !isCurrentTier,
                'bg-primary': isCurrentTier,
              })}
            >
              <UHText
                variant="button-sm-medium"
                className="text-typoInverted"
                textKey={!isCurrentTier ? subscription.tag : 'SHARED_TEXT_CURRENT'}
              />
            </Center>
          )}
          {/* Title */}
          <UHText
            variant="title"
            className={clsx('mb-4', {
              'text-primary': !subscription.tag || isCurrentTier,
              'text-primary-700': subscription.tag && !isCurrentTier,
            })}
            textKey={subscription.title}
          />
          {/* Feature list */}
          <div className="flex flex-col space-y-2">
            {subscription.description.map((d, index) => {
              return (
                <div key={index} className="flex space-x-4">
                  <UHIcon
                    className={clsx(
                      'text-xl',
                      { 'text-secondary': isCurrentTier && subscription.tier !== 'P0' },
                      { 'text-primary-300': !isCurrentTier || subscription.tier === 'P0' },
                    )}
                    icon="check-circle"
                  />
                  <UHText textKey={d} />
                </div>
              );
            })}
          </div>
          {/* Spacing */}
          <div className="flex-1" />
          {/* Separation line */}
          <div className="my-3 h-0.5 bg-neutral-300" />
          {/* Price */}
          <div className={clsx('flex items-center', { 'justify-between': !portrait, 'justify-center': portrait })}>
            <PriceInfo
              className="h-14"
              variant="lg"
              price={subscription.price}
              subscriptionPeriod={subscriptionPeriod}
            />
            {/* Choose button */}
            {canChoose && !portrait && (
              <UHButton
                loading={loadingTierChange === subscription.tier}
                className="w-44"
                width="auto"
                variant={isForbidden ? 'secondary' : 'default'}
                titleKey={
                  isForbidden ? 'SHARED_TEXT_YEARLY' : isCurrentTier ? 'SHARED_TEXT_CHANGE' : 'SHARED_TEXT_CHOOSE'
                }
                onClick={isForbidden ? onChangeToYearly : onSelectedOption}
              />
            )}
            {canCancel && !portrait && (
              <UHButton
                loading={loadingDowngrade}
                variant="danger"
                className="w-44"
                width="auto"
                titleKey="SUBSCRIPTION_CANCEL"
                onClick={onTryCancel}
              />
            )}
          </div>
        </div>
      </div>
      {/* Choose button */}
      {canChoose && portrait && (
        <UHButton
          loading={loadingTierChange === subscription.tier}
          className="w-44"
          width="auto"
          variant={isForbidden ? 'secondary' : 'default'}
          titleKey={isForbidden ? 'SHARED_TEXT_YEARLY' : isCurrentTier ? 'SHARED_TEXT_CHANGE' : 'SHARED_TEXT_CHOOSE'}
          onClick={isForbidden ? onChangeToYearly : onSelectedOption}
        />
      )}
      {/* Cancel button */}
      {canCancel && portrait && (
        <UHButton
          loading={loadingDowngrade}
          variant="danger"
          className="w-44"
          width="auto"
          titleKey="SUBSCRIPTION_CANCEL"
          onClick={onTryCancel}
        />
      )}
      {/* Downgrade info */}
      {downgradeAt && (
        <div className={clsx({ 'pb-4': !portrait, 'px-6': portrait })}>
          <UHText className="text-error-300" text={downgradeInfo} />
          <UHTextButton
            loading={loadingReactivateSubscription}
            underline
            textKey="SUBSCRIPTION_DOWNGRADE_CANCEL"
            onClick={onReactivateCancelledSubscription}
          />
        </div>
      )}
    </div>
  );
};

export default SubscriptionOption;
