import React, { useEffect, useRef, useState } from 'react';
import { Space } from 'src/types/spaces/SpaceType';
import ConferenceView from './ConferenceView';
import { getPublicSpace } from 'src/services/api/spacesAPI';
import { getToken } from 'src/services/api/tokenAPI';
import LoadingScreen from '../Loading/LoadingScreen';
import UHText from 'src/views/components/core/text/UHText';
import { cleanupUserStreams } from 'src/services/mediaStreams/userStreamService';
import Center from 'src/views/components/core/transform/Center';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import UHButton from 'src/views/components/core/buttons/UHButton';
import { useTypedSelector } from 'src/store/store';

type PublicSpaceLoadingState = 'loading' | 'waitingForHost' | 'running' | 'failed' | 'ended';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  token: string;
  displayName: string;
  onLeftConference: () => void;
  cameraEnabled: boolean;
  micMuted: boolean;
};

const PublicConferenceView: React.FC<Props> = ({
  token,
  displayName,
  onLeftConference,
  cameraEnabled,
  micMuted,
  ...htmlProps
}) => {
  const isAuthenticated = !!useTypedSelector((state) => state.user.cognitoUser);
  const [loadingState, setLoadingState] = useState<PublicSpaceLoadingState>('loading');
  const [space, setSpace] = useState<Space | undefined>();
  const [jwt, setJWT] = useState<string>();

  /** Reload space while waiting for host */
  const reloadPublicSpace = async () => {
    try {
      const publicSpace = await getPublicSpace(token);
      setSpace(publicSpace);
      if (publicSpace.active) setLoadingState('running');
    } catch (error) {
      setLoadingState('failed');
    }
  };

  /** Initially load public space */
  const loadPublicSpace = async () => {
    try {
      const publicSpace = await getPublicSpace(token);
      setSpace(publicSpace);
      const newJWT = (await getToken(publicSpace.id, token)).token;
      setJWT(newJWT);
      if (publicSpace.active) setLoadingState('running');
      else setLoadingState('waitingForHost');
    } catch (error) {
      setLoadingState('failed');
      cleanupUserStreams();
      onLeftConference();
    }
  };

  useEffect(() => {
    loadPublicSpace();
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (loadingState === 'waitingForHost') {
      interval = setInterval(reloadPublicSpace, 5000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [loadingState]);

  // Used to make sure the conference is not automatically left after it was ended by the host
  const endedRef = useRef(false);

  const onConferenceEnded = () => {
    endedRef.current = true;
    setLoadingState('ended');
    cleanupUserStreams();
  };

  const onLeaveConference = () => {
    if (!endedRef.current) onLeftConference();
  };

  if (loadingState === 'loading') return <LoadingScreen {...htmlProps} />;
  else if (loadingState === 'waitingForHost')
    return (
      // Waiting for host
      <Center col {...htmlProps}>
        <UHText className="-mt-24 mb-40 text-primary-700" variant="title-xl" text="Nevolane Space" />
        <UHIcon className="mb-4 text-primary-700 text-6xl" icon="moon" />
        <UHText className="mb-12 text-primary-700" variant="body-lg" textKey="LOBBY_SCREEN_WAITING_FOR_OWNER" />
        <UHText className="mb-12 max-w-sm text-center" textKey="LOBBY_SCREEN_WAITING_FOR_OWNER_DESCRIPTION" />
        <UHButton variant="secondary" titleKey="SHARED_TEXT_QUIT" onClick={onLeftConference} />
      </Center>
    );
  else if (loadingState === 'running' && space && jwt)
    return (
      // Conference
      <ConferenceView
        jwt={jwt}
        displayName={displayName}
        space={space}
        onLeftConference={onLeaveConference}
        onConferenceEnded={onConferenceEnded}
        cameraEnabled={cameraEnabled}
        micMuted={micMuted}
        {...htmlProps}
      />
    );
  else if (loadingState === 'ended')
    return (
      // Conference ended
      <Center col {...htmlProps}>
        <UHText className="-mt-24 mb-40 text-primary-700" variant="title-xl" text="Nevolane Space" />
        <UHIcon className="mb-4 text-primary-700 text-6xl" icon="flag-checkered" />
        <UHText className="mb-12 text-primary-700" variant="body-lg" textKey="LOBBY_SCREEN_HOST_LEFT" />
        {!isAuthenticated && (
          <UHText className="mb-12 max-w-sm text-center" textKey="LOBBY_SCREEN_HOST_LEFT_DESCRIPTION" />
        )}

        <UHButton
          titleKey={isAuthenticated ? 'SHARED_TEXT_QUIT' : 'SHARED_BUTTON_SIGN_UP'}
          onClick={onLeftConference}
        />
      </Center>
    );
  return null;
};

export default PublicConferenceView;
