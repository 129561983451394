import { fetchAnnouncements } from 'src/services/api/announcementsAPI';
import { Announcement } from 'src/services/announcements/AnnouncementType';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const fetchAnnouncementsThunk = createAsyncThunk<Announcement[], string | undefined, { state: RootState }>(
  '@@announcements/fetchAnnouncements',
  async (data: string | undefined, { getState }): Promise<Announcement[]> => {
    const language = data ?? getState().user.user.languageCode ?? 'en';
    return fetchAnnouncements(language);
  },
);
