import React, { useContext, useState } from 'react';
import { actions, useTypedSelector } from 'src/store/store';
import UHButton from 'src/views/components/core/buttons/UHButton';
import UHCard from 'src/views/components/core/containers/UHCard';
import UHText, { UHTextProps } from 'src/views/components/core/text/UHText';
import CardGrid from 'src/views/components/layout/CardGrid';
import PageContainer from 'src/views/components/layout/PageContainer';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import { Space, SpaceMember, spaceMemberFromInvitedMember } from 'src/types/spaces/SpaceType';
import { usePrivileges } from 'src/views/hooks/usePrivileges';
import MemberList from 'src/views/components/space/member/MemberList';
import SpaceImage from 'src/views/components/space/image/SpaceImage';
import SpaceNameForm from 'src/views/components/space/forms/SpaceNameForm';
import { SpaceNameData } from 'src/services/forms/schemaService';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import InviteUsersView from 'src/views/components/space/inivite/InviteUsersView';
import { Contact } from 'src/types/users/Contact';
import SpaceImageForm from 'src/views/components/space/forms/SpaceImageForm';
import clsx from 'clsx';
import SpaceMemberForm from 'src/views/components/space/forms/SpaceMemberForm';
import SelectionDropdown from 'src/views/components/core/inputs/SelectionDropdown';
import { AppContext } from 'src/App';
import { copySpaceLinkToClipboard, getPublicSpaceLink } from 'src/services/publicSpace/publicSpaceService';
import CopyToClipboard from 'src/views/components/tooltip/CopyToClipboard';
import UHTooltip from 'src/views/components/tooltip/UHTooltip';
import Avatar from 'src/views/components/profile/Avatar';
import { areMySpacesDisabled, hasSpaceTooManyMembers } from 'src/services/subscriptions/restrictionsService';
import UHTextButton from 'src/views/components/core/buttons/UHTextButton';
import { getStaticData } from 'src/services/staticData/staticDataService';
import { useParams } from 'react-router-dom';

type SpaceDetailState = 'default' | 'editName' | 'editImage' | 'inviteUsers' | 'editMember';

const SpaceDetailScreen: React.FC = () => {
  const history = useUHHistory();
  const spaceId = parseInt(useParams().spaceId ?? '-1');
  const spaces = useTypedSelector((state) => state.spaces.spaces);
  const space = spaces.find((s) => s.id === spaceId);
  const user = useTypedSelector((state) => state.user.user);
  const crew = useTypedSelector((state) => state.crews.crews).find((c) => c.id === space?.id);
  const privileges = usePrivileges(crew ?? space);
  const [loading, setLoading] = useState(false);
  const [regeneratingToken, setRegeneratingToken] = useState<boolean>(false);
  const { setConfirmData } = useContext(AppContext);

  // Edit data
  const [spaceDetailState, setSpaceDetailState] = useState<SpaceDetailState>('default');
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [selectedSpaceMember, setSelectedSpaceMember] = useState<SpaceMember>();

  // Edit dropdown
  const [dropdownOpen, setDropdownOpen] = useState(false);

  if (!space) {
    history.pushPage('spacesOverview');
    return <div />;
  }
  const isPublicSpace = !!space.token;

  const mySpacesDisabled = areMySpacesDisabled(user, spaces);
  const mySpaceDisabledMembers = hasSpaceTooManyMembers(user, space);
  const spaceDisabled = mySpaceDisabledMembers || mySpacesDisabled || space.disabled;

  const onEnterSpace = () => {
    history.pushPage('userMediaSelection', space.id);
  };

  const onEditMember = (member: SpaceMember) => {
    setSelectedSpaceMember(member);
    setSpaceDetailState('editMember');
  };

  // Cancel Invite
  const cancelInvite = (member: SpaceMember) => {
    if (member?.inviteId) actions.cancelInvite(member.inviteId);
  };

  // Remove User
  const removeUser = (member: SpaceMember) => {
    actions.removeSpaceUser({ memberId: member?.id, space });
  };

  // Update space

  const updateSpaceName = async (data: SpaceNameData) => {
    setLoading(true);
    try {
      await actions.updateSpace({
        id: space.id,
        data: { name: data.name, description: data.description, imageId: space.imageId },
      });
      setLoading(false);
      onCloseModal();
    } catch (error) {
      setLoading(false);
    }
  };

  const updateSpaceImage = async (data: number) => {
    setLoading(true);
    try {
      await actions.updateSpace({
        id: space.id,
        data: {
          name: space.name,
          description: space.description,
          imageId: data === -1 ? space.imageId : data,
          customImage: data !== -1 ? null : space.customImage,
        },
      });
      setLoading(false);
      onCloseModal();
    } catch (error) {
      setLoading(false);
    }
  };

  const onDeleteSpace = async () => {
    setLoading(true);
    try {
      await actions.removeSpace(space);
      setLoading(false);
      onCloseModal();
      history.pushPage('spacesOverview');
    } catch (error) {
      setLoading(false);
    }
  };

  const onTryDeleteSpace = () => {
    setConfirmData({
      headerTextKey: 'SHARED_BUTTON_DELETE_SPACE',
      bodyTextKey: 'SPACE_DELETE_CONFIRM',
      confirmAction: onDeleteSpace,
      variant: 'danger',
    });
  };

  const onLeaveSpace = async () => {
    setLoading(true);
    try {
      await actions.leaveSpace(space.id);
      setLoading(false);
      onCloseModal();
      history.pushPage('spacesOverview');
    } catch (error) {
      setLoading(false);
    }
  };

  const onTryLeaveSpace = () => {
    setConfirmData({
      headerTextKey: 'SHARED_BUTTON_LEAVE_SPACE',
      bodyTextKey: 'SPACE_LEAVE_CONFIRM',
      confirmAction: onLeaveSpace,
    });
  };

  // Invite

  const onInviteSelectedUsers = async () => {
    setLoading(true);
    try {
      await actions.inviteSpaceUsers({ spaceId: space.id, usersToInvite: selectedContacts.map((c) => c.email) });
      setLoading(false);
      onCloseModal();
      setSelectedContacts([]);
    } catch (error) {
      setLoading(false);
    }
  };

  // Public space

  const regenerateToken = async () => {
    if (!space.token) return;
    setRegeneratingToken(true);
    try {
      const result = await actions.regeneratePublicSpaceToken({ data: { token: space.token } });
      const updatedSpace = (result as unknown as { payload: Space }).payload;
      copySpaceLinkToClipboard(updatedSpace);
    } finally {
      setRegeneratingToken(false);
    }
  };

  const onTryRegenerateToken = () => {
    setConfirmData({
      headerTextKey: 'PUBLIC_SPACE_REGENERATE_LINK',
      bodyTextKey: 'SPACE_TOKEN_REGENERATE_CONFIRM',
      confirmAction: regenerateToken,
    });
  };

  // Modals

  const onEditName = () => {
    setSpaceDetailState('editName');
  };

  const onEditImage = () => {
    setSpaceDetailState('editImage');
  };

  const onInviteUsers = () => {
    setSpaceDetailState('inviteUsers');
  };

  const onCloseModal = () => {
    setSpaceDetailState('default');
  };

  // Dropdown options

  const ownerDropdown: UHTextProps[] = [{ textKey: 'SHARED_BUTTON_EDIT', onClick: onEditName }];
  // Delete space only for non-public spaces
  if (!isPublicSpace) {
    ownerDropdown.push({
      textKey: 'SHARED_BUTTON_DELETE_SPACE',
      className: 'group-hover:text-error',
      onClick: onTryDeleteSpace,
    });
  }

  const adminDropdown: UHTextProps[] = [
    { textKey: 'SHARED_BUTTON_EDIT', onClick: onEditName },
    {
      textKey: 'SHARED_BUTTON_LEAVE_SPACE',
      className: 'group-hover:text-error',
      onClick: onTryLeaveSpace,
    },
  ];

  const memberDropdown: UHTextProps[] = [
    {
      textKey: 'SHARED_BUTTON_LEAVE_SPACE',
      className: 'group-hover:text-error',
      onClick: onTryLeaveSpace,
    },
  ];

  const dropdownOptions = privileges.isOwner
    ? ownerDropdown
    : privileges.hasAdminPrivileges
    ? adminDropdown
    : memberDropdown;

  const publicSpaceLink = getPublicSpaceLink(space);

  const activeSpaceMembers = space.members.filter((m) => m.isActive);

  const showInviteCTA =
    !isPublicSpace && space.members.length === 1 && space.invites.length === 0 && privileges.isOwner;

  return (
    <PageContainer
      titleKey="SHARED_TEXT_SPACES"
      icon="rocket"
      onClickedTitle={() => history.pushPage('spacesOverview')}
      key={space.id}
    >
      <CardGrid>
        {/* Space Info */}
        <UHCard className="relative gap-4 col-span-3 grid-cols-4 sm:grid xl:grid-cols-3">
          {/* Image */}
          <div className="col-span-2 p-4 w-full sm:mb-0 xl:col-span-1">
            <SpaceImage
              className={clsx('relative', { 'cursor-pointer': privileges.hasAdminPrivileges })}
              onClick={privileges.hasAdminPrivileges ? onEditImage : undefined}
              space={space}
              showTitle={false}
            >
              {privileges.hasAdminPrivileges && (
                <div
                  className={clsx('absolute inset-0 transition-opacity duration-200', {
                    'opacity-0 hover:opacity-100': space.customImage,
                  })}
                >
                  <div className="absolute z-0 bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-black to-transparent opacity-60" />
                  <UHIcon className="absolute bottom-3 right-3 text-typoInverted text-2xl" icon="pencil" />
                </div>
              )}
            </SpaceImage>
          </div>
          {/* Title & Description */}
          <div className="col-span-2 p-4 pt-0 sm:p-4 sm:pl-0 sm:pt-4">
            <div className="line-clamp-2 mb-4">
              {/* Title */}
              <UHText
                text={space.name}
                className={clsx('wrap inline text-primary', { 'mr-4': isPublicSpace })}
                variant="title-2xl"
              />
              {/* Public space icon */}
              {isPublicSpace && <UHIcon className="inline text-primary-300 text-2xl" icon="earth-americas" />}
            </div>
            {/* Description */}
            <UHText className="wrap line-clamp-4" text={space.description} />
          </div>
          {/* Edit/Delete button */}
          <div className="absolute bottom-2 right-5">
            <UHIcon className="text-neutral text-2xl" icon="ellipsis" onClick={() => setDropdownOpen((o) => !o)} />
            <SelectionDropdown
              className="right-0 origin-top-right"
              dropdownOpen={dropdownOpen}
              setDropdownOpen={setDropdownOpen}
              options={dropdownOptions}
            />
          </div>
        </UHCard>
        {/* Enter space card */}
        <div className="col-span-3 xl:col-span-1">
          <UHCard className="p-4 py-6">
            {/* Enter space button */}
            <UHButton
              icon={spaceDisabled ? 'lock' : undefined}
              iconClass="text-base pr-1"
              titleKey={
                spaceDisabled
                  ? 'SHARED_TEXT_LOCKED'
                  : isPublicSpace
                  ? 'PUBLIC_SPACE_START'
                  : 'ENTER_SPACE_BUTTON_ENTER_SPACE'
              }
              className="w-full"
              width="auto"
              variant={spaceDisabled ? 'disabled' : showInviteCTA ? 'secondary' : 'default'}
              onClick={spaceDisabled ? undefined : onEnterSpace}
            />
            {(mySpacesDisabled || mySpaceDisabledMembers) && (
              <div>
                <UHText
                  className="mb-2 mt-4 text-neutral"
                  textKey={mySpacesDisabled ? 'SPACE_LOCKED_DESCRIPTION' : 'SPACE_LOCKED_MEMBERS_DESCRIPTION'}
                />
                {getStaticData().config.subscriptionsEnabled && (
                  <UHTextButton
                    textKey="PROFILE_OPTIONS_SUBSCRIPTIONS"
                    onClick={() => history.pushPage('subscriptions')}
                  />
                )}
              </div>
            )}
            {!mySpacesDisabled && !mySpaceDisabledMembers && spaceDisabled && (
              <div>
                <UHText className="mt-4 text-neutral" textKey="SPACE_LOCKED_OWNER_DESCRIPTION" />
              </div>
            )}
            {/* Active space members */}
            {activeSpaceMembers.length > 0 && (
              <div className="mt-4">
                <UHText variant="title-sm" textKey="SPACE_MEMBERS_ONLINE" />
                <div className="flex mt-2">
                  {activeSpaceMembers.map((member) => {
                    return (
                      <UHTooltip className="-mr-4" text={member.displayName} key={member.id}>
                        <Avatar className="w-12 h-12 border-2 border-background" user={member} />
                      </UHTooltip>
                    );
                  })}
                </div>
              </div>
            )}
          </UHCard>
        </div>
        {/* Space members */}
        {!isPublicSpace && !showInviteCTA && (
          <UHCard defaultPadding className="col-span-3 xl:col-span-2">
            <MemberList
              members={space.members.concat(space.invites.map(spaceMemberFromInvitedMember))}
              headerTitleKey="SHARED_TEXT_SPACE_MEMBERS"
              headerActionTitleKey={crew ? 'SHARED_BUTTON_ADD' : 'SHARED_BUTTON_INVITE'}
              onHeaderAction={privileges.hasAdminPrivileges ? onInviteUsers : undefined}
              onMemberAction={onEditMember}
              onRemoveMemberAction={crew ? removeUser : cancelInvite}
              isEditable={privileges.hasAdminPrivileges}
              type={crew ? 'CrewSpace' : 'Space'}
            />
          </UHCard>
        )}
        {/* Invite CTA */}
        {showInviteCTA && (
          <UHCard defaultPadding className="primary-gradient col-span-2">
            <UHText variant="title-xl" className="mb-4 text-typoInverted" textKey="SPACE_INVITE_CTA_TITLE" />
            <UHText className="mb-6 text-typoInverted" textKey="SPACE_INVITE_CTA_DESCRIPTION" />
            <UHButton width="md" variant="neutral" titleKey="SHARED_BUTTON_INVITE" onClick={onInviteUsers} />
          </UHCard>
        )}
        {/* Public space card */}
        {isPublicSpace && (
          <UHCard titleKey="SHARED_TEXT_PUBLIC_ACCESS" defaultPadding className="col-span-3 xl:col-span-2">
            {/* Public space info */}
            <UHText className="mb-2" textKey="PUBLIC_SPACE_LINK_INFO" />
            {/* Link box */}
            <div className="flex items-center justify-between p-3 bg-primary-200 rounded-xl">
              <div style={{ maxWidth: '90%' }}>
                <UHText variant="body-xs" textKey="PUBLIC_SPACE_INVITATION_LINK" />
                <UHText className="wrap text-primary" text={publicSpaceLink} />
              </div>
              {/* Copy icon */}
              <CopyToClipboard copyContent={publicSpaceLink}>
                <UHIcon className="text-primary text-xl" icon="copy" />
              </CopyToClipboard>
            </div>
            {/* Regenerate button */}
            <UHButton
              variant="plain"
              className="ml-6 mt-1"
              width="sm"
              height="sm"
              titleKey="PUBLIC_SPACE_REGENERATE_LINK"
              icon="refresh"
              onClick={onTryRegenerateToken}
              loading={regeneratingToken}
            />
            {/* Public space link tip */}
            <UHText variant="body-sm" className="mt-6 text-neutral" textKey="PUBLIC_SPACE_LINK_TIP" />
          </UHCard>
        )}
      </CardGrid>
      {/* Modals */}
      <SpaceNameForm
        show={spaceDetailState === 'editName'}
        onClose={onCloseModal}
        data={space}
        onChangedData={updateSpaceName}
        loading={loading}
        formProps={{ submitTextKey: 'SHARED_BUTTON_SAVE' }}
      />
      <SpaceImageForm
        show={spaceDetailState === 'editImage'}
        onClose={onCloseModal}
        data={space.imageId}
        customImage={space.customImage}
        spaceId={space.id}
        onChangedData={updateSpaceImage}
        loading={loading}
        submitTextKey="SHARED_BUTTON_SAVE"
      />
      <InviteUsersView
        show={spaceDetailState === 'inviteUsers'}
        onClose={onCloseModal}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
        onSubmit={selectedContacts.length > 0 ? onInviteSelectedUsers : undefined}
        submitTextKey="SHARED_BUTTON_INVITE"
        loading={loading}
        spaceId={space.id}
      />
      {selectedSpaceMember && (
        <SpaceMemberForm
          show={spaceDetailState === 'editMember'}
          onClose={onCloseModal}
          space={space}
          spaceMember={selectedSpaceMember}
          loading={loading}
        />
      )}
    </PageContainer>
  );
};

export default SpaceDetailScreen;
