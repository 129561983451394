import { SubscriptionPeriod } from 'src/services/subscriptions/subscriptionService';

export type UpdatableUserSettings = {
  firstName: string;
  lastName: string;
  displayName: string;
  locationCode: string;
  avatar?: string | null;
  languageCode: string;
  emailNotifications: boolean;
  pushNotificationsSpaceInvite: boolean;
  pushNotificationsSpaceEvents: boolean;
};

export type UserSettings = UpdatableUserSettings & {
  id: string;
  onboarded: boolean;
  crewsEnabled?: boolean;
  roles: Role[];
};

export type User = UserSettings & {
  email: string;
  subscription?: string;
  subscriptionPeriod?: SubscriptionPeriod;
};

export enum Role {
  ADMIN = 'admin',
  EXECUTIVE = 'executive',
  COMMUNITY_MANAGER = 'community_manager',
}

export type Credentials = {
  username: string;
  password: string;
};
