import { useEffect, useState } from 'react';

export const useDevicePixelRatio = (): number => {
  const [devicePixelRatio, setDevicePixelRatio] = useState(window.devicePixelRatio);

  useEffect(() => {
    const updateDevicePixelRatio = () => {
      setDevicePixelRatio(window.devicePixelRatio);
    };
    const media = matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`);
    media.addEventListener('change', updateDevicePixelRatio);
    return () => media.removeEventListener('change', updateDevicePixelRatio);
  }, []);

  return devicePixelRatio;
};
