import { User } from 'src/types/conference/UserType';
import React, { Key, useState } from 'react';
import clsx from 'clsx';
import UHTextButton from 'src/views/components/core/buttons/UHTextButton';
import { MockConference } from '../hooks/useMockConference';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  mockConference: MockConference;
};

const ButtonContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children }) => {
  return <div className="flex flex-col flex-wrap h-full">{children}</div>;
};

const MockConferenceControlPanel: React.FC<Props> = ({ mockConference, className, ...htmlProps }) => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const btn = (title: string, action: () => void, key: Key | null | undefined = undefined): JSX.Element => {
    return (
      <UHTextButton
        className="flex items-center justify-center m-1 p-2 w-8 h-8 rounded-lg"
        key={key}
        text={title}
        onClick={action}
      />
    );
  };

  return (
    <div className={clsx(className)} {...htmlProps}>
      <div className="h-32 overflow-x-auto">
        <div className="flex flex-wrap h-full space-x-4">
          {/* General Buttons */}
          <ButtonContainer>{btn('+', () => mockConference.addUser(undefined))}</ButtonContainer>
          {/* User List Buttons */}
          {selectedUser === null && (
            <ButtonContainer>
              {mockConference.sessionRenderData.users.map((u) => btn(`${u.id}`, () => setSelectedUser(u), u.id))}
            </ButtonContainer>
          )}
          {/* User specific buttons */}
          {selectedUser !== null && (
            <ButtonContainer>
              {/* Back */}
              {btn('<-', () => setSelectedUser(null))}
              {/* Remove user */}
              {btn('-', () => {
                if (selectedUser) {
                  mockConference.removeUser(selectedUser);
                  setSelectedUser(null);
                }
              })}
              {/* Leave conversation */}
              {btn('L', () => {
                if (selectedUser) mockConference.leaveConversation(selectedUser);
              })}
            </ButtonContainer>
          )}
          {/* Conversation specific buttons */}
          {selectedUser !== null && (
            <ButtonContainer>
              {mockConference.sessionRenderData.users
                .filter((u) => u.id !== selectedUser.id)
                .map((u) => btn(`${u.id}`, () => mockConference.joinUser(selectedUser, u), u.id))}
            </ButtonContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default MockConferenceControlPanel;
