let permissionStream: MediaStream | undefined;
let hasPermissions = false;

export const hasMediaPermissions = (): boolean => hasPermissions;

export const requestPermissions = async (onCompleted: () => void): Promise<void> => {
  try {
    permissionStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    hasPermissions = true;
    onCompleted();
  } catch (error) {
    console.error(error);
    onCompleted();
  }
};

export const removePermissionStream = (): void => {
  permissionStream?.getTracks().forEach((track) => {
    track.stop();
  });
};
