import { useEffect, useState } from 'react';
import fullConfig from 'src/tailwindConfig';

const getBreakpointValue = (value: string): number => parseInt(fullConfig.theme.screens[value].replace('px', ''));

/**
 * Returns the current responsive breakpoint
 */
const getBreakPoint = (): string => {
  let currentBreakpoint = 'xs';
  let biggestBreakpointValue = 0;
  for (const breakpoint of Object.keys(fullConfig.theme.screens)) {
    const breakpointValue = getBreakpointValue(breakpoint);
    if (breakpointValue > biggestBreakpointValue && window.innerWidth >= breakpointValue) {
      biggestBreakpointValue = breakpointValue;
      currentBreakpoint = breakpoint;
    }
  }
  return currentBreakpoint;
};

/**
 * Custom hook, that returns the current responsive breakpoint
 */
export const useCurrentBreakpoint = (): string => {
  const [breakPoint, setBreakPoint] = useState(getBreakPoint());

  useEffect(() => {
    function handleResize() {
      setBreakPoint(getBreakPoint());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakPoint;
};
