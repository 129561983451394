import { DictionaryType, localDictionary } from 'src/types/localization/dictionaryType';
import LocalizationService from 'src/services/localization/localizationService';
import { fetchDictionaryThunk, fetchLanguagesThunk } from 'src/store/localization/thunk';
import { LocalizationState } from 'src/store/localization/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import webpageDictionary from 'src/assets/localization/website_dictionary.json';
import { deviceLanguage } from 'src/services/device/deviceLocalization';
import { StorageService } from 'src/services/storage/StorageService';

export const initialState: LocalizationState = {
  languages: {},
  currentLanguageCode: '',
  currentWebpageLanguageCode: deviceLanguage,
  dictionary: {
    ...localDictionary.dictionary,
    ...webpageDictionary.find((d) => d.language === deviceLanguage)?.dictionary,
  },
};

const localizationSlice = createSlice({
  name: '@@localization',
  initialState,
  reducers: {
    updateDictionaryAction: (state, action: PayloadAction<DictionaryType>) => {
      state.dictionary = { ...state.dictionary, ...action.payload };
    },
    updateWebpageLanguage: (state, action: PayloadAction<string>) => {
      const webpageDict = webpageDictionary.find((d) => d.language === action.payload);
      if (webpageDict) {
        state.dictionary = { ...state.dictionary, ...webpageDict.dictionary };
        state.currentWebpageLanguageCode = action.payload;
        StorageService.setItem('language', action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDictionaryThunk.fulfilled, (state, action) => {
        if (action.payload) {
          // Get Locale Dictionary
          state.dictionary = { ...state.dictionary, ...LocalizationService.getDefaultDictionary() };

          // Get English Updated Dictionary
          const updatedEnglishDictionary = LocalizationService.createLocalizedString(
            LocalizationService.getAllLocalDictionaries().find((d) => d.language === 'en'),
          );

          state.dictionary = { ...state.dictionary, ...updatedEnglishDictionary, ...action.payload.dictionary };
          state.currentLanguageCode = action.payload.language;
        }
      })
      .addCase(fetchDictionaryThunk.rejected, (state) => {
        state.dictionary = { ...state.dictionary, ...LocalizationService.getDefaultDictionary() };
      })
      .addCase(fetchLanguagesThunk.fulfilled, (state, action) => {
        state.languages = action.payload;
      });
  },
});

export const { updateDictionaryAction, updateWebpageLanguage } = localizationSlice.actions;

export const localizationReducer = localizationSlice.reducer;
