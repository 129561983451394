import { useSpring } from '@react-spring/core';
import React, { useRef, useState } from 'react';
import { animated } from '@react-spring/web';
import { useGesture } from 'react-use-gesture';
import { clamp01 } from 'src/services/math/utils';
import clsx from 'clsx';

export type Props = React.HTMLAttributes<HTMLDivElement>;

const HoverTransformContainer: React.FC<Props> = ({ className, children, ...htmlProps }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);
  const [hover, setHover] = useState(false);
  const spring = useSpring({
    rotateX: hover ? rotateX : 0,
    rotateY: hover ? rotateY : 0,
    rotateZ: 0,
    scale: hover ? 1.05 : 1,
    zoom: 0,
    x: 0,
    y: 0,
    config: { mass: 5, tension: 350, friction: 40 },
  });

  const handleMove = (x: number, y: number) => {
    const clientRect = ref.current?.getBoundingClientRect();
    x -= clientRect?.x ?? 0;
    y -= clientRect?.y ?? 0;
    const xPercent = clamp01(x / Math.max(clientRect?.width ?? 0, 1));
    const yPercent = clamp01(y / Math.max(clientRect?.height ?? 0, 1));
    const strength = 6;
    // const aspect = Math.max(clientRect?.height ?? 0, 1) / Math.max(clientRect?.width ?? 0, 1);
    const rx = -(yPercent - 0.5) * strength;
    const ry = (xPercent - 0.5) * strength;
    setRotateX(rx);
    setRotateY(ry);
  };

  const bindMove = useGesture({
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
    onMove: ({ xy: [x, y] }) => handleMove(x, y),
  });

  return (
    <animated.div
      ref={ref}
      {...(htmlProps as Record<string, unknown>)}
      className={clsx('transition-shadow duration-500', className, { 'shadow-lg': !hover, 'shadow-2xl': hover })}
      style={{ ...(htmlProps.style ?? {}), transform: 'perspective(600px)', ...spring }}
      {...bindMove()}
    >
      {children}
    </animated.div>
  );
};

export default HoverTransformContainer;
