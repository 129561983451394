import React from 'react';

export const formatString = (source: string, ...params: string[]): string => {
  params.forEach((p, index) => {
    source = source.replace(new RegExp(`\\{${index}\\}`, 'g'), p);
  });
  return source;
};

export const padNumber = (n: number, digits = 2): string => {
  return String(n).padStart(digits, '0');
};

export const parseStylizedText = (text?: string, marker = '**', className = 'text-primary'): JSX.Element[] => {
  if (!text) return [];
  const elements = text.split(marker);

  return elements.map((e, index) =>
    index % 2 == 0 ? (
      <span key={index}>{e}</span>
    ) : (
      <span key={index} className={className}>
        {e}
      </span>
    ),
  );
};
