import { UserSettings } from 'src/types/users/userType';
import { UHAPI } from '.';

export const uploadFile = async (data: string | ArrayBuffer | null): Promise<UserSettings> => {
  const dataReceiver = await UHAPI.post<UserSettings>({ path: `/avatar`, body: { imageBinary: data } });
  return dataReceiver;
};

export const removeAvatar = async (): Promise<UserSettings> => {
  const dataReceiver = await UHAPI.del<UserSettings>({ path: `/avatar` });
  return dataReceiver;
};
