import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { isSafari } from 'react-device-detect';
import { Vector } from 'src/services/math/types';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  videoStream?: MediaStream;
  audioStream?: MediaStream;
  mirror?: boolean;
  muted?: boolean;
  rounded?: boolean;
  fillMode?: 'cover' | 'contain';
  onChangedDimensions?: (dimensions: Vector) => void;
};

const VideoStreamView: React.FC<Props> = ({
  videoStream,
  audioStream,
  mirror = false,
  muted = false,
  rounded = true,
  fillMode = 'cover',
  onChangedDimensions,
  className,
  ...htmlProps
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  // Reloading streams
  const reloadVideoStream = () => {
    if (videoRef.current && videoStream) {
      videoRef.current.srcObject = videoStream;
    }
  };

  const reloadAudioStream = () => {
    if (audioRef.current && audioStream) {
      audioRef.current.srcObject = audioStream;
    }
  };

  useEffect(() => {
    reloadVideoStream();
  }, [videoStream]);

  useEffect(() => {
    reloadAudioStream();
  }, [audioStream]);

  // Safari fix
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isSafari) reloadVideoStream();
    }, 2000);
    return () => clearTimeout(timeout);
  }, [videoStream]);

  const onResize = () => {
    if (videoRef.current) onChangedDimensions?.(new Vector(videoRef.current.videoWidth, videoRef.current.videoHeight));
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current?.addEventListener('resize', onResize);
    }
    return () => {
      if (videoRef.current) videoRef.current.removeEventListener('resize', onResize);
    };
  }, [videoRef]);

  return (
    <div className={clsx(className)} {...htmlProps}>
      {videoStream && (
        <video
          className={clsx('w-full h-full', {
            'rounded-full overflow-hidden': rounded,
            'object-cover': fillMode === 'cover',
            'object-contain': fillMode === 'contain',
          })}
          autoPlay
          playsInline
          muted={muted}
          style={{ transform: mirror ? 'scale(-1, 1)' : undefined }}
          ref={videoRef}
        />
      )}
      {audioStream && <audio muted={muted} autoPlay ref={audioRef} />}
    </div>
  );
};

export default VideoStreamView;
