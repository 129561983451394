import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { User } from 'src/types/conference';
import { isDisplayStream } from 'src/types/conference/UserStreamType';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import UHText from 'src/views/components/core/text/UHText';
import Absolute from 'src/views/components/core/transform/Absolute';
import Center from 'src/views/components/core/transform/Center';
import VideoStreamView from 'src/views/components/core/video/VideoStreamView';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  user: User;
  conversationId: string | null;
  myUserConversationId?: string | null;
  /** The size is used to determine fontsizes in the component */
  size?: number;
};

const DisplayBubble: React.FC<Props> = ({
  user,
  conversationId,
  myUserConversationId,
  size = 100,
  className,
  ...htmlProps
}) => {
  const videoStream = user?.streams.find((val) => isDisplayStream(val))?.stream;
  const videoTrack = videoStream?.getVideoTracks()[0];
  const isOnBreak = user.status === 'Break';
  const [, setLoading] = useState(videoTrack?.muted ?? true);
  const inConversation = !!conversationId;

  const onMute = () => {
    setLoading(true);
  };

  const onUnMute = () => {
    setLoading(false);
  };

  useEffect(() => {
    setLoading(videoTrack?.muted ?? true);
    videoTrack?.addEventListener('ended', onMute);
    videoTrack?.addEventListener('mute', onMute);
    videoTrack?.addEventListener('unmute', onUnMute);
    return () => {
      videoTrack?.removeEventListener('ended', onMute);
      videoTrack?.removeEventListener('mute', onMute);
      videoTrack?.removeEventListener('unmute', onUnMute);
    };
  }, [videoTrack]);

  const small = size <= 80;

  return (
    <div
      className={clsx(
        'relative rounded-full overflow-hidden',
        {
          'cursor-pointer': htmlProps.onClick,
          'border-2 border-background': inConversation && !user.sharingDisplay,
          'border-2 border-primary-300': inConversation && user.sharingDisplay,
        },
        className,
      )}
      {...htmlProps}
    >
      {/* Stream */}
      {inConversation && myUserConversationId === conversationId && (
        <Absolute className={clsx('rounded-full overflow-hidden')}>
          <VideoStreamView className="absolute inset-0 bg-neutral-300" videoStream={videoStream} />
        </Absolute>
      )}
      {/* Icon */}
      {myUserConversationId !== conversationId && (
        <Absolute
          className={clsx('flex flex-col items-center justify-center bg-secondary rounded-full overflow-hidden')}
        >
          <UHIcon className={clsx('text-typoInverted', { 'text-5xl': !small, 'text-2xl': small })} icon="display" />
        </Absolute>
      )}
      {/* Username */}
      {myUserConversationId === conversationId && (
        <Absolute className="flex flex-col items-center justify-end">
          <Center
            className={clsx('mb-5 p-1 bg-opacity-70 rounded-lg', { 'bg-black': !isOnBreak })}
            style={{ maxWidth: '60%' }}
          >
            <UHText className="line-clamp-2 wrap text-center text-typoInverted text-xs" text={user.name} />
          </Center>
        </Absolute>
      )}
    </div>
  );
};

export default DisplayBubble;
