import React, { useState } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import tailwindConfig from 'src/tailwind.config';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import UHButton from '../core/buttons/UHButton';
import UHText from '../core/text/UHText';

const UHCookieConsent: React.FC = () => {
  const history = useUHHistory();
  const [, setHasConsent] = useState(getCookieConsentValue());

  return (
    <CookieConsent
      onAccept={() => setHasConsent('true')}
      location="bottom"
      buttonText="Okay"
      buttonId="cookie_accept_button"
      style={{ background: tailwindConfig.theme.colors.neutral['900'] }}
      ButtonComponent={(props: { onClick: React.MouseEventHandler<HTMLButtonElement> | undefined }) => (
        <UHButton
          className="m-3"
          onClick={props.onClick}
          id="cookie_accept_button"
          height="sm"
          width="auto"
          titleKey="WEBSITE.COOKIE_ACCEPT"
        />
      )}
    >
      <div className="cursor-pointer" onClick={() => history.pushPage('privacyPolicy')}>
        <UHText textKey="WEBSITE.COOKIE_INFO" />
      </div>
    </CookieConsent>
  );
};

export default UHCookieConsent;
