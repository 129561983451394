import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';
import { deviceCurrentLocation } from '../device/deviceLocalization';

export type MetadataType = {
  operatingSystemName?: string;
  operatingSystemVersion?: string;
  browserName?: string;
  browserVersion?: string;
  batteryLevel?: number;
  location?: string;
  cameraEnabled?: boolean;
};

export const getDeviceMetadata = (): MetadataType => {
  return {
    operatingSystemName: osName,
    operatingSystemVersion: osVersion,
    browserName,
    browserVersion,
    location: deviceCurrentLocation,
  };
};
