import clsx from 'clsx';
import React from 'react';
import { lerp, clamp01, inverseLerp } from 'src/services/math/utils';
import Absolute from '../transform/Absolute';
import DraggableSliderContainer from './DraggableSliderContainer';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  /** The quantization of the values in steps. If not set the value is not restricted to discrete steps. */
  steps?: number;
  /** The number of markers that should be spread evenly over the slider bar. */
  markers?: number;
  /** Custom positions of markers on the slider. */
  markerPositions?: number[];
  /** The left most value on the slider. Default: 0 */
  minValue?: number;
  /** The right most value on the slider. Default: 1 */
  maxValue?: number;
  /** The current value on the slider between minValue and maxValue. */
  value: number;
  /** Custom handle component */
  handle?: React.ReactNode;
  /** Callback for when the value changes. */
  onChangeValue?: (v: number) => void;
};

const UHSlider: React.FC<Props> = ({
  steps = 0,
  markers = 0,
  markerPositions,
  minValue = 0,
  maxValue = 1,
  value,
  onChangeValue,
  handle,
  className,
  ...htmlProps
}) => {
  if (markers > 0) markers = Math.max(2, Math.round(markers));
  let markerValues = markers > 0 ? new Array(markers).fill(1).map((_, index) => index / (markers - 1)) : [];
  if (markerPositions) markerValues = markerPositions;

  const onDraggedToValue = (value: number) => {
    if (steps >= 2) value = Math.round(value * steps) / steps;
    value = lerp(minValue, maxValue, clamp01(value));
    onChangeValue?.(value);
  };

  const sliderPercentage = clamp01(inverseLerp(minValue, maxValue, value));

  return (
    <DraggableSliderContainer className={clsx(className)} onChangeValue={onDraggedToValue} {...htmlProps}>
      {/* Background */}
      <div className="relative w-full h-1.5 bg-neutral-300 rounded-full">
        {/* Fill */}
        <Absolute className="bg-primary rounded-full" style={{ width: `${sliderPercentage * 100}%` }} />
        {/* Option markers */}
        <div className="absolute bottom-0 left-1.5 right-1.5 top-0">
          {markerValues.map((markerValue, index) => {
            return (
              <Absolute
                key={index}
                position={{ x: clamp01(markerValue), y: 0.5 }}
                size={0}
                className="flex items-center justify-center"
              >
                <div className="flex-shrink-0 w-2 h-2 bg-background rounded-full" />
              </Absolute>
            );
          })}
        </div>
        {/* Handle */}
        <Absolute
          position={{ x: sliderPercentage, y: 0.5 }}
          size={0}
          className="flex flex-col items-center justify-center"
        >
          {handle}
          {!handle && <div className="flex-shrink-0 w-3 h-6 bg-background rounded shadow overflow-hidden" />}
        </Absolute>
      </div>
    </DraggableSliderContainer>
  );
};

export default UHSlider;
