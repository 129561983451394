import clsx from 'clsx';
import React from 'react';
import { memberRoleLocalizationKeys } from 'src/services/localization/dictionariesForTypes';
import { SpaceMember } from 'src/types/spaces/SpaceType';
import UHIcon from '../../core/icon/UHIcon';
import UHTag from '../../core/text/UHTag';
import UHText from '../../core/text/UHText';
import Center from '../../core/transform/Center';
import Avatar from '../../profile/Avatar';
import { MemberListEntryType } from './MemberList';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  member: SpaceMember;
  isEditable: boolean;
  type: MemberListEntryType;
  onRemove?: () => void;
};

const MemberEntry: React.FC<Props> = ({ member, isEditable, type, onClick, onRemove, className, ...htmlProps }) => {
  const navigatable =
    isEditable && !(type === 'CrewSpace') && (member.role !== 'owner' || type === 'Crew') && member.role !== 'invited';
  const removable = isEditable && (type === 'CrewSpace' || member.role === 'invited');
  return (
    <div
      className={clsx(
        'flex items-center justify-between px-4 h-18 border border-neutral-300 rounded-lg overflow-hidden',
        { 'cursor-pointer': navigatable && onClick },
        className,
      )}
      onClick={navigatable ? onClick : undefined}
      {...htmlProps}
    >
      <div className="flex items-center space-x-3">
        <Avatar className="w-10 h-10" user={member} />
        <div className="mr-2">
          {/* Display name */}
          <UHText className="mb-px" text={member.displayName} />
          {/* Email */}
          <UHText className="text-neutral" variant="body-xs" text={member.email} />
        </div>
      </div>
      <Center className="space-x-4">
        {/* Role */}
        <UHTag className="w-20" textKey={memberRoleLocalizationKeys[member.role]} />
        <Center className="w-2">
          {/* Remove button */}
          {removable && <UHIcon onClick={onRemove} icon="xmark" className="text-error" />}
          {/* Navigate icon */}
          {navigatable && <UHIcon className="text-neutral text-lg" icon="chevron-right" />}
        </Center>
      </Center>
    </div>
  );
};

export default MemberEntry;
