import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { cleanupUserStreams } from 'src/services/mediaStreams/userStreamService';
import { allPages, matchPathPages, pagesMatchForAuth, pagesMatchStreams } from 'src/services/pages/pageService';
import { useTypedSelector } from 'src/store/store';
import MainLayout from 'src/views/components/layout/MainLayout';
import { useTryAutologin } from 'src/views/hooks/auth/useTryAutologin';
import { useUserDataPolling } from 'src/views/hooks/auth/useUserDataPolling';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import ConferenceScreen from '../Conference/ConferenceScreen';
import LoadingScreen from '../Loading/LoadingScreen';
import OnboardingScreen from '../Onboarding/OnboardingScreen';
import SettingsScreen from '../Settings/SettingsScreen';
import SpaceDetailScreen from '../SpaceDetail/SpaceDetailScreen';
import SpacesOverviewScreen from '../SpacesOverview/SpacesOverviewScreen';
import SubscriptionCompletedScreen from '../Subscription/SubscriptionCompletedScreen';
import SubscriptionScreen from '../Subscription/SubscriptionScreen';
import UserMediaScreen from '../UserMediaScreen/UserMediaScreen';

const AppNavigator: React.FC = () => {
  const history = useUHHistory();
  const { isTryingAutologin, isAuthenticated } = useTryAutologin();
  const initializedUser = useTypedSelector((state) => state.user.initialized);
  const onboarded = useTypedSelector((state) => state.user.user.onboarded);

  // Cleanup streams if the user is not in a conference or in the media selection screen
  const streamsNeeded = matchPathPages(history.location.pathname, pagesMatchStreams);

  // Whether the page can be used without authentication
  const requiresAuth = matchPathPages(history.location.pathname, pagesMatchForAuth);

  // Redirect to auth if not authenticated after trying autologin
  useEffect(() => {
    if (!isTryingAutologin && !isAuthenticated && requiresAuth) history.replacePage('auth');
  }, [isTryingAutologin, isAuthenticated, requiresAuth]);

  useEffect(() => {
    if (!streamsNeeded) cleanupUserStreams();
  }, [streamsNeeded]);

  // Data polling in interval
  useUserDataPolling();

  return (
    <div className="w-screen h-screen bg-background">
      {/* Loading while autologin */}
      {(isTryingAutologin || (isAuthenticated && !initializedUser)) && <LoadingScreen className="w-full h-full" />}
      {/* Onboarding */}
      {isAuthenticated && initializedUser && !onboarded && <OnboardingScreen />}
      {/* Main application when authenticated */}
      {!isTryingAutologin && ((isAuthenticated && initializedUser && onboarded) || !requiresAuth) && (
        <MainLayout>
          <Routes>
            <Route path={allPages.spaceDetail.url} element={<SpaceDetailScreen />} />
            <Route path={allPages.userMediaSelection.url} element={<UserMediaScreen />} />
            <Route path={allPages.conference.url} element={<ConferenceScreen />} />
            <Route path={allPages.subscriptions.url} element={<SubscriptionScreen />} />
            <Route path={allPages.subscriptionsCompleted.url} element={<SubscriptionCompletedScreen />} />
            <Route path={allPages.settings.url} element={<SettingsScreen />} />
            <Route path={allPages.spacesOverview.url} element={<SpacesOverviewScreen />} />
          </Routes>
        </MainLayout>
      )}
    </div>
  );
};

export default AppNavigator;
