import clsx from 'clsx';
import React from 'react';
import Fade from 'src/views/components/animations/Fade';
import UHText from 'src/views/components/core/text/UHText';

type Props = React.HTMLAttributes<HTMLDivElement>;

const LoadingScreen: React.FC<Props> = ({ className, ...htmlProps }) => {
  return (
    <div className={clsx('main-gradient flex items-center justify-center', className)} {...htmlProps}>
      <Fade distance={0} delay={1500}>
        <UHText textKey="SHARED_TEXT_LOADING" />
      </Fade>
    </div>
  );
};

export default LoadingScreen;
