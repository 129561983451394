import React, { useEffect, useRef } from 'react';

/**
 * Custom hook, that returns a Ref to an HTML Element of type T and performs a custom action when the user clicks outside.
 * @param action The action to perform when the user clicked outside of the returned HTMLElement
 */
const useOnClickedOutside = <T extends HTMLElement>(action: () => void): React.RefObject<T> => {
  const element = useRef<T>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (element.current && !element.current.contains(event.target as Node)) {
        action();
      }
    };
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);
  return element;
};

export default useOnClickedOutside;
