import { DictionaryData, DictionaryType, localDictionary } from 'src/types/localization/dictionaryType';
import { StorageService } from 'src/services/storage/StorageService';
import { findEntry, findIndex, append } from 'src/services/object/objectService';
import { deviceLanguage } from '../device/deviceLocalization';

const getAllLocalDictionaries = (): DictionaryData[] => {
  return StorageService.getItem('dictionary') ?? [];
};

const changeLanguage = (language: string): DictionaryType | undefined => {
  const dictionaries = getAllLocalDictionaries();
  const dictionary = findEntry(dictionaries, language, 'language');
  if (dictionary) return dictionary.dictionary;
  return undefined;
};

const setDictionaries = (dictionary: DictionaryData[]): void => {
  StorageService.setItem('dictionary', dictionary);
};

const getLastTimestamp = (language: string): number | undefined => {
  const localDictionaries = getAllLocalDictionaries();
  const selectedDictionary = findEntry(localDictionaries, language, 'language');
  return selectedDictionary?.timestamp;
};

const initDictionary = (): void => {
  const dictionaries = getAllLocalDictionaries();

  const index = findIndex(dictionaries, 'en', 'language');
  if (index === -1) dictionaries.push(localDictionary);
  else dictionaries[index] = append(dictionaries[index], 'dictionary', localDictionary);

  setDictionaries(dictionaries);
};

const updateDictionary = (data: DictionaryData): DictionaryData => {
  const dictionaries = getAllLocalDictionaries();

  const index = findIndex(dictionaries, data.language, 'language');
  if (index === -1) {
    dictionaries.push(data);
    setDictionaries(dictionaries);
    return data;
  }

  dictionaries[index].timestamp = data.timestamp;
  dictionaries[index] = append(dictionaries[index], 'dictionary', data);
  setDictionaries(dictionaries);

  return dictionaries[index];
};

const removeDictionary = (): void => {
  StorageService.removeItem('dictionary');
};

const findLanguageLocally = (language: string): string | undefined => {
  const dictionaries = getAllLocalDictionaries();
  const result = findEntry(dictionaries, language, 'language');
  return result?.language;
};

const getDefaultDictionary = (): DictionaryType => {
  const language = findLanguageLocally(deviceLanguage) ?? 'en';
  const dictionaryData = findEntry(getAllLocalDictionaries(), language, 'language');
  return (dictionaryData ?? localDictionary).dictionary;
};

const createLocalizedString = (dictionaryEntry: DictionaryData | undefined): DictionaryType => {
  if (dictionaryEntry) return dictionaryEntry.dictionary;
  return getDefaultDictionary();
};

const LocalizationService = {
  getAllLocalDictionaries,
  changeLanguage,
  setDictionaries,
  getLastTimestamp,
  initDictionary,
  updateDictionary,
  removeDictionary,
  findLanguageLocally,
  getDefaultDictionary,
  createLocalizedString,
};

export default LocalizationService;
