import clsx from 'clsx';
import React from 'react';
import { copySpaceLinkToClipboard, getPublicSpaceLink } from 'src/services/publicSpace/publicSpaceService';
import { isSpaceDisabled } from 'src/services/subscriptions/restrictionsService';
import { useTypedSelector } from 'src/store/store';
import { Space } from 'src/types/spaces/SpaceType';
import UHButton from '../core/buttons/UHButton';
import UHCard from '../core/containers/UHCard';
import UHIcon from '../core/icon/UHIcon';
import UHText from '../core/text/UHText';
import Center from '../core/transform/Center';
import CopyToClipboard from '../tooltip/CopyToClipboard';
import SpaceImage from './image/SpaceImage';

type SpaceTileProps = React.HTMLAttributes<HTMLDivElement> & {
  space: Space;
  onOpen?: () => void;
  onShowDetails?: () => void;
};

const SpaceTile: React.FC<SpaceTileProps> = ({ space, onOpen, onShowDetails, className, ...htmlProps }) => {
  const spaces = useTypedSelector((state) => state.spaces.spaces);
  const user = useTypedSelector((state) => state.user.user);
  const spaceLink = getPublicSpaceLink(space);

  const spaceDisabled = isSpaceDisabled(user, space, spaces);

  return (
    // Container
    <UHCard
      className={clsx('group flex flex-col pt-2 px-2', { 'cursor-pointer': onOpen }, className)}
      {...htmlProps}
      onClick={onShowDetails}
    >
      <div className="relative">
        {/* Image */}
        <SpaceImage className="w-full" space={space} />
        {/* Public link */}
        {space.token && (
          <div
            className="absolute bottom-3 left-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
            onClick={(e) => e.stopPropagation()}
          >
            <CopyToClipboard copyContent={spaceLink}>
              <UHButton
                variant="tertiary"
                iconClass="text-base"
                width="auto"
                height="sm"
                icon="link"
                titleKey="PUBLIC_SPACE_LINK"
                onClick={() => {
                  copySpaceLinkToClipboard(space);
                }}
              />
            </CopyToClipboard>
          </div>
        )}
      </div>
      <div className="flex-1" />
      {/* Bottom container */}
      <div className="flex items-center justify-end p-2 w-full h-14 space-x-2">
        {/* Public info */}
        {space.token && (
          <Center col>
            <UHIcon className="text-primary-300" icon="earth-americas" />
            <UHText className="text-primary-300" variant="body-xs" textKey="SHARED_TEXT_PUBLIC" />
          </Center>
        )}
        <div className="flex-1" />
        <UHButton
          variant="secondary"
          height="sm"
          width="sm"
          icon="ellipsis"
          onClick={(e) => {
            e.stopPropagation();
            onShowDetails?.();
          }}
        />
        <UHButton
          height="sm"
          width="sm"
          iconClass={spaceDisabled ? 'text-base' : undefined}
          icon={spaceDisabled ? 'lock' : 'right-long'}
          variant={spaceDisabled ? 'disabled' : 'default'}
          onClick={
            spaceDisabled
              ? undefined
              : (e) => {
                  e.stopPropagation();
                  onOpen?.();
                }
          }
        />
      </div>
    </UHCard>
  );
};

export default SpaceTile;
