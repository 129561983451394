import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import UHIcon from '../icon/UHIcon';
import Loader from '../loader/Loader';
import UHText from '../text/UHText';
import Center from '../transform/Center';

type ButtonVariant =
  | 'default'
  | 'secondary'
  | 'tertiary'
  | 'plain'
  | 'selected'
  | 'unselected'
  | 'disabled'
  | 'danger'
  | 'neutral';

export type UHButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  title?: string;
  titleKey?: DictionaryKeyType;
  icon?: string;
  iconClass?: string;
  height?: 'default' | 'sm' | 'md';
  width?: 'auto' | 'default' | 'xs' | 'sm' | 'md';
  variant?: ButtonVariant;
  loading?: boolean;
};

const UHButton: React.FC<UHButtonProps> = ({
  title,
  titleKey,
  icon,
  iconClass,
  height = 'default',
  width = 'default',
  variant = 'default',
  loading = false,
  onClick,
  className,
  ...htmlProps
}) => {
  const localizedText = useLocalizedValue(title, titleKey);

  return (
    <button
      className={clsx(
        'relative flex items-center justify-center rounded-lg select-none transition-colors duration-150',
        {
          'px-6': variant !== 'plain',
          'cursor-pointer': onClick && !htmlProps.disabled && !loading,
          'cursor-default': !onClick || htmlProps.disabled || loading,
          'bg-primary hover:bg-primary-600 filter text-typoInverted': variant === 'default' && !loading,
          'bg-primary-300 text-typoInverted': variant === 'default' && loading,
          'bg-background hover:bg-primary-100 border-primary hover:border-primary-600 border-2 text-primary hover:text-primary-600':
            variant === 'secondary',
          'bg-primary-200  text-primary': variant === 'tertiary',
          'bg-primary-200 text-primary': variant === 'selected',
          'bg-background  text-primary': variant === 'plain',
          'bg-neutral-300 text-typoInverted': variant === 'disabled' || variant === 'unselected',
          'border-2 hover:bg-error-100 border-error text-error hover:border-error-600 hover:text-error-600':
            variant === 'danger',
          'bg-background hover:bg-primary-100 hover:text-primary-600 text-primary': variant === 'neutral',
          'min-w-max': localizedText,
          'h-12': height === 'default',
          'h-8': height === 'sm',
          'h-10': height === 'md',
          'w-56': width === 'default',
          'w-9': width === 'xs',
          'w-18': width === 'sm',
          'w-32': width === 'md',
        },
        className,
      )}
      onClick={variant === 'disabled' || loading ? undefined : onClick}
      {...htmlProps}
    >
      {loading && (
        <Center className="absolute inset-0 transform scale-50">
          <Loader
            colorClass={clsx({
              'bg-background': variant === 'default',
              'bg-primary': variant === 'secondary',
              'bg-error': variant === 'danger',
            })}
          />
        </Center>
      )}
      {icon && (
        <UHIcon
          className={clsx(
            'select-none',
            { 'mr-1.5 -ml-1': localizedText, invisible: loading },
            iconClass ?? (variant === 'plain' ? 'text-lg' : 'text-2xl'),
          )}
          icon={icon}
        />
      )}
      {localizedText && <UHText className={clsx({ invisible: loading })} variant="button" text={localizedText} />}
    </button>
  );
};

export default UHButton;
