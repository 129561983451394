import clsx from 'clsx';
import React from 'react';
import UHText from 'src/views/components/core/text/UHText';
import Absolute from 'src/views/components/core/transform/Absolute';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  highlight?: boolean;
};

export const UserBubbleDropZone: React.FC<Props> = ({ highlight = false, className, ...htmlProps }) => {
  return (
    <div
      className={clsx(
        'bg-background border-2 border-dashed rounded-full opacity-70',
        { 'border-neutral': !highlight, 'border-primary': highlight },
        className,
      )}
      {...htmlProps}
    >
      <Absolute center>
        <UHText
          className={clsx('text-center', { 'text-primary': highlight, 'text-neutral': !highlight })}
          textKey="CONFERENCE_DROP_HERE"
        />
      </Absolute>
    </div>
  );
};
