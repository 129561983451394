import isArray from 'lodash/isArray';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  /** Either an image url or a list of image urls. If a list of image urls is provided, they are loaded one by one until an image could be loaded successfully */
  src: string | string[];
  /** How the image should be placed within the container */
  fillType?: 'contain' | 'cover';
};

const UHImage: React.FC<Props> = ({ src, fillType = 'cover', className, ...props }) => {
  const [imgSrc, setImgSrc] = useState<string>(isArray(src) ? src[0] : src);
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgReady, setImgReady] = useState(false);

  return (
    <div className={clsx('select-none overflow-hidden', className)} {...props}>
      <div className="relative w-full h-full">
        <img
          ref={imgRef}
          className={clsx('w-full h-full transition-all duration-700', {
            'opacity-0': !imgReady,
            'opacity-100': imgReady,
            'object-cover': fillType === 'cover',
            'object-contain': fillType === 'contain',
          })}
          src={imgSrc}
          onLoad={() => {
            if (imgRef.current?.clientWidth === 0) {
              setImgSrc(isArray(src) ? src[1] : src);
            }
            setImgReady(true);
          }}
          onError={() => {
            setImgSrc(isArray(src) ? src[1] : src);
            setImgReady(true);
          }}
        />
      </div>
    </div>
  );
};

export default UHImage;
