export const clamp = (v: number, min: number, max: number): number => {
  return Math.min(Math.max(v, min), max);
};

export const clamp01 = (value: number): number => {
  return clamp(value, 0, 1);
};

export const mirror = (v: number, min: number, max: number): number => {
  if (v < min) return 2 * min - v;
  if (v > max) return 2 * max - v;
  return v;
};

export const lerp = (start: number, end: number, value: number): number => {
  return start + (end - start) * value;
};

export const inverseLerp = (start: number, end: number, value: number): number => {
  return (value - start) / (end - start);
};

/** @returns Random Int between 0 (inclusive) and max (exclusive) */
export const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * Math.floor(max));
};

/**
 * @param bias Likelyhood of true (between 0 and 1)
 * @returns Random Boolean */
export const getRandomBoolean = (bias = 0.5): boolean => {
  return Math.random() < bias;
};
