import clsx from 'clsx';
import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import { Vector } from 'src/services/math/types';
import Absolute from 'src/views/components/core/transform/Absolute';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  position: Vector;
  radius: number;
  dimensions: Vector;
  immediate?: boolean;
};

export const ConferenceElement: React.FC<Props> = ({
  position,
  radius,
  dimensions,
  immediate = false,
  children,
  className,
  style,
  ...htmlProps
}) => {
  let aspect = dimensions.x / dimensions.y;
  if (Number.isNaN(aspect)) aspect = 1;
  const x = (position.x - radius) / aspect;
  const y = position.y - radius;
  const width = (2 * radius) / aspect;

  const spring = useSpring({
    top: `${y * 100}%`,
    left: `${x * 100}%`,
    width: `${width * 100}%`,
    config: { mass: 1, tension: 100, friction: 15 },
    immediate,
  });

  return (
    <animated.div
      className={clsx('absolute', className)}
      {...(htmlProps as Record<string, never>)}
      style={{ ...(style ?? {}), ...spring }}
    >
      <div style={{ paddingBottom: '100%' }}>
        <Absolute>{children}</Absolute>
      </div>
    </animated.div>
  );
};
