import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTypedSelector } from 'src/store/store';
import Footer from 'src/views/components/navigation/Footer';
import Navbar from 'src/views/components/navigation/Navbar';
import DataPrivacyScreen from '../website/DataPrivacy/DataPrivacyScreen';
import ImpressumScreen from '../website/Impressum/ImpressumScreen';
import LandingScreenV2 from '../website/Landing/LandingScreenV2';
import SupportScreen from '../website/Support/SupportScreen';
import TermsScreen from '../website/Terms/TermsScreen';
import VerificationErrorScreen from '../website/Verification/VerificationErrorScreen';
import VerificationScreen from '../website/Verification/VerificationScreen';
import VerificationSuccessScreen from '../website/Verification/VerificationSuccessScreen';
import BlogScreen from '../website/Blog/BlogScreen';
import ArticleScreen from '../website/Blog/ArticleScreen';
import ApiInfoScreen from '../website/ApiInfo/ApiInfoScreen';

const WebsiteNavigator: React.FC = () => {
  const websiteLanguage = useTypedSelector((state) => state.localization.currentWebpageLanguageCode);

  return (
    <div className="w-screen h-screen bg-background">
      <div lang={websiteLanguage} className="flex flex-col min-h-screen">
        <Navbar />
        <div className="relative flex flex-col flex-grow">
          <Routes>
            <Route path="/api" element={<ApiInfoScreen />} />
            <Route path="/blog" element={<BlogScreen />} />
            <Route path="/blog/:articleId" element={<ArticleScreen />} />
            <Route path="/impressum" element={<ImpressumScreen />} />
            <Route path="/privacypolicy" element={<DataPrivacyScreen />} />
            <Route path="/terms" element={<TermsScreen />} />
            <Route path="/verification-success" element={<VerificationSuccessScreen />} />
            <Route path="/verification-error" element={<VerificationErrorScreen />} />
            <Route path="/verification" element={<VerificationScreen />} />
            <Route path="/support" element={<SupportScreen />} />
            <Route path="*" element={<LandingScreenV2 />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default WebsiteNavigator;
