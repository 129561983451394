import { useEffect, useRef } from 'react';
import { isLocalhost } from 'src/services/environment/envService';
import { allPages, matchPathPages } from 'src/services/pages/pageService';
import { actions, useTypedSelector } from 'src/store/store';
import { useUHHistory } from '../navigation/useUHHistory';

export const useUserDataPolling = (): void => {
  const history = useUHHistory();
  const isAuthenticated = !!useTypedSelector((state) => state.user.cognitoUser);
  const initializedUser = useTypedSelector((state) => state.user.initialized);
  const onboarded = useTypedSelector((state) => state.user.user.onboarded);
  const inConference = matchPathPages(history.location.pathname, [allPages.conference]);
  const inConferenceRef = useRef(inConference);

  const pollData = () => {
    actions.fetchSpaces();
    actions.fetchInvites();
  };

  useEffect(() => {
    // Poll data when exiting conference
    if (isAuthenticated && initializedUser && onboarded && inConferenceRef.current && !inConference) {
      pollData();
    }
    // Poll data in interval
    const interval = setInterval(
      () => {
        if (isAuthenticated && initializedUser && onboarded && !inConference) {
          pollData();
        }
      },
      isLocalhost ? 300000 : 60000,
    );
    inConferenceRef.current = inConference;

    return () => clearInterval(interval);
  }, [isAuthenticated, initializedUser, onboarded, inConference]);
};
