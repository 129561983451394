import clsx from 'clsx';
import React from 'react';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import UHSvgIcon from 'src/views/components/core/icon/UHSvgIcon';
import Center from 'src/views/components/core/transform/Center';
import Stripe from 'stripe';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  paymentCard: Stripe.PaymentMethod.Card;
};

const PaymentCardBrand: React.FC<Props> = ({ paymentCard, className, ...htmlProps }) => {
  const brand = paymentCard.brand;
  return (
    <div className={clsx(className)} {...htmlProps}>
      {brand === 'visa' && (
        <Center className="px-1 h-full bg-primary rounded">
          <UHSvgIcon icon="paymentCardBrandVisa" className="-my-9 h-9" />
        </Center>
      )}
      {brand === 'mastercard' && (
        <Center className="px-1 h-full bg-neutral-900 rounded">
          <UHSvgIcon icon="paymentCardBrandMastercard" className="-my-6 h-6" />
        </Center>
      )}
      {brand === 'amex' && (
        <Center className="h-full rounded overflow-hidden">
          <UHSvgIcon icon="paymentCardBrandAmex" className="-m-1.5 w-11 h-11" />
        </Center>
      )}
      {!['amex', 'mastercard', 'visa'].includes(brand) && (
        <Center className="pr-1 h-full rounded overflow-hidden">
          <UHIcon icon="credit-card" className="-my-6 text-neutral-400 text-2xl" />
        </Center>
      )}
    </div>
  );
};

export default PaymentCardBrand;
