import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import FadeInUpScroll from 'src/views/components/animations/FadeInUpScroll';
import UHImage from 'src/views/components/core/image/UHImage';
import UHText from 'src/views/components/core/text/UHText';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';

type Props = {
  imageSrc: string;
  imageSize?: number;
  text?: string;
  textKey?: DictionaryKeyType;
  author: string;
  company?: string;
  reverse?: boolean;
  className?: string;
};

const ReviewEntry: React.FC<Props> = ({
  imageSrc,
  imageSize = 200,
  text,
  textKey,
  author,
  company,
  reverse,
  className,
}) => {
  const localizedText = useLocalizedValue(text, textKey);
  return (
    <div className={clsx('flex flex-col items-center justify-between w-full lg:flex-row', className)}>
      <FadeInUpScroll className={clsx('object-contain', { 'lg:order-1 lg:ml-8': reverse, 'lg:mr-8': !reverse })}>
        <UHImage
          className="rounded-full overflow-hidden"
          fillType="cover"
          src={imageSrc}
          style={{ width: imageSize, height: imageSize, maxWidth: '90vw' }}
        />
      </FadeInUpScroll>
      <FadeInUpScroll
        className={clsx(
          'flex flex-1 flex-col items-center mt-12 w-full text-center lg:items-start lg:mt-0 lg:w-auto lg:text-left',
          {
            'lg:pl-28': reverse,
            'lg:pr-28': !reverse,
          },
        )}
      >
        <UHText tag="p" variant="body-lg" text={`„${localizedText}“`} />
        <UHText tag="p" variant="body-lg" className="mt-6 text-primary" text={author} />
        {company && <UHText tag="p" variant="body-lg" className="mt-1 text-neutral-400" text={company} />}
      </FadeInUpScroll>
    </div>
  );
};

export default ReviewEntry;
