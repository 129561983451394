import clsx from 'clsx';
import React from 'react';
import UHIcon from 'src/views/components/core/icon/UHIcon';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  icon: string;
  active?: boolean;
  disabled?: boolean;
};

const ToolbarIcon: React.FC<Props> = ({ icon, active = false, disabled = false, className, onClick, ...htmlProps }) => {
  return (
    <UHIcon
      icon={icon}
      className={clsx(className, 'flex items-center justify-center w-7 h-7 rounded-md', {
        'bg-neutral-250': active,
        'text-neutral-300': disabled,
        'hover:bg-neutral-300': !disabled,
      })}
      onClick={disabled ? undefined : onClick}
      {...htmlProps}
    />
  );
};

export default ToolbarIcon;
