/* eslint-disable no-underscore-dangle */
import { Device } from 'mediasoup-client';
import { registerGlobals } from 'src/types/conference/media/mediaStreamType';
import { Transport, Consumer, Producer, MediaKind } from 'mediasoup-client/lib/types';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import ConferenceService from 'src/services/mediasoup/conferenceService';
import { setupDevice, initTransport, consume, produce } from 'src/services/mediasoup/mediasoup';
import Signaling from 'src/services/signaling/socketConnection';
import {
  UserStatus,
  ConferenceState,
  SocketEvents,
  DataResponse,
  Conversation,
  User,
  UserStream,
  StreamUpdate,
  StreamUpdateType,
  ErrorCode,
  ErrorMessage,
  ConferenceSavedState,
} from 'src/types/conference';
import { AppError, getAppError } from 'src/helpers/Errors';
import { ContentType } from 'src/types/conference/ContentTypeEnum';
import { PeerMessage } from 'src/types/conference/PeerMessageType';
import {
  cleanupUserAudioStream,
  cleanupUserDisplayStream,
  cleanupUserVideoStream,
  loadUserAudioStream,
  loadUserDisplayStream,
  loadUserVideoStream,
} from '../mediaStreams/userStreamService';
import { getDeviceMetadata } from '../metadata/deviceMetadata';
import { isAudioStream, isDisplayStream, isVideoStream } from 'src/types/conference/UserStreamType';
import { EnterSpaceResponse, CreateProducerRequest, CreateConsumerRequest } from 'src/types/conference/sockets';
import Logger from 'src/helpers/Logger';

const logger = new Logger('conference');

class Conference {
  private _userId!: string;

  private _userStatus: UserStatus = 'Active';

  private _stillImageOnlyMode = false;

  private _sharingDisplay = false;

  private _micMuted = false;

  private _serverState: ConferenceState;

  private _savedState?: ConferenceSavedState;

  private _signaling: Signaling;

  private _localVideoStream: MediaStream | null;

  private _localAudioStream: MediaStream | null;

  private _localDisplayStream: MediaStream | null;

  private _device: Device | null;

  private _producerTransport: Transport | null;

  private _consumerTransport: Transport | null;

  private _camProducer: Producer | null;

  private _micProducer: Producer | null;

  private _displayProducer: Producer | null;

  private _reconnectingFlag = false;

  /**
   * Map of current Consumers
   * - Key = producer ID
   */
  private _consumers = new Map<string, Consumer>();

  private intervals = new Map<string, NodeJS.Timer>();

  private userStreams = new Map<string, boolean>();

  onUpdateConferenceState?: (state: ConferenceState) => void;

  onUpdateReconnectFlag?: (reconnectFlag: boolean) => void;

  onUpdateUserConnectionScore?: (userId: string, isConnectionScoreWeak: boolean) => void;

  constructor() {
    /**
     * Register WebRTC Globals
     * navigator.mediaDevices.getUserMedia()
     * navigator.mediaDevices.enumerateDevices()
     * window.RTCPeerConnection
     * window.RTCIceCandidate
     * window.RTCSessionDescription
     * window.MediaStream
     * window.MediaStreamTrack
     */
    registerGlobals();
    this._signaling = new Signaling();
    this._device = null;
    this._producerTransport = null;
    this._consumerTransport = null;
    this._camProducer = null;
    this._micProducer = null;
    this._displayProducer = null;
    this._localVideoStream = null;
    this._localAudioStream = null;
    this._localDisplayStream = null;
    this._serverState = ConferenceService.initialConferenceState;
  }

  public get reconnectFlag(): boolean {
    return this._reconnectingFlag;
  }

  private set reconnectFlag(flag: boolean) {
    this._reconnectingFlag = flag;
  }

  public get userId(): string {
    return this._userId;
  }

  public set userId(userId: string) {
    this._userId = userId;
  }

  public get userStatus(): UserStatus {
    return this._userStatus;
  }

  public set userStatus(userStatus: UserStatus) {
    this._userStatus = userStatus;
  }

  public get stillImageOnlyMode(): boolean {
    return this._stillImageOnlyMode;
  }

  public set stillImageOnlyMode(status: boolean) {
    this._stillImageOnlyMode = status;
  }

  public get sharingDisplay(): boolean {
    return this._sharingDisplay;
  }

  public set sharingDisplay(v: boolean) {
    this._sharingDisplay = v;
  }

  public get micMuted(): boolean {
    return this._micMuted;
  }

  public set micMuted(v: boolean) {
    this._micMuted = v;
  }

  public get serverState(): ConferenceState {
    return this._serverState;
  }

  public set serverState(v: ConferenceState) {
    this._serverState = v;
  }

  public get savedState(): ConferenceSavedState | undefined {
    return this._savedState;
  }

  public set savedState(v: ConferenceSavedState | undefined) {
    this._savedState = v;
  }

  public get signaling(): Signaling {
    return this._signaling;
  }

  public set signaling(signaling: Signaling) {
    this._signaling = signaling;
  }

  public get consumers(): Map<string, Consumer> {
    return this._consumers;
  }

  public set consumers(consumers: Map<string, Consumer>) {
    this._consumers = consumers;
  }

  public get localVideoStream(): MediaStream | null {
    return this._localVideoStream;
  }

  public set localVideoStream(v: MediaStream | null) {
    this._localVideoStream = v;
  }

  public get localAudioStream(): MediaStream | null {
    return this._localAudioStream;
  }

  public set localAudioStream(v: MediaStream | null) {
    this._localAudioStream = v;
  }

  public get localDisplayStream(): MediaStream | null {
    return this._localDisplayStream;
  }

  public set localDisplayStream(v: MediaStream | null) {
    this._localDisplayStream = v;
  }

  public get device(): Device | null {
    return this._device;
  }

  public set device(device: Device | null) {
    this._device = device;
  }

  public get producerTransport(): Transport | null {
    return this._producerTransport;
  }

  public set producerTransport(producerTransport: Transport | null) {
    this._producerTransport = producerTransport;
  }

  public get consumerTransport(): Transport | null {
    return this._consumerTransport;
  }

  public set consumerTransport(consumerTransport: Transport | null) {
    this._consumerTransport = consumerTransport;
  }

  public get camProducer(): Producer | null {
    return this._camProducer;
  }

  public set camProducer(camProducer: Producer | null) {
    this._camProducer = camProducer;
  }

  public get micProducer(): Producer | null {
    return this._micProducer;
  }

  public set micProducer(micProducer: Producer | null) {
    this._micProducer = micProducer;
  }

  public get displayProducer(): Producer | null {
    return this._displayProducer;
  }

  public set displayProducer(displayProducer: Producer | null) {
    this._displayProducer = displayProducer;
  }

  /**
   * User enters a Space and sets up the necessary connection to the Server.
   * Necessary mediasoup and webrtc resources for the local user is set up.
   *
   * @async
   * @param {string} name User's display name
   * @param {number} spaceId Space ID that is being joined
   * @param {string} userId User ID
   * @param {MediaStream | null} localVideoStream User's video stream
   * @param {boolean} stillImageOnlyMode User turned on still image only more or not
   * @param {(ConferenceError) => void} errorCallback Function to handle errors when joining space
   * @returns {PromiseLike<void>}
   */
  public enterSpace = async (
    name: string,
    spaceId: number,
    userId: string,
    localVideoStream: MediaStream | null,
    stillImageOnlyMode: boolean,
    micMuted = false,
    errorCallback: (error: AppError) => void,
    tempJwt?: string,
  ): Promise<void> => {
    logger.debug(`enterSpace()`);

    this.initSockets(
      async () => {
        this.userId = userId;
        this.localVideoStream = localVideoStream;
        this.stillImageOnlyMode = stillImageOnlyMode;
        this.micMuted = micMuted;

        let jwt = tempJwt;
        try {
          if (!jwt) {
            const currentSession: CognitoUserSession = await Auth.currentSession();
            const accessToken = currentSession.getAccessToken();
            jwt = accessToken.getJwtToken();
          }
        } catch (err) {
          errorCallback(new AppError(`JWT not retrieved`, ErrorCode.INTERNAL));
        }

        const enterSpaceResponse = (await this.signaling.requestPromise(SocketEvents.ENTER_SPACE, {
          spaceId,
          jwt,
          name,
        })) as DataResponse<EnterSpaceResponse>;

        if (
          enterSpaceResponse.error?.code === ErrorCode.INTERNAL &&
          enterSpaceResponse.error?.message === ErrorMessage.CONFERENCE_TRANSFERED
        ) {
          this.reconnectFlag = false;
          this.onUpdateReconnectFlag?.(this.reconnectFlag);
          this.savedState = this.saveState();
          this.exitSpace();
          this.disconnectUser();
          errorCallback(new AppError(ErrorMessage.CONFERENCE_TRANSFERED, ErrorCode.INTERNAL));
        } else if (enterSpaceResponse.error) {
          errorCallback(new AppError(enterSpaceResponse.error.message, enterSpaceResponse.error.code));
        }

        try {
          this.device = await setupDevice(enterSpaceResponse.data.rtpCapabilities);
          this.producerTransport = await initTransport(
            this.signaling,
            this.device,
            `send`,
            enterSpaceResponse.data.producerTransport,
          );
          this.consumerTransport = await initTransport(
            this.signaling,
            this.device,
            `recv`,
            enterSpaceResponse.data.consumerTransport,
          );

          const enteredSpaceResponse = (await this.signaling.requestPromise(SocketEvents.ENTERED_SPACE, {
            userId,
            userStatus: this.savedState?.myStatus ? this.savedState.myStatus : 'Active',
            stillImageOnlyMode: this.savedState?.myStillImageOnlyMode ?? this.stillImageOnlyMode,
            micMuted: this.savedState?.myMicMuted ?? this.micMuted,
            metadata: {
              ...getDeviceMetadata(),
              cameraEnabled: !this.stillImageOnlyMode,
            },
          })) as DataResponse<null>;

          if (enteredSpaceResponse.error) {
            errorCallback(new AppError(enteredSpaceResponse.error.message, enteredSpaceResponse.error.code));
          }

          this.reconnectFlag = false;
          this.onUpdateReconnectFlag?.(this.reconnectFlag);
        } catch (error) {
          if (error instanceof AppError) {
            errorCallback(error);
          }
          errorCallback(getAppError(error));
        }
      },
      async (error: AppError) => {
        this.reconnectFlag = true;
        this.onUpdateReconnectFlag?.(this.reconnectFlag);
        errorCallback(error);
      },
      spaceId,
    );
  };

  /**
   * User exits the Conference and leave the Space.
   * Local resources are cleaned up.
   */
  public exitSpace = (): void => {
    logger.debug(`exitSpace()`);

    this.removeProducer(this.camProducer);
    this.removeProducer(this.displayProducer);
    this.removeProducer(this.micProducer);
    this.device = null;

    if (this.producerTransport) {
      this.producerTransport.close();
      this.producerTransport = null;
    }

    if (this.consumerTransport) {
      this.consumerTransport.close();
      this.consumerTransport = null;
    }

    this.consumers.forEach((consumer: Consumer) => {
      consumer.close();
    });
    this.consumers.clear();

    this.intervals.forEach((interval: NodeJS.Timer) => {
      clearInterval(interval);
    });
    this.intervals.clear();
    this.userStreams.clear();
  };

  public disconnectUser = (): void => {
    logger.debug(`disconnectUser()`);

    this.signaling.disconnect();
  };

  /**
   * User starts a conversation by tapping on another user.
   * Audio will now be shared between the two users.
   *
   * @async
   * @param {string} userId User ID of the user clicked on
   * @returns {PromiseLike<void>}
   * @throws {@link AppError}
   */
  public startConversation = async (userId: string): Promise<void> => {
    logger.debug(`startConversation()`);

    if (userId === this.userId) {
      throw new AppError(
        'Error Starting Conversation: Cannot start conversation with yourself',
        ErrorCode.UNAUTHORIZED,
      );
    }

    const response = (await this.signaling.requestPromise(SocketEvents.START_CONVERSATION, {
      userId,
    })) as DataResponse<null>;

    if (response.error) {
      throw new AppError(response.error.message, response.error.code);
    }
  };

  /**
   * User joins an existing conversation.
   *
   * @async
   * @param {number} conversationId ID of existing conversation
   * @returns {PromiseLike<void>}
   * @throws {@link AppError}
   */
  public joinConversation = async (conversationId: string): Promise<void> => {
    logger.debug(`joinConversation()`);

    const response = (await this.signaling.requestPromise(SocketEvents.JOIN_CONVERSATION, {
      conversationId,
    })) as DataResponse<null>;

    if (response.error) {
      throw new AppError(response.error.message, response.error.code);
    }
  };

  /**
   * User leaves current conversation.
   *
   * @async
   * @param {number} conversationId ID of joined conversation
   * @returns {PromiseLike<void>}
   * @throws {@link AppError}
   */
  public leaveConversation = async (conversationId: string): Promise<void> => {
    logger.debug(`leaveConversation()`);

    const response = (await this.signaling.requestPromise(SocketEvents.LEAVE_CONVERSATION, {
      conversationId,
    })) as DataResponse<null>;

    if (response.error) {
      throw new AppError(response.error.message, response.error.code);
    }
  };

  /**
   * User adds another user to current conversation.
   *
   * @async
   * @param {string} userId ID of the user that should be added to the conversation
   * @param {number} conversationId ID of the conversation
   * @returns {PromiseLike<void>}
   * @throws {@link AppError}
   */
  public addUserToConversation = async (userId: string, conversationId: string): Promise<void> => {
    logger.debug(`addUserToConversation()`);

    const response = (await this.signaling.requestPromise(SocketEvents.JOIN_USER, {
      conversationId,
      userId,
    })) as DataResponse<null>;

    if (response.error) {
      throw new AppError(response.error.message, response.error.code);
    }
  };

  /**
   * User changes status to either 'Active' | 'Break'.
   *
   * @async
   * @param {UserStatus} status Status to which the user switches to
   * @returns {PromiseLike<void>}
   * @throws {@link AppError}
   */
  public changeStatus = async (status: UserStatus): Promise<void> => {
    logger.debug(`changeStatus()`);

    const oldStatus = this.userStatus;
    this.userStatus = status;

    const response = (await this.signaling.requestPromise(SocketEvents.CHANGE_STATUS, {
      status,
    })) as DataResponse<null>;

    if (response.error) {
      this.userStatus = oldStatus;
      throw new AppError(response.error.message, response.error.code);
    }
  };

  /**
   * User toggles sharing the display
   *
   * @async
   * @param {status} status Status to share screen or not
   * @throws {@link AppError}
   */
  public toggleDisplay = async (status: boolean): Promise<void> => {
    logger.debug(`toggleDisplay()`);

    const oldStatus = this.sharingDisplay;
    this.sharingDisplay = status;

    const response = (await this.signaling.requestPromise(SocketEvents.SHARING_DISPLAY, {
      sharingDisplay: status,
    })) as DataResponse<null>;

    if (response.error) {
      this.sharingDisplay = oldStatus;
      throw new AppError(response.error.message, response.error.code);
    }
  };

  public userCameraChanged = (videoStream: MediaStream): void => {
    if (this.localVideoStream && this.localVideoStream.id !== videoStream.id) {
      const oldTrack = this.localVideoStream.getVideoTracks()[0];
      const newTrack = videoStream.getVideoTracks()[0];
      const stateVideoStream = this.serverState.users
        .find((u) => u.id === this.userId)
        ?.streams.find((s) => isVideoStream(s));

      stateVideoStream?.stream?.removeTrack(oldTrack);
      stateVideoStream?.stream?.addTrack(newTrack);

      this.localVideoStream = videoStream;

      if (stateVideoStream) {
        this.onUpdateConferenceState?.(
          this.userStreamUpdated({ actor: this.userId, data: stateVideoStream, type: StreamUpdateType.ADDED_STREAM }),
        );
      }

      this.camProducer?.track?.stop();
      this.camProducer?.replaceTrack({ track: newTrack });
    }
  };

  public userMicrophoneChanged = (audioStream: MediaStream | undefined): void => {
    const stateAudioStream = this.serverState.users
      .find((u) => u.id === this.userId)
      ?.streams.find((s) => isAudioStream(s));

    if (this.localAudioStream && audioStream && this.localAudioStream.id !== audioStream.id) {
      const oldTrack = this.localAudioStream.getAudioTracks()[0];
      const newTrack = audioStream.getAudioTracks()[0];

      stateAudioStream?.stream?.removeTrack(oldTrack);
      stateAudioStream?.stream?.addTrack(newTrack);

      this.localAudioStream = audioStream;

      if (stateAudioStream) {
        this.onUpdateConferenceState?.(
          this.userStreamUpdated({ actor: this.userId, data: stateAudioStream, type: StreamUpdateType.ADDED_STREAM }),
        );
      }

      this.micProducer?.track?.stop();
      this.micProducer?.replaceTrack({ track: newTrack });
    } else if (this.localAudioStream && !audioStream) {
      const oldTrack = this.localAudioStream.getAudioTracks()[0];

      stateAudioStream?.stream?.removeTrack(oldTrack);
      this.localAudioStream = null;
      this.removeProducer(this.micProducer);
    }
  };

  /**
   * User change still image only status to either true | false.
   *
   * @async
   * @param {Boolean} status Status to which the user switches to
   * @returns {PromiseLike<void>}
   * @throws {@link AppError}
   */
  public changeStillImageOnlyMode = async (status: boolean): Promise<void> => {
    logger.debug(`changeStillImageOnlyMode()`);

    const oldStillImageOnlyMode = this.stillImageOnlyMode;
    this.stillImageOnlyMode = status;

    const response = (await this.signaling.requestPromise(SocketEvents.STILL_IMAGE_MODE, {
      status,
    })) as DataResponse<null>;

    if (response.error) {
      this.stillImageOnlyMode = oldStillImageOnlyMode;
      throw new AppError(response.error.message, response.error.code);
    }
  };

  public changeMicStatus = async (muted: boolean): Promise<void> => {
    logger.debug(`changeMicStatus()`);

    const oldStatus = this.micMuted;
    this.micMuted = muted;

    const response = (await this.signaling.requestPromise(SocketEvents.MIC_STATUS_CHANGED, {
      muted,
    })) as DataResponse<null>;

    if (response.error) {
      this.micMuted = oldStatus;
      throw new AppError(response.error.message, response.error.code);
    }
  };

  /**
   * Send a message to remote peers.
   *
   * @param receivers Users to receive the message
   * @param contentType Content Type to be sent i.e. MESSAGE | VIDEO_FREEZE
   * @param content Content passed with the Content Type
   */
  private sendMessageToPeers = (receivers: string[], contentType: ContentType, content?: never): void => {
    this.signaling.requestPromise(SocketEvents.PEER_MESSAGE, {
      receivers,
      sender: this.userId,
      contentType,
      content,
    });
  };

  /**
   * Connects the Signaling object to the media server and listens for events
   *
   * @async
   * @param {() => void} successCallback Callback for when the Socket is successfully connected
   * @param {(Error) => void} errorCallback Callback for when the Socket connection fails
   * @returns {PromiseLike<void>}
   */
  private initSockets = async (
    successCallback: () => void,
    errorCallback: (error: AppError) => void,
    spaceId: number,
  ): Promise<void> => {
    let reconnectionAttempts = 0;
    this.signaling.connect(spaceId);

    if (this.signaling.socket !== undefined && this.signaling.socket !== null) {
      this.signaling.socket.on('connect', () => {
        logger.debug(`socket:connect()`);

        successCallback();
      });

      this.signaling.socket.on('connect_error', (error: Error) => {
        logger.debug(`socket:connect_error() | %o`, error);

        this.reconnectFlag = true;
        this.onUpdateReconnectFlag?.(this.reconnectFlag);
        reconnectionAttempts += 1;
        if (reconnectionAttempts >= 3) {
          errorCallback(new AppError(`Failed to establish connection to server`, ErrorCode.NETWORK));
        }
      });

      this.signaling.socket.on('disconnect', (reason: string) => {
        logger.debug(`socket:disconnect() | %s`, reason);

        this.exitSpace();
      });

      this.signaling.socket.on('reconnect_attempt', () => {
        logger.debug(`socket:reconnect_attempt()`);
      });

      this.signaling.socket.on('reconnect_failed', () => {
        logger.debug(`socket:reconnect_failed()`);

        errorCallback(new AppError(`Failed to establish connection to server`, ErrorCode.NETWORK_FAILED));
      });

      /**
       * Create consumers provided by the server.
       */
      this.signaling.socket.on(SocketEvents.CREATE_CONSUMERS, async (consumerRequests: CreateConsumerRequest[]) => {
        logger.debug(`socket:create_consumers() | [${consumerRequests.map((c) => c.producerId)}]`);

        this.consumeNewProducers(consumerRequests);
      });

      /**
       * Resume consumers for the provided producer ids.
       */
      this.signaling.socket.on(SocketEvents.RESUME_CONSUMER, (producerId: string) => {
        logger.debug(`socket:resume_consumer() | ${producerId}`);

        const consumer: Consumer | undefined = [...this.consumers.values()].find(
          (c: Consumer) => producerId === c.producerId,
        );

        if (consumer) {
          consumer.resume();
        }
      });

      /**
       * Remove consumers for the provided producer ids.
       */
      this.signaling.socket.on(SocketEvents.CLOSE_CONSUMERS, (producerIds: string[]) => {
        logger.debug(`socket:close_consumer() | ${JSON.stringify(producerIds)}`);

        try {
          const consumers: Consumer[] = [...this.consumers.values()]
            .filter((c: Consumer) => producerIds.includes(c.producerId))
            .map((v) => v);

          consumers.forEach((consumer: Consumer) => {
            consumer.close();
            this.consumers.delete(consumer.producerId);
            this.removeVideoStreamInterval(consumer.producerId);
            this.userStreams.delete(consumer.producerId);
          });
        } catch (error) {
          console.error(`Error removing consumers: ${error}`);
        }
      });

      this.signaling.socket.on(SocketEvents.CREATE_PRODUCERS, (producers: CreateProducerRequest[]) => {
        logger.debug(`socket:create_producer() | [${producers.map((p) => p.kind)}]`);

        try {
          producers.forEach((p: CreateProducerRequest) => {
            switch (p.kind) {
              case 'audio':
                this.createProducer(p.kind, this.micProducer, p.paused);
                break;

              case 'video':
                this.createProducer(p.kind, this.camProducer);
                break;

              case 'display':
                this.createProducer(p.kind, this.displayProducer);
                break;

              default:
                logger.info(`Unsupported producer type: ${p.kind}.`);
                break;
            }
          });
        } catch (error) {
          logger.error(error);
        }
      });

      /**
       * Resume producers for the provided producer ids.
       */
      this.signaling.socket.on(SocketEvents.RESUME_PRODUCERS, (producerIds: string[]) => {
        logger.debug(`socket:resume_producers()`);

        try {
          producerIds.forEach((id: string) => {
            if (this.micProducer && this.micProducer.id === id) {
              this.micProducer.resume();
            }
          });
        } catch (error) {
          console.error(`Error resuming producers.`, error);
        }
      });

      /**
       * Pause producers for the provided producer ids.
       */
      this.signaling.socket.on(SocketEvents.PAUSE_PRODUCERS, (producerIds: string[]) => {
        logger.debug(`socket:pause_producers()`);

        try {
          producerIds.forEach((id: string) => {
            if (this.micProducer && this.micProducer.id === id) {
              this.micProducer.pause();
            }
          });
        } catch (error) {
          console.error(`Error pausing producers.`, error);
        }
      });

      /**
       * Remove producers for the provided producer ids.
       */
      this.signaling.socket.on(SocketEvents.CLOSE_PRODUCERS, (producerIds: string[]) => {
        logger.debug(`socket:close_producers()`);

        try {
          producerIds.forEach((id: string) => {
            if (this.micProducer && this.micProducer.id === id) {
              this.removeProducer(this.micProducer);
            } else if (this.camProducer && this.camProducer.id === id) {
              this.removeProducer(this.camProducer);
            } else if (this.displayProducer && this.displayProducer.id === id) {
              this.removeProducer(this.displayProducer);
            }
          });
        } catch (error) {
          console.error(`Error closing producers.`, error);
        }
      });

      /**
       * Conference state updated.
       */
      this.signaling.socket.on(SocketEvents.CONFERENCE_UPDATE, (state: ConferenceState) => {
        this.onUpdateConferenceState?.(this.conferenceStateUpdated(state));
      });

      /**
       * User stream updated.
       */
      this.signaling.socket.on(SocketEvents.STREAM_UPDATE, (stream: StreamUpdate) => {
        this.onUpdateConferenceState?.(this.userStreamUpdated(stream));
      });

      /**
       * Conference transferred to another server.
       */
      this.signaling.socket.on(SocketEvents.CONFERENCE_TRANSFERRED, () => {
        logger.debug(`socket:conferenceStateTransferred()`);

        this.reconnectFlag = false;
        this.onUpdateReconnectFlag?.(this.reconnectFlag);
        this.savedState = this.saveState();
        this.exitSpace();
        this.disconnectUser();
        errorCallback(new AppError(ErrorMessage.CONFERENCE_TRANSFERED, ErrorCode.INTERNAL));
      });

      /**
       * Message received from a remote peer
       */
      /* eslint-disable @typescript-eslint/no-explicit-any */
      this.signaling.socket.on(
        SocketEvents.PEER_MESSAGE,
        (data: PeerMessage<any | { isConnectionScoreWeak: boolean }>) => {
          logger.debug(`socket:peer_message()`);

          switch (data.contentType) {
            case ContentType.VIDEO_FREEZE:
              this.resumeVideoStream();
              break;

            case ContentType.CONNECTION_SCORE: {
              const userId = data.sender;
              const { producerKind, isConnectionScoreWeak } = data.content as {
                producerKind: MediaKind;
                isConnectionScoreWeak: boolean;
              };

              if (userId && producerKind === 'video') {
                this.onUpdateUserConnectionScore?.(userId, isConnectionScoreWeak);
              }
              break;
            }
            default:
              logger.info(`Case ${data.contentType} is not catered for`);
              break;
          }
        },
      );
      /* eslint-enable @typescript-eslint/no-explicit-any */
    } else {
      logger.error('Handle Socket Not Connected Error');
    }
  };

  /**
   * Test if video is frozen or not.
   *
   * @param video Video to test for freeze
   * @returns True if video is frozen
   */
  private static isVideoFrozen = (video: UserStream): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (video as any).stream?.getVideoTracks()[0]?._track?.muted === true;
  };

  /**
   * Resume frozen local stream
   */
  private resumeVideoStream = (): void => {
    if (this.localVideoStream) {
      this.localVideoStream.getVideoTracks()[0].enabled = false;
      this.localVideoStream.getVideoTracks()[0].enabled = true;
    }
  };

  /**
   * Tests for video freezes.
   *
   * @param user User to which the stream belongs to
   * @param video Video stream to test for freezes
   */
  private testVideoStreamFreeze = (user: User, video: UserStream): void => {
    // wait to see if stream unfroze itself
    setTimeout(() => {
      const frozen = Conference.isVideoFrozen(video);

      // stream still muted, old frozen state not the same as new frozen state and user not on a break and not on image only
      if (
        frozen &&
        this.userStreams.get(video.producerId) !== frozen &&
        user.status !== 'Break' &&
        !user.imageOnlyMode
      ) {
        this.sendMessageToPeers([user.id], ContentType.VIDEO_FREEZE);
      }

      this.userStreams.set(video.producerId, frozen);
    }, 1000);
  };

  /**
   * Interval to test if a user's video is frozen.
   *
   * @param user User to which the stream belongs to
   * @param video Video stream to test for freezes
   */
  private createVideoStreamInterval = (user: User, video: UserStream): void => {
    if (!this.intervals.has(video.producerId)) {
      const interval = setInterval(() => {
        const frozen = Conference.isVideoFrozen(video);

        // stream was muted and user is not on a break
        if (frozen && user.status !== 'Break') {
          this.testVideoStreamFreeze(user, video);
        }
      }, 1000);

      this.intervals.set(video.producerId, interval);
    }
  };

  /**
   * Removes an interval stored for the specific producerID
   *
   * @param producerId Producer ID of the interval to remove
   */
  private removeVideoStreamInterval = (producerId: string): void => {
    const interval = this.intervals.get(producerId);
    if (interval) {
      clearInterval(interval);
      this.intervals.delete(producerId);
    }
  };

  private conferenceStateUpdated = (state: ConferenceState): ConferenceState => {
    this.serverState = state;
    const localUser: User | undefined = this.serverState.users.find((user: User) => {
      return user.id === this.userId;
    });

    if (this.savedState && localUser) {
      // save user's status
      localUser.status = this.savedState.myStatus;
      localUser.imageOnlyMode = this.savedState.myStillImageOnlyMode;
      localUser.micMuted = this.savedState.myMicMuted;

      if (localUser.status !== 'Break' && this.savedState.myConversation) {
        this.savedState.myConversation.peerIds.forEach(async (peerId: string) => {
          let conversation: Conversation | undefined;

          if (peerId !== localUser.id && !conversation) {
            // eslint-disable-next-line no-restricted-syntax
            for (const serverConversation of this.serverState.conversations) {
              if (serverConversation.peerIds.includes(peerId)) {
                conversation = serverConversation;
                break;
              }
            }

            if (conversation) {
              this.joinConversation(conversation.id);
            } else {
              this.startConversation(peerId);
            }
          }
        });
      }

      this.savedState = undefined;
    }
    return this.serverState;
  };

  private userStreamUpdated = (streamUpdate: StreamUpdate): ConferenceState => {
    const user: User | undefined = this.serverState.users.find((stateUser: User) => {
      return stateUser.id === streamUpdate.actor;
    });
    const localUser: User | undefined = this.serverState.users.find((stateUser: User) => {
      return stateUser.id === this.userId;
    });

    if (!user || !localUser) {
      return this.serverState;
    }

    switch (streamUpdate.type) {
      case StreamUpdateType.ADDED_STREAM:
        if (user.id === this.userId) {
          if (isAudioStream(streamUpdate.data) && this.localAudioStream) {
            streamUpdate.data.stream = this.localAudioStream;
          } else if (isVideoStream(streamUpdate.data) && this.localVideoStream) {
            streamUpdate.data.stream = this.localVideoStream;
          } else if (isDisplayStream(streamUpdate.data) && this.localDisplayStream) {
            streamUpdate.data.stream = this.localDisplayStream;
          }
        }
        user.streams.push(streamUpdate.data);
        break;

      case StreamUpdateType.CHANGED_STREAM_STATUS:
        break;
      default:
        break;
    }

    return this.serverState;
  };

  private saveState = (): ConferenceSavedState => {
    const myConversation = this.serverState.conversations.find((conversation: Conversation) => {
      return conversation.peerIds.includes(this.userId);
    });

    return {
      myStatus: this.userStatus,
      myStillImageOnlyMode: this.stillImageOnlyMode,
      mySharingDisplay: this.sharingDisplay,
      myMicMuted: this.micMuted,
      myConversation,
    };
  };

  /**
   * Consumes the new producers and set the user's stream
   *
   * @param producers Producers which should be consumed
   * @returns {PromiseLike<void>}
   */
  private consumeNewProducers = async (producers: CreateConsumerRequest[]): Promise<void> => {
    logger.debug(`consumerNewProducers()`);

    try {
      const consumers = await this.consumeAll(producers);

      consumers.forEach((consumer: Consumer | undefined) => {
        if (!consumer) return;

        this.consumers.set(consumer.producerId, consumer);

        consumer.on('trackended', () => {
          if (!consumer.closed) {
            consumer.close();
          }
          this.consumers.delete(consumer.producerId);
          this.removeVideoStreamInterval(consumer.producerId);
          this.userStreams.delete(consumer.producerId);
        });

        consumer.on('transportclose', () => {
          if (!consumer.closed) {
            consumer.close();
          }
          this.consumers.delete(consumer.producerId);
          this.removeVideoStreamInterval(consumer.producerId);
          this.userStreams.delete(consumer.producerId);
        });
      });

      this.serverState.users.forEach((user: User) => {
        user.streams.forEach((userStream: UserStream) => {
          if (this.consumers.has(userStream.producerId)) {
            const consumer = this.consumers.get(userStream.producerId);
            const stream = new MediaStream();
            if (consumer?.track) stream.addTrack(consumer.track);
            userStream.stream = stream;

            // if stream is video create intervals to check the state of the video
            if (userStream.kind === 'video') {
              this.userStreams.set(userStream.producerId, false);
              this.createVideoStreamInterval(user, userStream);
            }
          }
        });
      });

      this.onUpdateConferenceState?.(this.serverState);
    } catch (error) {
      console.error(`Error consuming new producers: ${error}`);
    }
  };

  /**
   * Consumes all the Producers and returns when all of them finished.
   *
   * @param {CreateConsumerRequest[]} list Producer list that should be consumed
   * @returns {PromiseLike<Consumer[]>}
   */
  private consumeAll = (list: CreateConsumerRequest[]): Promise<(Consumer | undefined)[]> => {
    const promises: Promise<Consumer | undefined>[] = [];

    if (this.device && this.consumerTransport) {
      const { device, consumerTransport } = this;
      list.forEach((producer) => {
        promises.push(consume(this.signaling, device, consumerTransport, producer));
      });
    }

    return Promise.all(promises);
  };

  private createProducer = async (
    kind: 'video' | 'display' | 'audio',
    producer: Producer | null,
    paused = false,
  ): Promise<void> => {
    logger.debug(`createProducer()`);

    if (producer) {
      if (producer.kind === 'audio' && producer.paused && !paused) {
        producer.resume();
      }
      return;
    }

    if (this.device && !this.device.canProduce(kind === 'display' ? 'video' : kind)) {
      throw new Error(`Cannot produce ${kind}`);
    }

    if (this.producerTransport) {
      try {
        let track: MediaStreamTrack;
        let createdProducer: Producer | undefined;

        if (kind === 'video') {
          if (!this.localVideoStream || this.localVideoStream.getVideoTracks()[0].readyState === 'ended') {
            this.localVideoStream = await loadUserVideoStream();
          }
          [track] = this.localVideoStream.getVideoTracks();
          this.camProducer = await produce(track, this.producerTransport);
          createdProducer = this.camProducer;
        } else if (kind === 'display') {
          if (!this.localDisplayStream || this.localDisplayStream.getVideoTracks()[0].readyState === 'ended') {
            this.localDisplayStream = await loadUserDisplayStream();
          }
          [track] = this.localDisplayStream.getVideoTracks();

          track.addEventListener('ended', () => {
            console.log(`screen sharing stopped`);
            this.toggleDisplay(false);
          });

          this.displayProducer = await produce(track, this.producerTransport, true);
          createdProducer = this.displayProducer;
        } else {
          if (!this.localAudioStream || this.localAudioStream.getAudioTracks()[0].readyState === 'ended') {
            this.localAudioStream = (await loadUserAudioStream()) || null;
          }

          if (this.localAudioStream) {
            [track] = this.localAudioStream.getAudioTracks();
            this.micProducer = await produce(track, this.producerTransport);
            createdProducer = this.micProducer;
          }
        }

        createdProducer?.on('transportclose', () => this.removeProducer(createdProducer || null));
        createdProducer?.on('trackended', () => this.removeProducer(createdProducer || null));
      } catch (error) {
        logger.error(`Error creating producer [%o]`, error);
      }
    }
  };

  private removeProducer = (producer: Producer | null): void => {
    logger.debug(`removeProducer()`);

    if (!producer) {
      return;
    }

    if (producer.kind === 'video' && !producer.appData?.display) {
      this.camProducer = null;
      cleanupUserVideoStream();
      this.localVideoStream = null;
    } else if (producer.kind === 'video' && producer.appData?.display) {
      this.displayProducer = null;
      cleanupUserDisplayStream();
      this.localDisplayStream = null;
    } else {
      this.micProducer = null;
      cleanupUserAudioStream();
      this.localAudioStream = null;
    }

    producer.close();
  };
}

export default Conference;
