import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useContext } from 'react';
import { LocalizationContext } from 'src/App';
import { formatString } from 'src/services/string/stringService';

/**
 * Hook to use the localized string from the dictionary
 * @param keys The keys corresponding to the localized string values
 * @returns An array of all the localized strings
 */
export const useLocalizedString = (...keys: (DictionaryKeyType | undefined)[]): string[] => {
  const dictionary = useContext(LocalizationContext);
  return keys.map((key) => (!key ? '' : dictionary[key] ?? ''));
};

export const useLocalizedKey = (key: DictionaryKeyType | undefined, ...params: string[]): string => {
  const dictionary = useContext(LocalizationContext);
  if (!key) return '';
  const value = dictionary[key] ?? '';
  return formatString(value, ...params);
};

export const useLocalizedValue = (text: string | undefined, key: DictionaryKeyType | undefined): string | undefined => {
  const dictionary = useContext(LocalizationContext);
  return key !== undefined ? dictionary[key] ?? '' : text;
};
