import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import UHText from 'src/views/components/core/text/UHText';

type Props = {
  questionKey: DictionaryKeyType;
  answerKey: DictionaryKeyType;
};

const QuestionAnswer: React.FC<Props> = ({ questionKey, answerKey }) => {
  return (
    <div className="flex flex-col space-y-0">
      <UHText tag="p" className="text-white m-0 text-neutral italic" textKey={questionKey} />
      <UHText tag="p" className="text-white" textKey={answerKey} />
    </div>
  );
};

export default QuestionAnswer;
