import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

// Fields

const optionalString = Yup.string() as Yup.StringSchema<string, AnyObject, string>;
const optionalStringMaxLength = optionalString.max(100, 'SHARED_TEXT_INVALID');
const requiredString = Yup.string().required('SHARED_TEXT_REQUIRED');
const requiredStringMaxMinLength = requiredString.min(2, 'SHARED_TEXT_INVALID').max(100, 'SHARED_TEXT_INVALID');
const email = requiredString.email('SHARED_TEXT_INVALID');
const password = requiredString
  .min(8, 'AUTH_SIGNUP_VALIDATION_LENGTH')
  .max(99, 'SHARED_TEXT_INVALID')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/g,
    'AUTH_SIGNUP_VALIDATION_CHARACTERS',
  );
const confirmPassword = requiredString.oneOf([Yup.ref('password'), null], 'SHARED_TEXT_INVALID');
const resetPasswordCode = requiredString.matches(/^\d+$/, 'SHARED_TEXT_INVALID').length(6, 'SHARED_TEXT_INVALID');

// Schemas

// Auth

export type SignUpFormData = {
  email: string;
  password: string;
  confirmPassword: string;
};

export const signUpSchema: Yup.SchemaOf<SignUpFormData> = Yup.object({
  email,
  password,
  confirmPassword,
});

export type LoginFormData = {
  email: string;
  password: string;
};

export const loginSchema: Yup.SchemaOf<LoginFormData> = Yup.object({
  email: requiredString,
  password: requiredString,
});

export type ResetPasswordEmailData = {
  email: string;
};

export const forgotPasswordEmailSchema: Yup.SchemaOf<ResetPasswordEmailData> = Yup.object({
  email,
});

export type ResetPasswordCodeData = {
  resetPasswordCode: string;
  password: string;
  confirmPassword: string;
};

export const resetPasswordCodeSchema: Yup.SchemaOf<ResetPasswordCodeData> = Yup.object({
  resetPasswordCode,
  password,
  confirmPassword,
});

export type ChangePasswordData = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

export const changePasswordSchema: Yup.SchemaOf<ChangePasswordData> = Yup.object({
  oldPassword: requiredString,
  password,
  confirmPassword,
});

// User

export type OnboardingNameData = {
  firstName: string;
  lastName: string;
  displayName: string;
};

export const onboardingNameSchema: Yup.SchemaOf<OnboardingNameData> = Yup.object({
  firstName: requiredStringMaxMinLength,
  lastName: requiredStringMaxMinLength,
  displayName: requiredStringMaxMinLength,
});

export type NameData = {
  firstName: string;
  lastName: string;
};

export const nameSchema: Yup.SchemaOf<NameData> = Yup.object({
  firstName: requiredStringMaxMinLength,
  lastName: requiredStringMaxMinLength,
});

export type DisplayNameData = {
  displayName: string;
};

export const displayNameSchema: Yup.SchemaOf<DisplayNameData> = Yup.object({
  displayName: requiredStringMaxMinLength,
});

// Space

export type SpaceNameData = {
  name: string;
  description: string;
};

export const spaceNameSchema: Yup.SchemaOf<SpaceNameData> = Yup.object({
  name: requiredStringMaxMinLength,
  description: optionalStringMaxLength,
});
