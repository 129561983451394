import clsx from 'clsx';
import { FormikContext } from 'formik';
import React, { useContext } from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedKey, useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import { useOverlayCardWidth } from 'src/views/hooks/views/useOverlayCardWidth';
import FormikButton from '../core/buttons/FormikButton';
import UHButton from '../core/buttons/UHButton';
import UHText from '../core/text/UHText';

export type FormButtonProps = React.HTMLAttributes<HTMLDivElement> & {
  cancelText?: string;
  cancelTextKey?: DictionaryKeyType;
  submitText?: string;
  submitTextKey?: DictionaryKeyType;
  onCancel?: () => void;
  onSubmit?: () => void;
  loading?: boolean;
  variant?: 'default' | 'danger';
};

const FormButtons: React.FC<FormButtonProps> = ({
  cancelText,
  cancelTextKey,
  submitText,
  submitTextKey,
  onCancel,
  onSubmit,
  loading = false,
  variant = 'default',
  className,
  ...htmlProps
}) => {
  const defaulCancelText = useLocalizedKey('SHARED_BUTTON_BACK');
  const defaulSubmitText = useLocalizedKey('SHARED_BUTTON_CONFIRM');
  const localizedCancelText = useLocalizedValue(cancelText, cancelTextKey) ?? defaulCancelText;
  const localizedSubmitText = useLocalizedValue(submitText, submitTextKey) ?? defaulSubmitText;
  const width = useOverlayCardWidth();
  const formikContext = useContext(FormikContext);
  return (
    <div
      className={clsx(
        'flex items-center justify-center',
        { 'flex-col': width === 'sm', 'self-end': width !== 'sm' },
        className,
      )}
      {...htmlProps}
    >
      {/* Submit */}
      {formikContext && (
        <FormikButton
          className={clsx('min-w-max', { 'order-1': width !== 'sm' })}
          title={localizedSubmitText}
          loading={loading}
        />
      )}
      {!formikContext && (
        <UHButton
          className={clsx('min-w-max', { 'order-1': width !== 'sm' })}
          title={localizedSubmitText}
          onClick={onSubmit}
          variant={onSubmit ? variant : 'disabled'}
          loading={loading}
        />
      )}
      {/* Cancel */}
      {localizedCancelText && onCancel && (
        <UHText
          className={clsx({
            'mt-4': width === 'sm',
            'mr-8': width !== 'sm',
            'text-primary': !loading,
            'text-neutral-300': loading,
          })}
          text={localizedCancelText}
          onClick={onCancel}
        />
      )}
    </div>
  );
};

export default FormButtons;
