export const isLocalhost = location.hostname === 'localhost' || location.hostname === '127.0.0.1';

export const env = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  AWSRegion: process.env.REACT_APP_AWS_REGION,
  AWSCognitoUserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  AWSCognitoUserPoolWebclientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEBCLIENT_ID,
  AWSAPIName: process.env.REACT_APP_API_NAME,
  AWSAPIEndpoint: process.env.REACT_APP_API_ENDPOINT,
  ConferenceEndpoint: process.env.REACT_APP_CONFERENCE_ENDPOINT ?? '',
  DebugStreams: false,
  LoginOnLandingPage: process.env.REACT_APP_LOGIN_ON_LANDING_PAGE === '1',
  ConferencingEnabled: process.env.REACT_APP_CONFERENCING_ENABLED === '1',
  AnalyticsTrackingID: process.env.REACT_APP_ANALYTICS_TRACKING_ID ?? '',
  ImageCDN: process.env.REACT_APP_IMAGE_CDN ?? '',
};
