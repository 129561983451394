import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { MemberRole } from 'src/types/spaces/SpaceType';

export const memberRoleLocalizationKeys: Record<MemberRole, DictionaryKeyType | undefined> = {
  admin: 'SHARED_ROLE_ADMIN',
  invited: 'SHARED_ROLE_INVITED',
  member: 'SHARED_ROLE_MEMBER',
  owner: 'SHARED_ROLE_OWNER',
  none: undefined,
};
