import React, { useEffect } from 'react';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import { ArticleCard } from './components/ArticleCard';
import { actions, useTypedSelector } from 'src/store/store';
import { BlogEntry } from 'src/types/blog/blogTypes';
import FadeInUpScroll from 'src/views/components/animations/FadeInUpScroll';
import { TwitterSection } from '../Landing/components/TwitterSection';

const BlogScreen: React.FC = () => {
  const history = useUHHistory();
  const blogState = useTypedSelector((state) => state.blog);
  const loaded = blogState.loadingState === 'loaded';

  const languageCode = useTypedSelector((state) => state.localization.currentWebpageLanguageCode);

  const goToArticle = (article: BlogEntry) => {
    history.pushPage('article', undefined, { articleId: article.slug });
  };

  useEffect(() => {
    if (!loaded) actions.fetchBlog();
  }, []);

  return (
    <main className="bg-card">
      <div className="page-width relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-8 items-center pt-16 mb-16 md:items-start">
        {loaded &&
          blogState.blogEntries.map((e) => <ArticleCard key={e.id} onClick={() => goToArticle(e)} article={e} />)}
      </div>
      {/* Twitter Section */}
      <FadeInUpScroll>
        <TwitterSection languageCode={languageCode} />
      </FadeInUpScroll>
    </main>
  );
};

export default BlogScreen;
