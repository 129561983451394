import { getReadAnnouncementIds, markAnnouncementAsRead } from 'src/services/announcements/announcementService';
import { Announcement } from 'src/services/announcements/AnnouncementType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAnnouncementsThunk } from './thunk';
import { AnnouncementsState } from './types';

export const initialState: AnnouncementsState = {
  announcements: [],
  readAnnouncementIds: getReadAnnouncementIds(),
};

const announcementSlice = createSlice({
  name: '@@announcements',
  initialState,
  reducers: {
    markAnnouncementAsReadAction: (state, action: PayloadAction<Announcement>) => {
      markAnnouncementAsRead(action.payload);
      state.readAnnouncementIds = getReadAnnouncementIds();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnnouncementsThunk.fulfilled, (state, action) => {
      state.announcements = action.payload;
      state.readAnnouncementIds = getReadAnnouncementIds();
    });
  },
});

export const { markAnnouncementAsReadAction } = announcementSlice.actions;

export const announcementsReducer = announcementSlice.reducer;
