import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { AppContext } from 'src/App';
import { openLink } from 'src/services/url/linkService';
import { actions, useTypedSelector } from 'src/store/store';
import { UpdatableUserSettings } from 'src/types/users/userType';
import UHButton from 'src/views/components/core/buttons/UHButton';
import UHCard from 'src/views/components/core/containers/UHCard';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import SelectionDropdown from 'src/views/components/core/inputs/SelectionDropdown';
import SingleSelectionDropdown from 'src/views/components/core/inputs/SingleSelectionDropdown';
import UHCheckbox from 'src/views/components/core/inputs/UHCheckbox';
import UHArrowLink from 'src/views/components/core/text/UHArrowLink';
import UHText, { UHTextProps } from 'src/views/components/core/text/UHText';
import Absolute from 'src/views/components/core/transform/Absolute';
import Center from 'src/views/components/core/transform/Center';
import CardGrid from 'src/views/components/layout/CardGrid';
import PageContainer from 'src/views/components/layout/PageContainer';
import ProfileIcon from 'src/views/components/profile/ProfileIcon';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import ChangePasswordForm from './components/ChangePasswordForm';
import DisplayNameForm from './components/DisplayNameForm';
import NameForm from './components/NameForm';
import UploadImageForm from './components/UploadImageForm';

type SettingsState = 'default' | 'editName' | 'editDisplayName' | 'changePassword' | 'changeAvatar';

const SettingsScreen: React.FC = () => {
  const history = useUHHistory();
  const { setConfirmData } = useContext(AppContext);

  const user = useTypedSelector((state) => state.user.user);
  const [userSettings, setUserSettings] = useState<UpdatableUserSettings>({
    displayName: user.displayName,
    firstName: user.firstName,
    lastName: user.lastName,
    emailNotifications: user.emailNotifications,
    locationCode: user.locationCode,
    languageCode: user.languageCode,
    pushNotificationsSpaceInvite: user.pushNotificationsSpaceInvite,
    pushNotificationsSpaceEvents: user.pushNotificationsSpaceEvents,
  });

  const languages = useTypedSelector((state) => state.localization.languages);
  const languageKeys = Object.keys(languages);

  const [state, setState] = useState<SettingsState>('default');

  // Sign out
  const onSignOut = () => actions.signOut();

  // Delete
  const onDeleteAccount = () => actions.deleteAccount();

  const onTryDeleteAccount = () => {
    setConfirmData({
      headerTextKey: 'SETTINGS_DELETE_ACCOUNT_CONFIRM',
      bodyTextKey: 'SETTINGS_DELETE_ACCOUNT_DESCRIPTION',
      confirmAction: onDeleteAccount,
      variant: 'danger',
    });
  };

  // Modals
  const onEditName = () => {
    setState('editName');
  };

  const onEditDisplayName = () => {
    setState('editDisplayName');
  };

  const onChangePassword = () => {
    setState('changePassword');
  };

  const onChangeAvatar = () => {
    setState('changeAvatar');
  };

  const onCloseModal = () => {
    setState('default');
  };

  // Update settings data
  const updateData = (updates: Partial<UpdatableUserSettings>) => {
    const updatedSettigns = { ...userSettings, ...updates };
    setUserSettings(updatedSettigns);
    actions.updateUserSettings(updatedSettigns);
  };

  const onChangedEmailNotifications = (on: boolean) => {
    updateData({ emailNotifications: on });
  };

  const onChangedLanguage = (key: string) => {
    console.log('Language', key);
    updateData({ languageCode: key });
  };

  const onRemoveAvatar = () => {
    actions.removeAvatar();
  };

  const hasAvatar = !!user.avatar;

  // Profile image dropdown
  const dropdownOptions: UHTextProps[] = [
    { textKey: 'SHARED_BUTTON_UPLOAD_IMAGE', onClick: onChangeAvatar },
    { textKey: 'SHARED_BUTTON_REMOVE', onClick: onRemoveAvatar, className: 'group-hover:text-error' },
  ];

  // Edit dropdown
  const [avatarDropdownOpen, setAvatarDropdownOpen] = useState(false);

  return (
    <PageContainer width="sm" titleKey="PROFILE_OPTIONS_SETTINGS" icon="cog">
      <CardGrid maxCols={1}>
        {/* Account */}
        <UHCard className="pb-8 pt-6 px-4">
          <div className="flex flex-row items-start justify-between">
            <div>
              <UHText textKey="SHARED_TITLE_ACCOUNT" variant="title" className="mb-3 text-primary" />
              <div className="flex flex-col space-y-3">
                {/* Email */}
                <div>
                  <UHText variant="body-xs" textKey="SHARED_TEXT_EMAIL" className="text-neutral" />
                  <UHText text={user.email} className="text-neutral" />
                </div>
                {/* Name */}
                <div>
                  <UHText variant="body-xs" textKey="PROFILE_SETTINGS_TITLE_NAME" className="text-neutral" />
                  <div className="flex items-center space-x-2">
                    <UHText text={`${userSettings.firstName} ${userSettings.lastName}`} />
                    <UHIcon className="-my-2 text-neutral text-lg" icon="pencil" onClick={onEditName} />
                  </div>
                </div>
                {/* Display name */}
                <div>
                  <UHText
                    variant="body-xs"
                    textKey="PROFILE_SETTINGS_PLACEHOLDER_DISPLAY_NAME"
                    className="text-neutral"
                  />
                  <div className="flex items-center space-x-2">
                    <UHText text={userSettings.displayName} />
                    <UHIcon className="-my-2 text-neutral text-lg" icon="pencil" onClick={onEditDisplayName} />
                  </div>
                </div>
              </div>
            </div>
            {/* Avatar */}
            <div className="transitionfix relative">
              {/* Profile Icon */}
              <ProfileIcon showName={false} size="large" />
              {/* Edit overlay */}
              <Absolute
                center
                className={clsx(
                  'rounded-full hover:opacity-100 cursor-pointer overflow-hidden transition-opacity duration-200',
                  { 'opacity-0': !avatarDropdownOpen },
                )}
                onClick={hasAvatar ? () => setAvatarDropdownOpen(true) : onChangeAvatar}
              >
                <Absolute className="absolute z-0 bg-black opacity-60" />
                <UHIcon className="absolute text-typoInverted text-xl" icon="pencil" />
              </Absolute>
              {/* Edit dropdown */}
              <SelectionDropdown
                className="right-0 origin-top-right"
                dropdownOpen={avatarDropdownOpen}
                setDropdownOpen={setAvatarDropdownOpen}
                options={dropdownOptions}
              />
              {!user.avatar && (
                <Absolute
                  center
                  size={0.32}
                  position={{ x: 0.82, y: 0.82 }}
                  className="bg-primary rounded-full pointer-events-none ring-2 ring-background"
                >
                  <UHIcon className="text-typoInverted text-xs" icon="pencil" />
                </Absolute>
              )}
            </div>
          </div>
          <UHButton
            className="mt-5 w-full"
            width="auto"
            height="sm"
            variant="secondary"
            titleKey="CHANGE_PASSWORD_TITLE"
            onClick={onChangePassword}
          />
          <UHButton
            height="sm"
            className="mt-5 w-full"
            width="auto"
            variant="danger"
            titleKey="SHARED_BUTTON_SIGNOUT"
            onClick={onSignOut}
          />
        </UHCard>
        {/* Notifications */}
        <UHCard className="pb-8 pt-6 px-4">
          <UHText textKey="PROFILE_SETTINGS_NOTIFICATIONS" variant="title" className="mb-4 text-primary" />
          {/* Checkbox */}
          <UHCheckbox
            size="sm"
            selected={userSettings.emailNotifications}
            setSelected={onChangedEmailNotifications}
            textProps={{ textKey: 'PROFILE_SETTINGS_EMAIL_NOTIFICATIONS' }}
          />
        </UHCard>
        {/* Language */}
        <UHCard className="pb-8 pt-6 px-4">
          <UHText textKey="PROFILE_SETTINGS_LABEL_LANGUAGE" variant="title" className="mb-4 text-primary" />
          {/* Language dropdown */}
          <div className="relative">
            <SingleSelectionDropdown
              buttonProps={{
                title: languages[userSettings.languageCode],
                infoTitleKey: 'PROFILE_SETTINGS_LABEL_LANGUAGE',
              }}
              options={languageKeys.map((k) => languages[k])}
              value={languageKeys.indexOf(userSettings.languageCode)}
              onChangedValue={(index) => onChangedLanguage(languageKeys[index])}
            />
          </div>
        </UHCard>
        {/* Terms, Privacy Policy, Imprint */}
        <UHCard className="pb-8 pt-6 px-4">
          <UHText textKey="TERMS_OF_USE_TITLE" variant="title" className="mb-4 text-primary" />
          <UHArrowLink textKey="TERMS_OF_USE_TITLE" onClick={() => openLink('/terms', history, true)} />
          <UHArrowLink textKey="PRIVACY_POLICY_TITLE" onClick={() => openLink('/privacypolicy', history, true)} />
          <UHArrowLink textKey="IMPRESSUM_TITLE" onClick={() => openLink('/impressum', history, true)} />
        </UHCard>
        {/* Delete account */}
        <Center>
          <UHText className="text-error" textKey="SETTINGS_DELETE_ACCOUNT" onClick={onTryDeleteAccount} />
        </Center>
      </CardGrid>
      {/* Name Form */}
      <NameForm show={state === 'editName'} data={userSettings} onChangedData={updateData} onClose={onCloseModal} />
      {/* Display name Form */}
      <DisplayNameForm
        show={state === 'editDisplayName'}
        data={userSettings}
        onChangedData={updateData}
        onClose={onCloseModal}
      />
      {/* Change password Form */}
      <ChangePasswordForm show={state === 'changePassword'} onClose={onCloseModal} />
      {/* Upload Image Form */}
      <UploadImageForm show={state === 'changeAvatar'} uploadType="avatar" onClose={onCloseModal} />
    </PageContainer>
  );
};

export default SettingsScreen;
