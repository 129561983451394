import clsx from 'clsx';
import React from 'react';
import Tag, { TagProps } from './Tag';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  tags: TagProps[];
};

const TagSelection: React.FC<Props> = ({ tags, className, ...htmlProps }) => {
  return (
    <div className={clsx('flex flex-wrap items-start', className)} {...htmlProps}>
      {tags.map((v, index) => {
        return <Tag className="mb-1.5 mr-1.5 max-w-full" key={index} {...v} />;
      })}
    </div>
  );
};

export default TagSelection;
