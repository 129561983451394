import React from 'react';
import { NameData, nameSchema } from 'src/services/forms/schemaService';
import OverlayCard, { OverlayCardProps } from 'src/views/components/core/containers/OverlayCard';
import FormikTextField from 'src/views/components/core/inputs/FormikTextField';
import UHForm from 'src/views/components/form/UHForm';

type Props = OverlayCardProps & {
  data: NameData;
  onChangedData: (data: NameData) => void;
};

const NameForm: React.FC<Props> = ({ data, onChangedData, width = 'sm', ...htmlProps }) => {
  const onSubmit = (data: NameData) => {
    onChangedData(data);
    htmlProps.onClose?.();
  };

  return (
    <OverlayCard width={width} titleKey="PROFILE_SETTINGS_TITLE_NAME" {...htmlProps}>
      {/* Form */}
      <UHForm<NameData> data={data} schema={nameSchema} onSubmit={onSubmit} onCancel={htmlProps.onClose}>
        <FormikTextField name="firstName" labelKey="PROFILE_SETTINGS_PLACEHOLDER_FIRST_NAME" />
        <FormikTextField name="lastName" labelKey="PROFILE_SETTINGS_PLACEHOLDER_LAST_NAME" submitOnEnter />
      </UHForm>
    </OverlayCard>
  );
};

export default NameForm;
