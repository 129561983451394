import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import FadeInUpScroll from 'src/views/components/animations/FadeInUpScroll';
import UHImage from 'src/views/components/core/image/UHImage';
import UHText from 'src/views/components/core/text/UHText';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';

type Props = {
  imageSrc: string;
  title?: string;
  titleKey?: DictionaryKeyType;
  text?: string;
  textKey?: DictionaryKeyType;
  animationOffset?: number;
  imageHeight?: number;
  imageBottomSpace?: number;
  className?: string;
};

const ClaimSectionV2: React.FC<Props> = ({
  imageSrc,
  title,
  titleKey,
  text,
  textKey,
  animationOffset = 0,
  imageHeight = 200,
  imageBottomSpace,
  className,
}) => {
  const localizedTitle = useLocalizedValue(title, titleKey);
  const localizedText = useLocalizedValue(text, textKey);
  return (
    <div className={clsx('flex flex-col items-center px-4', className)}>
      <FadeInUpScroll bottomOffset={animationOffset} distance={Math.max(200 - animationOffset * 0.1, 100)}>
        <UHImage fillType="contain" src={imageSrc} style={{ height: imageHeight, marginBottom: imageBottomSpace }} />
      </FadeInUpScroll>
      <FadeInUpScroll
        className="flex flex-col"
        bottomOffset={animationOffset + 80}
        distance={Math.max(200 - animationOffset * 0.1, 100)}
      >
        {localizedTitle && (
          <UHText
            tag="h1"
            variant="title"
            className="mt-8 text-center text-primary-700 font-semibold"
            text={localizedTitle}
          />
        )}
        <UHText tag="p" variant="body-lg" className="mt-2 text-center" text={localizedText} />
      </FadeInUpScroll>
    </div>
  );
};

export default ClaimSectionV2;
