import clsx from 'clsx';
import React from 'react';

export type UHIconProps = React.HTMLAttributes<HTMLDivElement> & {
  icon: string;
};

const UHIcon: React.FC<UHIconProps> = ({ icon, className, ...htmlProps }) => {
  // Icon class depending on prefix
  // const iconNameSplit = icon.split('-');
  // let iconClass = `mdi mdi-${icon}`;
  // if (iconNameSplit[0] === 'fas') iconClass = `fa-solid fa-${iconNameSplit.slice(1).join('-')}`;
  const iconClass = `fa-solid fa-${icon}`;

  return (
    <div
      className={clsx(iconClass, className, {
        'cursor-pointer flex items-center justify-center': !!htmlProps.onClick,
      })}
      {...htmlProps}
    ></div>
  );
};

export default UHIcon;
