import { useEffect, useState } from 'react';
import {
  AudioInput,
  availableAudioDevices,
  availableVideoDevices,
  getAvailableAudioInputs,
  getAvailableVideoInputs,
  VideoInput,
} from 'src/services/mediaStreams/userStreamService';
import {
  hasMediaPermissions,
  removePermissionStream,
  requestPermissions,
} from 'src/services/permission/permissionService';
import MediaDevices from 'media-devices';

export const useAvailableInputDevices = (): {
  cameras: VideoInput[];
  microphones: AudioInput[];
  isLoadingPermissions: boolean;
} => {
  const [cameras, setCameras] = useState(availableVideoDevices());
  const [microphones, setMicrophones] = useState(availableAudioDevices());

  const [isLoadingPermissions, setIsLoadingPermissions] = useState(!hasMediaPermissions());

  const loadAvailableDevices = async (): Promise<void> => {
    setCameras(await getAvailableVideoInputs());
    setMicrophones(await getAvailableAudioInputs());
  };

  useEffect(() => {
    /** Media device connected or disconnected */
    MediaDevices.ondevicechange = ({ changes, devices }) => {
      console.log('Changed devices');
      console.log(changes);
      console.log(devices);
      loadAvailableDevices();
    };

    if (hasMediaPermissions()) return;
    requestPermissions(async () => {
      await loadAvailableDevices();
      setIsLoadingPermissions(false);
      removePermissionStream();
    });
  }, []);

  return { cameras, microphones, isLoadingPermissions };
};
