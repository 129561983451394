import { Subscription } from '../subscriptions/subscriptionService';
import personalSubscriptionData from './personalSubscriptions.json';
import crewSubscriptionData from './crewSubscriptions.json';
import { StorageService } from '../storage/StorageService';
import { fetchDeviceCurrentLocation, fetchProductsData, fetchStaticData } from '../api/staticDataAPI';

export type StaticData = {
  personalSubscriptions: Subscription[];
  crewSubscriptions: Subscription[];
  config: {
    subscriptionsEnabled: boolean;
    maintenance: boolean;
  };
};

const staticData: StaticData = {
  personalSubscriptions: personalSubscriptionData as Subscription[],
  crewSubscriptions: crewSubscriptionData as Subscription[],
  config: {
    subscriptionsEnabled: true,
    maintenance: false,
  },
};

const loadedLocalStaticData: StaticData | undefined = StorageService.getItem('staticData');
let localStaticData: StaticData = loadedLocalStaticData ? { ...staticData, ...loadedLocalStaticData } : staticData;

export const getStaticData = (): StaticData => {
  return localStaticData;
};

export const updateStaticData = (data: StaticData): void => {
  StorageService.setItem('staticData', data);
  localStaticData = { ...staticData, ...data };
};

export const refreshStaticData = async (): Promise<void> => {
  fetchDeviceCurrentLocation();
  const fetchedData = await fetchStaticData();
  updateStaticData(fetchedData);
};

export const loadProductsData = async (): Promise<void> => {
  try {
    const fetchedData = await fetchProductsData();
    updateStaticData(fetchedData);
  } catch (error) {}
};
