import clsx from 'clsx';
import React from 'react';
import { getUserInitials, UserInitialsType } from 'src/services/contacts/userProfileService';
import { env } from 'src/services/environment/envService';
import UHImage from '../core/image/UHImage';
import UHText from '../core/text/UHText';
import Center from '../core/transform/Center';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  user: UserInitialsType & { avatar?: string };
};

const Avatar: React.FC<Props> = ({ user, className, ...htmlProps }) => {
  const initials = getUserInitials(user);

  return (
    <Center className={clsx('bg-secondary rounded-full pointer-events-none', className)} {...htmlProps}>
      {user.avatar && (
        <UHImage
          src={`${env.ImageCDN}/${user.avatar}-xs`}
          className="transitionfix w-full h-full bg-primary rounded-full overflow-hidden"
        />
      )}
      {!user.avatar && <UHText className="text-typoInverted select-none" variant="title-xl" text={initials} />}
    </Center>
  );
};

export default Avatar;
