import React, { useEffect, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  initialScale?: number;
  scale?: number;
  delay?: number;
  speed?: 'default' | 'slow' | 'fast';
}

const Scale: React.FC<Props> = ({
  delay = 0,
  initialScale = 0,
  scale = 1,
  speed = 'default',
  children,
  style,
  ...htmlProps
}) => {
  let springConfig = { mass: 1, tension: 400, friction: 100 };
  if (speed === 'slow') springConfig = { mass: 1, tension: 200, friction: 100 };
  if (speed === 'fast') springConfig = { mass: 1, tension: 800, friction: 100 };
  const [targetScale, setTargetScale] = useState(initialScale);
  const spring = useSpring({
    transform: `scale(${targetScale})`,
    config: springConfig,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTargetScale(scale);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [scale]);

  return (
    <animated.div
      {...(htmlProps as Record<string, unknown>)}
      style={{ ...style, ...(spring as Record<string, unknown>) }}
    >
      {children}
    </animated.div>
  );
};

export default Scale;
