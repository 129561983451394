import axios from 'axios';
import { UHAPI } from '.';
import { StaticData } from '../staticData/staticDataService';
import { StorageService } from '../storage/StorageService';

export const fetchStaticData = async (): Promise<StaticData> => {
  const data = await UHAPI.get<StaticData>({ path: `/public/static` });
  const loadedConfig = (data.config ?? {}) as unknown as Record<string, unknown>;
  data.config = {
    subscriptionsEnabled:
      loadedConfig.APP_SUBSCRIPTIONS_ENABLED === '1' || loadedConfig.APP_SUBSCRIPTIONS_ENABLED === true,
    maintenance: loadedConfig.APP_MAINTENANCE === '1' || loadedConfig.APP_MAINTENANCE === true,
  };
  return data;
};

/**
 * Fetch subscription data with price information
 * @returns A promise resolving to Static data object
 */
export const fetchProductsData = async (): Promise<StaticData> => {
  return UHAPI.get<StaticData>({ path: `/public/products` });
};

/**
 * Find the country code of the device's current location.
 */
export const fetchDeviceCurrentLocation = async (): Promise<void> => {
  try {
    const response = await axios.get('https://ipapi.co/json/');
    StorageService.setItem('deviceCurrentLocation', response.data.country);
  } catch (error) {
    console.error(`Could not retrieve device's current location.`);
  }
};
