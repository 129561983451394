import { useLocation } from 'react-router-dom';
import { Location } from 'history';

const getQueryParam = (location: Location<unknown>, param: string): string | undefined => {
  const searchParams = new URLSearchParams(location.search);
  if (searchParams.has(param)) return searchParams.get(param) ?? undefined;
  return undefined;
};

export const useQueryParam = (param: string): string | undefined => {
  const location = useLocation();

  return getQueryParam(location, param);
};
