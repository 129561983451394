import clsx from 'clsx';
import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  maxCols?: number;
};

const CardGrid: React.FC<Props> = ({ maxCols = 3, className, children, ...htmlProps }) => {
  return (
    <div
      className={clsx(
        'grid gap-y-4 grid-cols-1',
        { 'lg:grid-cols-2 lg:gap-4': maxCols > 1 },
        { 'xl:grid-cols-3': maxCols > 2 },
        className,
      )}
      {...htmlProps}
    >
      {children}
    </div>
  );
};

export default CardGrid;
