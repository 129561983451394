import { Invites } from 'src/types/invites/Invite';
import { fetchInvites, respondToCrewInvite, respondToSpaceInvite } from 'src/services/api/invitesAPI';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchInvitesThunk = createAsyncThunk('@@invites/fetchInvites', async (): Promise<Invites> => {
  return fetchInvites();
});

export const respondToSpaceInviteThunk = createAsyncThunk(
  '@@invites/respondToSpaceInvite',
  async (data: { inviteId: number; accepted: boolean }): Promise<Invites> => {
    return respondToSpaceInvite(data.inviteId, data.accepted);
  },
);

export const respondToCrewInviteThunk = createAsyncThunk(
  '@@invites/respondToCrewInvite',
  async (data: { inviteId: number; accepted: boolean }): Promise<Invites> => {
    return respondToCrewInvite(data.inviteId, data.accepted);
  },
);
