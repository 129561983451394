import clsx from 'clsx';
import React from 'react';
import './Loader.css';

type Props = {
  colorClass?: string;
  className?: string;
};

/** Animated loader */
const Loader: React.FC<Props> = ({ colorClass = 'bg-primary', className }) => {
  return (
    <div className={clsx('flex items-center justify-center', className)}>
      <div className="lds-ellipsis">
        <div className={colorClass} />
        <div className={colorClass} />
        <div className={colorClass} />
        <div className={colorClass} />
      </div>
    </div>
  );
};

export default Loader;
