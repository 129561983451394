import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import UHText from '../text/UHText';
import Center from '../transform/Center';
import PageIndicatorEntry, { IndicatorState } from './PageIndicatorEntry';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  indicators: IndicatorState[];
  clickableStates?: IndicatorState[];
  onSelectedPage?: (index: number) => void;
  text?: string;
  textKey?: DictionaryKeyType;
};

const PageIndicator: React.FC<Props> = ({
  indicators,
  clickableStates,
  onSelectedPage,
  text,
  textKey,
  className,
  ...htmlProps
}) => {
  const localizedText = useLocalizedValue(text, textKey);
  return (
    <Center
      col
      className={clsx(
        'pb-3 pt-4 px-14 w-max bg-neutral-900 bg-opacity-50 rounded-full select-none space-y-2',
        className,
      )}
      {...htmlProps}
    >
      <div className="flex space-x-4">
        {indicators.map((indicator, index) => {
          const clickable = clickableStates === undefined || clickableStates.includes(indicator);
          return (
            <PageIndicatorEntry
              key={index}
              state={indicator}
              onClick={clickable ? () => onSelectedPage?.(index) : undefined}
              text={`${index + 1}`}
            />
          );
        })}
      </div>
      {localizedText && <UHText variant="button-xs" className="text-typoInverted" text={localizedText} />}
    </Center>
  );
};

export default PageIndicator;
