import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { default as _ReactPlayer } from 'react-player/lazy';
import { ReactPlayerProps } from 'react-player/types/lib';
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

type Props = React.HTMLAttributes<HTMLDivElement> & {
  url: string;
  allowPlaying?: boolean;
  autoplay?: boolean;
  controls?: boolean;
  setAutoplay?: (autoplay: boolean) => void;
  onEnablePIP?: () => void;
  onDisablePIP?: () => void;
  freeAspect?: boolean;
};

/** Wrapper for react-player to control video playback */
const VideoPlayer: React.FC<Props> = ({
  url,
  allowPlaying,
  autoplay,
  controls = true,
  setAutoplay,
  onEnablePIP,
  onDisablePIP,
  freeAspect,
  className,
  ...htmlProps
}) => {
  /** The actual playing state of the player */
  const [isPlaying, setIsPlaying] = useState(false);
  /** The current command for the player (is sometimes ignored if switching while the player is loading) */
  const [targetPlayingState, setTargetPlayingState] = useState(allowPlaying);

  /** Handle autoplay locally if not set externally */
  const [localAutoplay, setLocalAutoplay] = useState(false);
  if (autoplay === undefined) autoplay = localAutoplay;
  if (setAutoplay === undefined) setAutoplay = setLocalAutoplay;

  /** External change of playing state */
  useEffect(() => {
    if (allowPlaying === false && isPlaying) {
      setTargetPlayingState(false);
      setTimeout(() => setTargetPlayingState(true), 1);
    } else if (allowPlaying !== false && !isPlaying && autoplay) {
      setTargetPlayingState(false);
      setTimeout(() => setTargetPlayingState(true), 1);
    }
  }, [allowPlaying]);

  useEffect(() => {
    if (allowPlaying === false && isPlaying) {
      setTargetPlayingState(false);
    }
  }, [targetPlayingState]);

  /** Internal change of playing state */
  useEffect(() => {
    if (allowPlaying === false && isPlaying) {
      setTargetPlayingState(true);
      setTimeout(() => setTargetPlayingState(false), 1);
    }
    if (allowPlaying !== false) setAutoplay?.(isPlaying);
  }, [isPlaying]);

  return (
    <div className={clsx(className)} {...htmlProps}>
      <div className={clsx('shadow-lg', { 'aspect-w-16 aspect-h-9': !freeAspect })}>
        <ReactPlayer
          height="100%"
          width="100%"
          playing={targetPlayingState}
          onStart={() => setIsPlaying(true)}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onEnablePIP={onEnablePIP}
          onDisablePIP={onDisablePIP}
          loop={false}
          controls={controls}
          muted={false}
          url={url}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload',
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
