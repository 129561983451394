import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import UHButton from 'src/views/components/core/buttons/UHButton';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import Loader from 'src/views/components/core/loader/Loader';
import UHText from 'src/views/components/core/text/UHText';
import Center from 'src/views/components/core/transform/Center';
import { useCurrentBreakpoint } from 'src/views/hooks/useCurrentBreakpoint';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  languageCode?: string;
};

const TWITTER_SCREEN_NAME = 'nevolane_space';

export const TwitterSection: React.FC<Props> = ({ languageCode = 'en', className, ...props }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const currentBreakpoint = useCurrentBreakpoint();
  const isSmallLayout = useMemo(() => ['xs', 'sm'].includes(currentBreakpoint), [currentBreakpoint]);

  const twitterLink = `https://twitter.com/intent/follow?original_referer=https://nevolane.space&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5E${TWITTER_SCREEN_NAME}&region=follow_link&screen_name=${TWITTER_SCREEN_NAME}`;

  return (
    <div
      className={clsx(
        'page-width flex items-center justify-end my-16 w-full h-auto md:h-128 flex-col md:flex-row',
        className,
      )}
      {...props}
    >
      <div className="mb-8 md:mb-0 md:h-auto flex-1 flex flex-col justify-center items-center">
        <UHIcon icon="brands fa-twitter" className="text-8xl lg:text-9xl text-primary mb-4 md:mb-8" />
        <UHText
          variant="title-xl"
          textKey="WEBSITE.TWITTER_SECTION.TITLE"
          className="mb-2 md:mb-4 font-bold md:text-4xl"
        />
        <UHText
          variant="title"
          textKey="WEBSITE.TWITTER_SECTION.SUBTITLE"
          className="max-w-md text-center text-base md:text-xl"
        />
        <Center className="mt-8">
          <a href={twitterLink} target="_blank" rel="noreferrer noopener">
            <UHButton titleKey="WEBSITE.TWITTER_SECTION.FOLLOW" onClick={() => null} />
          </a>
        </Center>
      </div>
      <div className={clsx('w-full max-w-md sm:w-1/2 lg:w-1/3 h-128 md:h-full relative')}>
        <TwitterTimelineEmbed
          key={languageCode + isSmallLayout}
          sourceType="profile"
          screenName={TWITTER_SCREEN_NAME}
          tweetLimit={isSmallLayout ? 1 : 3}
          lang={languageCode}
          autoHeight
          onLoad={() => setHasLoaded(true)}
        />
        {!hasLoaded && (
          <div
            className={clsx(
              'absolute top-0 right-0 left-0 bottom-0 bg border bg-neutral-100 border-neutral-300 rounded-xl flex justify-center items-center z-100 transition-all',
            )}
          >
            <Loader colorClass="bg-neutral" />
          </div>
        )}
      </div>
    </div>
  );
};
