import clsx from 'clsx';
import React from 'react';
import UHIcon from '../icon/UHIcon';
import UHText, { UHTextProps } from '../text/UHText';
import Center from '../transform/Center';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  selected: boolean;
  setSelected?: (selected: boolean) => void;
  size?: 'sm' | 'md';
  textProps?: UHTextProps;
};

const UHCheckbox: React.FC<Props> = ({ selected, setSelected, size = 'md', textProps, className, ...htmlProps }) => {
  const onClick = () => {
    setSelected?.(!selected);
  };

  return (
    <div className={clsx('flex items-center cursor-pointer space-x-2.5', className)} onClick={onClick} {...htmlProps}>
      <Center
        className={clsx('flex-shrink-0 rounded-full', {
          'bg-primary': selected,
          'border-2 border-neutral': !selected,
          'w-6 h-6': size === 'sm',
          'w-10 h-10': size === 'md',
        })}
      >
        {selected && (
          <UHIcon
            className={clsx('text-typoInverted', { 'text-base': size === 'sm', 'text-xl': size === 'md' })}
            icon="check"
          />
        )}
      </Center>
      {textProps && <UHText {...textProps} />}
    </div>
  );
};

export default UHCheckbox;
