import React, { useEffect } from 'react';
import { actions, useTypedSelector } from 'src/store/store';
import FadeInUpScroll from 'src/views/components/animations/FadeInUpScroll';
import UHText from 'src/views/components/core/text/UHText';
import Center from 'src/views/components/core/transform/Center';
import { FeatureCard } from './components/FeatureCard';
import UHButton from 'src/views/components/core/buttons/UHButton';
import HoverTransformContainer from 'src/views/components/animations/HoverTransformContainer';
import UHImage from 'src/views/components/core/image/UHImage';

const ApiInfoScreen: React.FC = () => {
  const blogState = useTypedSelector((state) => state.blog);
  const loaded = blogState.loadingState === 'loaded';

  const mailToHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, email: string) => {
    window.location.href = `mailto:${email}`;
    e.preventDefault();
  };

  useEffect(() => {
    if (!loaded) actions.fetchBlog();
  }, []);

  return (
    <main className="bg-card">
      <FadeInUpScroll>
        <div className="flex flex-col items-center pt-16">
          <div className="max-w-xl px-4">
            <UHText tag="h2" className="text-center pb-8" variant="title-xl" textKey="WEBSITE.API_INFO.TITLE" />
            <UHText tag="p" variant="body-lg" className="text-center pb-4" textKey="WEBSITE.API_INFO.HOOK" />
            <UHText tag="p" variant="body-lg" className="text-center" textKey="WEBSITE.API_INFO.VALUE_SUMMARY" />
          </div>
        </div>
      </FadeInUpScroll>
      <FadeInUpScroll>
        <div className="grid grid-cols-1 px-8 sm:px-20 md:px-4 md:grid-cols-3 gap-4 max-w-5xl mx-auto py-8">
          <FeatureCard icon="screwdriver-wrench">
            <UHText tag="h3" variant="title" textKey="WEBSITE.API_INFO.FEATURE_VERSATILE.TITLE" />
            <UHText textKey="WEBSITE.API_INFO.FEATURE_VERSATILE.TEXT" />
          </FeatureCard>
          <FeatureCard icon="chess-rook">
            <UHText tag="h3" variant="title" textKey="WEBSITE.API_INFO.FEATURE_SECURE.TITLE" />
            <UHText textKey="WEBSITE.API_INFO.FEATURE_SECURE.TEXT" />
          </FeatureCard>
          <FeatureCard icon="headset">
            <UHText tag="h3" variant="title" textKey="WEBSITE.API_INFO.FEATURE_SUPPORT.TITLE" />
            <UHText textKey="WEBSITE.API_INFO.FEATURE_SUPPORT.TEXT" />
          </FeatureCard>
        </div>
      </FadeInUpScroll>
      <FadeInUpScroll className="flex flex-col items-center mt-12 mb-6">
        <UHText tag="h3" variant="title-xl" textKey="WEBSITE.API_INFO.SNIPPET.TITLE" />
      </FadeInUpScroll>
      <div className="grid grid-cols-1 px-8 sm:px-20 md:px-4 lg:grid-cols-2 gap-4 max-w-5xl mx-auto py-8">
        <FadeInUpScroll className="flex flex-col items-center">
          <UHText className="text-center mb-3" tag="h3" variant="title" textKey="WEBSITE.API_INFO.SNIPPET.SERVER" />
          <HoverTransformContainer className="rounded-3xl w-min border-background border-4 overflow-hidden">
            <UHImage fillType="contain" src="assets/images/API/server_snippet.png" style={{ width: 450 }} />
          </HoverTransformContainer>
        </FadeInUpScroll>
        <FadeInUpScroll className="flex flex-col items-center">
          <UHText className="text-center mb-3" tag="h3" variant="title" textKey="WEBSITE.API_INFO.SNIPPET.CLIENT" />
          <HoverTransformContainer className="rounded-3xl w-min border-background border-4 overflow-hidden">
            <UHImage fillType="contain" src="assets/images/API/client_snippet.png" style={{ width: 450 }} />
          </HoverTransformContainer>
        </FadeInUpScroll>
      </div>
      <FadeInUpScroll>
        <div className="py-16 mb-32 mx-auto max-w-5xl px-4">
          <UHText textKey="WEBSITE.API_INFO.CTA" className="text-center pb-6" variant="body-lg" />
          <Center onClick={(e) => mailToHandler(e, 'support@nevolane.com')}>
            <UHButton titleKey="WEBSITE.API_INFO.CTA.BUTTON_LABEL" icon="envelope" />
          </Center>
        </div>
      </FadeInUpScroll>
    </main>
  );
};

export default ApiInfoScreen;
