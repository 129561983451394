import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import Center from '../transform/Center';
import UHText from './UHText';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  text?: string;
  textKey?: DictionaryKeyType;
};

const UHTag: React.FC<Props> = ({ text, textKey, className, ...htmlProps }) => {
  const localizedText = useLocalizedValue(text, textKey);
  return (
    <Center className={clsx('px-0.5 py-0.5 min-w-max bg-neutral-300 rounded', className)} {...htmlProps}>
      <UHText className="text-typoInverted" variant="body-sm" text={localizedText} />
    </Center>
  );
};

export default UHTag;
