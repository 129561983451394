import React, { useState } from 'react';
import { copyToClipboard } from 'src/services/clipboard/clipboardService';
import UHTooltip from './UHTooltip';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  copyContent: string;
};

const CopyToClipboard: React.FC<Props> = ({ copyContent, ...htmlProps }) => {
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    copyToClipboard(copyContent);
    setCopied(true);
  };

  const onReset = () => {
    setCopied(false);
  };

  return (
    <UHTooltip
      className="cursor-pointer"
      onClick={onCopy}
      onHide={onReset}
      textKey={!copied ? 'SHARED_TEXT_COPY_LINK' : 'SHARED_TEXT_COPIED'}
      {...htmlProps}
    />
  );
};

export default CopyToClipboard;
