import { Crew } from 'src/types/crews/CrewType';
import { Contact } from 'src/types/users/Contact';
import { Space, SpaceMember } from 'src/types/spaces/SpaceType';
import { validateEmail } from '../validation/validation';

/** Contact list operactions */

// Contains
export const contactsContains = (contacts: Contact[], contact: Contact): boolean => {
  return contacts.find((c) => c.email === contact.email) !== undefined;
};

// Difference
export const contactsDifference = (contacts1: Contact[], contacts2: Contact[]): Contact[] => {
  return contacts1.filter((c1) => !contactsContains(contacts2, c1));
};

// Unique
export const contactsUnique = (contacts: Contact[]): Contact[] => {
  return contacts.filter((c1, i, a) => a.findIndex((c2) => c2.email === c1.email) === i);
};

// Union
export const contactsUnion = (contacts1: Contact[], contacts2: Contact[]): Contact[] => {
  return contactsUnique(contacts1.concat(contacts2));
};

/** Utilities */

// Convert contact to space member
export const spaceMemberFromContact = (contact: Contact): SpaceMember => {
  return {
    id: (contact.id as number) ?? null,
    displayName: contact.displayName ?? '',
    role: 'none',
    email: contact.email,
    userId: (contact.id as string) ?? null,
    isActive: false,
  };
};

// Get contact list from a list of emails
export const contactsFromEmails = (contactList: Contact[], emails: string[]): Contact[] => {
  return emails.map((email) => {
    const contactWithMail = contactList.find((c) => c.email === email);
    return contactWithMail ?? { email };
  });
};

/** Contacts from spaces and crews */

// Contacts from space members
export const getContactsFromSpaceOrCrew = (spaceOrCrew: Space | Crew, includeInvites = true): Contact[] => {
  const members: Contact[] = spaceOrCrew.members.map((m) => {
    return { id: m.id ?? undefined, email: m.email, displayName: m.displayName };
  });
  const invited: Contact[] = spaceOrCrew.invites.map((m) => {
    return {
      id: m.id.toString(),
      email: m.invitedUser?.email ?? '',
      displayName: m.invitedUser?.displayName ?? undefined,
    };
  });
  if (includeInvites) return members.concat(invited);
  return members;
};

// Contacts from all crews and spaces
export const getContactList = (spaces: Space[], crews: Crew[]): Contact[] => {
  return contactsUnique(
    spaces.flatMap((s) => getContactsFromSpaceOrCrew(s)).concat(crews.flatMap((c) => getContactsFromSpaceOrCrew(c))),
  );
};

/** Search */

export const contactMatchesSearchInput = (contact: Contact, searchInput: string): boolean => {
  return (
    contact.email.toLowerCase().includes(searchInput) || (contact.displayName ?? '').toLowerCase().includes(searchInput)
  );
};

export const filterContactsWithSearchInput = (contacts: Contact[], searchInput: string): Contact[] => {
  if (!searchInput) return contacts;
  searchInput = searchInput.toLowerCase();

  const filteredContacts = contacts.filter((c) => contactMatchesSearchInput(c, searchInput));

  return filteredContacts;
};

export const getEmailContactFromSearchInput = (searchInput: string): Contact | null => {
  searchInput = searchInput.toLowerCase().trim();

  if (validateEmail(searchInput)) return { email: searchInput };
  return null;
};

/** Sort contacts by name and selection status */
const compareContacts = (c1: Contact, c2: Contact, selectedMails: string[]): number => {
  const c1Selected = selectedMails.includes(c1.email);
  const c2Selected = selectedMails.includes(c2.email);
  if (c1Selected && !c2Selected) return -1;
  if (!c1Selected && c2Selected) return 1;
  return (c1.displayName ?? c1.email) > (c2.displayName ?? c2.email) ? 1 : -1;
};

/** Assembly of final contact list */

// Returns the final contact list for the invitation views
export const getInvitationContacts = (
  spaces: Space[],
  crews: Crew[],
  isCrewSpace: boolean,
  space: Space | undefined,
  crew: Crew | undefined,
  selectedMails: string[],
  searchInput: string,
  myUserEmail: string | undefined = undefined,
): Contact[] => {
  // Get contact list
  const contacts = isCrewSpace && crew ? getContactsFromSpaceOrCrew(crew, false) : getContactList(spaces, crews);
  const myUserContact = contacts.find((c) => c.email && c.email === myUserEmail);

  // Filter by search input
  let finalContacts = filterContactsWithSearchInput(contacts, searchInput);

  // Add list of selected contacts
  const selectedContacts = contactsFromEmails(contacts, selectedMails);
  finalContacts = contactsUnion(selectedContacts, finalContacts);
  finalContacts.sort((c1, c2) => compareContacts(c1, c2, searchInput === '' ? selectedMails : []));

  // Add email contact
  const emailContact = getEmailContactFromSearchInput(searchInput);
  if (!isCrewSpace && emailContact && !contactsContains(finalContacts, emailContact))
    finalContacts = [emailContact, ...finalContacts];

  // Exclude contacts that are already in the space/crew
  let contactsToExclude = space ? getContactsFromSpaceOrCrew(space) : [];
  contactsToExclude = contactsToExclude.concat(crew && !isCrewSpace ? getContactsFromSpaceOrCrew(crew) : []);
  if (!isCrewSpace && myUserContact) contactsToExclude = contactsToExclude.concat(myUserContact);
  finalContacts = contactsDifference(finalContacts, contactsToExclude);

  return finalContacts;
};
