import clsx from 'clsx';
import React from 'react';
import UHIcon, { UHIconProps } from '../../core/icon/UHIcon';
import Loader from '../../core/loader/Loader';
import UHText, { UHTextProps } from '../../core/text/UHText';
import Center from '../../core/transform/Center';
import UHTooltip from '../../tooltip/UHTooltip';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  selected: boolean;
  collapsed: boolean;
  iconProps: UHIconProps;
  textProps: UHTextProps;
  loading?: boolean;
};

const DrawerEntryIcon: React.FC<Props> = ({
  selected,
  collapsed,
  iconProps,
  textProps,
  loading = false,
  className,
  ...htmlProps
}) => {
  return (
    <UHTooltip
      text={collapsed ? textProps.text : undefined}
      textKey={collapsed ? textProps.textKey : undefined}
      position="right"
    >
      {loading && (
        <Center className="w-16 h-12 transform scale-50">
          <Loader colorClass="bg-primary" />
        </Center>
      )}
      {!loading && (
        <div
          className={clsx(
            'group flex items-center p-1 h-12 rounded-lg cursor-pointer space-x-2 transition-all duration-200',
            {
              'hover:bg-neutral-200': !collapsed,
              'bg-neutral-200': selected && !collapsed,
              'bg-primary': selected && collapsed,
              'opacity-30 hover:opacity-100': !selected && collapsed,
            },
            className,
          )}
          {...htmlProps}
        >
          <Center
            className={clsx(
              'w-16 h-10 text-primary text-xl bg-neutral-200 group-hover:bg-neutral-300 rounded transition-all duration-200',
            )}
          >
            <UHIcon {...iconProps} />
          </Center>
          {!collapsed && (
            <UHText
              variant="body-sm"
              className="wrap line-clamp-2 flex-1 flex-shrink text-neutral group-hover:text-primary italic transition-all duration-200"
              {...textProps}
            />
          )}
        </div>
      )}
    </UHTooltip>
  );
};

export default DrawerEntryIcon;
