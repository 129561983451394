import clsx from 'clsx';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { UHAnalytics } from 'src/services/analytics/analyticEventsService';
import { LoginFormData, SignUpFormData, signUpSchema } from 'src/services/forms/schemaService';
import { actions } from 'src/store/store';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import FormikButton from 'src/views/components/core/buttons/FormikButton';
import FormikTextField from 'src/views/components/core/inputs/FormikTextField';
import UHText from 'src/views/components/core/text/UHText';
import { AuthState } from '../AuthScreen';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  setAuthState: (state: AuthState) => void;
  setLoginFormData: (data: LoginFormData) => void;
};

const SignUpForm: React.FC<Props> = ({ setAuthState, setLoginFormData, className, ...htmlProps }) => {
  const [errorKey, setErrorKey] = useState<DictionaryKeyType>();
  const [loading, setLoading] = useState(false);

  const handleErrorCode = (code: string) => {
    if (code === 'NetworkError') setErrorKey('SHARED_ERROR_NETWORK_ERROR');
    else if (code === 'InvalidPasswordException') setErrorKey('AUTH_SCREEN_ERROR_INVALID_PASSWORD');
    else if (code === 'UsernameExistsException') setErrorKey('AUTH_SCREEN_ERROR_USERNAME_EXISTS');
    else setErrorKey('SHARED_ERROR_UNKNOWN_ERROR');
  };

  const resetErrorCode = () => setErrorKey(undefined);

  const onSubmit = async (values: SignUpFormData) => {
    setLoading(true);
    try {
      await actions.signUp({ username: values.email, password: values.password });
      UHAnalytics.signedUp();
      setLoginFormData(values);
      setAuthState('verification');
      await actions.signIn({ username: values.email, password: values.password });
      await actions.init();
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code) handleErrorCode(error.code);
      setLoading(false);
    }
  };

  return (
    <div
      className={clsx('flex flex-col items-center', { 'pointer-events-none select-none': loading }, className)}
      {...htmlProps}
    >
      <UHText variant="title-xl" className="mb-12 mt-8 text-primary" textKey="SHARED_BUTTON_SIGN_UP" />
      {/* Form */}
      <Formik<SignUpFormData>
        validateOnMount={true}
        initialValues={{ email: '', password: '', confirmPassword: '' }}
        validationSchema={signUpSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <div className="flex flex-col w-full">
              {/* TextField: E-Mail */}
              <FormikTextField
                className="mb-10"
                name="email"
                labelKey="SHARED_TEXT_EMAIL"
                type="email"
                errorTextKey={errorKey}
                onChangeText={resetErrorCode}
              />
              {/* TextField: Password */}
              <FormikTextField
                className="mb-10"
                name="password"
                labelKey="AUTH_SCREEN_PASSWORD_PLACEHOLDER"
                type="password"
                autoComplete="new-password"
                errorTextKey={errorKey}
                onChangeText={resetErrorCode}
              />
              {/* TextField: Confirm Password */}
              <FormikTextField
                className="mb-10"
                name="confirmPassword"
                labelKey="AUTH_SCREEN_CONFIRM_PASSWORD_PLACEHOLDER"
                type="password"
                autoComplete="new-password"
                onEnter={handleSubmit}
              />
              {/* Login button */}
              <FormikButton className="self-center" titleKey="AUTH_SCREEN_SIGNUP_CREATE_ACCOUNT" loading={loading} />
            </div>
          );
        }}
      </Formik>
      {/* Or */}
      <UHText className="mb-2 mt-3 text-neutral-300" variant="body-sm" textKey="SHARED_TEXT_OR" />
      {/* Switch to signup */}
      <UHText className="text-primary" textKey="SHARED_BUTTON_LOGIN" onClick={() => setAuthState('login')} />
    </div>
  );
};

export default SignUpForm;
