import clsx from 'clsx';
import React from 'react';
import { User } from 'src/types/conference';
import UHCard from '../core/containers/UHCard';
import UHText from '../core/text/UHText';
import MediaDebugVideoStreamView from './MediaDebugVideoStreamView';
import MediaDebugAudioStreamView from './MediaDebugAudioStreamView';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  user: User;
};

const MediaDebugUserView: React.FC<Props> = ({ user, className, ...htmlProps }) => {
  return (
    <div className={clsx(className)} {...htmlProps}>
      <UHCard className="p-4">
        <UHText variant="title" className="-mb-2 text-primary" text={`${user.name}`} />
        {[...user.streams]
          .sort((s1, s2) => (s1.kind < s2.kind ? 1 : -1))
          .map((stream, index) => (
            <div className="mt-6" key={index}>
              {stream.stream && stream.kind === 'video' && <MediaDebugVideoStreamView videoStream={stream.stream} />}
              {stream.stream && stream.kind === 'audio' && <MediaDebugAudioStreamView audioStream={stream.stream} />}
            </div>
          ))}
      </UHCard>
    </div>
  );
};

export default MediaDebugUserView;
