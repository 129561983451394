import clsx from 'clsx';
import React from 'react';
import { clamp01 } from 'src/services/math/utils';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  /** Progress between 0 and 1 */
  progress: number;
  variant?: 'default' | 'danger';
};

const UHProgressBar: React.FC<Props> = ({ progress, variant = 'default', className, ...htmlProps }) => {
  progress = clamp01(progress);
  return (
    <div className={clsx('relative h-4 bg-neutral-300 rounded-full', className)} {...htmlProps}>
      <div
        className={clsx('absolute bottom-0 left-0 top-0 rounded-full', {
          'bg-primary': variant === 'default',
          'bg-error': variant === 'danger',
        })}
        style={{ width: `${progress * 100}%` }}
      />
    </div>
  );
};

export default UHProgressBar;
