import clsx from 'clsx';
import React from 'react';
import { Subscription } from 'src/services/subscriptions/subscriptionService';
import UHButton from 'src/views/components/core/buttons/UHButton';
import UHCard from 'src/views/components/core/containers/UHCard';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import UHText from 'src/views/components/core/text/UHText';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  subscription: Subscription;
  onContinue?: () => void;
};

const NewSubscriptionCard: React.FC<Props> = ({ subscription, onContinue, className, ...htmlProps }) => {
  return (
    <UHCard className={clsx('flex flex-col p-8 pb-4', className)} {...htmlProps}>
      {/* Title */}
      <UHText className="mb-8 mt-2" variant="button" textKey="SUBSCRIPTION_INCLUDES_TEXT" />
      {/* Feature list */}
      <div className="flex flex-col mb-16 space-y-2">
        {subscription.description.map((d, index) => {
          return (
            <div key={index} className="flex items-center space-x-4">
              <UHIcon className="text-secondary text-2xl" icon="circle-check" />
              <UHText textKey={d} />
            </div>
          );
        })}
      </div>
      {/* Continue button */}
      <UHButton className="self-center" titleKey="SHARED_TEXT_LETS_GO" onClick={onContinue} />
    </UHCard>
  );
};

export default NewSubscriptionCard;
