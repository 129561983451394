import ReactGA from 'react-ga4';
import { env, isLocalhost } from '../environment/envService';

ReactGA._gtag({
  ad_storage: 'denied',
  analytics_storage: 'denied',
});

let isInitialized = false;

export const initAnalytics = (): void => {
  isInitialized = true;
  ReactGA._gtag({
    ad_storage: 'granted',
    analytics_storage: 'granted',
  });
  ReactGA.initialize([{ trackingId: env.AnalyticsTrackingID, gaOptions: { debug: isLocalhost } }]);
};

export const trackEvent = (category: string, action: string): void => {
  // console.log('Event: ', category, action);
  if (isLocalhost || !isInitialized) return;
  ReactGA.event({ category, action });
};
