import clsx from 'clsx';
import React from 'react';
import { useMediaTrackState } from 'src/views/hooks/streams/useMediaTrackState';
import VideoStreamView from '../core/video/VideoStreamView';
import UHText from '../core/text/UHText';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  videoStream?: MediaStream;
};

const MediaDebugVideoStreamView: React.FC<Props> = ({ videoStream, className, ...htmlProps }) => {
  const videoTrack = videoStream?.getVideoTracks()[0];
  const videoTrackState = useMediaTrackState(videoTrack);

  return (
    <div className={clsx(className)} {...htmlProps}>
      {videoTrack && (
        <div>
          <UHText variant="title" text={`${videoTrack.kind}`} />
          <UHText text={`Track status: ${videoTrackState.readyState}`} />
          <UHText text={`Muted: ${videoTrackState.muted}`} />
          <VideoStreamView
            className="transitionfix mt-2 rounded-xl overflow-hidden"
            rounded={false}
            videoStream={videoStream}
            muted
          />
        </div>
      )}
    </div>
  );
};

export default MediaDebugVideoStreamView;
