import clsx from 'clsx';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { UHAnalytics } from 'src/services/analytics/analyticEventsService';
import { LoginFormData, loginSchema } from 'src/services/forms/schemaService';
import { actions } from 'src/store/store';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import FormikButton from 'src/views/components/core/buttons/FormikButton';
import FormikTextField from 'src/views/components/core/inputs/FormikTextField';
import UHText from 'src/views/components/core/text/UHText';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import { AuthState } from 'src/views/screens/Auth/AuthScreen';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  setAuthState: (state: AuthState) => void;
  setLoginFormData: (data: LoginFormData) => void;
};

const LoginForm: React.FC<Props> = ({ setAuthState, setLoginFormData, className, ...htmlProps }) => {
  const history = useUHHistory();
  const [errorKey, setErrorKey] = useState<DictionaryKeyType>();
  const [loading, setLoading] = useState(false);

  const handleErrorCode = (values: LoginFormData, code: string) => {
    if (code === 'NetworkError') setErrorKey('SHARED_ERROR_NETWORK_ERROR');
    else if (code === 'NotAuthorizedException') setErrorKey('SHARED_TEXT_INVALID');
    else if (code === 'UserNotConfirmedException') {
      setLoginFormData(values);
      setAuthState('verification');
    } else setErrorKey('SHARED_ERROR_UNKNOWN_ERROR');
  };

  const resetErrorCode = () => setErrorKey(undefined);

  const onSubmit = async (values: LoginFormData) => {
    setLoading(true);
    try {
      await actions.signIn({ username: values.email, password: values.password });
      UHAnalytics.signedIn();
      setLoading(false);
      history.pushPage('spacesOverview');
      await actions.init();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code) handleErrorCode(values, error.code);
      setLoading(false);
    }
  };

  const onForgotPassword = () => setAuthState('resetPassword');

  return (
    <div
      className={clsx('flex flex-col items-center', { 'pointer-events-none select-none': loading }, className)}
      {...htmlProps}
    >
      <UHText variant="title-xl" className="mb-12 mt-8 text-primary" textKey="SHARED_BUTTON_LOGIN" />
      {/* Form */}
      <Formik<LoginFormData>
        validateOnMount={true}
        initialValues={{ email: '', password: '' }}
        validationSchema={loginSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <div className="flex flex-col w-full">
              {/* TextField: E-Mail */}
              <FormikTextField
                className="mb-10"
                name="email"
                labelKey="SHARED_TEXT_EMAIL"
                type="email"
                errorTextKey={errorKey}
                onChangeText={resetErrorCode}
              />
              {/* TextField: Password */}
              <FormikTextField
                className="mb-10"
                name="password"
                labelKey="AUTH_SCREEN_PASSWORD_PLACEHOLDER"
                type="password"
                autoComplete="current-password"
                onEnter={handleSubmit}
                errorTextKey={errorKey}
                onChangeText={resetErrorCode}
              />
              {/* Forgot password button */}
              <UHText
                className="self-start mb-8 ml-4 text-primary"
                variant="body-sm"
                textKey="AUTH_SCREEN_FORGOT_PASSWORD"
                onClick={onForgotPassword}
              />
              {/* Login button */}
              <FormikButton className="self-center" titleKey="SHARED_BUTTON_LOGIN" loading={loading} />
            </div>
          );
        }}
      </Formik>
      {/* Or */}
      <UHText className="mb-2 mt-3 text-neutral" variant="body-sm" textKey="SHARED_TEXT_OR" />
      {/* Switch to signup */}
      <UHText className="text-primary" textKey="SHARED_BUTTON_SIGN_UP" onClick={() => setAuthState('signup')} />
    </div>
  );
};

export default LoginForm;
