import Signaling from 'src/services/signaling/socketConnection';
import { SocketEvents, DataResponse, WebRTCTransportResponse } from 'src/types/conference';
import { Device } from 'mediasoup-client';
import {
  Consumer,
  ConsumerOptions,
  Producer,
  ProducerCodecOptions,
  RtpCapabilities,
  RtpEncodingParameters,
  Transport,
} from 'mediasoup-client/lib/types';
import { AppError, getAppError } from 'src/helpers/Errors';
import { CreateConsumerRequest } from 'src/types/conference/sockets';
import Logger from 'src/helpers/Logger';

// Used for simulcast video
const VIDEO_SIMULCAST_ENCODINGS: RtpEncodingParameters[] = [
  { scaleResolutionDownBy: 4, maxBitrate: 625000 },
  { scaleResolutionDownBy: 2, maxBitrate: 1250000 },
  { scaleResolutionDownBy: 1, maxBitrate: 2500000 },
];

// Used for display sharing
const DISPLAY_SHARING_SIMULCAST_ENCODINGS: RtpEncodingParameters[] = [
  { dtx: true, maxBitrate: 4000000, priority: 'high' },
];

const logger = new Logger('mediasoup');

/**
 * Set up the device to communicate with the mediaserver.
 *
 * @async
 * @param {RtpCapabilities} rtpCapabilities The RTP Capabilities of the space.
 * @returns {PromiseLike<Device>} Promise that resolves to {@link Device}.
 */
export const setupDevice = async (rtpCapabilities: RtpCapabilities): Promise<Device> => {
  logger.debug(`setupDevice()`);

  const device = new Device();
  await device.load({ routerRtpCapabilities: rtpCapabilities });
  return device;
};

/**
 * Initialise a transport between the device and the server.
 *
 * @async
 * @param {Signaling} signaling Connected {@link Signaling} object
 * @param {Device} device {@link Device} ready to connect to the server.
 * @param {'send' | 'recv'} direction Direction of the Transport to be created.
 * @param {WebRTCTransportResponse} transportResponse The transport response from the server.
 * @returns {PromiseLike<Transport>} Promise that resolves to the {@link Transport}.
 */
export const initTransport = async (
  signaling: Signaling,
  device: Device,
  direction: 'send' | 'recv',
  transportResponse: WebRTCTransportResponse,
): Promise<Transport> => {
  logger.debug(`initTransport()`);

  let transport: Transport;
  const params = {
    id: transportResponse.id,
    iceParameters: transportResponse.iceParameters,
    iceCandidates: transportResponse.iceCandidates,
    dtlsParameters: transportResponse.dtlsParameters,
  };

  if (direction === 'send') {
    transport = device.createSendTransport(params);
  } else {
    transport = device.createRecvTransport(params);
  }

  transport.on('connect', async ({ dtlsParameters }, callback, errorback) => {
    logger.debug(`transport:connect()`);

    try {
      const response = (await signaling.requestPromise(SocketEvents.CONNECT_TRANSPORT, {
        transportId: transport.id,
        dtlsParameters,
      })) as DataResponse<null>;

      if (response.error) {
        errorback(new Error(response.error.message));
      }

      callback();
    } catch (error) {
      errorback(new Error(getAppError(error).message));
    }
  });

  transport.on('produce', async (args, callback, errorback) => {
    logger.debug(`transport:produce()`);

    try {
      const response = (await signaling.requestPromise(SocketEvents.PRODUCE, {
        transportId: transport.id,
        ...args,
      })) as DataResponse<string>;

      if (response.error) {
        errorback(new Error(response.error.message));
      }

      callback({ id: response.data });
    } catch (error) {
      errorback(new Error(getAppError(error).message));
    }
  });

  transport.observer.on('newconsumer', async (consumer: Consumer) => {
    logger.debug(`transport:newConsumer()`);

    try {
      const response = (await signaling.requestPromise(SocketEvents.RESUME, {
        consumerId: consumer.id,
      })) as DataResponse<null>;

      if (response.error) {
        throw new AppError(response.error.message, response.error.code);
      }
    } catch (error) {
      logger.error(`Error resuming new consumer [%o]`, error);
    }
  });

  transport.on('connectionstatechange', (state) => {
    logger.debug(`transport:connectionStateChange() | [state: ${state}]`);
    switch (state) {
      case 'connecting':
        break;

      case 'connected':
        break;

      case 'failed':
        transport.close();
        throw new Error('transport: Connection failed');

      default:
        break;
    }
  });

  return transport;
};

// export const restartIce(transports: Transport[]) {
//   logger.debug(`restartIce()`);

//   transports.forEach(async (transport: Transport) => {
//     try {
//       const iceParameters = await // get ice restart;
//     await transport.restartIce({iceParameters});
//     } catch (error) {
//       logger.error(`Error restarting ICE [%o]`, error)
//     }

//   })
// }

/**
 * Produces a track of the user's mediastream.
 *
 * @async
 * @param {MediaStreamTrack} track {@link MediaStreamTrack} which should be produced.
 * @param {Transport} transport {@link Transport} on which the track should be produced.
 * @returns {PromiseLike<Producer>} Promise that resolves to the {@link Producer}.
 */
export const produce = async (track: MediaStreamTrack, transport: Transport, display = false): Promise<Producer> => {
  logger.debug(`produce()`);

  let encodings: RtpEncodingParameters[] | undefined;
  let codecOptions: ProducerCodecOptions | undefined;

  if (track.kind === 'video') {
    codecOptions = {
      videoGoogleStartBitrate: 1000,
    };

    if (!display) {
      encodings = VIDEO_SIMULCAST_ENCODINGS;
    }

    if (display) {
      encodings = DISPLAY_SHARING_SIMULCAST_ENCODINGS;
    }
  } else if (track.kind === 'audio') {
    codecOptions = {
      opusStereo: true,
      opusDtx: true,
    };
  }
  return transport.produce({
    track,
    encodings,
    codecOptions,
    appData: { display },
  });
};

/**
 * Consume a producer.
 *
 * @async
 * @param {Signaling} signaling Connected {@link Signaling} object
 * @param {Device} device {@link Device} with the specific {@link RtpCapabilities}.
 * @param {Transport} transport {@link Transport} to use.
 * @returns {PromiseLike<Consumer>} Promise that resolves to the {@link Consumer}.
 * @throws {@link AppError}.
 */
export const consume = async (
  signaling: Signaling,
  device: Device,
  transport: Transport,
  producerArgs: CreateConsumerRequest,
): Promise<Consumer | undefined> => {
  logger.debug(`consume()`);

  const { rtpCapabilities } = device;

  const response = (await signaling.requestPromise(SocketEvents.CONSUME, {
    transportId: transport.id,
    rtpCapabilities,
    ...producerArgs,
  })) as DataResponse<ConsumerOptions | undefined>;

  if (response.error) {
    throw new AppError(response.error.message, response.error.code);
  }

  if (!response.data) {
    return undefined;
  }

  return transport.consume(response.data);
};
