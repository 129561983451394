import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import { CardWidth, useCardWidth } from 'src/views/hooks/views/useCardWidth';
import { OverlayCardContext, OverlayCardContextType } from 'src/views/hooks/views/useOverlayCardWidth';
import UHIcon from '../icon/UHIcon';
import UHText from '../text/UHText';
import ModalContainer from './ModalContainer';
import UHCard from './UHCard';

export type OverlayCardProps = React.HTMLAttributes<HTMLDivElement> & {
  /** Whether to show the content in a fullscreen overlay */
  isModal?: boolean;
  show: boolean;
  width?: CardWidth;
  title?: string;
  titleKey?: DictionaryKeyType;
  subTitle?: string;
  subTitleKey?: DictionaryKeyType;
  loading?: boolean;
  onClose?: () => void;
  onBack?: () => void;
  variant?: 'default' | 'danger';
};

const OverlayCard: React.FC<OverlayCardProps> = ({
  isModal = true,
  show,
  title,
  titleKey,
  subTitle,
  subTitleKey,
  width,
  onClose,
  loading = false,
  className,
  style,
  variant,
  children,
  ...htmlProps
}) => {
  const localizedTitle = useLocalizedValue(title, titleKey);
  const localizedSubTitle = useLocalizedValue(subTitle, subTitleKey);
  width = useCardWidth(width);

  const content = (
    <UHCard
      className={clsx(
        'relative flex flex-col pt-8 px-6 py-6 max-h-screen overflow-y-auto',
        { 'w-96 items-center': width === 'sm', 'w-192': width === 'md' },
        { 'pointer-events-none select-none': loading },
        className,
      )}
      style={{ ...style, maxWidth: '98vw' }}
      {...htmlProps}
    >
      {/* Title */}
      {localizedTitle && (
        <UHText
          variant="title-xl"
          className={clsx('mr-2 ', {
            'text-primary': variant === 'default',
            'text-error': variant === 'danger',
            'mb-4': width === 'md',
            'mb-8': width === 'sm',
          })}
          text={localizedTitle}
        />
      )}
      {/* Subtitle */}
      {localizedSubTitle && <UHText variant="body-sm" className="mb-4" text={localizedSubTitle} />}
      {children}
      {/* Close Button */}
      <UHIcon className="absolute right-3 top-3 px-1.5 text-neutral text-2xl" icon="xmark" onClick={onClose} />
    </UHCard>
  );

  const context: OverlayCardContextType = {
    width,
  };

  if (isModal)
    return (
      <ModalContainer showModal={show} onClickedOutside={loading ? undefined : onClose}>
        <OverlayCardContext.Provider value={context}>{content}</OverlayCardContext.Provider>
      </ModalContainer>
    );
  if (!show) return null;
  return content;
};

export default OverlayCard;
