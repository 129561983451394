import clsx from 'clsx';
import React, { useState } from 'react';
import ReactImageUploading, { ImageListType, ImageType } from 'react-images-uploading';
import { rangeArray } from 'src/services/collections/collectionService';
import { useTypedSelector } from 'src/store/store';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import OverlayCard, { OverlayCardProps } from '../../core/containers/OverlayCard';
import UHIcon from '../../core/icon/UHIcon';
import UHText from '../../core/text/UHText';
import Center from '../../core/transform/Center';
import FormButtons from '../../form/FormButtons';
import SpaceImage from '../image/SpaceImage';
import UploadImageForm from './../../../screens/Settings/components/UploadImageForm';

type Props = OverlayCardProps & {
  data: number;
  spaceId?: number;
  customImage?: string;
  onChangedData: (data: number) => void;
  loading?: boolean;
  submitText?: string;
  submitTextKey?: DictionaryKeyType;
};

const SpaceImageForm: React.FC<Props> = ({
  data,
  onChangedData,
  submitText,
  spaceId,
  customImage,
  submitTextKey,
  width = 'md',
  ...htmlProps
}) => {
  const [selectedIndex, setSelectedIndex] = useState(customImage ? -1 : data);
  const [selectedUploadImage, setSelectedUploadImage] = React.useState<ImageType | undefined>(undefined);
  const space = useTypedSelector((state) => state.spaces.spaces).find((selectedSpace) => selectedSpace.id === spaceId);
  const localizedSubmitText = useLocalizedValue(submitText, submitTextKey);
  const [state, setState] = useState<'default' | 'upload-image'>('default');
  const onCloseModal = () => {
    setSelectedUploadImage(undefined);
    setState('default');
  };

  const onChangeImages = (imageList: ImageListType) => {
    if (imageList.length > 0) {
      setSelectedUploadImage(imageList[0]);
      setState('upload-image');
    } else setSelectedUploadImage(undefined);
  };

  const spaceImageOption = (index: number): JSX.Element => {
    const selected = index === selectedIndex;
    return (
      <div className="relative" key={index}>
        <SpaceImage
          className={clsx('cursor-pointer transition-opacity duration-200', {
            'ring-2 ring-primary': selected,
            'opacity-50 hover:opacity-100': !selected,
          })}
          onClick={() => setSelectedIndex(index)}
          showTitle={false}
          space={index === -1 ? { customImage: space?.customImage } : { imageId: index }}
          variant="sm"
        />
        {selected && (
          <Center className="absolute bottom-2 right-2 w-6 h-6 bg-primary rounded-full shadow">
            <UHIcon className="text-typoInverted" icon="check" />
          </Center>
        )}
      </div>
    );
  };

  return (
    <OverlayCard className="min-h-120" width={width} titleKey="SPACE_MANAGEMENT_TITLE_SELECT_IMAGE" {...htmlProps}>
      {/* Image selection */}
      <div className="grid gap-4 grid-cols-2 self-stretch mb-10 lg:grid-cols-4">
        {rangeArray(8).map(spaceImageOption)}
      </div>
      {/* Custom image section */}
      <ReactImageUploading value={[selectedUploadImage as never]} onChange={onChangeImages}>
        {({ onImageUpload, isDragging, dragProps }) => {
          return (
            <div className="grid gap-4 grid-cols-2 self-stretch mb-10 lg:grid-cols-4">
              {/* Custom image */}
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (space?.customImage) setSelectedIndex(-1);
                  else onImageUpload();
                }}
              >
                {space?.customImage && spaceImageOption(-1)}
                {!space?.customImage && (
                  <div className="aspect-w-9 aspect-h-5 w-full h-full bg-neutral-200 rounded-lg">
                    <Center col>
                      <UHIcon className="text-neutral-600 text-xl" icon="camera" />
                      <UHText className="text-neutral-600" variant="body-sm" textKey="SHARED_TEXT_CUSTOM_IMAGE" />
                    </Center>
                  </div>
                )}
              </div>
              {/* Drop area */}
              <Center
                col
                className={clsx('self-stretch bg-neutral-200 rounded-lg cursor-pointer space-y-1 lg:col-span-3', {
                  'ring-2 ring-primary': isDragging,
                })}
                onClick={onImageUpload}
                {...dragProps}
              >
                <UHText className="text-center pointer-events-none" textKey="SHARED_TEXT_DRAG_AND_DROP_UPLOAD_IMAGE" />
                <UHText
                  className="text-center text-neutral pointer-events-none"
                  variant="body-xs"
                  textKey="SHARED_TEXT_IMAGE_UPLOAD_RESTRICTIONS"
                />
              </Center>
            </div>
          );
        }}
      </ReactImageUploading>
      {/* Spacing */}
      <div className="flex-1" />
      {/* Form buttons */}
      <FormButtons
        onCancel={htmlProps.onBack ?? htmlProps.onClose}
        onSubmit={() => onChangedData(selectedIndex)}
        submitText={localizedSubmitText}
        loading={htmlProps.loading}
      />
      <UploadImageForm
        show={state === 'upload-image'}
        spaceId={spaceId}
        onFinish={() => {
          setSelectedIndex(-1);
        }}
        uploadType="spaceImage"
        image={selectedUploadImage}
        onClose={onCloseModal}
      />
    </OverlayCard>
  );
};

export default SpaceImageForm;
