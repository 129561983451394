import { Formik, FormikValues } from 'formik';
import React, { PropsWithChildren } from 'react';
import FormButtons, { FormButtonProps } from './FormButtons';

export type UHFormProps<T> = Omit<FormButtonProps, 'onSubmit'> & {
  data: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: any;
  onSubmit: (data: T) => void;
};

const UHForm = <T extends FormikValues>({
  data,
  schema,
  onSubmit,
  children,
  ...htmlProps
}: PropsWithChildren<UHFormProps<T>>): JSX.Element => {
  return (
    <Formik<T> validateOnMount={true} initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      <div className="flex flex-1 flex-col w-full">
        {children}
        <div className="flex-1" />
        <FormButtons {...htmlProps} />
      </div>
    </Formik>
  );
};

export default UHForm;
