import { createSlice } from '@reduxjs/toolkit';
import { fetchInvitesThunk, respondToCrewInviteThunk, respondToSpaceInviteThunk } from './thunk';
import { InvitesState } from './types';

export const initialState: InvitesState = {
  spaceInvites: [],
  crewInvites: [],
};

const invitesSlice = createSlice({
  name: '@@invites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvitesThunk.fulfilled, (state, action) => {
        state.spaceInvites = action.payload.spaceInvites;
        state.crewInvites = action.payload.crewInvites;
      })
      .addCase(respondToSpaceInviteThunk.fulfilled, (state, action) => {
        state.spaceInvites = action.payload.spaceInvites;
      })
      .addCase(respondToCrewInviteThunk.fulfilled, (state, action) => {
        state.crewInvites = action.payload.crewInvites;
      });
  },
});

export const invitesReducer = invitesSlice.reducer;
