import { Auth, Amplify } from 'aws-amplify';
import { env } from 'src/services/environment/envService';

export const configureAmplify = (): void => {
  Amplify.configure({
    Auth: {
      region: env.AWSRegion,
      userPoolId: env.AWSCognitoUserPoolId,
      userPoolWebClientId: env.AWSCognitoUserPoolWebclientId,
    },
    API: {
      endpoints: [
        {
          name: env.AWSAPIName,
          endpoint: env.AWSAPIEndpoint,
          service: 'lambda',
          custom_header: async () => {
            try {
              const jwt = (await Auth.currentSession()).getAccessToken().getJwtToken();
              return { Authorization: `${jwt}` };
            } catch (error) {
              return undefined;
            }
          },
          region: env.AWSRegion,
        },
      ],
    },
  });
};
