import { createSlice } from '@reduxjs/toolkit';
import { fetchBlogEntriesThunk } from './thunk';
import { BlogState } from './types';

export const initialState: BlogState = {
  blogEntries: [],
  loadingState: 'not loaded',
};

const blogSlice = createSlice({
  name: '@@blog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBlogEntriesThunk.fulfilled, (state, action) => {
      state.blogEntries = action.payload;
      state.loadingState = 'loaded';
    });
  },
});

export const blogReducer = blogSlice.reducer;
