import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { cleanupUserStreams } from 'src/services/mediaStreams/userStreamService';
import { allPages } from 'src/services/pages/pageService';
import { useTypedSelector } from 'src/store/store';
import { Space } from 'src/types/spaces/SpaceType';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import ConferenceView from './ConferenceView';
import PublicConferenceView from './PublicConferenceView';

type ConferenceScreenLocation = {
  cameraEnabled?: boolean;
  micMuted?: boolean;
  token?: string;
  displayName?: string;
};

const ConferenceScreen: React.FC = () => {
  const history = useUHHistory();
  const spaceId = parseInt(useParams().spaceId ?? '-1');
  const isAuthenticated = !!useTypedSelector((state) => state.user.cognitoUser);
  const user = useTypedSelector((state) => state.user.user);
  const spaces = useTypedSelector((state) => state.spaces.spaces);
  const conferenceScreenLocation = history.location.state as ConferenceScreenLocation | undefined;
  const cameraEnabled = conferenceScreenLocation?.cameraEnabled ?? true;
  const micMuted = conferenceScreenLocation?.micMuted ?? false;
  const displayName = conferenceScreenLocation?.displayName ?? user.displayName;
  const [token, setToken] = useState(conferenceScreenLocation?.token);
  const [activeSpaces, setActiveSpaces] = useState(spaces.filter((s) => s.id === spaceId));

  const returnToSpacesOverview = () => {
    cleanupUserStreams();
    if (isAuthenticated) history.replacePage('spacesOverview');
    else history.navigate(allPages.auth.url, { state: { signUp: true }, replace: true });
  };

  const onLeftConference = (space: Space) => {
    setActiveSpaces((v) => v.filter((s) => s.id !== space.id));
  };

  // Return to spaces overview when no active space is left
  useEffect(() => {
    if (activeSpaces.length === 0 && !token) returnToSpacesOverview();
  }, [activeSpaces, token]);

  useEffect(() => {
    if (!token) setActiveSpaces(spaces.filter((s) => s.id === spaceId));
  }, [spaceId]);

  return (
    <div className="flex flex-1 w-full select-none">
      {activeSpaces.map((space) => {
        return (
          <ConferenceView
            key={space.id}
            displayName={displayName}
            className="w-full h-full"
            space={space}
            onLeftConference={() => onLeftConference(space)}
            cameraEnabled={cameraEnabled}
            micMuted={micMuted}
          />
        );
      })}
      {token && (
        <PublicConferenceView
          token={token}
          displayName={displayName}
          className="w-full h-full"
          onLeftConference={() => setToken(undefined)}
          cameraEnabled={cameraEnabled}
          micMuted={micMuted}
        />
      )}
    </div>
  );
};

export default ConferenceScreen;
