import { useField, useFormikContext } from 'formik';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import UHTextField, { TextFieldProps } from './UHTextField';

type Props = { name: string; submitOnEnter?: boolean } & TextFieldProps;

const FormikTextField: React.FC<Props> = (props) => {
  const [field, meta] = useField(props.name);
  const { handleSubmit } = useFormikContext();
  return (
    <UHTextField
      {...field}
      {...props}
      errorTextKey={props.errorTextKey ?? (meta.touched ? (meta.error as DictionaryKeyType) : undefined)}
      value={field.value ?? ''}
      onEnter={props.submitOnEnter ? handleSubmit : undefined}
    />
  );
};

export default FormikTextField;
