import clsx from 'clsx';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { allPages, matchPathPages } from 'src/services/pages/pageService';
import { getStaticData } from 'src/services/staticData/staticDataService';
import { useTypedSelector } from 'src/store/store';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import { useCreateSpace } from 'src/views/hooks/space/useCreateSpace';
import { useCurrentBreakpoint } from 'src/views/hooks/useCurrentBreakpoint';
import { useLocalizedKey } from 'src/views/hooks/useLocalizedString';
import InvitationScreen from 'src/views/screens/Invites/InvitationScreen';
import ModalContainer from '../../core/containers/ModalContainer';
import UHText from '../../core/text/UHText';
import ProfileIcon from '../../profile/ProfileIcon';
import CreateSpaceView from '../../space/createSpace/CreateSpaceView';
import DrawerEntry from './DrawerEntry';
import DrawerEntryIcon from './DrawerEntryIcon';
import DrawerSpaceEntry from './DrawerSpaceEntry';

type Props = React.HTMLAttributes<HTMLDivElement>;

const Drawer: React.FC<Props> = ({ className, ...htmlProps }) => {
  const history = useUHHistory();
  const location = useLocation();
  const spaces = useTypedSelector((state) => state.spaces.spaces);
  const invites = useTypedSelector((state) => state.invites);
  const [createSpaceOpen, setCreateSpaceOpen] = useState(false);
  const [showingInvites, setShowingInvites] = useState(false);

  const breakpoint = useCurrentBreakpoint();

  const smallScreen = ['xs', 'sm'].includes(breakpoint);
  const inConference = matchPathPages(location.pathname, [allPages.conference]);
  const collapsed = smallScreen || inConference;

  const totalInvites = invites.spaceInvites.length + invites.crewInvites.length;
  const invitationsString = `${useLocalizedKey('PROFILE_OPTIONS_INVITATIONS')} (${totalInvites})`;

  // Create space
  const { onCreateSpace, creatingSpace } = useCreateSpace();

  return (
    <div
      className={clsx(
        'flex justify-between sm:flex-col sm:pr-4',
        {
          'sm:w-20 sm:pb-0 sm:pl-0 sm:pt-36 items-center p-4': collapsed,
          'w-72': !collapsed,
          'hidden sm:flex': inConference,
        },
        className,
      )}
      {...htmlProps}
    >
      {!collapsed && <UHText className="mb-20 ml-4 mt-8 text-primary" variant="title-xl" text="Nevolane Space" />}
      {/* Space menu entries */}
      <div className="no-scrollbar hidden flex-1 flex-col sm:flex">
        {/* Spaces Overview */}
        {!inConference && (
          <DrawerEntry
            icon="rocket"
            textKey="SHARED_TEXT_SPACES"
            path={allPages.spacesOverview.url}
            collapsed={collapsed}
          />
        )}
        {/* Spaces list */}
        <div className={clsx('space-y-1', { 'ml-10': !collapsed, 'mt-2': !inConference })}>
          {/* Existing spaces */}
          {spaces.map((space, index) => {
            return <DrawerSpaceEntry key={index} space={space} collapsed={collapsed} inConference={inConference} />;
          })}
          {/* Invites */}
          {totalInvites > 0 && (
            <DrawerEntryIcon
              iconProps={{ icon: 'envelope' }}
              textProps={{ text: invitationsString }}
              selected={showingInvites}
              collapsed={collapsed}
              onClick={() => setShowingInvites(true)}
            />
          )}
          {/* New space */}
          {!inConference && (
            <DrawerEntryIcon
              iconProps={{ icon: 'plus' }}
              textProps={{ textKey: 'SPACE_CREATE_TITLE' }}
              selected={createSpaceOpen}
              collapsed={collapsed}
              onClick={onCreateSpace}
              loading={creatingSpace}
            />
          )}
        </div>
      </div>
      {/* Mobile: Spaces Overview */}
      <DrawerEntry
        className="block sm:hidden"
        icon="rocket"
        textKey="SHARED_TEXT_SPACES"
        path={allPages.spacesOverview.url}
        collapsed={collapsed}
      />
      {/* Subscriptions */}
      {!inConference && getStaticData().config.subscriptionsEnabled && (
        <DrawerEntry
          className="sm:mb-1"
          icon="star"
          textKey="PROFILE_OPTIONS_SUBSCRIPTIONS"
          path={allPages.subscriptions.url}
          collapsed={collapsed}
        />
      )}
      {/* Settings */}
      {!inConference && (
        <DrawerEntry icon="cog" textKey="PROFILE_OPTIONS_SETTINGS" path={allPages.settings.url} collapsed={collapsed} />
      )}
      {/* Divider line */}
      <div className="hidden mt-4 w-full h-0.5 bg-neutral-300 sm:block" />
      {/* Profile icon */}
      <ProfileIcon
        className="hidden mb-8 ml-2.5 mt-4 sm:flex"
        showName={!collapsed}
        onClick={!inConference ? () => history.pushPage('settings') : undefined}
      />
      {/* Create Space Overlay */}
      <ModalContainer showModal={createSpaceOpen} onClickedOutside={() => setCreateSpaceOpen(false)}>
        <CreateSpaceView
          onDismiss={() => setCreateSpaceOpen(false)}
          onCreatedSpace={(spaceId) => history.pushPage('spaceDetail', spaceId)}
        />
      </ModalContainer>
      {/* Invite Overlay */}
      <InvitationScreen show={showingInvites} onClose={() => setShowingInvites(false)} />
    </div>
  );
};

export default Drawer;
