import clsx from 'clsx';
import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  col?: boolean;
};

const Center: React.FC<Props> = ({ col, className, ...htmlProps }) => {
  return (
    <div className={clsx('flex items-center justify-center', { 'flex-col': col }, className)} {...htmlProps}></div>
  );
};

export default Center;
