import clsx from 'clsx';
import React from 'react';
import { animated, useTransition } from '@react-spring/web';
import Loader from '../loader/Loader';

type Props = {
  /** Whether to show the dark overlay and a loading animation of the modal */
  showOverlay?: boolean;
  /** Whether to show the modal content */
  showModal: boolean;
  /** Callback when clicking outside the modal content on the dark overlay */
  onClickedOutside?: () => void;
  fullScreen?: boolean;
  transparentBg?: boolean;
  children?: React.ReactNode;
};

/** Full screen modal with dark overlay.*/
const ModalContainer: React.FC<Props> = ({
  children,
  showOverlay,
  showModal,
  fullScreen = true,
  transparentBg = false,
  onClickedOutside,
}) => {
  if (showOverlay === undefined) showOverlay = showModal;
  if (showModal) showOverlay = true;
  /** Enter/Leave transition of background */
  const transitionsBackground = useTransition(showOverlay, {
    from: { backgroundColor: 'rgba(0, 0, 0, 0)' },
    enter: { backgroundColor: `rgba(0, 0, 0, ${transparentBg ? 0 : 0.7})` },
    leave: { backgroundColor: 'rgba(0, 0, 0, 0)' },
    config: { friction: 30, tension: 300, mass: 1 },
  });

  /** Enter/Leave transition of content */
  const transitionsContent = useTransition(showModal, {
    from: { transform: 'scale(1.3) translateY(0px)', opacity: 0 },
    enter: { transform: 'scale(1) translateY(0px)', opacity: 1 },
    leave: { transform: 'scale(1.3) translateY(0px)', opacity: 0 },
    config: { friction: 30, tension: 300, mass: 1 },
  });

  return (
    <div
      className={clsx('inset-0 overflow-hidden', {
        'pointer-events-none': !showOverlay,
        'fixed z-40': fullScreen,
        absolute: !fullScreen,
      })}
    >
      <div className={clsx('inset-0', { fixed: fullScreen, absolute: !fullScreen })}>
        {transitionsBackground(
          (style, item) =>
            item && (
              <animated.div
                onClick={(e) => {
                  e.stopPropagation();
                  onClickedOutside?.();
                }}
                className="absolute inset-0 flex items-center justify-center"
                style={style as never}
              >
                {!showModal && <Loader colorClass="bg-white" className={clsx({ 'opacity-0': !showOverlay })} />}
              </animated.div>
            ),
        )}
      </div>
      {transitionsContent(
        (style, item) =>
          item && (
            <animated.div
              className="flex items-center justify-center w-full h-full pointer-events-none transform"
              style={style as never}
            >
              <div className="pointer-events-auto">{children}</div>
            </animated.div>
          ),
      )}
    </div>
  );
};

export default ModalContainer;
