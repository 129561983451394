import { useEffect, useState } from 'react';

export type MediaStreamTrackStateType = {
  readyState?: MediaStreamTrackState;
  muted?: boolean;
};

export const useMediaTrackState = (track?: MediaStreamTrack): MediaStreamTrackStateType => {
  const [readyState, setReadyState] = useState(track?.readyState);
  const [muted, setMuted] = useState(track?.muted);

  const onTrackEnded = () => {
    setReadyState('ended');
  };

  const onTrackMute = () => {
    setMuted(true);
  };

  const onTrackUnmute = () => {
    setMuted(false);
  };

  useEffect(() => {
    setReadyState(track?.readyState);
    setMuted(track?.muted);
    track?.addEventListener('ended', onTrackEnded);
    track?.addEventListener('mute', onTrackMute);
    track?.addEventListener('unmute', onTrackUnmute);

    return () => {
      track?.removeEventListener('ended', onTrackEnded);
      track?.removeEventListener('mute', onTrackMute);
      track?.removeEventListener('unmute', onTrackUnmute);
    };
  }, [track]);

  return {
    readyState,
    muted,
  };
};
