import clsx from 'clsx';
import React, { useState } from 'react';
import { MediaStreamData } from 'src/views/hooks/streams/useMediaStreams';
import UHText from '../core/text/UHText';
import UHCard from '../core/containers/UHCard';
import VideoStreamView from '../core/video/VideoStreamView';
import SingleSelectionDropdown from '../core/inputs/SingleSelectionDropdown';
import UHProgressBar from '../core/progress/UHProgressBar';
import UHCheckbox from '../core/inputs/UHCheckbox';
import UHIcon from '../core/icon/UHIcon';
import { ConferenceData } from 'src/views/screens/Conference/hooks/useConference';
import MediaDebugUserView from './MediaDebugUserView';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  mediaStreamData: MediaStreamData;
  conferenceData?: ConferenceData;
  onClose?: () => void;
};

const MediaDebugView: React.FC<Props> = ({ mediaStreamData, conferenceData, onClose, className, ...htmlProps }) => {
  const {
    cameras,
    microphones,
    localVideoTrackState,
    localVideoStream,
    // localVideoTrackSettings,
    // localVideoTrackConstraints,
    // localVideoTrackCapabilities,
    audioVolume,
    localAudioTrackState,
    localAudioStream,
    // localAudioTrackSettings,
    // localAudioTrackConstraints,
    // localAudioTrackCapabilities,
    cameraEnabled,
    onSetCameraEnabled,
    microphoneEnabled,
    isLoadingPermissions,
    selectedCameraIndex,
    selectedMicrophoneIndex,
    onSetSelectedCameraIndex,
    onSetSelectedMicrophoneIndex,
  } = mediaStreamData;

  const noCameras = cameras.length === 0;
  const noMicrophones = microphones.length === 0;
  const [listenToOwnMic, setListenToOwnMic] = useState(false);

  return (
    <div className={clsx('main-gradient w-full h-full z-100 overflow-y-auto p-10', className)} {...htmlProps}>
      {/* Close button */}
      <UHIcon className="fixed top-5 right-5" icon="times" onClick={onClose} />
      <UHText className="text-primary mb-4" variant="title" text={`Local streams`} />
      <div className="grid grid-cols-2 gap-8">
        {/* General */}
        <UHCard className="p-4 col-span-2">
          <UHText variant="title" className="mb-2" text={`General`} />
          <UHText text={`Loading permissions: ${isLoadingPermissions}`} />
        </UHCard>
        {/* Camera */}
        <UHCard className="p-4">
          <UHText variant="title" className="mb-2" text={`Camera`} />
          <UHText text={`Enabled: ${cameraEnabled}`} />
          <UHText text={`Track status: ${localVideoTrackState.readyState}`} />
          <UHText text={`Muted: ${localVideoTrackState.muted}`} />
          <div className="flex space-x-4 items-center mt-2">
            {/* Camera Selection */}
            <SingleSelectionDropdown
              className=" w-56"
              buttonProps={{
                icon: 'video',
                titleKey: noCameras ? 'SHARED_TEXT_NOT_AVAILABLE' : undefined,
                title: cameras[selectedCameraIndex]?.name,
                infoTitle: 'Camera',
                size: 'small',
                disabled: noCameras,
              }}
              options={cameras.map((v) => v.name)}
              value={selectedCameraIndex}
              onChangedValue={(selection: number) => {
                onSetSelectedCameraIndex(selection);
                onSetCameraEnabled(true);
              }}
            />
            {/* Toggle camera */}
            <UHCheckbox
              size="sm"
              selected={cameraEnabled}
              setSelected={onSetCameraEnabled}
              textProps={{ text: 'Enable camera' }}
            />
          </div>
          <VideoStreamView
            className="transitionfix mt-2 rounded-xl overflow-hidden"
            rounded={false}
            videoStream={localVideoStream}
            audioStream={listenToOwnMic ? localAudioStream : undefined}
            mirror={true}
          />
          {/* <UHText
            className="mt-2 wrap"
            text={`Capabilities: ${JSON.stringify(localVideoTrackCapabilities, null, 2)}`}
          />
          <UHText className="mt-2 wrap" text={`Constraints: ${JSON.stringify(localVideoTrackConstraints, null, 2)}`} />
          <UHText className="mt-2 wrap" text={`Settings: ${JSON.stringify(localVideoTrackSettings, null, 2)}`} /> */}
        </UHCard>
        {/* Microphone */}
        <UHCard className="p-4">
          <UHText variant="title" className="mb-2" text={`Microphone`} />
          <UHText text={`Enabled: ${microphoneEnabled}`} />
          <UHText text={`Track status: ${localAudioTrackState.readyState}`} />
          <UHText text={`Muted: ${localAudioTrackState.muted}`} />
          <div className="flex items-center space-x-4 mt-2">
            {/* Microphone selection */}
            <SingleSelectionDropdown
              className=" w-56"
              buttonProps={{
                icon: 'microphone',
                titleKey: noMicrophones ? 'SHARED_TEXT_NOT_AVAILABLE' : undefined,
                title: microphones[selectedMicrophoneIndex]?.name,
                infoTitle: 'Microphone',
                size: 'small',
                disabled: noMicrophones,
              }}
              options={microphones.map((v) => v.name)}
              value={selectedMicrophoneIndex}
              onChangedValue={onSetSelectedMicrophoneIndex}
            />
            <UHCheckbox
              size="sm"
              selected={listenToOwnMic}
              setSelected={setListenToOwnMic}
              textProps={{ text: 'Play my audio' }}
            />
          </div>
          <UHProgressBar className="mt-2" progress={audioVolume} />
          {/* <UHText
            className="mt-2 wrap"
            text={`Capabilities: ${JSON.stringify(localAudioTrackCapabilities, null, 2)}`}
          /> */}
          {/* <UHText className="mt-2 wrap" text={`Constraints: ${JSON.stringify(localAudioTrackConstraints, null, 2)}`} />
          <UHText className="mt-2 wrap" text={`Settings: ${JSON.stringify(localAudioTrackSettings, null, 2)}`} /> */}
        </UHCard>
        {conferenceData && (
          <div>
            <UHText className="text-primary mb-4" variant="title" text={`Conference`} />
            <div className="grid grid-cols-2 gap-8">
              {conferenceData.sessionRenderData.users.map((user) => {
                return <MediaDebugUserView user={user} key={user.id} />;
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaDebugView;
