import hark from 'hark';
import { useEffect, useState } from 'react';
import { clamp01, inverseLerp } from 'src/services/math/utils';

export const useAudioTrackEvents = (mediaStream?: MediaStream): number => {
  const [audioVolume, setAudioVolume] = useState(0);

  useEffect(() => {
    const audioSpeechEvents = mediaStream ? hark(mediaStream, {}) : undefined;
    audioSpeechEvents?.on('volume_change', (v) => {
      setAudioVolume(clamp01(inverseLerp(-100, -20, v)));
    });

    return () => {
      audioSpeechEvents?.stop();
    };
  }, [mediaStream]);

  return audioVolume;
};
