import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'src/App';
import { getPersonalSubscriptionForTier } from 'src/services/subscriptions/subscriptionService';
import { actions, useTypedSelector } from 'src/store/store';
import { SpaceInvite } from 'src/types/spaces/SpaceInviteType';
import OverlayCard, { OverlayCardProps } from 'src/views/components/core/containers/OverlayCard';
import FormButtons from 'src/views/components/form/FormButtons';
import InviteListEntry, { InviteState } from './components/InviteListEntry';

type Props = OverlayCardProps;

const InvitationScreen: React.FC<Props> = ({ className, width = 'md', ...htmlProps }) => {
  const invites = useTypedSelector((state) => state.invites);
  const user = useTypedSelector((state) => state.user.user);
  const spaces = useTypedSelector((state) => state.spaces.spaces);
  const { setConfirmData } = useContext(AppContext);

  const loadInvites = () => {
    return invites.spaceInvites.reduce((map: Record<number, { state: InviteState; invite: SpaceInvite }>, v) => {
      map[v.id] = { state: 'open', invite: v };
      return map;
    }, {});
  };

  const [spaceInvites, setSpaceInvites] = useState(loadInvites());

  useEffect(() => {
    setSpaceInvites(loadInvites());
  }, [htmlProps.show]);

  useEffect(() => {
    setSpaceInvites((map) => {
      invites.spaceInvites.forEach((v) => {
        map[v.id] = map[v.id] ?? { state: 'open', invite: v };
      });
      return { ...map };
    });
  }, [invites]);

  const changeStateOfInvite = (inviteId: number, state: InviteState) => {
    setSpaceInvites((map) => {
      map[inviteId].state = state;
      return { ...map };
    });
  };

  const respondToInvite = async (inviteId: number, accepted: boolean) => {
    // Max spaces reached
    const numAllowedSpaces = getPersonalSubscriptionForTier(user.subscription).restrictions.spaces;
    if (spaces.length >= numAllowedSpaces && accepted) {
      setConfirmData({
        headerTextKey: 'BETA_PHASE_SPACE_LIMIT_TITLE',
        bodyTextKey: 'BETA_PHASE_SPACE_LIMIT_DESCRIPTION',
        confirmTextKey: 'SHARED_TEXT_OKAY',
        withoutCancel: true,
      });
      return;
    }
    changeStateOfInvite(inviteId, 'loading');
    try {
      await actions.respondToInvite({ inviteId, accepted });
      changeStateOfInvite(inviteId, accepted ? 'accepted' : 'declined');
      // actions.fetchCrews();
      actions.fetchSpaces();
    } catch (e) {
      changeStateOfInvite(inviteId, 'open');
    }
  };

  return (
    <OverlayCard
      titleKey="PROFILE_INVITES_TITLE"
      subTitleKey="PROFILE_SPACE_INVITES_TITLE"
      className={clsx(className)}
      width={width}
      {...htmlProps}
    >
      <div className="flex flex-col self-stretch mb-10 space-y-2">
        {Object.entries(spaceInvites).map(([id, entry]) => {
          const inviteId = parseInt(id);
          return (
            <InviteListEntry
              key={inviteId}
              invite={entry.invite}
              state={entry.state}
              onDecline={() => respondToInvite(inviteId, false)}
              onAccept={() => respondToInvite(inviteId, true)}
            />
          );
        })}
      </div>
      <FormButtons onSubmit={htmlProps.onClose} />
    </OverlayCard>
  );
};

export default InvitationScreen;
