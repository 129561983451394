import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUHHistory } from 'src/views/hooks/navigation/useUHHistory';
import UHText from 'src/views/components/core/text/UHText';
import UHImage from 'src/views/components/core/image/UHImage';
import Center from 'src/views/components/core/transform/Center';
import LandingSectionTitle from '../Landing/components/LandingSectionTitle';
import FadeInUpScroll from 'src/views/components/animations/FadeInUpScroll';
import UHButton from 'src/views/components/core/buttons/UHButton';
import { UHAnalytics } from 'src/services/analytics/analyticEventsService';
import { allPages } from 'src/services/pages/pageService';
import { openLink } from 'src/services/url/linkService';
import { calendlyLink } from '../Landing/config/config';
import { actions, useTypedSelector } from 'src/store/store';
import UHTextEditor from 'src/views/components/core/text/editor/UHTextEditor';

const ArticleScreen: React.FC = () => {
  const history = useUHHistory();
  const blogState = useTypedSelector((state) => state.blog);
  const loaded = blogState.loadingState === 'loaded';

  useEffect(() => {
    if (!loaded) actions.fetchBlog();
  }, []);

  const backAction = () => {
    history.pushPage('blog');
  };

  const onSignup = () => {
    UHAnalytics.goToSignUp();
    history.navigate(allPages.auth.url, { state: { signUp: true } });
  };

  const onBookADemo = () => {
    UHAnalytics.bookADemo();
    openLink(calendlyLink, history, true);
  };

  const { articleId } = useParams();
  const article = blogState.blogEntries.find((e) => e.slug === articleId);

  return (
    <main className="bg-background">
      {article && (
        <article className="page-width relative items-center py-12">
          <UHText textKey="SHARED_BUTTON_BACK" onClick={backAction} className="text-primary-300 pb-4" />
          <div className="max-w-3xl">
            {article.image && <UHImage src={article.image} className="w-full h-full rounded-lg mb-8" />}
            <UHText className="pb-4" variant="title-2xl" text={article.title} tag="h1" />
            <UHText
              text={
                new Date(article.publishedAt).toLocaleDateString('de-DE', { dateStyle: 'medium' }) +
                ' | ' +
                article.author
              }
              className="text-neutral-400 pb-8"
            />
            <UHTextEditor initialState={article.content} />
          </div>
        </article>
      )}
      {/* CTA section */}
      <Center col className="py-24 bg-primary-200">
        <div className="page-width flex flex-col items-center">
          <LandingSectionTitle textKey="WEBSITE.LANDING_CTA2_TITLE" subtitleKey="WEBSITE.LANDING_CTA2_DESCRIPTION" />
          <div className="flex flex-col w-max space-y-4">
            <FadeInUpScroll>
              <UHButton
                className="mb-4 w-full transform scale-125 sm:mb-0 sm:scale-100"
                titleKey="WEBSITE.SHARED_BUTTON_CREATE_ACCOUNT"
                onClick={onSignup}
              />
            </FadeInUpScroll>
            <FadeInUpScroll>
              <UHButton
                variant="secondary"
                className="w-full transform scale-125 sm:scale-100"
                titleKey="WEBSITE.SHARED_BUTTON_BOOK_A_DEMO"
                onClick={onBookADemo}
              />
            </FadeInUpScroll>
          </div>
        </div>
      </Center>
    </main>
  );
};

export default ArticleScreen;
