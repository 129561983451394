import { Crew } from 'src/types/crews/CrewType';
import { Stripe } from 'stripe';
import { UserSettings } from 'src/types/users/userType';
import { UHAPI } from '.';
import { CreateBillingPortalSessionType } from 'src/types/subscriptions/createBillingPortalSession';
import { UserSubscription } from 'src/types/subscriptions/downgradeTier';

export const updatePersonalSubscription = async (tier: string): Promise<UserSettings> => {
  return UHAPI.patch({ path: '/subscriptions', body: { tier } });
};

export const updateCrewSubscription = async (crewId: number, tier: string): Promise<Crew> => {
  return UHAPI.patch({ path: `/subscriptions/${crewId}`, body: { tier } });
};

export const createCheckoutSession = async (subscriptionOptions: {
  tier: string;
  period: string;
  successUrl: string;
  cancelUrl: string;
}): Promise<{ session: Stripe.Checkout.Session }> => {
  return UHAPI.post({ path: `/create-checkout-session`, body: subscriptionOptions });
};

export const createPortalSession = async (
  createBillingPortalSession: CreateBillingPortalSessionType,
): Promise<{ url: string }> => {
  return UHAPI.post({ path: `/create-billing-portal-session`, body: createBillingPortalSession });
};

export const updateSubscription = async (updateSubscription: {
  option: 'RENEW' | 'CANCEL';
}): Promise<UserSubscription[]> => {
  return UHAPI.patch({ path: `/subscriptions`, body: updateSubscription });
};

export const downgradeTier = async (tierSelection: { tier: string; period: string }): Promise<UserSubscription[]> => {
  return UHAPI.post({ path: `/downgrade-subscription-tier`, body: tierSelection });
};

export const cancelDowngrade = async (): Promise<UserSubscription[]> => {
  return UHAPI.patch({ path: `/cancel-downgrade` });
};

export const getSubscriptions = async (): Promise<UserSubscription[]> => {
  return UHAPI.get({ path: `/subscriptions` });
};

export const getPaymentMethods = async (): Promise<Stripe.PaymentMethod[]> => {
  return UHAPI.get({ path: `/payment-methods` });
};

export const getManagePaymentsUrl = async (redirectUrl: string): Promise<{ url: string }> => {
  return UHAPI.patch({ path: `/payment-methods`, body: { redirectUrl } });
};
