import { UHAPI } from '.';
import { BlogEntry } from 'src/types/blog/blogTypes';
import { parseDate } from '../date/dateService';
import { isBefore } from 'date-fns';

export const fetchBlogEntries = async (): Promise<BlogEntry[]> => {
  const blogEntries = await UHAPI.get<BlogEntry[]>({ path: `/blog` });
  blogEntries.sort((e1, e2) =>
    isBefore(parseDate(e1.publishedAt) ?? new Date(), parseDate(e2.publishedAt) ?? new Date()) ? 1 : -1,
  );
  return blogEntries;
};
