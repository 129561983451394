import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import FadeInUpScroll from 'src/views/components/animations/FadeInUpScroll';
import UHText from 'src/views/components/core/text/UHText';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  text?: string;
  textKey?: DictionaryKeyType;
  subtitle?: string;
  subtitleKey?: DictionaryKeyType;
};

const LandingSectionTitle: React.FC<Props> = ({ text, textKey, subtitle, subtitleKey, className, ...htmlProps }) => {
  const localizedText = useLocalizedValue(text, textKey);
  const localizedSubtitle = useLocalizedValue(subtitle, subtitleKey);

  return (
    <div className={clsx(className)} {...htmlProps}>
      <FadeInUpScroll>
        <UHText
          className={clsx('text-center', { 'mb-20': !localizedSubtitle, 'mb-4': localizedSubtitle })}
          variant="title-3xl"
          text={localizedText}
        />
      </FadeInUpScroll>
      {localizedSubtitle && (
        <FadeInUpScroll>
          <UHText variant="body-lg" className="mb-12 text-center text-primary" text={localizedSubtitle} />
        </FadeInUpScroll>
      )}
    </div>
  );
};

export default LandingSectionTitle;
