import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { contactsDifference, getInvitationContacts } from 'src/services/contacts/contactService';
import {
  getCrewSubscriptionForTier,
  getPersonalSubscriptionForTier,
} from 'src/services/subscriptions/subscriptionService';
import { useTypedSelector } from 'src/store/store';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { Contact } from 'src/types/users/Contact';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import OverlayCard, { OverlayCardProps } from '../../core/containers/OverlayCard';
import SelectionDropdown from '../../core/inputs/SelectionDropdown';
import UHTextField from '../../core/inputs/UHTextField';
import TagSelection from '../../core/tagselection/TagSelection';
import FormButtons from '../../form/FormButtons';

type Props = OverlayCardProps & {
  selectedContacts: Contact[];
  setSelectedContacts: (contacts: Contact[]) => void;
  spaceName?: string;
  spaceId?: number;
  crewId?: number;
  isCrewSpace?: boolean;
  crewSubscriptionTier?: string;
  onSubmit?: () => void;
  submitText?: string;
  submitTextKey?: DictionaryKeyType;
};

const InviteUsersView: React.FC<Props> = ({
  selectedContacts,
  setSelectedContacts,
  spaceId,
  crewId,
  isCrewSpace = false,
  crewSubscriptionTier,
  onSubmit,
  submitText,
  submitTextKey,
  width = 'md',
  ...htmlProps
}) => {
  const localizedSubmitText = useLocalizedValue(submitText, submitTextKey);
  const [searchInput, setSearchInput] = useState('');
  const [inputFocused, setInputFocused] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const user = useTypedSelector((state) => state.user.user);
  const spaces = useTypedSelector((state) => state.spaces.spaces) ?? [];
  const crews = useTypedSelector((state) => state.crews.crews) ?? [];
  const crew = crews.find((c) => c.id === crewId);
  const space = spaces.find((s) => s.id === spaceId);

  const finalContacts = getInvitationContacts(
    spaces,
    crews,
    isCrewSpace,
    space,
    crew,
    selectedContacts.map((c) => c.email),
    searchInput,
    user.email,
  );
  const finalUnselectedContacts = contactsDifference(finalContacts, selectedContacts);

  useEffect(() => {
    if (inputFocused && searchInput.length > 0 && finalContacts.length > 0) {
      setDropdownOpen(true);
    }
  }, [searchInput, inputFocused, finalContacts]);

  // Current user count
  let currentUserCount = 0;
  if (space) currentUserCount = space.members.length + space.invites.length;
  else if (crew && !isCrewSpace) currentUserCount = crew.members.length + crew.invites.length;
  // New personal space: Count my user
  else if (!space && !crew) currentUserCount = 1;

  // Selected user count
  const selectedUserCount = selectedContacts.length;

  // Max user count
  let maxUserCount = 0;
  if (isCrewSpace && crew) maxUserCount = getCrewSubscriptionForTier(crew.subscription).restrictions.spaceMembers;
  else if (crew || crewSubscriptionTier) maxUserCount = -1;
  else maxUserCount = getPersonalSubscriptionForTier(user.subscription).restrictions.spaceMembers;

  // Allow further selection
  const [showLimitMessage, setShowLimitMessage] = useState(false);
  const allowSelection = maxUserCount === -1 || currentUserCount + selectedUserCount < maxUserCount;
  useEffect(() => {
    if (allowSelection) setShowLimitMessage(false);
  }, [allowSelection]);

  const onSelectedContact = (contact: Contact) => {
    if (selectedContacts.find((c) => c.email === contact.email) !== undefined || !allowSelection) return;
    setSelectedContacts([...selectedContacts, contact]);
    // Clear textfield after selection
    setSearchInput('');
    setDropdownOpen(false);
  };

  const onDeselectedContact = (contact: Contact) => {
    setSelectedContacts(selectedContacts.filter((c) => c.email !== contact.email));
  };

  const onEnter = () => {
    const contact = finalUnselectedContacts[0];
    if (contact) {
      onSelectedContact(contact);
    }
  };

  return (
    <OverlayCard className="min-h-120" width={width} titleKey="SPACE_CREATE_LABEL_INVITE_USERS" {...htmlProps}>
      {/* Search field */}
      <div className="relative flex flex-col self-stretch mb-4">
        <div
          onClick={() => {
            if (!allowSelection) setShowLimitMessage(true);
          }}
        >
          <UHTextField
            value={searchInput}
            onChangeText={setSearchInput}
            className={clsx('self-stretch', { 'mb-5': !allowSelection && showLimitMessage })}
            labelKey="PROFILE_SETTINGS_PLACEHOLDER_SEARCH_INVITE"
            onEnter={onEnter}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            mb="none"
            disabled={!allowSelection}
            errorTextKey={!allowSelection && showLimitMessage ? 'BETA_PHASE_SPACE_MEMBER_LIMIT_DESCRIPTION' : undefined}
          />
        </div>
        <SelectionDropdown
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
          options={
            searchInput.length > 0
              ? finalUnselectedContacts.map((c) => {
                  return {
                    text: c.displayName ? `${c.displayName} (${c.email})` : c.email,
                    onClick: () => onSelectedContact(c),
                  };
                })
              : []
          }
        />
      </div>
      {/* Tag selection */}
      <TagSelection
        className="self-start mb-10 max-w-full min-h-20"
        tags={selectedContacts.map((c) => {
          return {
            text: c.displayName ? `${c.displayName} (${c.email})` : c.email,
            onRemove: () => onDeselectedContact(c),
          };
        })}
      />
      {/* Spacing */}
      <div className="flex-1" />
      {/* Form buttons */}
      <FormButtons
        onCancel={htmlProps.onBack ?? htmlProps.onClose}
        onSubmit={onSubmit}
        submitText={localizedSubmitText}
        loading={htmlProps.loading}
      />
    </OverlayCard>
  );
};

export default InviteUsersView;
