import clsx from 'clsx';
import React from 'react';
import UHText, { TextVariant } from './UHText';

export type RichTextElement = {
  type: 'text' | 'gap';
  variant?: string;
  content: string;
  size?: number;
};

export type RichText = RichTextElement[];

type Props = React.HTMLAttributes<HTMLDivElement> & {
  content: RichText;
  autoparagraph?: boolean;
};

const UHRichText: React.FC<Props> = ({ content, autoparagraph, className, ...htmlProps }) => {
  return (
    <div className={clsx(className)} {...htmlProps}>
      {content.map((e, index) => {
        let element: JSX.Element | undefined;
        if (e.type === 'gap') element = <div style={{ height: (e.size ?? 0) * 4 }} key={index} />;
        else if (e.type === 'text')
          element = (
            <UHText
              variant={e.variant as TextVariant}
              text={e.content}
              key={index}
              className={autoparagraph ? 'pb-6' : ''}
            />
          );
        return element;
      })}
    </div>
  );
};

export default UHRichText;
