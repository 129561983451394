export enum ConferenceAction {
  ENTER_CONFERENCE = 'ENTER_CONFERENCE',
  EXIT_CONFERENCE = 'EXIT_CONFERENCE',
  STATUS_CHANGE = 'STATUS_CHANGE',
  JOIN_CONVERSATION = 'JOIN_CONVERSATION',
  START_CONVERSATION = 'START_CONVERSATION',
  LEAVE_CONVERSATION = 'LEAVE_CONVERSATION',
  END_CONVERSATION = 'END_CONVERSATION',
  ADD_TO_CONVERSATION = 'ADD_TO_CONVERSATION',
}
