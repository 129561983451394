import clsx from 'clsx';
import React, { useState } from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import UHText from '../text/UHText';

export type TextFieldProps = React.HTMLAttributes<HTMLInputElement> & {
  /** Identifier of the element */
  name?: string;
  /** Textfield's label */
  label?: string;
  labelKey?: DictionaryKeyType;
  errorText?: string;
  errorTextKey?: DictionaryKeyType;
  /** Initial Value */
  initialValue?: string;
  /** Textfield's type */
  type?: React.HTMLInputTypeAttribute;
  autoComplete?: string;
  /** Controlled value */
  value?: string;
  disabled?: boolean;
  /** Convenience function called on input change */
  onChangeText?: (text: string) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onEnter?: () => void;
  /** Bottom margin */
  mb?: 'default' | 'none';
  /** Custom Styles */
  className?: string;
};

const UHTextField: React.FC<TextFieldProps> = ({
  name,
  label,
  labelKey,
  errorText,
  errorTextKey,
  type,
  autoComplete,
  value,
  disabled,
  initialValue,
  onChangeText,
  onChange,
  onBlur,
  onFocus,
  onEnter,
  mb = 'default',
  className,
  ...inputProps
}) => {
  const localizedLabel = useLocalizedValue(label, labelKey);
  const localizedError = useLocalizedValue(errorText, errorTextKey);
  const [isFocused, setIsFocused] = useState(false);
  const labelOnTop = isFocused || value !== '';

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onEnter?.();
    }
  };

  const onLocalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    onChangeText?.(e.target.value);
  };

  const onLocalFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const onLocalBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  return (
    <div className={clsx('relative', { 'mb-10': mb === 'default' }, className)}>
      <input
        id={name}
        type={type}
        autoComplete={autoComplete}
        value={value}
        defaultValue={initialValue}
        onChange={onLocalChange}
        onFocus={onLocalFocus}
        onBlur={onLocalBlur}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        className="p-3 pt-7 w-full h-14 border border-neutral-300 focus:border-primary rounded-xl outline-none"
        {...inputProps}
      />
      {/* Floating label */}
      <UHText
        className={clsx(
          'line-clamp-1 absolute left-4 right-4 top-4 h-full text-neutral whitespace-nowrap pointer-events-none transform origin-left transition-all duration-200 ease-in-out',
          { 'opacity-75 -translate-y-4 -translate-x-1 scale-75': labelOnTop },
        )}
        variant="body"
        text={localizedLabel}
      />
      {/* Error label */}
      <UHText
        variant="body-xs"
        className="line-clamp-2 wrap absolute left-4 right-0 top-full pt-0.5 text-error-600"
        text={localizedError}
      />
    </div>
  );
};

export default UHTextField;
