import { pathToRegexp } from 'path-to-regexp';

export type PageAccessibility = 'public' | 'auth' | 'both';
export type Page = { url: string; access: PageAccessibility };

const landing: Page = { url: '/', access: 'public' };
const verification: Page = { url: '/verification', access: 'public' };
const verificationSuccess: Page = { url: '/verification-success', access: 'public' };
const verificationError: Page = { url: '/verification-error', access: 'public' };
const privacyPolicy: Page = { url: '/privacypolicy', access: 'public' };
const blog: Page = { url: '/blog', access: 'public' };
const article: Page = { url: '/blog/:articleId', access: 'public' };
const apiInfo: Page = { url: '/api', access: 'public' };

const auth: Page = { url: '/auth', access: 'public' };
const lobby: Page = { url: '/lobby', access: 'public' };
const spacesOverview: Page = { url: '/spaces', access: 'auth' };
const spaceDetail: Page = { url: '/spaces/:spaceId', access: 'auth' };
const userMediaSelection: Page = { url: '/spaces/:spaceId/enter', access: 'both' };
const conference: Page = { url: '/spaces/:spaceId/active', access: 'both' };
const subscriptions: Page = { url: '/subscriptions', access: 'auth' };
const subscriptionsCompleted: Page = { url: '/subscriptions/completed', access: 'auth' };
const settings: Page = { url: '/settings', access: 'auth' };
export const allPages = {
  landing,
  verification,
  verificationSuccess,
  verificationError,
  privacyPolicy,
  auth,
  spacesOverview,
  userMediaSelection,
  conference,
  spaceDetail,
  subscriptions,
  subscriptionsCompleted,
  settings,
  lobby,
  blog,
  article,
  apiInfo,
};

export type PagesType = typeof allPages;
export type Pages = keyof typeof allPages;

export const pagesMatchForAuth = Object.values(allPages).filter((p) => p.access === 'auth');

export const pagesMatchForBoth = Object.values(allPages).filter((p) => p.access === 'both');

export const pagesMatchStreams = [allPages.conference, allPages.userMediaSelection];

export const matchPathPages = (path: string, pages: Page[]): boolean => {
  return pages.some((p) => !!path.match(pathToRegexp(p.url)));
};
