import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import OverlayCard, { OverlayCardProps } from 'src/views/components/core/containers/OverlayCard';
import UHButton from 'src/views/components/core/buttons/UHButton';
import Center from 'src/views/components/core/transform/Center';
import getCroppedImg, { getBase64FromUrl } from 'src/services/canvas/image';
import { actions } from 'src/store/store';
import UHText from 'src/views/components/core/text/UHText';
import ReactImageUploading, { ImageListType, ImageType } from 'react-images-uploading';
import clsx from 'clsx';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import UHSlider from 'src/views/components/core/inputs/UHSlider';
import FormButtons from 'src/views/components/form/FormButtons';

type Props = OverlayCardProps & {
  /** The image can already be provided. In that case only the cropping view is shown. */
  image?: ImageType;
  uploadType: 'avatar' | 'spaceImage';
  spaceId?: number;
  onFinish?: () => void;
};

type CroppedAreaPixels = { x: number; y: number; width: number; height: number };

const initialState = {
  image: undefined,
  crop: { x: 0, y: 0 },
  zoom: 1,
  croppedAreaPixels: null,
};

const MAX_ZOOM = 4;

const UploadImageForm: React.FC<Props> = ({ width = 'sm', uploadType, image, spaceId, onFinish, ...htmlProps }) => {
  const [selectedUploadImage, setSelectedUploadImage] = useState<ImageType | undefined>(undefined);
  const [crop, setCrop] = useState(initialState.crop);
  const [zoom, setZoom] = useState(initialState.zoom);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CroppedAreaPixels | null>(initialState.croppedAreaPixels);
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    if (!selectedUploadImage?.dataURL || !croppedAreaPixels) return onClose();
    try {
      setLoading(true);
      const croppedImage = await getCroppedImg(selectedUploadImage.dataURL, croppedAreaPixels, 0);
      const imageFile = await getBase64FromUrl(croppedImage);
      if (uploadType === 'avatar') await actions.updateAvatar(imageFile);
      if (uploadType === 'spaceImage' && spaceId)
        await actions.updateSpaceImage({ id: spaceId, imageBinary: imageFile });
      onFinish?.();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      return onClose();
    }
  }, [selectedUploadImage, croppedAreaPixels]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCropComplete = useCallback((_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onChangeImages = (imageList: ImageListType) => {
    if (imageList.length > 0) setSelectedUploadImage(imageList[0]);
    else setSelectedUploadImage(undefined);
  };

  const onZoomChange = (v: number | number[]) => {
    if (Array.isArray(v)) setZoom(v.pop() ?? 1);
    else setZoom(v);
  };

  const resetState = () => {
    setSelectedUploadImage(initialState.image);
    setCrop(initialState.crop);
    setZoom(initialState.zoom);
    setCroppedAreaPixels(initialState.croppedAreaPixels);
  };

  const onClose = () => {
    resetState();
    htmlProps.onClose?.();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (image) setSelectedUploadImage(image);
    }, 100);
    return () => clearTimeout(timeout);
  }, [image]);

  return (
    <OverlayCard
      loading={loading}
      width={width}
      titleKey={uploadType === 'avatar' ? 'PROFILE_SETTINGS_TITLE_CHANGE_AVATAR' : 'SPACE_IMAGES_TITLE_CHANGE_IMAGE'}
      {...htmlProps}
      onClose={onClose}
    >
      {/* Image upload view */}
      {!selectedUploadImage && uploadType !== 'spaceImage' && (
        <ReactImageUploading value={[selectedUploadImage as never]} onChange={onChangeImages}>
          {({ onImageUpload, isDragging, dragProps }) => {
            return (
              <Center col className="self-stretch">
                {/* Drop area */}
                <Center
                  col
                  className={clsx('self-stretch p-8 bg-neutral-200 rounded-lg cursor-pointer space-y-1', {
                    'ring-2 ring-primary': isDragging,
                  })}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <UHText
                    className="text-center pointer-events-none"
                    textKey="SHARED_TEXT_DRAG_AND_DROP_UPLOAD_IMAGE"
                  />
                  <UHText
                    className="text-center text-neutral pointer-events-none"
                    variant="body-xs"
                    textKey="SHARED_TEXT_IMAGE_UPLOAD_RESTRICTIONS"
                  />
                </Center>
                {/* Or */}
                <UHText className="my-4 text-neutral" variant="body-xs" textKey="SHARED_TEXT_OR" />
                {/* Upload button */}
                <UHButton
                  height="md"
                  variant="secondary"
                  titleKey="SHARED_BUTTON_UPLOAD_IMAGE"
                  onClick={onImageUpload}
                />
                <UHText className="mt-12 text-primary" textKey="SHARED_BUTTON_CANCEL" onClick={htmlProps.onClose} />
              </Center>
            );
          }}
        </ReactImageUploading>
      )}
      {/* Image crop view */}
      {(selectedUploadImage || uploadType === 'spaceImage') && (
        <div key={!!selectedUploadImage ? 'true' : 'false'} className="flex flex-col w-full">
          <div className="transitionfix relative w-full h-64 rounded-xl overflow-hidden">
            <Cropper
              image={(selectedUploadImage ?? image)?.dataURL}
              crop={crop}
              zoom={zoom}
              aspect={uploadType === 'avatar' ? 1 : 9 / 5}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape={uploadType === 'avatar' ? 'round' : 'rect'}
              classes={{
                containerClassName: 'w-full h-full bg-black rounded-xl',
              }}
              maxZoom={MAX_ZOOM}
            />
          </div>
          <div className="flex items-center mb-8 mt-4 mx-8 space-x-4">
            <UHIcon className="text-neutral-300" icon="image" />
            <UHSlider className="flex-1" value={zoom} minValue={1} maxValue={MAX_ZOOM} onChangeValue={onZoomChange} />
            <UHIcon className="text-neutral-300 text-2xl" icon="image" />
          </div>
          <FormButtons
            submitTextKey="SHARED_BUTTON_UPLOAD_IMAGE"
            onSubmit={onSubmit}
            onCancel={image ? onClose : resetState}
            loading={loading}
          />
        </div>
      )}
    </OverlayCard>
  );
};

export default UploadImageForm;
