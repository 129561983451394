export enum ErrorMessage {
  RTPCAPABILITIES_ERROR = 'Failed to get rtpCapabilities for room',
  TRANSPORT_ERROR = 'Failed to create Web RTC Transport',
  CONSUMER_ERROR = 'Failed to create consumer',
  CLOSE_PRODUCER_ERROR = 'Failed to close producer',
  GET_PRODUCERS_ERROR = 'Could not retrieve producers',
  ENTER_SPACE_ERROR = 'Could not enter space',
  PRODUCE_ERROR = 'Could not produce',
  CONFERENCE_TRANSFERED = 'Conference is transfered to another server',
}
