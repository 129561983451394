import React, { useEffect, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { lerp } from 'src/services/math/utils';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  direction?: 'up' | 'left';
  initialFade?: number;
  fadeTarget?: number;
  delay?: number;
  distance?: number;
  speed?: 'default' | 'slow' | 'fast';
}

const Fade: React.FC<Props> = ({
  delay = 0,
  direction = 'up',
  initialFade = 0,
  fadeTarget = 1,
  distance = -100,
  speed = 'default',
  style,
  children,
  ...htmlProps
}) => {
  let springConfig = { mass: 1, tension: 400, friction: 100 };
  if (speed === 'slow') springConfig = { mass: 1, tension: 200, friction: 100 };
  if (speed === 'fast') springConfig = { mass: 1, tension: 800, friction: 100 };
  const [targetOpacity, setTargetOpacity] = useState(initialFade);
  const spring = useSpring({
    transform:
      direction === 'up'
        ? `translateY(${lerp(-distance, 0, targetOpacity)}px)`
        : `translateX(${lerp(-distance, 0, targetOpacity)}px)`,
    opacity: targetOpacity,
    config: springConfig,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTargetOpacity(fadeTarget);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [fadeTarget]);

  return (
    <animated.div
      {...(htmlProps as Record<string, unknown>)}
      style={{ ...style, ...(spring as Record<string, unknown>) }}
    >
      {children}
    </animated.div>
  );
};

export default Fade;
