import { useState, useEffect } from 'react';
import { refreshStaticData } from 'src/services/staticData/staticDataService';
import { useTypedSelector, actions } from 'src/store/store';

export const useTryAutologin = (): { isTryingAutologin: boolean; isAuthenticated: boolean } => {
  const isAuthenticated = !!useTypedSelector((state) => state.user.cognitoUser);
  const [isTryingAutologin, setIsTryingAutologin] = useState(!isAuthenticated);

  // Try Auto-Login
  useEffect(() => {
    const tryAutoLogin = async () => {
      try {
        await refreshStaticData();
        await actions.autoLoginInit();
      } catch (e) {
        console.log(e);
      }
      setIsTryingAutologin(false);
    };
    if (!isAuthenticated) tryAutoLogin();
  }, []);

  return { isTryingAutologin, isAuthenticated };
};
