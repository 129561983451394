import React from 'react';
import { getSvgElement, SvgIconType } from 'src/assets/icons/svg-icons';

type Props = {
  /** One of the defined icon types */
  icon: SvgIconType;
} & React.SVGProps<SVGSVGElement>;

const UHSvgIcon: React.FC<Props> = ({ icon, ...props }) => {
  return getSvgElement(icon)({ ...props });
};

export default UHSvgIcon;
