import clsx from 'clsx';
import React from 'react';
import { SpaceMember } from 'src/types/spaces/SpaceType';
import MemberEntry from './MemberEntry';
import { useTypedSelector } from 'src/store/store';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import UHText from '../../core/text/UHText';
import UHButton from '../../core/buttons/UHButton';

export type MemberListEntryType = 'Space' | 'CrewSpace' | 'Crew';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  members: SpaceMember[];
  onHeaderAction?: () => void;
  headerTitle?: string;
  headerTitleKey?: DictionaryKeyType;
  headerActionTitle?: string;
  headerActionTitleKey?: DictionaryKeyType;
  onMemberAction: (member: SpaceMember) => void;
  onRemoveMemberAction: (member: SpaceMember) => void;
  isEditable: boolean;
  type: MemberListEntryType;
};

const MemberList: React.FC<Props> = ({
  members,
  onHeaderAction,
  headerTitle,
  headerTitleKey,
  headerActionTitle,
  headerActionTitleKey,
  onMemberAction,
  onRemoveMemberAction,
  isEditable,
  type,
  className,
  ...htmlProps
}) => {
  const myUser = useTypedSelector((state) => state.user.user);
  const localizedHeaderTitle = useLocalizedValue(headerTitle, headerTitleKey);
  const localizedHeaderActionTitle = useLocalizedValue(headerActionTitle, headerActionTitleKey);

  const sortedMembers = [...members].sort((m1, m2) => {
    if (m1.role === 'owner') return -1;
    if (m2.role === 'owner') return 1;
    if (m1.role === 'admin' && m2.role !== 'admin') return -1;
    if (m1.role !== 'admin' && m2.role === 'admin') return 1;
    if (m1.role === 'invited' && m2.role !== 'invited') return 1;
    if (m1.role !== 'invited' && m2.role === 'invited') return -1;
    return (m1.displayName ?? m1.email) < (m2.displayName ?? m2.email) ? -1 : 1;
  });

  return (
    <div className={clsx(className)} {...htmlProps}>
      <div className="flex items-center justify-between mb-8">
        <UHText className="mb-3 text-primary" variant="title" text={localizedHeaderTitle} />
        {onHeaderAction && (
          <UHButton
            height="sm"
            width="md"
            variant="secondary"
            icon="plus"
            iconClass="text-base"
            title={localizedHeaderActionTitle}
            onClick={onHeaderAction}
          />
        )}
      </div>
      {/* Member list */}
      <div className="space-y-2">
        {sortedMembers.map((member) => {
          return (
            <MemberEntry
              isEditable={isEditable && member.userId !== myUser.id}
              type={type}
              onClick={isEditable ? () => onMemberAction(member) : undefined}
              onRemove={() => onRemoveMemberAction(member)}
              key={member.email}
              member={member}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MemberList;
