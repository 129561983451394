import clsx from 'clsx';
import React from 'react';
import { DictionaryKeyType } from 'src/types/localization/dictionaryType';
import FadeInUpScroll from 'src/views/components/animations/FadeInUpScroll';
import UHText from 'src/views/components/core/text/UHText';
import Center from 'src/views/components/core/transform/Center';
import { useLocalizedValue } from 'src/views/hooks/useLocalizedString';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  text?: string;
  textKey?: DictionaryKeyType;
};

const ClaimCard: React.FC<Props> = ({ text, textKey, className, ...htmlProps }) => {
  const localizedText = useLocalizedValue(text, textKey);

  return (
    <FadeInUpScroll className={clsx(className)} {...htmlProps}>
      <Center className="px-4 py-3 w-full bg-primary-200 rounded-xl" style={{ minWidth: 400 }}>
        <UHText variant="body-lg" className="text-center text-primary-700" text={localizedText} />
      </Center>
    </FadeInUpScroll>
  );
};

export default ClaimCard;
