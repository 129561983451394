import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BubbleRenderData, SessionRenderData } from 'src/types/conference/render/ConferenceViewTypes';
import useLayoutSize from 'src/views/hooks/useLayoutSize';
import {
  canDragUser,
  generateBubbles,
  tryJoining,
  updateBubbles,
} from 'src/services/conferenceView/conferenceViewService';
import { correctBubbles, correctBubbleScales } from 'src/services/conferenceView/rectangularContainerService';
import { Vector } from 'src/services/math/types';
import { User } from 'src/types/conference/UserType';
import { vibrate } from 'src/services/haptics/hapticService';
import { ConferenceElement } from './ConferenceElement';
import { UserBubbleDropZone } from './UserBubbleDropZone';
import clsx from 'clsx';
import UserBubble from './UserBubble';
import UHIcon from 'src/views/components/core/icon/UHIcon';
import Absolute from 'src/views/components/core/transform/Absolute';
import Scale from 'src/views/components/animations/Scale';
import { useDrag } from 'react-use-gesture';
import { FullGestureState } from 'react-use-gesture/dist/types';
import DisplayBubble from './DisplayBubble';
import { isSafari } from 'react-device-detect';
import { useDevicePixelRatio } from 'src/views/hooks/useDevicePixelRatio';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  sessionRenderData: SessionRenderData;
  detailViewOpen: boolean;
  selectedBubbleDetail: BubbleRenderData | undefined;
  onLeave: () => void;
  onJoinUser: (u1: User, u2: User) => void;
  onOpenBubbleDetail: (bubble?: BubbleRenderData) => void;
};

export const ConferenceContainer: React.FC<Props> = ({
  sessionRenderData,
  detailViewOpen,
  selectedBubbleDetail,
  onLeave,
  onJoinUser,
  onOpenBubbleDetail,
  className,
  ...htmlProps
}) => {
  /** Dimension */
  const [containerRef, dimensions] = useLayoutSize();
  if (dimensions.x === 0 && dimensions.y === 0) {
    dimensions.x = 1;
    dimensions.y = 1;
  }
  /** Bubbles */
  // eslint-disable-next-line prefer-const
  let [bubbles, setBubbles] = useState(generateBubbles(sessionRenderData, dimensions));
  /** Local SessionRenderData */
  const localSessionRenderData = useRef(sessionRenderData);

  /** Update the positions of the current bubbles to fit in the container */
  const updateCurrentBubbles = () => {
    setBubbles((oldBubbles) => correctBubbles(oldBubbles, dimensions));
  };

  // Updated bubbles when dimension changes
  useLayoutEffect(() => {
    if (dimensions.x === 0 || dimensions.y === 0) return;
    setBubbles((oldBubbles) => correctBubbleScales(oldBubbles, dimensions));
    updateCurrentBubbles();
  }, [dimensions]);

  // Updated bubbles from changed session render data
  useLayoutEffect(() => {
    setBubbles((oldBubbles) => {
      const [newBubbles, anyChanges] = updateBubbles(
        oldBubbles,
        localSessionRenderData.current,
        sessionRenderData,
        dimensions,
      );
      localSessionRenderData.current = { ...sessionRenderData };
      let scaledBubbles = correctBubbleScales(newBubbles, dimensions);
      if (anyChanges) scaledBubbles = correctBubbles(scaledBubbles, dimensions);
      return scaledBubbles;
    });
  }, [sessionRenderData, dimensions]);

  const myUserBubble = bubbles.find((b) => b.content.isMe && b.type === 'user');
  const isMyUserInConversation = myUserBubble?.conversationId !== null;
  const myConversationDisplayBubble = bubbles.find(
    (b) => isMyUserInConversation && b.conversationId === myUserBubble?.conversationId && b.type === 'display',
  );
  const oldDisplayBubble = useRef<BubbleRenderData | undefined>(undefined);

  // Open bubble detail if a new one appeared
  useEffect(() => {
    const availableDetailView = !!myConversationDisplayBubble;
    // Close if there is none
    if (detailViewOpen && !availableDetailView) {
      onOpenBubbleDetail(undefined);
    }
    // Close if new one is my display
    else if (
      oldDisplayBubble.current?.content.id !== myConversationDisplayBubble?.content.id &&
      myConversationDisplayBubble?.content.id === myUserBubble?.content.id
    ) {
      onOpenBubbleDetail(undefined);
    }
    // Switch to different one
    else if (
      oldDisplayBubble.current?.content.id !== myConversationDisplayBubble?.content.id &&
      myConversationDisplayBubble?.content.id !== myUserBubble?.content.id
    ) {
      onOpenBubbleDetail(myConversationDisplayBubble);
    }
    // Open new one
    else if (
      !detailViewOpen &&
      !oldDisplayBubble.current &&
      availableDetailView &&
      myConversationDisplayBubble?.content.id !== myUserBubble?.content.id
    ) {
      onOpenBubbleDetail(myConversationDisplayBubble);
    }
    oldDisplayBubble.current = myConversationDisplayBubble;
  }, [myConversationDisplayBubble, selectedBubbleDetail, detailViewOpen]);

  // Drag and drop

  const [draggingUserState, setDraggingUserState] = useState<BubbleRenderData | null>(null);

  /** Get relative pointer event position in container */
  const relativeNativeEventPosition = (event: React.PointerEvent<Element> | PointerEvent): Vector => {
    const clientRect = containerRef.current?.getBoundingClientRect();
    const distanceFromLeft = event.clientX - (clientRect?.left ?? 0);
    const distanceFromTop = event.clientY - (clientRect?.top ?? 0);
    return new Vector(distanceFromLeft / dimensions.y, distanceFromTop / dimensions.y);
  };

  /** Get user bubble at relative position in container */
  const getUserAtPosition = (position: Vector): BubbleRenderData | null => {
    let closestBubble: BubbleRenderData | null = null;
    let closestDistance = Infinity;
    for (const b of bubbles.filter((v) => v.type === 'user')) {
      const distance = Vector.subtract(b.pos, position).length();
      if (distance < b.radius * b.scale && distance < closestDistance) {
        closestBubble = b;
        closestDistance = distance;
      }
    }
    return closestBubble;
  };

  /** The pan gesture changed between start, cancel, stop */
  const onPanStateChanged = (
    props: Omit<FullGestureState<'drag'>, 'event'> & {
      event: React.PointerEvent<Element> | PointerEvent;
    },
  ) => {
    // Start pan
    if (props.first) {
      props.event.stopPropagation();
      props.event.preventDefault();
      const pos = relativeNativeEventPosition(props.event);
      const bubble = getUserAtPosition(pos);
      if (bubble) setDraggingUserState({ ...bubble, pos });
    }
    // Cancel pan
    else if (props.canceled) setDraggingUserState(null);
    // Stop pan
    else if (props.last) {
      const pos = relativeNativeEventPosition(props.event);
      const bubble = getUserAtPosition(pos);
      if (myUserBubble) tryJoining(myUserBubble, draggingUserState, bubble, onJoinUser);
      setDraggingUserState(null);
    }
  };

  /** Setup drag gesture */
  const bindDrag = useDrag(
    (props) => {
      const pos = relativeNativeEventPosition(props.event);
      setDraggingUserState((oldState) => {
        if (oldState) return { ...oldState, pos };
        return null;
      });
      onPanStateChanged(props);
    },
    { filterTaps: true, capture: true, passive: false, threshold: 10 },
  );

  const onClickedUserBubble = (bubble: BubbleRenderData) => {
    if (myUserBubble) tryJoining(myUserBubble, myUserBubble, bubble, onJoinUser);
  };

  const onClickedDisplayBubble = (bubble: BubbleRenderData) => {
    // Open display bubble if its a display in my conversation
    if (bubble.conversationId === myUserBubble?.conversationId) onOpenBubbleDetail(bubble);
  };

  if (detailViewOpen) bubbles = bubbles.filter((b) => b.conversationId === myConversationDisplayBubble?.conversationId);
  const userBubbles = bubbles.filter((b) => b.type === 'user');
  const displayBubbles = bubbles.filter((b) => b.type === 'display');

  const conversationIds = [...new Set(bubbles.flatMap((b) => (b.conversationId ? [b.conversationId] : [])))];

  // Device pixel ratio
  const devicePixelRatio = useDevicePixelRatio();

  return (
    <div ref={containerRef} className={clsx('relative select-none', className)} {...htmlProps} {...bindDrag()}>
      {/* Conversation backgrounds */}
      {conversationIds.map((conversationId) => {
        const bubblesInConversation = bubbles.filter((b) => b.conversationId === conversationId);
        if (bubblesInConversation.length === 0) return null;
        return (
          <Absolute
            key={conversationId}
            className="mix-blend-darken"
            style={
              conversationId === myUserBubble?.conversationId
                ? { filter: 'saturate(0.5) hue-rotate(290deg)', opacity: isSafari ? 0.4 : 0.75 }
                : { filter: 'saturate(0.5) hue-rotate(290deg)', opacity: isSafari ? 0.2 : 0.25 }
            }
          >
            <Absolute style={{ filter: `brightness(${100 - 12 * devicePixelRatio}%) contrast(10000%)` }}>
              <div className="bg-background absolute -inset-20" />
              <Absolute
                style={{
                  filter: 'blur(40px)',
                  transform: isSafari ? 'translate3d(0, 0, 0)' : undefined,
                }}
              >
                {/* Conversation background of bubble */}
                {!detailViewOpen &&
                  bubblesInConversation.map((b) => {
                    const radius = b.radius * b.scale * 0.93 + Math.min(20 / dimensions.y, 0.018);
                    return (
                      <ConferenceElement
                        key={`c${b.type}${b.content.id}`}
                        position={b.pos}
                        radius={radius}
                        dimensions={dimensions}
                      >
                        <Scale
                          delay={300}
                          speed="fast"
                          className="w-full h-full rounded-full transition-colors duration-1000"
                          style={{ backgroundColor: '#ff00ff' }}
                        />
                      </ConferenceElement>
                    );
                  })}
              </Absolute>
            </Absolute>
          </Absolute>
        );
      })}
      {/* User bubbles */}
      {userBubbles.map((b, index) => {
        const showDropzone = canDragUser(myUserBubble, draggingUserState, b);
        const highlightDropzone =
          showDropzone &&
          !!draggingUserState &&
          Vector.subtract(b.pos, draggingUserState.pos).length() < b.radius * b.scale;
        const pos = !detailViewOpen
          ? b.pos
          : new Vector(dimensions.x / dimensions.y / 2 - (userBubbles.length - 1) / 2 + index, 0.5);
        const radius = !detailViewOpen ? b.radius * b.scale * 0.95 : 0.5;
        return (
          <ConferenceElement key={`${b.type}${b.content.id}`} position={pos} radius={radius} dimensions={dimensions}>
            <UserBubble
              className="w-full h-full"
              onClick={() => onClickedUserBubble(b)}
              user={b.content}
              conversationId={b.conversationId}
              myUserConversationId={myUserBubble?.conversationId}
              size={dimensions.y * b.radius * b.scale * 0.95}
              onlyPlaceHolder={draggingUserState?.content.id === b.content.id}
              myImageOnlyMode={myUserBubble?.content.imageOnlyMode}
              variant={detailViewOpen ? 'plain' : 'default'}
            />
            {/* User drop zone */}
            {showDropzone && <UserBubbleDropZone className="absolute inset-0" highlight={highlightDropzone} />}
          </ConferenceElement>
        );
      })}
      {/* Display bubbles */}
      {!detailViewOpen &&
        displayBubbles.map((b) => {
          return (
            <ConferenceElement
              key={`${b.type}${b.content.id}`}
              position={b.pos}
              radius={b.radius * b.scale * 0.95}
              dimensions={dimensions}
            >
              <DisplayBubble
                className="w-full h-full"
                onClick={() => onClickedDisplayBubble(b)}
                user={b.content}
                conversationId={b.conversationId}
                myUserConversationId={myUserBubble?.conversationId}
                size={dimensions.y * b.radius * b.scale * 0.95}
              />
            </ConferenceElement>
          );
        })}
      {/* Leave Button */}
      {myUserBubble && isMyUserInConversation && !detailViewOpen && (
        <ConferenceElement
          position={myUserBubble.pos}
          radius={myUserBubble.radius * myUserBubble.scale}
          dimensions={dimensions}
        >
          <Absolute className="flex items-center justify-center" size={0} position={{ x: 0.8, y: 0.2 }}>
            <Scale delay={500} speed="fast">
              <UHIcon
                icon="xmark"
                className="absolute w-10 h-10 text-lg bg-neutral-100 rounded-full"
                onClick={() => {
                  onLeave();
                  vibrate('impactMedium');
                }}
              />
            </Scale>
          </Absolute>
        </ConferenceElement>
      )}
      {/* Dragging User */}
      {draggingUserState && (
        <ConferenceElement
          position={draggingUserState.pos}
          radius={draggingUserState.radius * draggingUserState.scale * 1.1}
          dimensions={dimensions}
          immediate
        >
          <UserBubble
            className="w-full h-full shadow-xl opacity-70"
            conversationId={draggingUserState.conversationId}
            user={draggingUserState.content}
            size={dimensions.y * draggingUserState.radius * draggingUserState.scale * 1.1}
            myImageOnlyMode={myUserBubble?.content.imageOnlyMode}
          />
        </ConferenceElement>
      )}
    </div>
  );
};
